import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { Helpers } from '../../../../utils/helpers';
import { availableReports } from '../../ReportingPage/ReportManager/availableReports';
import styles from './ScheduledReportReview.module.css';
import { ReportDeliveryType } from '../../../../utils/constants/enums';

export const ScheduledReportReview = ({
  reportConfig,
  customers,
  transactions,
  setStepCallback,
  allStepsCompleted
}) => {
  if (!allStepsCompleted) {
    return (
      <>
        <div className={styles.reviewNotCompleteHeading}>Missing Required Information</div>
        <div>
          Please review the
          <FontAwesomeIcon size="sm" className={styles.reviewNotCompleteIcon} icon={faCircleDot} />
          marked areas and complete the required sections.
        </div>
      </>
    );
  }

  const reportInfo = availableReports.find(
    (r) => r.id === reportConfig.reportParameters.reportType
  );

  const fullReportName = reportInfo?.name ?? 'Unknown';
  const hasCustomerSelect = reportInfo?.customerSelect ?? true;

  const fullCustomerName =
    customers.find((c) => c.id === reportConfig.reportParameters.customerId)?.name ?? 'Unknown';

  const selectedTransactions =
    transactions?.filter((tx) => {
      return reportConfig.reportParameters.transactionIds.includes(tx.id);
    }) ?? [];

  const transactionCount = reportConfig.reportParameters.transactionIds.length;
  const allTransactions = reportConfig.reportParameters.allTransactions;
  const includeDeleted = reportConfig.reportParameters.includeDeletedTransactions;
  const deliveryText = Helpers.getReportDeliveryTypeAsText(reportConfig.deliveryType);

  let deliveryEmailText = '';
  if (reportConfig.deliveryType === ReportDeliveryType.EMAIL_NOTIFICATION) {
    deliveryEmailText = `Send to: ${reportConfig.deliveryEmail}`;
  }

  const goToStepLinkHandler = (e, step) => {
    e.preventDefault();
    setStepCallback(step);
  };

  //show summary
  return (
    <>
      <div className={styles.reviewHeading}>Review Your Scheduled Report</div>

      <dl className="row">
        <dt className="col-sm-3">
          Name{' '}
          <a
            href="#"
            className="float-sm-end"
            aria-label="Goto report name step"
            onClick={(e) => goToStepLinkHandler(e, 0)}>
            <FontAwesomeIcon size="sm" className="ms-1" icon={faPenToSquare} />
          </a>
        </dt>
        <dd className="col-sm-9">{reportConfig.name}</dd>

        <dt className="col-sm-3">
          Report{' '}
          <a
            href="#"
            className="float-sm-end"
            aria-label="Open report parameters step"
            onClick={(e) => goToStepLinkHandler(e, 1)}>
            <FontAwesomeIcon size="sm" className="ms-1" icon={faPenToSquare} />
          </a>
        </dt>
        <dd className="col-sm-9">{fullReportName}</dd>

        {hasCustomerSelect && (
          <>
            <dt className="col-sm-3">
              Customer{' '}
              <a
                href="#"
                className="float-sm-end"
                aria-label="Open report parameters step"
                onClick={(e) => goToStepLinkHandler(e, 1)}>
                <FontAwesomeIcon size="sm" className="ms-1" icon={faPenToSquare} />
              </a>
            </dt>
            <dd className="col-sm-9">{fullCustomerName}</dd>
            <dt className="col-sm-3">
              Transactions
              {!allTransactions && transactionCount > 1 ? ` (${transactionCount}) ` : ''}
              <a
                href="#"
                className="float-sm-end"
                aria-label="Open report parameters step"
                onClick={(e) => goToStepLinkHandler(e, 1)}>
                <FontAwesomeIcon size="sm" className="ms-1" icon={faPenToSquare} />
              </a>
            </dt>
            <dd
              className={`col-sm-9 ${
                !allTransactions && transactionCount > 1 ? styles.transactionReviewList : ''
              }`}>
              {allTransactions && (
                <>All transactions, {includeDeleted ? 'including' : 'excluding'} deleted.</>
              )}
              {!allTransactions && transactionCount === 1 && <>{selectedTransactions[0]?.name}</>}
              {!allTransactions && transactionCount > 1 && (
                <>
                  {selectedTransactions.map((tx) => (
                    <div key={tx.id} className={styles.transactionReviewListItem}>
                      {tx.name}
                    </div>
                  ))}
                </>
              )}
            </dd>
          </>
        )}
        <dt className="col-sm-3">
          Schedule{' '}
          <a
            href="#"
            className="float-sm-end"
            aria-label="Goto schedule step"
            onClick={(e) => goToStepLinkHandler(e, 2)}>
            <FontAwesomeIcon size="sm" className="ms-1" icon={faPenToSquare} />
          </a>
        </dt>
        <dd className="col-sm-9">{Helpers.getRecurringScheduleAsText(reportConfig)}</dd>

        <dt className="col-sm-3">
          Delivery{' '}
          <a
            href="#"
            className="float-sm-end"
            aria-label="Open delivery step"
            onClick={(e) => goToStepLinkHandler(e, 3)}>
            <FontAwesomeIcon size="sm" className="ms-1" icon={faPenToSquare} />
          </a>
        </dt>
        <dd className="col-sm-9">
          {deliveryText}
          <div className={styles.emailList}>{deliveryEmailText}</div>
        </dd>
      </dl>
    </>
  );
};

export default ScheduledReportReview;

ScheduledReportReview.propTypes = {
  reportConfig: PropTypes.object,
  customers: PropTypes.array,
  transactions: PropTypes.array,
  setStepCallback: PropTypes.func,
  allStepsCompleted: PropTypes.bool
};
