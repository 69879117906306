import React from 'react';
import { Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUsers, faChevronDown, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { AlertMessage, ModalDialog, ProgressBar } from '../../../../components/ui';
import { MfaRequirementType } from '../../../../utils/constants/enums';
import './RoleManager.css';
import useRoleManager from './useRoleManager';

export const RoleManager = () => {
  const manager = useRoleManager();

  return (
    <>
      <ModalDialog
        showDialog={manager.deleteDialogVisible}
        heading="Delete User"
        message="Are you sure you want to delete this role?"
        okCallback={manager.deleteRole}
        okButtonText="Yes, delete this role."
        cancelCallback={manager.closeDeleteDialog}
        cancelButtonText="No, don't delete."
      />
      <div className="titleBar">
        <h1 className="pageTitle">Manage User Role Templates</h1>
        <div className="actions"></div>
      </div>
      <div className="searchContainer">
        {manager.rolesLoading && <Spinner animation="border" />}
        {!manager.rolesLoading && (
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="roleSelect" className="form-label">
                Template
              </label>
            </div>
            <div className="col-auto">
              <div className="searchWrap">
                <Form.Select
                  id="roleSelect"
                  onChange={(e) => manager.loadRoleForEdit(e.target.value)}
                  value={manager.role.id ?? ''}>
                  <option>- Select -</option>
                  {manager.roles.map((role) => {
                    return (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
            </div>
            <div className="col-auto">
              <button onClick={() => manager.loadRoleForEdit(null)} className="btn btn-success">
                Add
              </button>
            </div>
          </div>
        )}
      </div>

      {manager.showEditor && (
        <div className="card">
          <div className="card-header">
            <div className="titleBar mb-0">
              <h2 className="pageTitle">
                {manager.role.name.trim().length > 0 ? manager.role.name : 'Role name'}
              </h2>
              <div className="actions">
                {!manager.isNewRole && (
                  <button className="btn btn-danger me-2" onClick={manager.handleDelete}>
                    Delete
                  </button>
                )}
                {manager.isNewRole && (
                  <button className="btn btn-danger me-2" onClick={manager.cancelNewRole}>
                    Cancel
                  </button>
                )}
                <button className="btn btn-success" onClick={manager.handleSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
          <ProgressBar className="m-0" visible={manager.showProgressBar} />
          <AlertMessage
            className="card-body rounded-0"
            visible={manager.message.content.trim() !== ''}
            message={manager.message.content}
            status={manager.message.status}
          />
          <div className="card-body pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group mb-3">
                  <label htmlFor="roleName">Role Name</label>
                  <input
                    id="roleName"
                    type="text"
                    className="form-control"
                    placeholder="Role name"
                    value={manager.role.name}
                    onChange={(e) => manager.updateRoleProperty('name', e.target.value)}
                  />
                  {manager.errors.name && (
                    <div className="alert-danger mt-1 p-2">Role name required.</div>
                  )}
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-12 col-md-3">
                <div className="form-group mt-2">
                  <fieldset>
                    <legend>
                      <FontAwesomeIcon size="sm" className="text-secondary me-2" icon={faLock} />
                      Access restrictions:
                    </legend>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => manager.updateRule('allowMultipleBrands')}
                        checked={manager.role.rules.allowMultipleBrands}
                        id="checkAllowMultBrands"
                      />
                      <label className="form-check-label" htmlFor="checkAllowMultBrands">
                        Allow Multiple Brands
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => manager.updateRule('allowMultipleCustomers')}
                        checked={manager.role.rules.allowMultipleCustomers}
                        id="checkAllowMultCustomers"
                      />
                      <label className="form-check-label" htmlFor="checkAllowMultCustomers">
                        Allow Multiple Customers
                      </label>
                    </div>
                    <div className="form-check mt-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => manager.updateRule('allowAssigningToOtherUsers')}
                        checked={manager.role.rules.allowAssigningToOtherUsers}
                        id="checkAllowAssigningToOtherUsers"
                      />
                      <label className="form-check-label" htmlFor="checkAllowAssigningToOtherUsers">
                        Allow Role Assignment
                        <small className="mt-1 d-block">
                          Allow users assigned to this role to assign it to other users.
                        </small>
                      </label>
                    </div>
                  </fieldset>

                  <fieldset className="mt-3">
                    <legend>
                      <FontAwesomeIcon size="sm" className="text-secondary me-2" icon={faUsers} />
                      Role access:
                    </legend>
                    {manager.roles.map((role) => {
                      return (
                        <div key={role.id} className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={() => manager.toggleRoleAccess(role.id)}
                              checked={manager.hasRoleAccess(role.id)}
                            />
                            {role.name}
                          </label>
                        </div>
                      );
                    })}
                  </fieldset>

                  <fieldset className="mt-3">
                    <legend>
                      <FontAwesomeIcon
                        size="sm"
                        className="text-secondary me-2"
                        icon={faShieldHalved}
                      />
                      MFA Requirements
                    </legend>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="MFA"
                        id="MFA_Customer"
                        onChange={() => manager.updateMfaRule(MfaRequirementType.NONE)}
                        checked={manager.role.rules.mfaRequirementType === MfaRequirementType.NONE}
                      />
                      <label className="form-check-label" htmlFor="MFA_Customer">
                        Customer Determined
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="MFA"
                        id="MFA_EveryLogin"
                        onChange={() => manager.updateMfaRule(MfaRequirementType.EVERY_LOGIN)}
                        checked={
                          manager.role.rules.mfaRequirementType === MfaRequirementType.EVERY_LOGIN
                        }
                      />
                      <label className="form-check-label" htmlFor="MFA_EveryLogin">
                        Every Login
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="MFA"
                        id="MFA_24hours"
                        onChange={() => manager.updateMfaRule(MfaRequirementType.EVERY_24_HOURS)}
                        checked={
                          manager.role.rules.mfaRequirementType ===
                          MfaRequirementType.EVERY_24_HOURS
                        }
                      />
                      <label className="form-check-label" htmlFor="MFA_24hours">
                        Every 24 Hours
                      </label>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="col-sm-12 col-md-9 border-start">
                {manager.errors.permissions && (
                  <div className="alert-danger mt-1 p-2 d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon size="lg" className="me-3" icon={faChevronDown} />
                    At least one selection is required.
                    <FontAwesomeIcon size="lg" className="ms-3" icon={faChevronDown} />
                  </div>
                )}
                <div className="row ps-3 role-group-container">
                  {manager.permissions.map((group) => (
                    <div
                      key={group.key}
                      className={
                        group.permissions.length < 14 ? 'role-group' : 'role-group-full-width'
                      }>
                      <div className="form-check mb-0 role-group-title">
                        <label className="form-check-labelx">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={() =>
                              manager.updatePermissionGroup(group.key, group.permissions.length)
                            }
                            checked={manager.hasAllInPermissionGroup(
                              group.key,
                              group.permissions.length
                            )}
                          />
                          <legend>{group.name}</legend>
                        </label>
                      </div>
                      <div className="role-permissions">
                        {group.permissions.map((permission) => {
                          const permissionKey = group.key + ':' + permission.key;
                          return (
                            <div key={permissionKey} className="permission form-check">
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={() => manager.updatePermission(permissionKey)}
                                  checked={manager.hasPermission(permissionKey)}
                                />
                                {permission.name} {manager.hasPermission(permissionKey)}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
