import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

export const ModalDialog = ({
  showDialog,
  heading,
  message,
  okCallback,
  okButtonText,
  cancelCallback,
  cancelButtonText
}) => {
  if (typeof okCallback !== 'function') {
    okCallback = () => {};
  }
  if (typeof cancelCallback !== 'function') {
    cancelCallback = () => {};
  }

  return (
    <>
      <Modal show={showDialog} backdrop="static" keyboard={false} size="md" centered={true}>
        <Modal.Header>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">{message}</div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          {typeof cancelCallback === 'function' && (
            <div>
              <button className="btn btn-primary" onClick={cancelCallback}>
                {cancelButtonText ?? 'Cancel'}
              </button>
            </div>
          )}
          {typeof okCallback === 'function' && (
            <div>
              <button className="btn btn-primary" onClick={okCallback}>
                {okButtonText ?? 'Ok'}
              </button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

ModalDialog.propTypes = {
  showDialog: PropTypes.bool,
  heading: PropTypes.string,
  message: PropTypes.string,
  okCallback: PropTypes.func,
  okButtonText: PropTypes.string,
  cancelCallback: PropTypes.func,
  cancelButtonText: PropTypes.string
};

export default ModalDialog;
