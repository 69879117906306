import { useEffect, useState, useCallback, useRef } from 'react';
import { useAuthContext } from '../../../../context/provider/AuthContext';
import { Api, Helpers } from '../../../../utils/helpers';
import { SortDirection } from '../../../../utils/constants/enums';
import { AuditDateRangeType, ReportResponseType } from '../../../../utils/constants/enums';

const useAuditReportManager = () => {
  const [{ user }] = useAuthContext();
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [displayResults, setDisplayResults] = useState('');
  const reportResults = useRef('');
  const [findText, setFindText] = useState('');
  const [findTextCount, setFindTextCount] = useState(0);
  const [searchParameters, setSearchParameters] = useState({
    keywords: '',
    timestamp: '',
    sortDirection: SortDirection.ASCENDING,
    sortField: '',
    pageSize: 5,
    page: 1,
    filters: {}
  });

  const [reportParameters, setReportParameters] = useState({
    changedBy: '',
    modifiedUser: '',
    modifiedRole: '',
    dateRangeType: AuditDateRangeType.ANY,
    fromDate: null,
    toDate: null,
    responseType: ReportResponseType.HTML
  });

  const [errors, setErrors] = useState({});

  const runReport = () => {
    //the Run Report button was clicked, so reset the page back to 1
    if (searchParameters.page !== 1) {
      let params = { ...searchParameters };
      params.page = 1;
      setSearchParameters(params);
    } else {
      //force a refresh of the report if the user clicks "Run Report" even if nothing changed
      //so the user doesn't think the report is not responding
      getAuditResults();
    }
  };

  const getAuditResults = useCallback(async () => {
    setApiError('');
    let formErrors = {};

    //if they selected custom date, they need to fill in the dates
    if (reportParameters.dateRangeType === AuditDateRangeType.CUSTOM) {
      if (!reportParameters.fromDate || !reportParameters.toDate) {
        formErrors.customDateMissing = true;
      } else {
        //check to make sure dates are valid and do not include 'today'
        const currentDate = Helpers.dateWithoutTime(new Date());
        const testFromDate = Helpers.tryParseDate(`${reportParameters.fromDate} 00:00:00`, null);
        const testToDate = Helpers.tryParseDate(`${reportParameters.toDate} 00:00:00`, null);

        if (
          testFromDate === null ||
          testToDate === null ||
          testFromDate.getTime() === currentDate.getTime() ||
          testToDate.getTime() === currentDate.getTime() ||
          (testFromDate < currentDate && testToDate > currentDate)
        ) {
          formErrors.customDateInvalid = true;
        }
      }
    }

    setErrors(formErrors);

    //do we have any errors?
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    setLoading(true);

    const apiResponse = await Api.post(`/policy/Audit/Search`, JSON.stringify(searchParameters));

    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setApiError(
        `${apiResponse.status.statusCode} - ${apiResponse.status.statusDescription}
        ${apiResponse.errors.join(', ')}`
      );
      setLoading(false);
      return;
    }
    setFindText('');
    setFindTextCount(0);
    reportResults.current = apiResponse.response;
    setDisplayResults(apiResponse.response);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameters]);

  const getRoles = async () => {
    let results = await Api.get('/policy/RoleAudit');
    setRoles(results.response.roles);
  };

  const updateCustomerTransactionFilter = (values) => {
    let params = { ...searchParameters };
    if (values.customerId) {
      params.filters.customerId = values.customerId.toString();
    } else {
      delete params.filters.customerId;
    }
    if (values.transactionId) {
      params.filters.transactionId = values.transactionId.toString();
    } else {
      delete params.filters.transactionId;
    }

    //if customer id or transaction id is provided, we also need to include the brand
    if (values.customerId || values.transactionId) {
      params.filters.brandId = values.brandId.toString();
    } else {
      delete params.filters.brandId;
    }
    setSearchParameters(params);
  };

  const updateFilter = (prop, value) => {
    let reportParams = { ...reportParameters };
    let params = { ...searchParameters };
    switch (prop) {
      case 'modifiedRole':
        reportParams.modifiedRole = value;
        break;
      case 'changedBy':
        reportParams.changedBy = value;
        break;
      case 'modifiedUser':
        reportParams.modifiedUser = value;
        break;
      case 'dateRange':
        reportParams.dateRangeType = value;
        if (value !== AuditDateRangeType.CUSTOM) {
          reportParams.toDate = '';
          params.filters['startDate'] = '';
          reportParams.fromDate = '';
          params.filters['endDate'] = '';
        }
        break;
      case 'startDate':
        reportParams.fromDate = value;
        break;
      case 'endDate':
        reportParams.toDate = value;
        break;
      default:
        break;
    }
    setReportParameters(reportParams);

    params.filters[prop] = value?.toString();
    setSearchParameters(params);
  };

  const updateSorting = (field, direction) => {
    let params = { ...searchParameters };
    params.sortDirection = direction;
    params.sortField = field;
    setSearchParameters(params);
  };

  const changePage = (page) => {
    let params = { ...searchParameters };
    params.page = page;
    setSearchParameters(params);
  };

  const exportAuditResults = async () => {
    const apiResponse = await Api.post(`/policy/Audit/Export`, JSON.stringify(searchParameters));
    if (apiResponse.status.statusCode === 200) {
      window.open(
        `${process.env.REACT_APP_BFF_API_URL}/policy/Audit/Export/${apiResponse.response}`
      );
    } else {
      console.log(`Api Error Occurred:  ${apiResponse.response}`);
    }
  };

  const getKeyValues = (record) => {
    //check to see if we have any key value data we want to display
    const knownKeyValues = ['CustomerName', 'TransactionName', 'RevisionDescription'];
    let keyValues = [];
    try {
      const keyData = JSON.parse(record.keyValues);
      if (typeof keyData === 'object') {
        knownKeyValues.forEach((key) => {
          if (!Helpers.isNullOrWhitespace(keyData[key])) {
            keyValues.push(keyData[key]);
          }
        });
      }
    } catch {
      //ignore
    }

    if (keyValues.length === 0) {
      keyValues.push(record.modifiedTarget);
    }
    return keyValues;
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(
    () => {
      getAuditResults();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParameters.page]
  );

  return {
    roles,
    displayResults,
    findText,
    findTextCount,
    loading,
    errors,
    apiError,
    reportParameters,
    searchParameters,
    activeBrandId: user.activeBrand.id,
    runReport,
    getAuditResults,
    updateFilter,
    updateCustomerTransactionFilter,
    updateSorting,
    changePage,
    exportAuditResults,
    getKeyValues
  };
};

export default useAuditReportManager;
