import { useState, useEffect } from 'react';
import { Api, Helpers } from '../../../../utils/helpers';
import { ResearchDataSource, PaymentConfirmationView } from '../../../../utils/constants/enums';

const usePaymentBatchDetailsModal = ({ brandId, payPalId, batchDate, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [batchTransactions, setBatchTransactions] = useState([]);
  const [modalConfirmationParams, setModalConfirmationParams] = useState({
    brandId: brandId,
    source: ResearchDataSource.BRAND,
    confirmationNumber: null,
    viewType: PaymentConfirmationView.DEFAULT,
    canVoid: false,
    canChargeback: false
  });
  const [rowView, setRowView] = useState([]);

  const isRowViewed = (cpiId) => {
    return rowView.includes(cpiId);
  };

  const closeModal = () => {
    setBatchTransactions([]);
    onClose();
  };

  const closeModalConfirmation = () => {
    let confParams = { ...modalConfirmationParams };
    confParams.confirmationNumber = null;
    setModalConfirmationParams(confParams);
  };

  const viewPaymentDetails = (cpiId, confirmationNumber) => {
    console.log('add to view', cpiId);
    //track this cpiId as viewed
    setRowView((rv) => {
      if (!rv.includes(cpiId)) {
        rv.push(cpiId);
      }
      return rv;
    });

    let confParams = { ...modalConfirmationParams };
    confParams.source = ResearchDataSource.BRAND;
    confParams.confirmationNumber = confirmationNumber;
    confParams.viewType = PaymentConfirmationView.DEFAULT;
    confParams.canVoid = false;
    confParams.canChargeback = false;
    setModalConfirmationParams(confParams);
  };

  useEffect(() => {
    const loadBatchDetails = async () => {
      setLoading(true);

      if (brandId === null || Helpers.isNullOrWhitespace(batchDate)) {
        setBatchTransactions([]);
        setLoading(false);
        return;
      }

      const queryParams = [];
      queryParams.push(`brandId=${brandId}`);
      queryParams.push(`payPalId=${payPalId}`);
      queryParams.push(`batchDate=${encodeURIComponent(batchDate)}`);

      const endpoint = `/data/paypal/activity?${queryParams.join('&')}`;
      const apiResponse = await Api.get(endpoint);
      if (apiResponse.status.statusCode === 200) {
        setBatchTransactions(apiResponse.response);
      }
      setLoading(false);
    };
    loadBatchDetails();
  }, [brandId, payPalId, batchDate]);

  return {
    loading,
    batchTransactions,
    modalConfirmationParams,
    closeModal,
    isRowViewed,
    viewPaymentDetails,
    closeModalConfirmation
  };
};

export default usePaymentBatchDetailsModal;
