import { useEffect } from 'react';

export const useBodyClass = (className) => {
  useEffect(() => {
    document.body.classList.toggle(className, true);
    return () => {
      document.body.classList.toggle(className, false);
    };
  }, [className]);
};
