import { TransactionSections } from '../../../utils/constants/enums';
import { Permissions } from '../../../utils/constants/permissions';

export const sectionMenu = [
  {
    id: TransactionSections.CONFIGURATION_SUMMARY,
    requiredPermissions: [Permissions.AdminTools.TRANSACTION_SUMMARY],
    name: 'Configuration Summary'
  },
  {
    id: TransactionSections.AUTOMATED_UPLOADS,
    requiredPermissions: [Permissions.Admin.AUTOMATED_IMPORT],
    name: 'Automated Import Configuration'
  }
];
