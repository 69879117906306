import { useEffect, useState, useCallback, useRef } from 'react';
import { useAuthContext } from '../../../../context/provider/AuthContext';
import debounce from 'lodash.debounce';
import {
  MfaNotificationMethod,
  AlertStatus,
  MfaRequirementType
} from '../../../../utils/constants/enums';
import { Api, Helpers } from '../../../../utils/helpers';

const useUserEditor = ({ userId, resourceDefinition, roles, brands, returnToSearchCallback }) => {
  const [authContext] = useAuthContext();
  const [readOnly, setReadOnly] = useState(true);

  const [errors, setErrors] = useState({});
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [brandCustomerSelect, setBrandCustomerSelect] = useState({});
  const [brandSelectorValue, setBrandSelectorValue] = useState(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [roleChangeDialogVisible, setRoleChangeDialogVisible] = useState(false);
  const [newRoleId, setNewRoleId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [unlocking, setUnlocking] = useState(false);
  const [unlockMessage, setUnlockMessage] = useState(null);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [usernameValid, setUsernameValid] = useState(null);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [emailValid, setEmailValid] = useState(null);
  const [resourcesChanged, setResourcesChanged] = useState(false);
  const [user, setUser] = useState({
    userId: userId,
    userName: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    lastLogin: null,
    lastPasswordEmailSent: null,
    hasPassword: false,
    lockoutEnabled: false,
    permissions: [],
    resources: { brands: [] },
    role: null,
    mfaNotificationMethod: MfaNotificationMethod.EMAIL
  });

  const [role, setRole] = useState({
    id: null,
    name: '',
    roleType: 0,
    rules: {
      allowMultipleBrands: false,
      allowMultipleCustomers: false
    }
  });

  const [message, setMessage] = useState({
    status: AlertStatus.INFO,
    content: ''
  });

  const [activeAccordians, setActiveAccordians] = useState([]);

  const isNewUser = user.userId === null;

  const getUser = useCallback(async () => {
    setLoading(true);
    setUsernameValid(null);

    if (isNewUser) {
      //if the user only has a single brand, automatically apply it to new users
      if (brands.length === 1) {
        let userToUpdate = { ...user };
        userToUpdate.resources.brands = [{ id: brands[0].id, customers: [] }];
        setUser(userToUpdate);
      }
      setReadOnly(false);
      setLoading(false);
      return;
    }

    const apiResponse = await Api.get(
      `/policy/UserManagement/Users/${encodeURIComponent(user.userId)}`
    );
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      return;
    }
    let data = apiResponse.response;
    let dataRole = null;

    //get additional role info which contains rules
    if (data.role) {
      dataRole = roles.find((x) => x.id === data.role);
      setRole(dataRole);
    }

    data.resources.brands = syncResourceAccess(data.resources.brands);

    //set to readonly if editing my own account or editing a user with a non-assignable role
    const currentUserRoleId = authContext.user.roles[0].id;
    const roleIsAssignable =
      dataRole === null ||
      dataRole.id !== currentUserRoleId ||
      (dataRole.id === currentUserRoleId && dataRole.rules.allowAssigningToOtherUsers);

    const isReadOnly = authContext.user.userId === userId || !roleIsAssignable;
    setReadOnly(isReadOnly);

    setUser(data);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userId, isNewUser]);

  const syncResourceAccess = (resources) => {
    //we need to make sure the user resources are synced with the user updating the account
    //for example, if the editing user has brands/customers/transactions removed, we will remove those from users being edited
    let results = [];
    let changeFound = false;
    resources.forEach((b) => {
      const resourceBrand = resourceDefinition.brands.find((x) => x.id === b.id);
      if (resourceBrand) {
        let brand = { id: b.id, customers: [] };
        b.customers.forEach((c) => {
          const hasAllCustomers = resourceBrand.customers.some((x) => x.id === 0);
          const resourceCustomer = resourceBrand.customers.find((x) => x.id === c.id);
          const hasAllTransactions =
            resourceCustomer?.transactions.some((x) => x.id === 0) ?? false;
          if (hasAllCustomers || resourceCustomer) {
            let customer = { id: c.id, transactions: [] };
            c.transactions.forEach((t) => {
              const resourceTransaction =
                hasAllCustomers ||
                hasAllTransactions ||
                resourceCustomer.transactions.find((x) => x.id === t.id);
              if (resourceTransaction) {
                customer.transactions.push({ id: t.id });
              } else {
                changeFound = true;
              }
            });
            brand.customers.push(customer);
          } else {
            changeFound = true;
          }
        });
        results.push(brand);
      } else {
        changeFound = true;
      }
    });

    setResourcesChanged(changeFound);

    return results;
  };

  const checkUsername = async (username) => {
    if (Helpers.isNullOrWhitespace(username)) {
      setUsernameValid(null);
      return;
    }
    setCheckingUsername(true);
    setUsernameValid(null);
    try {
      const apiResponse = await Api.get(
        `/policy/UserManagement/UsernameExists/${encodeURIComponent(username)}`
      );
      if (apiResponse.status.statusCode !== 200) {
        console.error('api error occurred');
        return false;
      }
      let usernameExists = apiResponse.response;
      setUsernameValid(!usernameExists);
      setFieldError('userNameTaken', usernameExists);
    } catch (e) {
      console.log('Error checking username', e);
    } finally {
      setCheckingUsername(false);
    }
  };

  const debouncedUserSearch = useRef(
    debounce(async (username) => {
      checkUsername(username);
    }, 500)
  ).current;

  useEffect(() => {
    return () => debouncedUserSearch.cancel();
  }, [debouncedUserSearch]);

  const checkEmail = async (email, userId) => {
    if (Helpers.isNullOrWhitespace(email)) {
      setEmailValid(null);
      return;
    }
    setCheckingEmail(true);
    setEmailValid(null);
    try {
      const apiResponse = await Api.get(
        `/policy/UserManagement/EmailExists/${encodeURIComponent(email)}/${encodeURIComponent(
          userId ?? ''
        )}`
      );
      if (apiResponse.status.statusCode !== 200) {
        console.error('api error occurred');
        return false;
      }
      let emailExists = apiResponse.response;
      setEmailValid(!emailExists);
      setFieldError('emailTaken', emailExists);
    } catch (e) {
      console.log('Error checking Email', e);
    } finally {
      setCheckingEmail(false);
    }
  };

  const debouncedEmailSearch = useRef(
    debounce(async (email, userId) => {
      checkEmail(email, userId);
    }, 500)
  ).current;

  useEffect(() => {
    return () => debouncedEmailSearch.cancel();
  }, [debouncedEmailSearch]);

  const messageTimerRef = useRef(null);
  const showMessage = (message, status = AlertStatus.INFO, timeout = null) => {
    clearTimeout(messageTimerRef.current);
    setMessage({
      status: status,
      content: message
    });

    if (timeout && typeof timeout === 'number') {
      messageTimerRef.current = setTimeout(() => {
        clearMessage();
      }, timeout);
    }
  };

  const clearMessage = () => {
    setMessage({
      status: AlertStatus.INFO,
      content: ''
    });
  };

  const setFieldError = (field, hasError) => {
    let errorsToUpdate = { ...errors };
    errorsToUpdate[field] = hasError;
    setErrors(errorsToUpdate);
  };

  const updateUserProperty = async (prop, value) => {
    setFieldError(prop, false);
    let userToUpdate = { ...user };
    userToUpdate[prop] = value.trim();
    setUser(userToUpdate);

    //if new account, run username check
    if (isNewUser && prop === 'userName') {
      setFieldError('userNameTaken', false);
      debouncedUserSearch(value);
    }

    //we want to make sure the email entered isn't already taken,
    //but we also don't want to claim it's taken if it's the email already on
    //file for the user.
    if (prop === 'emailAddress') {
      setFieldError('emailTaken', false);
      debouncedEmailSearch(value, user.userId);
    }
  };

  const isAssignableRole = (role) => {
    //role is assignable if...
    //- editing a user and they already have that role
    //- role is not the same as current users role
    //- role is the same as the user, but rules allow assigning to others
    const currentUserRoleId = authContext.user.roles[0].id;
    return (
      (!isNewUser && role.id === user.role) ||
      role.id !== currentUserRoleId ||
      (role.id === currentUserRoleId && role.rules.allowAssigningToOtherUsers)
    );
  };

  const updateUserRole = async (roleId) => {
    setFieldError('role', false);
    let userToUpdate = { ...user };
    userToUpdate.role = roleId;
    setUser(userToUpdate);
    setRole(roles.find((x) => x.id === roleId));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    clearMessage();

    let formErrors = {};

    if (user.userName.trim() === '') {
      formErrors.userName = true;
    }
    if (isNewUser && user.userName.trim() !== '' && !usernameValid) {
      formErrors.userNameTaken = true;
    }
    if (user.emailAddress !== '' && emailValid === false) {
      //By specifically checking if emailValid is equal to false, we can allow the form
      //to be saved if emailValid is true or null.
      //this is because if you went into a user's profile and updated something other than
      //the email (i.e. never edited the emailAddress box to trigger the email check),
      //emailValid would be null, resulting in !emailValid evaluating to true, preventing the
      //changes from being saved.
      //This won't cause issues when adding a new user, since the only way for emailValid to stay null is
      //if they don't type in the emailAddress box, leaving it empty, which is already checked against.
      formErrors.emailTaken = true;
    }
    if (!Helpers.isValidEmail(user.emailAddress)) {
      formErrors.emailAddress = true;
    }
    if (user.firstName.trim() === '') {
      formErrors.firstName = true;
    }
    if (user.lastName.trim() === '') {
      formErrors.lastName = true;
    }
    if (!Helpers.isValidPhoneNumber(user.phoneNumber)) {
      formErrors.phoneNumber = true;
    }
    if (!user.role) {
      formErrors.role = true;
    }
    if (
      !user.resources.brands.some((b) =>
        b.customers.some((c) => c.id === 0 || c.transactions.length > 0)
      )
    ) {
      formErrors.resources = true;
    }

    setErrors(formErrors);

    //do we have any errors?
    if (Object.keys(formErrors).length > 0) {
      return false;
    }
    await saveUser();
  };

  const saveUser = async () => {
    setShowProgressBar(true);
    let userToSave = { ...user };
    userToSave.returnUrl = `${window.location.protocol}//${window.location.host}/login`;

    try {
      const apiResponse = await Api.post(
        '/policy/UserManagement/Users',
        JSON.stringify(userToSave)
      );
      if (apiResponse.status.statusCode !== 200) {
        showMessage('Error occurred ' + apiResponse.status.errors, AlertStatus.ERROR, 8000);
        return;
      }

      //new user created, update with userid from response
      if (isNewUser) {
        userToSave.userId = apiResponse.response.userId;
        setUser(userToSave);
      }
      showMessage('User saved successfully!', AlertStatus.SUCCESS, 4000);
    } catch (e) {
      showMessage('Error occurred ' + e, AlertStatus.ERROR, 8000);
    } finally {
      setShowProgressBar(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    setDeleteDialogVisible(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const closeRoleChangeDialog = () => {
    setRoleChangeDialogVisible(false);
  };

  const roleChangeVerify = (roleId) => {
    // need to determine if this role change would affect the selections based on role rules
    // if so, prompt the user to confirm

    // get the new role
    const newRole = roles.find((x) => x.id === roleId);
    if (!newRole) {
      return; //for safety, but this should never happen
    }

    // get info about user's current resources
    const brandCount = user.resources.brands?.length ?? 0;
    let maxCustomerCount = 0;
    for (const brand of user.resources.brands) {
      let custCount = brand.customers?.length ?? 0;
      if (custCount > 0) {
        // check for 'all customers'
        const allCustomers = brand.customers.find((x) => x.id === 0);
        if (allCustomers) {
          custCount = 99; //bump the count so 'all customers' are verified
        }
      }

      if (custCount > maxCustomerCount) {
        maxCustomerCount = custCount;
      }
    }

    // check for situations where verification is not needed

    // if the new role has multi-brand and multi-customer access
    if (newRole.rules.allowMultipleBrands && newRole.rules.allowMultipleCustomers) {
      updateUserRole(roleId);
      return;
    }

    // if the new role allows multiple brands, but not multiple customers, and the user only has 1 customer selected
    if (
      newRole.rules.allowMultipleBrands &&
      !newRole.rules.allowMultipleCustomers &&
      maxCustomerCount <= 1
    ) {
      updateUserRole(roleId);
      return;
    }

    // if the new role does not allow multiple brands, but allows multiple customers, and the user only has 1 brand selected
    if (
      !newRole.rules.allowMultipleBrands &&
      newRole.rules.allowMultipleCustomers &&
      brandCount <= 1
    ) {
      updateUserRole(roleId);
      return;
    }

    // if the new role does not allow multiple brands nor multiple customers, and the user only has 1 brand and customer selected
    if (
      !newRole.rules.allowMultipleBrands &&
      !newRole.rules.allowMultipleCustomers &&
      brandCount <= 1 &&
      maxCustomerCount <= 1
    ) {
      updateUserRole(roleId);
      return;
    }

    // if we get here, we must ask the user to confirm the role change
    setNewRoleId(roleId);
    setRoleChangeDialogVisible(true);
  };

  const applyNewRole = () => {
    //clear resources
    let userToUpdate = { ...user };

    //if the user only has a single brand, automatically apply it
    if (brands.length === 1) {
      userToUpdate.resources.brands = [{ id: brands[0].id, customers: [] }];
    } else {
      userToUpdate.resources.brands = [];
    }
    setUser(userToUpdate);
    //set new role
    updateUserRole(newRoleId);
    setRoleChangeDialogVisible(false);
  };

  const deleteUser = async () => {
    const apiResponse = await Api.delete(`/policy/UserManagement/Users/${user.userId}`);
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      return;
    }
    returnToSearchCallback();
  };

  const updateMfaMethod = (mfaMethod) => {
    let userToUpdate = { ...user };
    userToUpdate.mfaNotificationMethod = mfaMethod;
    setUser(userToUpdate);
  };

  const hasAccessToAllCustomers = (brandId) => {
    return resourceDefinition.brands
      .find((brand) => brand.id === brandId)
      ?.customers.some((c) => c.id === 0);
  };

  const toggleAllCustomers = (e, brandId) => {
    e.stopPropagation();
    let userToUpdate = { ...user };
    let brand = userToUpdate.resources.brands.find((x) => x.id === brandId);

    //check if user has this 'all customers' selected
    if (isAllCustomersChecked(brandId)) {
      // clear customer selections
      brand.customers = [];
    } else {
      // add customer 0 (all customers)
      brand.customers = [{ id: 0, transactions: [] }];
    }
    setUser(userToUpdate);
  };

  const isAllCustomersChecked = (brandId) => {
    const brand = user.resources.brands.find((x) => x.id === brandId);
    if (!brand) {
      return false;
    }
    if (!hasAccessToAllCustomers(brandId)) {
      return false;
    }
    return brand.customers.some((x) => x.id === 0);
  };

  const toggleAllTransactions = (e, brandId, customerId) => {
    e.stopPropagation();
    let userToUpdate = { ...user };
    let brand = userToUpdate.resources.brands.find((x) => x.id === brandId);
    let customer = brand.customers.find((x) => x.id === customerId);

    //check if user has this 'all transactions' selected
    if (isAllTransactionsChecked(brandId, customerId)) {
      // clear transaction selections
      customer.transactions = [];
    } else {
      // add transaction 0 (all transactions)
      customer.transactions = [{ id: 0 }];
    }
    setUser(userToUpdate);
  };

  const isAllTransactionsChecked = (brandId, customerId) => {
    const brand = user.resources.brands.find((x) => x.id === brandId);
    let customer = brand.customers.find((x) => x.id === customerId);
    if (!brand || !customer) {
      return false;
    }
    if (!hasAccessToAllTransactions(brandId, customerId)) {
      return false;
    }
    return brand.customers.some(
      (c) => c.id === 0 || (c.id === customerId && c.transactions.some((tx) => tx.id === 0))
    );
  };

  const hasAccessToAllTransactions = (brandId, customerId) => {
    return resourceDefinition.brands
      .find((brand) => brand.id === brandId)
      ?.customers.some(
        (c) => c.id === 0 || (c.id === customerId && c.transactions.some((tx) => tx.id === 0))
      );
  };

  const toggleSelectedBrand = (e, brandId) => {
    e.stopPropagation();
    let userToUpdate = { ...user };

    //check if user has this brand selected
    const hasBrand = userToUpdate.resources.brands.some((x) => x.id === brandId);
    if (!hasBrand) {
      userToUpdate.resources.brands.push({ id: brandId, customers: [] });
    } else {
      userToUpdate.resources.brands = userToUpdate.resources.brands.filter((x) => x.id !== brandId);
    }

    setUser(userToUpdate);
  };

  const selectSingleBrand = (e, brandId) => {
    e.stopPropagation();
    let userToUpdate = { ...user };

    //remove any brand that is not this one
    userToUpdate.resources.brands = userToUpdate.resources.brands.filter((x) => x.id === brandId);

    //add brand if it's not already there
    const hasBrand = userToUpdate.resources.brands.some((x) => x.id === brandId);
    if (!hasBrand) {
      userToUpdate.resources.brands.push({ id: brandId, customers: [] });
    }
    setUser(userToUpdate);
  };

  const brandCustomerCount = (brandId) => {
    const brand = user.resources.brands.find((x) => x.id === brandId);
    return brand?.customers?.length ?? 0;
  };

  const isBrandChecked = (brandId) => {
    return user.resources.brands.some((x) => x.id === brandId);
  };

  const isCustomerSelected = (brandId, customerId) => {
    const brand = user.resources.brands.find((x) => x.id === brandId);
    if (!brand) {
      return false;
    }
    return brand.customers.some((x) => x.id === customerId);
  };

  const isTransactionSelected = (brandId, customerId, transactionId) => {
    const brand = user.resources.brands.find((x) => x.id === brandId);
    if (!brand) {
      return false;
    }
    const customer = brand.customers.find((x) => x.id === customerId);
    if (!customer) {
      return false;
    }
    return customer.transactions.some((x) => x.id === transactionId);
  };

  const brandSelectionDetails = (brandId) => {
    if (isAllCustomersChecked(brandId)) {
      return <span className="brand-stats">All customers</span>;
    }

    // get stats to show
    const customerCount = brandCustomerCount(brandId);

    if (!isBrandChecked(brandId)) {
      return <></>; //brand not selected no need to show stats
    }
    //return stats
    const stats = `${customerCount} customer${customerCount === 1 ? '' : 's'} assigned`;
    return <span className="brand-stats">{stats}</span>;
  };

  const isMfaRequired = () => {
    let mfaRequired = false;

    //check the currently selected role to see if MFA is required
    const userRole = roles.find((role) => role.id === user.role);
    if (
      userRole &&
      userRole.rules &&
      (userRole.rules.mfaRequirementType === MfaRequirementType.EVERY_LOGIN ||
        userRole.rules.mfaRequirementType === MfaRequirementType.EVERY_24_HOURS)
    ) {
      //role requires MFA, so no need to continue checking customers
      return true;
    }

    for (const brand of user.resources.brands) {
      for (const customer of brand.customers) {
        mfaRequired = brands.some(
          (b) =>
            b.id === brand.id &&
            b.customers.some(
              (c) =>
                (customer.id === 0 || c.id === customer.id) &&
                c.mfaRequirement !== MfaRequirementType.NONE
            )
        );
        //no need to continue if we find at least one customer with mfa required
        if (mfaRequired) {
          return true;
        }
      }
    }
    return mfaRequired;
  };

  const updateBrandCustomerSelect = (key, value) => {
    let valueToUpdate = { ...brandCustomerSelect };
    valueToUpdate[key] = value ? parseInt(value) : null;
    setBrandCustomerSelect(valueToUpdate);
  };

  const isActiveCustomerAccordian = (brandId, customerId) => {
    return activeAccordians.some((x) => x.brandId === brandId && x.customerId === customerId);
  };

  const toggleCustomerAccordian = (brandId, customerId) => {
    let accordiansToUpdate = [...activeAccordians];
    if (isActiveCustomerAccordian(brandId, customerId)) {
      //remove
      accordiansToUpdate = accordiansToUpdate.filter(
        (x) => !(x.brandId === brandId && x.customerId === customerId)
      );
    } else {
      //add
      accordiansToUpdate.push({ brandId: brandId, customerId: customerId });
    }
    setActiveAccordians(accordiansToUpdate);
  };

  const addCustomer = (brandId, customerId) => {
    if (!customerId || isCustomerSelected(brandId, customerId)) {
      return;
    }

    let userToUpdate = { ...user };
    const brand = userToUpdate.resources.brands.find((x) => x.id === brandId);
    if (brand) {
      brand.customers.push({
        id: customerId,
        classificationId: getClassificationId(brandId, customerId),
        transactions: []
      });
      setUser(userToUpdate);
      //clear selection
      updateBrandCustomerSelect(brandId, null);

      //update active accordian
      let accordiansToUpdate = [...activeAccordians];
      accordiansToUpdate.push({ brandId: brandId, customerId: customerId });
      setActiveAccordians(accordiansToUpdate);
    }
  };

  const getClassificationId = (brandId, customerId) => {
    const brand = brands.find((x) => x.id === brandId);
    if (!brand) return null;
    const customer = brand.customers.find((x) => x.id === customerId);
    return customer?.classificationId ?? null;
  };

  const removeCustomer = (event, brandId, customerId) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isCustomerSelected(brandId, customerId)) {
      return;
    }
    let userToUpdate = { ...user };
    let brand = userToUpdate.resources.brands.find((x) => x.id === brandId);
    if (brand) {
      brand.customers = brand.customers.filter((x) => x.id !== customerId);
      setUser(userToUpdate);
      //clear selection
      updateBrandCustomerSelect(brandId, null);

      //remove from active accordians
      let accordiansToUpdate = [...activeAccordians];
      accordiansToUpdate.current = accordiansToUpdate.filter(
        (x) => !(x.brandId === brandId && x.customerId === customerId)
      );
      setActiveAccordians(accordiansToUpdate);
    }
  };

  const addTransaction = (brandId, customerId, transactionId) => {
    if (!transactionId || isTransactionSelected(brandId, customerId, transactionId)) {
      return;
    }
    let userToUpdate = { ...user };
    let brand = userToUpdate.resources.brands.find((x) => x.id === brandId);
    if (!brand) {
      return;
    }

    let customer = brand.customers.find((x) => x.id === customerId);
    if (!customer) {
      return;
    }
    customer.transactions.push({ id: transactionId });
    setUser(userToUpdate);
    //reset select
    updateBrandCustomerSelect(`${brandId}:${customerId}`, null);
  };

  const removeTransaction = (event, brandId, customerId, transactionId) => {
    event.preventDefault();
    if (!isTransactionSelected(brandId, customerId, transactionId)) {
      return;
    }
    let userToUpdate = { ...user };
    let brand = userToUpdate.resources.brands.find((x) => x.id === brandId);
    if (!brand) {
      return;
    }
    let customer = brand.customers.find((x) => x.id === customerId);
    if (!customer) {
      return;
    }

    customer.transactions = customer.transactions.filter((x) => x.id !== transactionId);
    setUser(userToUpdate);
    //reset select
    updateBrandCustomerSelect(`${brandId}:${customerId}`, null);
  };

  const toggleTransaction = (brandId, customerId, transactionId) => {
    let userToUpdate = { ...user };
    const brand = userToUpdate.resources.brands.find((x) => x.id === brandId);
    if (!transactionId || !brand) {
      return;
    }

    const customer = brand.customers.find((x) => x.id === customerId);
    if (!customer) {
      return;
    }

    if (isTransactionSelected(brandId, customerId, transactionId)) {
      //remove
      customer.transactions = customer.transactions.filter((x) => x.id !== transactionId);
    } else {
      //add
      customer.transactions.push({ id: transactionId });
    }
    setUser(userToUpdate);
  };

  const selectAllTransactions = (brandId, customerId) => {
    let userToUpdate = { ...user };
    const brand = userToUpdate.resources.brands.find((x) => x.id === brandId);
    if (!brand) {
      return;
    }

    const customer = brand.customers.find((x) => x.id === customerId);
    if (!customer) {
      return;
    }

    if (hasAllTransactions(brandId, customerId)) {
      customer.transactions = [];
    } else {
      customer.transactions = brands
        .find((brand) => brand.id === brandId)
        ?.customers.find((customer) => customer.id === customerId)
        ?.transactions.map((t) => ({
          id: t.id
        }));
    }
    setUser(userToUpdate);
  };

  const hasAllTransactions = (brandId, customerId) => {
    const transactionCount = brands
      .find((brand) => brand.id === brandId)
      ?.customers.find((customer) => customer.id === customerId)?.transactions.length;

    const selectedTransactionCount = user.resources.brands
      .find((brand) => brand.id === brandId)
      ?.customers.find((customer) => customer.id === customerId)?.transactions.length;

    return transactionCount === selectedTransactionCount;
  };

  const resetPending = useRef(false);
  const sendPasswordReset = async () => {
    if (resetPending.current) {
      return;
    }
    resetPending.current = true;
    setUnlockMessage(null);
    setUnlocking(true);

    try {
      const apiResponse = await Api.post(
        `/policy/UserManagement/Unlock/${user.userId}`,
        JSON.stringify(`${window.location.protocol}//${window.location.host}/login`)
      );
      if (apiResponse.status.statusCode !== 200) {
        console.error('api error occurred');
        return;
      }
      //check identity status returned
      if (!apiResponse.response.isSuccess) {
        setUnlockMessage(apiResponse.response.errorMessage);
      } else {
        setUser({ ...user, lockoutEnabled: false });
        setUnlockMessage('Password reset email sent!');
      }
    } catch (e) {
      setUnlockMessage('Error occurred unlocking account.');
      console.error('api error occurred', e);
    } finally {
      resetPending.current = false;
      setUnlocking(false);
      //hide message automatically
      setTimeout(() => {
        setUnlockMessage(null);
      }, 6000);
    }
  };

  const sendFirstPasswordEmail = async () => {
    if (resetPending.current) {
      return;
    }
    resetPending.current = true;
    setUnlockMessage(null);
    setUnlocking(true);

    try {
      const apiResponse = await Api.post(
        `/policy/UserManagement/SendFirstPasswordEmail/${user.userId}`,
        JSON.stringify(`${window.location.protocol}//${window.location.host}/login`)
      );
      if (apiResponse.status.statusCode !== 200) {
        console.error('api error occurred');
        return;
      }
      //check identity status returned
      if (!apiResponse.response.isSuccess) {
        setUnlockMessage(apiResponse.response.errorMessage);
      } else {
        setUser({ ...user, lastPasswordEmailSent: new Date().toLocaleString() });
        setUnlockMessage('First Password email sent!');
      }
    } catch (e) {
      setUnlockMessage('Error occurred unlocking account.');
      console.error('api error occurred', e);
    } finally {
      resetPending.current = false;
      setUnlocking(false);
      //hide message automatically
      setTimeout(() => {
        setUnlockMessage(null);
      }, 6000);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await getUser();
    };
    loadData();
  }, [getUser]);

  return {
    readOnly,
    isNewUser,
    loading,
    message,
    user,
    role,
    roles,
    brands,
    resourcesChanged,
    isAssignableRole,
    updateMfaMethod,
    brandCustomerSelect,
    addCustomer,
    removeCustomer,
    updateBrandCustomerSelect,
    updateUserProperty,
    updateUserRole,
    handleSave,
    errors,
    showProgressBar,
    isBrandChecked,
    isCustomerSelected,
    isMfaRequired,
    brandSelectorValue,
    setBrandSelectorValue,
    toggleSelectedBrand,
    selectSingleBrand,
    toggleAllCustomers,
    hasAccessToAllCustomers,
    isAllCustomersChecked,
    toggleAllTransactions,
    hasAccessToAllTransactions,
    isAllTransactionsChecked,
    brandCustomerCount,
    isTransactionSelected,
    addTransaction,
    removeTransaction,
    toggleTransaction,
    selectAllTransactions,
    hasAllTransactions,
    deleteDialogVisible,
    handleDelete,
    closeDeleteDialog,
    newRoleId,
    roleChangeVerify,
    applyNewRole,
    roleChangeDialogVisible,
    closeRoleChangeDialog,
    deleteUser,
    checkingUsername,
    usernameValid,
    checkingEmail,
    emailValid,
    brandSelectionDetails,
    sendPasswordReset,
    sendFirstPasswordEmail,
    unlocking,
    unlockMessage,
    activeAccordians,
    toggleCustomerAccordian
  };
};

export default useUserEditor;
