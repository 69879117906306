import { DefaultLayout as Layout } from '../../../layouts';
import { AuditReportManager } from './AuditReportManager/AuditReportManager';

export const AuditReportPage = () => {
  return (
    <Layout>
      <AuditReportManager />
    </Layout>
  );
};
