import { useState } from 'react';
import { Api } from '../../../utils/helpers';

const useLoginForm = () => {
  const [username, setUsername] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);

  const getAuthorityRedirect = async (username) => {
    if (loggingIn) {
      return;
    }
    setLoggingIn(true);
    setInvalidUser(false);

    try {
      const apiResponse = await Api.post('/auth/authority', JSON.stringify(username));
      if (apiResponse.status.statusCode !== 200) {
        setLoggingIn(false);
        setInvalidUser(true);
        return;
      }
      window.location.href = apiResponse.response;
    } catch {
      setLoggingIn(false);
      setInvalidUser(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await getAuthorityRedirect(username);
  };

  const updateUsername = (value) => {
    setInvalidUser(false);
    setUsername(value);
  };

  return {
    loggingIn,
    username,
    invalidUser,
    updateUsername,
    handleSubmit
  };
};

export default useLoginForm;
