import React, { useEffect, useState, useCallback } from 'react';
import { Api, Helpers } from '../../../utils/helpers';
import { MessageOptions, MessageViewType } from '../../../utils/constants/enums';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './ScheduledReports.module.css';

export const ScheduledReports = () => {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState(null);

  const getReports = useCallback(async () => {
    setLoading(true);
    const apiResponse = await Api.get('/data/ReportScheduling/DashboardTileReports/?limit=20');
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }
    setReports(apiResponse.response);
    setLoading(false);
  }, []);

  useEffect(() => {
    getReports();
  }, [getReports]);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <div className={styles.container}>
      {reports?.length === 0 && (
        <div className={styles.noReports}>You have no scheduled reports.</div>
      )}
      {reports?.map((report) => {
        let reportDate = 'Never';
        if (report.lastGeneratedReportDateUtc !== null) {
          reportDate = Helpers.toShortDate(report.lastGeneratedReportDateUtc);
        }

        let reportLink = <em>Never</em>;
        if (report.lastGeneratedReportId !== null) {
          reportLink = (
            <Link to={`/view-reports/${report.id}/${report.lastGeneratedReportId}`}>
              <strong>{reportDate}</strong>
            </Link>
          );
        }

        return (
          <div key={report.id} className={'mb-3 ' + styles.reportItem}>
            <strong>{report.name}</strong>
            <div>
              <em>Last Run: {reportLink}</em>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ScheduledReports;
