import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../context/provider/AuthContext';
import {
  TransactionStatus,
  ReportScheduleType,
  ReportDateRangeType,
  DaysOfWeek,
  ReportDeliveryType
} from '../../../../utils/constants/enums';
import { Helpers } from '../../../../utils/helpers';
import styles from './ScheduledReportConfigModal.module.css';
import { availableReports } from '../../ReportingPage/ReportManager/availableReports';

const useScheduledReportConfigModal = ({ scheduleConfig, saveChangesCallback, closeCallback }) => {
  const [{ user }] = useAuthContext();
  const [showCustomerSelect, setShowCustomerSelect] = useState(true);
  const showInactiveTransactions = true;
  const [currentStep, setCurrentStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [reportConfig, setReportConfig] = useState(scheduleConfig);
  const wizardSteps = ['Report Name', 'Report Parameters', 'Schedule', 'Delivery', 'Review'];

  const customers = user.userResources.brands
    .find((brand) => brand.id === user.activeBrand.id)
    .customers.map((customer) => {
      return { id: customer.id, name: customer.name, transactions: customer.transactions };
    });
  const defaultCustomer = customers.length === 1 ? customers[0] : null;

  useEffect(() => {
    const transactionsByCustomer = user.userResources.brands
      .find((brand) => brand.id === user.activeBrand.id)
      .customers.find((customer) => customer.id === reportConfig.reportParameters.customerId)
      ?.transactions.map((transaction) => {
        return {
          id: transaction.id,
          name: transaction.name,
          status: transaction.status,
          deleted: transaction.deleted
        };
      });
    setTransactions(transactionsByCustomer);
  }, [user.userResources.brands, user.activeBrand.id, reportConfig.reportParameters.customerId]);

  const filteredTransactions = transactions
    ?.filter(
      (transaction) =>
        (showInactiveTransactions ||
          (!showInactiveTransactions && transaction.status !== TransactionStatus.INACTIVE)) &&
        (reportConfig.reportParameters.includeDeletedTransactions ||
          (!reportConfig.reportParameters.includeDeletedTransactions && !transaction.deleted))
    )
    .map((transaction) => {
      return {
        id: transaction.id,
        name: transaction.name,
        status: transaction.status,
        deleted: transaction.deleted
      };
    });

  const hasAllCustomerAccess =
    user.resources.brands
      .find((brand) => brand.id === user.activeBrand.id)
      ?.customers.some((x) => x.id === 0) ?? false;

  const allowedReports = availableReports.filter((report) => {
    const hasRequiredPermission = report.requiredPermissions.every((p) =>
      user.permissions.includes(p)
    );
    const hasRequiredCustomerAccess =
      !hasAllCustomerAccess || (hasAllCustomerAccess && report.accessibleToAllCustomerUser);

    return report.dateSelect && hasRequiredPermission && hasRequiredCustomerAccess;
  });

  const handleCloseModal = () => {
    closeCallback();
  };

  const clearErrorForKeys = (keys) => {
    let errorsToUpdate = { ...errors };
    keys.forEach((key) => (errorsToUpdate[key] = false));
    setErrors(errorsToUpdate);
  };

  const updateReportParams = (key, value, options) => {
    //clear any previous errors related to this field
    setFieldError(key, false);

    let config = { ...reportConfig };
    if (options?.numbersOnly) {
      value = value.replace(/\D/g, '');
    }
    let params = config.reportParameters;
    params[key] = value;

    //reset transaction id selection if customer id changes
    if (key === 'customerId') {
      config.reportParameters.transactionIds = [];
    }

    //if the date range type is changed to business daily, remove sat/sun since they don't apply
    if (key === 'dateRangeType' && value === ReportDateRangeType.BUSINESS_DAILY) {
      if (Helpers.hasFlag(config.recurrenceParameters.daysOfWeek, DaysOfWeek.SATURDAY)) {
        config.recurrenceParameters.daysOfWeek ^= DaysOfWeek.SATURDAY;
      }
      if (Helpers.hasFlag(config.recurrenceParameters.daysOfWeek, DaysOfWeek.SUNDAY)) {
        config.recurrenceParameters.daysOfWeek ^= DaysOfWeek.SUNDAY;
      }
    }
    setReportConfig(config);

    if (key === 'reportType') {
      const report = allowedReports.find((r) => r.id === value);
      if (report) {
        setShowCustomerSelect(report.customerSelect);
      }
    }
  };

  const recurrenceParameterChangeHandler = (value) => {
    let config = { ...reportConfig };
    config.recurrenceParameters = value;
    setReportConfig(config);
  };

  const updateScheduleType = async (scheduleType) => {
    let config = { ...reportConfig };
    config.scheduleType = scheduleType;
    setReportConfig(config);
  };

  const setFieldError = (field, hasError) => {
    let errorsToUpdate = { ...errors };
    errorsToUpdate[field] = hasError;
    setErrors(errorsToUpdate);
  };

  const updateTransaction = (transactionId) => {
    setFieldError('transactionIds', false);
    transactionId = parseInt(transactionId);
    let config = { ...reportConfig };
    if (config.reportParameters.transactionIds.includes(transactionId)) {
      //remove it
      config.reportParameters.transactionIds = config.reportParameters.transactionIds.filter(
        (t) => t !== transactionId
      );
    } else {
      //add it
      config.reportParameters.transactionIds.push(transactionId);
    }
    const includeDeleted = config.reportParameters.includeDeletedTransactions;

    //update 'all transactions' selection based on current list
    const visibleTransactionCount = transactions.filter(
      (tx) => includeDeleted || (!includeDeleted && !tx.deleted)
    ).length;
    config.reportParameters.allTransactions =
      visibleTransactionCount === config.reportParameters.transactionIds.length;

    setReportConfig(config);
  };

  const hasTransaction = (transactionId) => {
    transactionId = parseInt(transactionId);
    return reportConfig.reportParameters.transactionIds.includes(transactionId);
  };

  const toggleDeletedTransactions = () => {
    //if hiding deleted transactions
    //unselect any that are deleted
    let config = { ...reportConfig };
    config.reportParameters.transactionIds = config.reportParameters.transactionIds.filter((tx) =>
      transactions.some((transaction) => transaction.id === tx && !transaction.deleted)
    );
    //toggle deleted
    config.reportParameters.includeDeletedTransactions =
      !config.reportParameters.includeDeletedTransactions;

    //update 'all transactions' selection based on current list
    config.reportParameters.allTransactions = hasAllTransactions();

    setReportConfig(config);
  };

  const selectAllTransactions = () => {
    setFieldError('transactionIds', false);
    let config = { ...reportConfig };
    if (hasAllTransactions()) {
      config.reportParameters.transactionIds = [];
      config.reportParameters.allTransactions = false;
    } else {
      config.reportParameters.transactionIds = filteredTransactions.map((t) => t.id);
      config.reportParameters.allTransactions = true;
    }
    setReportConfig(config);
  };

  const hasAllTransactions = () => {
    const includeDeleted = reportConfig.reportParameters.includeDeletedTransactions;
    const visibleTransactionCount = transactions.filter(
      (tx) => includeDeleted || (!includeDeleted && !tx.deleted)
    ).length;
    return visibleTransactionCount === reportConfig.reportParameters.transactionIds.length;
  };

  const updateConfigProperty = (prop, value) => {
    switch (prop) {
      case 'deliveryEmail':
        setFieldError('invalidDeliveryEmail', false);
        break;
    }
    setFieldError(prop, false);
    let config = { ...reportConfig };
    config[prop] = value;
    setReportConfig(config);
  };

  const saveScheduledReport = async () => {
    //validate all steps -- if something is wrong open that step
    //and run validation so they can see the errors
    for (let i = 0; i < wizardSteps.length - 1; i++) {
      if (!isStepCompleted(i)) {
        setCurrentStep(i);
        validateStep(i);
        return;
      }
    }

    const reportToSave = { ...reportConfig };
    if (reportToSave.reportParameters.customerId === null) {
      reportToSave.reportParameters.customerId = customers[0].id;
    }

    const saveResult = await saveChangesCallback(reportToSave);
    if (saveResult) handleCloseModal();
  };

  const getPreviousWizardStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const getNextWizardStep = () => {
    const stepIsValid = validateStep(currentStep);
    if (currentStep < wizardSteps.length && stepIsValid) {
      setCurrentStep(currentStep + 1);
    }
  };
  const gotoWizardStep = (e, index) => {
    e.preventDefault();
    //if they are returning to a previous step, run validation so they can see the errors
    if (index < currentStep) {
      validateStep(index);
    }
    setCurrentStep(index);
  };

  const isValidRunDate = (value, allowNull = true) => {
    value = Helpers.toInputFieldDate(value);
    const currentDate = Helpers.dateWithoutTime(new Date());
    const dateToTest = Helpers.tryParseDate(`${value} 00:00:00`, null);
    return (
      (dateToTest === null && allowNull) ||
      (dateToTest !== null && dateToTest.getTime() > currentDate.getTime())
    );
  };
  const isValidRunDateRange = (fromDate, toDate) => {
    const fromDateTest = Helpers.tryParseDate(`${fromDate} 00:00:00`, null);
    const toDateTest = Helpers.tryParseDate(`${toDate} 00:00:00`, null);
    return fromDateTest === null || toDateTest === null || toDate >= fromDate;
  };

  const validateStep = (step, showErrors = true) => {
    let errorFields = {};
    switch (step) {
      case 0:
        //report name
        if (Helpers.isNullOrWhitespace(reportConfig.name)) {
          errorFields.name = true;
        }
        break;
      case 1: {
        //report parameters
        if (reportConfig.reportParameters.reportType === 0) {
          errorFields.reportType = true;
        }

        const report = allowedReports.find(
          (r) => r.id === scheduleConfig.reportParameters.reportType
        );
        const requiresCustomerSelect = report?.customerSelect ?? true;
        if (requiresCustomerSelect) {
          if (Helpers.isNullOrWhitespace(reportConfig.reportParameters.customerId)) {
            errorFields.customerId = true;
          }
          if (reportConfig.reportParameters.transactionIds.length === 0) {
            errorFields.transactionIds = true;
          }
        }

        break;
      }
      case 2:
        //schedule
        if (Helpers.isNullOrWhitespace(reportConfig.reportParameters.dateRangeType)) {
          errorFields.dateRangeType = true;
        }

        if (reportConfig.scheduleType === ReportScheduleType.ONE_TIME) {
          //ONE TIME VALIDATION
          if (!isValidRunDate(reportConfig.sendDateUtc, false)) {
            errorFields.sendDateUtc = true;
          }
        } else if (reportConfig.scheduleType === ReportScheduleType.RECURRING) {
          //RECURRENCE VALIDATION
          //check recurrence parameters based on report date range type

          //previous day, business daily, and weekly require at least one day selected
          if (
            reportConfig.reportParameters.dateRangeType === ReportDateRangeType.YESTERDAY ||
            reportConfig.reportParameters.dateRangeType === ReportDateRangeType.BUSINESS_DAILY ||
            reportConfig.reportParameters.dateRangeType === ReportDateRangeType.WEEKLY ||
            reportConfig.reportParameters.dateRangeType === ReportDateRangeType.PREVIOUS_7_DAYS
          ) {
            if (reportConfig.recurrenceParameters.daysOfWeek === 0) {
              errorFields.daysOfWeek = true;
            }
          }

          //previous month/previous quarter/previous year/fiscal year require day or frequency inteval
          //since they are prefilled entries, validation should not be needed

          //check run dates
          if (!isValidRunDate(reportConfig.recurrenceParameters.endDateUtc)) {
            errorFields.endDateUtc = true;
          }

          if (
            !isValidRunDateRange(
              reportConfig.recurrenceParameters.startDateUtc,
              reportConfig.recurrenceParameters.endDateUtc
            )
          ) {
            errorFields.runDateOrder = true;
          }
        }

        break;
      case 3:
        //delivery
        if (Helpers.isNullOrWhitespace(reportConfig.deliveryType)) {
          errorFields.deliveryType = true;
        }
        if (
          reportConfig.deliveryType === ReportDeliveryType.EMAIL_NOTIFICATION &&
          Helpers.isNullOrWhitespace(reportConfig.deliveryEmail)
        ) {
          errorFields.deliveryEmail = true;
        } else if (
          reportConfig.deliveryType === ReportDeliveryType.EMAIL_NOTIFICATION &&
          !hasInvalidDeliveryEmail(reportConfig.deliveryEmail)
        ) {
          errorFields.invalidDeliveryEmail = true;
        }
        break;
    }
    if (showErrors) setErrors(errorFields);
    return Object.keys(errorFields).length === 0;
  };

  const hasInvalidDeliveryEmail = (value) => {
    let allValid = true;
    const emails = value.split(/[,;]/);
    for (let i = 0; i < emails.length; i++) {
      const email = emails[i].trim();
      if (!Helpers.isNullOrWhitespace(email) && !Helpers.isValidEmail(email)) {
        allValid = false;
        break;
      }
    }
    return allValid;
  };

  const isStepCompleted = (step) => {
    if (step === 4) {
      //special check for the review section
      return areAllStepsCompleted();
    }
    return validateStep(step, false);
  };

  const areAllStepsCompleted = () => {
    let allComplete = true;
    for (let i = 0; i < wizardSteps.length - 1; i++) {
      allComplete = isStepCompleted(i);
      if (!allComplete) break;
    }
    return allComplete;
  };

  useEffect(() => {
    //update modal config if new version was passed from the parent

    //handle removed customer access (reset customer id and transaction ids)
    if (!customers.some((x) => x.id === scheduleConfig.reportParameters.customerId)) {
      if (customers.length === 1) {
        scheduleConfig.reportParameters.customerId = customers[0].id;
      } else {
        scheduleConfig.reportParameters.customerId = null;
      }
    }

    const availableTransactions =
      customers.find((c) => c.id === scheduleConfig.reportParameters.customerId)?.transactions ??
      [];

    //if customer is no longer accessible, reset transactions
    if (scheduleConfig.reportParameters.customerId === null) {
      scheduleConfig.reportParameters.transactionIds = [];
    } else if (scheduleConfig.reportParameters.allTransactions) {
      //if they had all transactions selected before, select all available now
      //including deleted if they had that checked
      scheduleConfig.reportParameters.transactionIds = availableTransactions
        .filter(
          (tx) =>
            scheduleConfig.reportParameters.includeDeletedTransactions ||
            (!scheduleConfig.reportParameters.includeDeletedTransactions && !tx.deleted)
        )
        .map((tx) => tx.id);
    } else {
      //filter out any transactions that this user no longer has access to
      scheduleConfig.reportParameters.transactionIds =
        scheduleConfig.reportParameters.transactionIds.filter((x) =>
          availableTransactions.some((tx) => tx.id === x)
        );
    }

    const report = allowedReports.find((r) => r.id === scheduleConfig.reportParameters.reportType);
    if (report) {
      setShowCustomerSelect(report.customerSelect);
    }

    //if they are editing, we're going to bring them to the review step
    setCurrentStep(scheduleConfig.id === null ? 0 : wizardSteps.length - 1);
    setErrors({});
    setReportConfig(scheduleConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleConfig]);

  const oneTimeLabelStyle =
    reportConfig.scheduleType === ReportScheduleType.ONE_TIME
      ? styles.scheduleTypeLabelActive
      : styles.scheduleTypeLabel;
  const recurringLabelStyle =
    reportConfig.scheduleType === ReportScheduleType.RECURRING
      ? styles.scheduleTypeLabelActive
      : styles.scheduleTypeLabel;

  return {
    errors,
    allowedReports,
    reportConfig,
    currentStep,
    wizardSteps,
    showCustomerSelect,
    defaultCustomer,
    customers,
    transactions,
    oneTimeLabelStyle,
    recurringLabelStyle,
    showInactiveTransactions,
    handleCloseModal,
    updateConfigProperty,
    updateReportParams,
    updateScheduleType,
    setCurrentStep,
    getNextWizardStep,
    getPreviousWizardStep,
    gotoWizardStep,
    isStepCompleted,
    areAllStepsCompleted,
    hasTransaction,
    toggleDeletedTransactions,
    hasAllTransactions,
    updateTransaction,
    selectAllTransactions,
    recurrenceParameterChangeHandler,
    saveScheduledReport,
    clearErrorForKeys
  };
};

export default useScheduledReportConfigModal;
