import { useState, useEffect, useCallback, useRef } from 'react';
import { Api, Helpers } from '../../../../utils/helpers';
import { AlertStatus } from '../../../../utils/constants/enums';

const useSftpAccounts = ({ customerParameters }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState('');
  const [newPassword, setNewPassword] = useState(null);
  const [sftpAccount, setSftpAccount] = useState(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [resetDialogVisible, setResetDialogVisible] = useState(false);
  const containerExists = useRef(false);

  const [message, setMessage] = useState({
    status: AlertStatus.INFO,
    content: ''
  });
  const confirmDeleteAccount = () => {
    setDeleteDialogVisible(true);
  };
  const closeDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };
  const confirmResetPassword = () => {
    setResetDialogVisible(true);
  };
  const closeResetDialog = () => {
    setResetDialogVisible(false);
  };

  const messageTimerRef = useRef(null);
  const showMessage = (message, status = AlertStatus.INFO, timeout = null) => {
    clearTimeout(messageTimerRef.current);
    setMessage({
      status: status,
      content: message
    });

    if (timeout && typeof timeout === 'number') {
      messageTimerRef.current = setTimeout(() => {
        clearMessage();
      }, timeout);
    }
  };

  const clearMessage = () => {
    setMessage({
      status: AlertStatus.INFO,
      content: ''
    });
  };

  const getSftpAccounts = useCallback(async () => {
    setLoading(true);
    setSftpAccount(null);
    setNewPassword(null);

    if (
      !customerParameters ||
      customerParameters.brandId <= 0 ||
      customerParameters.customerId <= 0
    ) {
      return false;
    }
    setLoading(true);

    const container = `${customerParameters.brandId}-${customerParameters.customerId}`;

    //check of container exists
    let apiResponse = await Api.get(`/files/containers/${container}`);
    if (apiResponse.status.statusCode === 404) {
      //container does not exist
      containerExists.current = false;
      setLoading(false);
      return;
    } else if (apiResponse.status.statusCode !== 200) {
      //not a good response
      showMessage(
        'Error getting status of SFTP account, please try again later.',
        AlertStatus.ERROR,
        8000
      );
      setLoading(false);
      return;
    }

    containerExists.current = true;
    apiResponse = await Api.get(`/files/containers/${container}/user`);

    if (apiResponse.status.statusCode === 404) {
      //account does not exist
      setLoading(false);
      return;
    }

    if (apiResponse.status.statusCode !== 200) {
      showMessage('Error occurred ' + apiResponse.status.errors, AlertStatus.ERROR, 8000);
      setLoading(false);
      return;
    }

    setSftpAccount({
      host: apiResponse.response.sftpHost,
      port: apiResponse.response.sftpPort,
      userName: apiResponse.response.sftpUserName
    });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerParameters]);

  const filterUserName = (value) => {
    setFieldError('userName', false);
    value = value.toLowerCase().replace(/[^a-z0-9]/g, '');
    setUserName(value);
  };

  const setFieldError = (field, hasError) => {
    let errorsToUpdate = { ...errors };
    errorsToUpdate[field] = hasError;
    setErrors(errorsToUpdate);
  };

  const createSftpAccount = async () => {
    clearMessage();

    let formErrors = {};
    if (userName.trim() === '' || userName.trim().length < 3) {
      formErrors.userName = true;
    }

    setErrors(formErrors);
    //do we have any errors?
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    let apiResponse;

    //create the container if it doesn't exist
    if (!containerExists.current) {
      const createRequest = {
        siteId: customerParameters.brandId,
        customerId: customerParameters.customerId
      };
      try {
        apiResponse = await Api.post(`/files/containers`, JSON.stringify(createRequest));
        if (apiResponse.status.statusCode !== 200) {
          showMessage('Error occurred ' + apiResponse.status.errors, AlertStatus.ERROR, 8000);
          return;
        }
        containerExists.current = true;
      } catch (e) {
        showMessage(`Server error occurred ${e}`, AlertStatus.ERROR, 8000);
        return;
      }
    }

    //create the sftp user account
    const createUserRequest = {
      siteId: customerParameters.brandId,
      customerId: customerParameters.customerId,
      userName: userName
    };
    const container = `${customerParameters.brandId}-${customerParameters.customerId}`;
    try {
      apiResponse = await Api.post(
        `/files/containers/${container}/sftp`,
        JSON.stringify(createUserRequest)
      );
      if (apiResponse.status.statusCode !== 200) {
        showMessage('Error occurred ' + apiResponse.status.errors, AlertStatus.ERROR, 8000);
        return;
      }
    } catch (e) {
      showMessage(`Server error occurred ${e}`, AlertStatus.ERROR, 8000);
      return;
    }

    const newAccount = {
      host: apiResponse.response.sftpHost,
      port: apiResponse.response.sftpPort,
      userName: apiResponse.response.sftpUserName
    };
    setNewPassword(apiResponse.response.sftpUserPassword);
    setSftpAccount(newAccount);
    setLoading(false);
  };

  const deleteAccount = async () => {
    const container = `${customerParameters.brandId}-${customerParameters.customerId}`;
    const apiResponse = await Api.delete(`/files/containers/${container}/user`);
    if (apiResponse.status.statusCode !== 200) {
      showMessage('Error occurred ' + apiResponse.status.errors, AlertStatus.ERROR, 8000);
      return;
    }
    setSftpAccount(null);
    closeDeleteDialog();
  };

  const resetPassword = async () => {
    const container = `${customerParameters.brandId}-${customerParameters.customerId}`;
    const apiResponse = await Api.post(`/files/containers/${container}/user`);
    if (apiResponse.status.statusCode !== 200) {
      showMessage('Error occurred ' + apiResponse.status.errors, AlertStatus.ERROR, 8000);
      return;
    }
    setNewPassword(apiResponse.response.sftpUserPassword);
    closeResetDialog();
  };

  useEffect(() => {
    getSftpAccounts();
  }, [getSftpAccounts]);

  return {
    errors,
    loading,
    message,
    userName,
    newPassword,
    sftpAccount,
    deleteDialogVisible,
    resetDialogVisible,
    filterUserName,
    createSftpAccount,
    closeDeleteDialog,
    closeResetDialog,
    deleteAccount,
    resetPassword,
    confirmDeleteAccount,
    confirmResetPassword
  };
};

export default useSftpAccounts;
