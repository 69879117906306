import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { FileBrowser } from '../';
import styles from './FileBrowserModal.module.css';

export const FileBrowserModal = ({
  showModal = false,
  closeModalCallback,
  uploadApiEndpoint,
  browseApiEndpoint,
  imageApiEndpoint,
  downloadApiEndpoint,
  selectFileCallback,
  maxConcurrentUploads = 3,
  acceptExtensions = '',
  sizeLimit = 5242880,
  multipleFiles = true
}) => {
  return (
    <>
      <Modal
        show={showModal}
        dialogClassName={styles.fileBrowseModal}
        contentClassName={styles.modalContent}
        onHide={closeModalCallback}
        centered={false}>
        <Modal.Body className={styles.modalBody}>
          <FileBrowser
            isModal={true}
            closeButtonCallback={closeModalCallback}
            uploadApiEndpoint={uploadApiEndpoint}
            browseApiEndpoint={browseApiEndpoint}
            imageApiEndpoint={imageApiEndpoint}
            downloadApiEndpoint={downloadApiEndpoint}
            selectFileCallback={selectFileCallback}
            acceptExtensions={acceptExtensions}
            sizeLimit={sizeLimit}
            maxConcurrentUploads={maxConcurrentUploads}
            multipleFiles={multipleFiles}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

FileBrowserModal.propTypes = {
  showModal: PropTypes.bool,
  closeModalCallback: PropTypes.func.isRequired,
  uploadApiEndpoint: PropTypes.string.isRequired,
  browseApiEndpoint: PropTypes.string.isRequired,
  imageApiEndpoint: PropTypes.string.isRequired,
  downloadApiEndpoint: PropTypes.string.isRequired,
  selectFileCallback: PropTypes.func,
  maxConcurrentUploads: PropTypes.number,
  acceptExtensions: PropTypes.string,
  sizeLimit: PropTypes.number,
  multipleFiles: PropTypes.bool
};
export default FileBrowserModal;
