import React from 'react';
import styles from './MaintainImportFileDates.module.css';
import { Spinner } from 'react-bootstrap';
import { AlertMessage } from '../../../../components/ui';
import { Helpers } from '../../../../utils/helpers';
import useMaintainImportFileDates from './useMaintainImportFileDates';

export const MaintainImportFileDates = () => {
  const manager = useMaintainImportFileDates();

  return (
    <>
      <div>
        {manager.defaultCustomer && (
          <div className={styles.defaultCustomer}>{manager.defaultCustomer.name}</div>
        )}
        {!manager.defaultCustomer && (
          <select
            className="form-select w-auto"
            value={manager.customerParameters.customerId ?? ''}
            onChange={(e) => manager.updateCustomerParams(parseInt(e.currentTarget.value))}>
            <option value={0}>- Select Customer -</option>
            {manager.customers.map((cust) => {
              return (
                <option key={cust.id} value={cust.id}>
                  {cust.name}
                </option>
              );
            })}
          </select>
        )}
      </div>
      <AlertMessage
        className="card-body mt-2 rounded-0"
        visible={manager.message.content.trim() !== ''}
        message={manager.message.content}
        status={manager.message.status}
      />
      {manager.loading && (
        <div className="m-2 text-center">
          <Spinner animation="border" />
        </div>
      )}
      {manager.customerParameters.customerId !== null && !manager.loading && (
        <>
          {manager.fileDateResults.length === 0 && (
            <div className={'card p-3 mt-3 ' + styles.noImportDates}>
              No import file dates found for this customer.
            </div>
          )}
          {manager.fileDateResults.length > 0 && (
            <div className="card p-3 mt-3">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Transaction</th>
                    <th scope="col">Description</th>
                    <th scope="col">Expires</th>
                    <th scope="col">Demand Fee Date</th>
                    <th scope="col">Due Date/AutoPay Date</th>
                    <th scope="col">Begin Interest Date</th>
                  </tr>
                </thead>
                <tbody>
                  {manager.fileDateResults.map((item) => {
                    return (
                      <React.Fragment key={item.rowId}>
                        <tr
                          className={
                            item.errors.length > 0
                              ? styles.rowError
                              : item.hasChanged
                              ? styles.rowHasChanged
                              : ''
                          }>
                          <td>{item.name}</td>
                          <td>{item.description}</td>
                          <td>
                            <input
                              type="date"
                              className={manager.getFieldClass(item.rowId, 'expires')}
                              value={Helpers.toInputFieldDate(item.expires)}
                              onChange={(e) =>
                                manager.rowChangeByKey(item.rowId, 'expires', e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="date"
                              className={manager.getFieldClass(item.rowId, 'interestDemandFeeDate')}
                              value={Helpers.toInputFieldDate(item.interestDemandFeeDate)}
                              disabled={Helpers.isNullOrWhitespace(item.interestDemandFeeDate)}
                              onChange={(e) =>
                                manager.rowChangeByKey(
                                  item.rowId,
                                  'interestDemandFeeDate',
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="date"
                              className={manager.getFieldClass(item.rowId, 'dueDate')}
                              value={Helpers.toInputFieldDate(item.dueDate)}
                              readOnly={!manager.hasModifyDueDatePermission}
                              onChange={(e) =>
                                manager.rowChangeByKey(item.rowId, 'dueDate', e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="date"
                              className={manager.getFieldClass(
                                item.rowId,
                                'interestCalculationDate'
                              )}
                              value={Helpers.toInputFieldDate(item.interestCalculationDate)}
                              readOnly={
                                !manager.hasModifyInterestDatePermission || !item.calculateInterest
                              }
                              onChange={(e) =>
                                manager.rowChangeByKey(
                                  item.rowId,
                                  'interestCalculationDate',
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        </tr>
                        {item.errors.length > 0 && (
                          <tr className={styles.rowErrorDetail}>
                            <td colSpan={2}></td>
                            <td colSpan={4} className="pb-3">
                              {item.errors.map((errorMsg, index) => {
                                return (
                                  <div key={index} className="alert alert-danger p-1 m-1">
                                    {errorMsg}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
              <div className="text-end">
                <button
                  className="btn btn-primary"
                  disabled={!manager.formHasChanges || manager.saving}
                  onClick={manager.saveChanges}>
                  Save Changes{' '}
                  {manager.saving && <Spinner size="sm" animation="border" className="ms-2" />}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
