import { useEffect, useState, useCallback, useRef } from 'react';
import { Api, Helpers } from '../../../../utils/helpers';
import debounce from 'lodash.debounce';
import { MessageAudienceType, SortDirection } from '../../../../utils/constants/enums';

const useMessageSearch = ({ roles, brandCustomers }) => {
  const [pagedResult, setPagedResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [audienceFilters, setAudienceFilters] = useState([]);
  const [searchParameters, setSearchParameters] = useState({
    keywords: '',
    sortDirection: SortDirection.ASCENDING,
    sortField: 'startdate',
    pageSize: 20,
    page: 1,
    filters: {
      options: null,
      startDate: null,
      endDate: null,
      audiences: null,
      roles: null
    }
  });

  const search = useCallback(async () => {
    setLoading(true);
    const apiResponse = await Api.post(
      '/policy/MessageManager/Messages/Search',
      JSON.stringify(searchParameters)
    );
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }

    setPagedResult(apiResponse.response);
    setLoading(false);
  }, [searchParameters]);

  const audienceToText = (audience) => {
    return Helpers.audienceToText(audience, roles, brandCustomers);
  };

  const hasBrandCustomerFilter = (audiences) => {
    return (
      audiences &&
      audiences.some(
        (x) =>
          x.type === MessageAudienceType.BRAND_CUSTOMER ||
          x.type === MessageAudienceType.BRAND_CUSTOMER_CLASSIFICATION
      )
    );
  };

  const debouncedKeywords = useRef(
    debounce(async (value) => {
      let params = { ...searchParameters };
      params.page = 1; //reset page if filtered by keyword
      params.keywords = value;
      setSearchParameters(params);
      console.log('search parameters changed');
    }, 500)
  ).current;

  useEffect(() => {
    return () => debouncedKeywords.cancel();
  }, [debouncedKeywords]);

  const updateKeywords = (value) => {
    debouncedKeywords(value);
  };

  const updateFilter = async (prop, value) => {
    let params = { ...searchParameters };
    params.filters[prop] = value?.toString();
    params.page = 1; //reset page if filtered
    setSearchParameters(params);
  };

  const updateMessageOptionsFilter = async (value) => {
    let params = { ...searchParameters };
    let currentOption = params.filters.options ? parseInt(params.filters.options) : 0;

    if (Helpers.hasFlag(params.filters.options, value)) {
      currentOption ^= value;
    } else {
      currentOption |= value;
    }

    //set 0 to null so all are returned instead of just 'nones'
    params.filters.options = currentOption === 0 ? null : currentOption.toString();
    params.page = 1; //reset page if filtered by options
    setSearchParameters(params);
  };

  const updateBrandCustomerSelection = async (audience) => {
    let audFilters = [...audienceFilters];
    audFilters = audFilters.filter(
      (x) =>
        !(
          x.type === MessageAudienceType.BRAND_CUSTOMER ||
          x.type === MessageAudienceType.BRAND_CUSTOMER_CLASSIFICATION
        )
    );
    audFilters.push(audience);
    setAudienceFilters(audFilters);
    updateAudienceFilter(audFilters);
  };

  const updateAudienceFilter = async (audienceFilters) => {
    let params = { ...searchParameters };
    params.filters['audiences'] = JSON.stringify(audienceFilters);
    params.page = 1; //reset page if filtered
    setSearchParameters(params);
  };

  const updateRoleFilter = async (roleId) => {
    let audFilters = [...audienceFilters];
    if (isRoleFilterChecked(roleId)) {
      //remove it
      audFilters = audFilters.filter((x) => {
        return !(x.type === MessageAudienceType.ROLE && x.value === roleId);
      });
    } else {
      //add it
      audFilters.push({
        type: MessageAudienceType.ROLE,
        value: roleId
      });
    }
    setAudienceFilters(audFilters);
    updateAudienceFilter(audFilters);
  };

  const isRoleFilterChecked = (roleId) => {
    return audienceFilters.some((x) => x.type === MessageAudienceType.ROLE && x.value === roleId);
  };

  const updateSorting = (field, direction) => {
    let params = { ...searchParameters };
    params.sortDirection = direction;
    params.sortField = field;
    setSearchParameters(params);
  };

  const changePage = (page) => {
    let params = { ...searchParameters };
    params.page = page;
    setSearchParameters(params);
  };

  useEffect(() => {
    search();
  }, [search, searchParameters]);

  return {
    loading,
    pagedResult,
    searchParameters,
    changePage,
    updateFilter,
    updateKeywords,
    updateSorting,
    updateMessageOptionsFilter,
    updateBrandCustomerSelection,
    updateRoleFilter,
    isRoleFilterChecked,
    audienceToText,
    hasBrandCustomerFilter
  };
};

export default useMessageSearch;
