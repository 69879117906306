import { Helpers } from '../../../utils/helpers';
import ebpImage from '../../../assets/images/ebp-option.png';
import useFileImportMonitor from './useFileImportMonitor';
import { PagingControls, CheckBox } from '../../../components/ui';
import styles from './FileImportMonitor.module.css';
import { Spinner } from 'react-bootstrap';

export const FileImportMonitor = () => {
  const manager = useFileImportMonitor();

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-12">
          <div>
            <CheckBox
              key="cbAutoRefresh"
              value="cbAutoRefresh"
              label="Auto Refresh"
              checked={manager.autoRefresh}
              checkOnChange={manager.checkBoxChanged}
            />
          </div>
          {manager.defaultCustomer && (
            <div className="researchCustomerName">{manager.defaultCustomer.name}</div>
          )}
          {!manager.defaultCustomer && (
            <>
              <select
                id="customer"
                className="form-select"
                aria-label="Customer selection"
                value={manager.searchParameters.customerId ?? ''}
                onChange={(e) => manager.loadData(Helpers.tryParseInt(e.target.value, null))}>
                <option>- Select -</option>
                {manager.customers.map((customer) => {
                  return (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  );
                })}
              </select>
            </>
          )}
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-sm-12">
          {manager.apiError.trim() !== '' && (
            <div className="alert-danger mb-1 p-2">{manager.apiError}</div>
          )}
          <div className="card">
            <div className="card-body p-3">
              {manager.loading && <Spinner animation="border" />}
              {!manager.loading && (
                <div className="table-responsive">
                  {manager.pagedResult && manager.pagedResult.results.length === 0 && (
                    <>
                      <h2>No Results Found</h2>
                      <div>Please select a different customer.</div>
                    </>
                  )}
                  {manager.pagedResult?.results.length > 0 && (
                    <>
                      <div className="resultCount">
                        {manager.pagedResult.resultCount} result
                        {manager.pagedResult.resultCount === 1 ? '' : 's'} found.
                      </div>
                      <h2>Results</h2>
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Transaction</th>
                            <th scope="col">File Name</th>
                            <th scope="col">Start/Complete</th>
                            <th scope="col">Due Date</th>
                            <th scope="col">Expiration</th>
                            <th scope="col">User</th>
                            <th scope="col">Status</th>
                            <th scope="col" className={styles.colCenter}>
                              Rec Count
                            </th>
                            <th scope="col" className={styles.colRight}>
                              Batch Total
                            </th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {manager.pagedResult?.results.map((result, index) => {
                            return (
                              <tr key={index}>
                                <td>{result.transactionName}</td>
                                <td>{result.fileName}</td>
                                <td className="text-nowrap">
                                  {Helpers.toShortDateTime(result.requestDate)}
                                  <br />
                                  {Helpers.toShortDateTime(result.completeDate)}
                                </td>
                                <td>{Helpers.toShortDate(result.originalDueDate)}</td>
                                <td>{Helpers.toShortDate(result.expirationDate)}</td>
                                <td>{result.userName}</td>
                                <td>
                                  <div dangerouslySetInnerHTML={{ __html: result.status }} />
                                </td>
                                <td align="center">{result.recordCount}</td>
                                <td align="right">{Helpers.formatCurrency(result.batchTotal)}</td>
                                <td>
                                  {result.ebpid != null && (
                                    <a href={manager.adminEbpLink(result.ebpid)}>
                                      <img src={ebpImage} className={styles.ebpIcon} />
                                    </a>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div>
                        <PagingControls
                          page={manager.pagedResult.currentPage}
                          pageCount={manager.pagedResult.pageCount}
                          changePageCallback={manager.changePage}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
