import React from 'react';
import Select from 'react-select';
import styles from '../../../PayPal.module.css';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import useLegalBusinessInformation from './useLegalBusinessInformation';

export const LegalBusinessInformation = ({ containerFormData, formStatusCallback }) => {
  const manager = useLegalBusinessInformation(containerFormData, formStatusCallback);

  return (
    <>
      {!manager.loading && (
        <div>
          <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
            <thead>
              <tr>
                <th className={styles.paypalOnboardingSectionHeader}>Legal Business Information</th>
                {manager.componentStatus === FormComponentStatus.COMPLETE && (
                  <th className={styles.paypalOnboardingCompleteStatusHeader}>Complete</th>
                )}
                {manager.componentStatus === FormComponentStatus.INCOMPLETE && (
                  <th className={styles.paypalOnboardingIncompleteStatusHeader}>Incomplete</th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label>Business Type:</label>
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={manager.formData.businessType}
                    readOnly={true}
                    disabled={true}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="businessMerchantCategoryCode">
                    <span className="req">*</span>&nbsp;Merchant Category Code:
                  </label>
                </td>
                <td>
                  <Select
                    name="businessMerchantCategoryCode"
                    id="businessMerchantCategoryCode"
                    value={manager.defaultSelectValue}
                    options={manager.merchantCategoryCodes}
                    onChange={manager.updateSelect}
                  />
                  {manager.errors.businessMerchantCategoryCode && (
                    <div className="alert-danger mt-1 p-2">Merchant Category Code is required.</div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="businessLegalName">
                    <span className="req">*</span>Legal Name:
                  </label>
                </td>
                <td>
                  <input
                    name="businessLegalName"
                    id="businessLegalName"
                    type="text"
                    className="form-control"
                    value={manager.formData.businessLegalName}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.businessLegalName && (
                    <div className="alert-danger mt-1 p-2">Legal Name is required.</div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="businessEmail">
                    <span className="req">*</span>Email Address:
                  </label>
                </td>
                <td>
                  <input
                    name="businessEmail"
                    id="businessEmail"
                    type="text"
                    className="form-control"
                    value={manager.formData.businessEmail}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.businessEmail && (
                    <div className="alert-danger mt-1 p-2">Email Address is required.</div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="businessWebsite">Website Address:</label>
                </td>
                <td>
                  <input
                    name="businessWebsite"
                    id="businessWebsite"
                    type="text"
                    className="form-control"
                    value={manager.formData.businessWebsite}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table className={'table ' + styles.nestedStripedTable}>
                    <thead>
                      <tr>
                        <th className={styles.nestedStripedTableHeader} colSpan={2}>
                          Legal Business Information - Phone Number
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="businessPhoneNumber">
                            <span className="req">*</span>Phone Number:
                          </label>
                        </td>
                        <td>
                          <input
                            name="businessPhoneNumber"
                            id="businessPhoneNumber"
                            type="text"
                            className="form-control"
                            placeholder="Numerical digits only: 5081234567"
                            value={manager.formData.businessPhoneNumber}
                            onChange={(e) => manager.updateValue(e.target)}
                            onBlur={(e) => manager.updateValue(e.target)}
                          />
                          {manager.errors.businessPhoneNumber && (
                            <div className="alert-danger mt-1 p-2">
                              Phone Number is required. Numerical digits only, no parentheses or
                              dashes.
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default LegalBusinessInformation;
