import React, { useEffect, useState, useCallback } from 'react';
import { PagingControls } from '../../../components/ui';
import { Api, Helpers } from '../../../utils/helpers';
import { Link, useNavigate } from 'react-router-dom';
import { MessageOptions, MessageViewType, SortDirection } from '../../../utils/constants/enums';

export const MessageList = () => {
  const navigate = useNavigate();
  const [pagedResult, setPagedResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchParameters, setSearchParameters] = useState({
    keywords: '',
    sortDirection: SortDirection.DESCENDING,
    sortField: 'options',
    pageSize: 30,
    page: 1
  });

  const search = useCallback(async () => {
    setLoading(true);
    const apiResponse = await Api.post('/policy/Messages/Search', JSON.stringify(searchParameters));
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }

    setPagedResult(apiResponse.response);
    setLoading(false);
  }, [searchParameters]);

  const changePage = (page) => {
    let params = { ...searchParameters };
    params.page = page;
    setSearchParameters(params);
  };

  useEffect(() => {
    search();
  }, [search, searchParameters]);

  return (
    <div>
      <div className="titleBar">
        <h1 className="pageTitle">
          <button onClick={() => navigate(-1)} className="btn btn-primary d-block mb-3">
            <i className="icofont-arrow-left"></i> Back
          </button>
          Messages
        </h1>
      </div>
      <div className="card">
        <div className="card-body">
          {!loading && (
            <>
              {pagedResult?.results?.map((message) => {
                let linkStyle = 'message-link';
                if (
                  Helpers.hasFlag(message.messageOptions, MessageOptions.HIGH_PRIORITY) ||
                  Helpers.hasFlag(message.messageOptions, MessageOptions.REQUIRES_ACKNOWLEDGEMENT)
                ) {
                  linkStyle = 'message-link text-danger';
                }

                const iconStyle =
                  message.viewType === MessageViewType.UNREAD
                    ? 'message-icon unread'
                    : 'message-icon';

                return (
                  <div key={message.id} className="mb-3 pb-3 border-bottom">
                    <div className="d-flex">
                      <div className="pe-2 pt-2">
                        <div className={iconStyle}></div>
                      </div>
                      <div className="flex-grow-1 ">
                        <Link to={`/messages/${message.id}`} className={linkStyle}>
                          <strong>{message.title}</strong>
                        </Link>{' '}
                        -{' '}
                        <em>
                          {Helpers.toShortDate(message.startDateUtc ?? message.dateCreatedUtc)}
                        </em>
                      </div>
                    </div>
                    <div className="card-body m-0 p-0">{message.preview}</div>
                  </div>
                );
              })}
              <div>
                <PagingControls
                  page={pagedResult.currentPage}
                  pageCount={pagedResult.pageCount}
                  changePageCallback={changePage}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
