import { useState } from 'react';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import { Helpers } from '../../../../../../../utils/helpers/Helpers';

const useBeneficialOwner = (containerFormData, formStatusCallback) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    beneficialOwnerPercentageOfOwnership: containerFormData.beneficialOwnerPercentageOfOwnership,
    beneficialOwnerGivenName: containerFormData.beneficialOwnerGivenName,
    beneficialOwnerSurname: containerFormData.beneficialOwnerSurname,
    beneficialOwnerEmailAddress: containerFormData.beneficialOwnerEmailAddress,
    beneficialOwnerPhoneNumber: containerFormData.beneficialOwnerPhoneNumber,
    beneficialOwnerDOB: containerFormData.beneficialOwnerDOB,
    beneficialOwnerAddressLine1: containerFormData.beneficialOwnerAddressLine1,
    beneficialOwnerAddressLine2: containerFormData.beneficialOwnerAddressLine2,
    beneficialOwnerAdminArea1: containerFormData.beneficialOwnerAdminArea1,
    beneficialOwnerAdminArea2: containerFormData.beneficialOwnerAdminArea2,
    beneficialOwnerPostalCode: containerFormData.beneficialOwnerPostalCode,
    beneficialOwnerIdentificationDocumentType:
      containerFormData.beneficialOwnerIdentificationDocumentType,
    beneficialOwnerIdentificationDocumentIdNumber:
      containerFormData.beneficialOwnerIdentificationDocumentIdNumber
  });

  const isValidField = (name, value) => {
    //place any custom field validation logic in here
    switch (name) {
      case 'beneficialOwnerAddressLine2':
        return true; //optional field
      case 'beneficialOwnerEmailAddress':
        return Helpers.isValidEmail(value);
      case 'beneficialOwnerDOB':
        return Helpers.isValidUnformattedDob(value);
      case 'beneficialOwnerPhoneNumber':
        return Helpers.isValidUnformattedPhoneNumber(value);
      case 'beneficialOwnerIdentificationDocumentIdNumber':
        return Helpers.isValidIdDocumentNumber(
          Helpers.isNullOrWhitespace(formData.beneficialOwnerIdentificationDocumentType)
            ? 'UNSET'
            : formData.beneficialOwnerIdentificationDocumentType,
          value
        );
      default:
        return !Helpers.isNullOrWhitespace(value);
    }
  };

  const isMissingValues = (fields) => {
    return Object.entries(fields).some(([key, value]) => {
      return !isValidField(key, value);
    });
  };

  const [componentStatus, setComponentStatus] = useState(
    isMissingValues(formData) ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
  );

  const updateValue = ({ name, value }) => {
    const fields = { ...formData };
    fields[name] = value;
    setFormData(fields);

    setErrors((err) => {
      return { ...err, [name]: !isValidField(name, value) };
    });

    const missingValues = isMissingValues(fields);
    setComponentStatus(
      missingValues ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
    );
    formStatusCallback('beneficialOwner', !missingValues, fields);
  };

  return {
    componentStatus,
    formData,
    errors,
    updateValue
  };
};

export default useBeneficialOwner;
