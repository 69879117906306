import Modal from 'react-bootstrap/Modal';
import NumberFormat from 'react-number-format';
import { Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretRight,
  faClock,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import { Helpers } from '../../../utils/helpers';
import { FileBrowser, HelpTip, AlertMessage, ModalDialog } from '../../../components/ui';
import { InterestType, TransactionStatus } from '../../../utils/constants/enums';
import styles from './ImportConfigure.module.css';
import useImportConfigure from './useImportConfigure';

export const ImportConfigure = () => {
  const manager = useImportConfigure();
  return (
    <>
      <ModalDialog
        showDialog={manager.modalDialog.visible}
        heading={manager.modalDialog.heading}
        message={manager.modalDialog.message}
        okCallback={manager.modalDialog.okCallback}
        okButtonText={manager.modalDialog.okButtonText}
        cancelCallback={manager.modalDialog.cancelCallback}
        cancelButtonText={manager.modalDialog.cancelButtonText}
      />

      {/* Scheduled Imports Modal */}
      <Modal
        show={manager.showScheduledImportsModal}
        onHide={manager.closeScheduledImportModal}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered={true}>
        <Modal.Header closeButton={true}>
          <Modal.Title>
            Scheduled Imports for {manager.viewScheduledImports.customerName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Transaction</th>
                <th>File</th>
                <th>Scheduled On</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {manager.viewScheduledImports.scheduledImports.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className="fw-bold mb-1">{item.transactionName}</div>
                      <div>{item.importDetails?.description ?? ''}</div>
                      {item.importDetails && !item.showDetails && (
                        <a
                          href="#"
                          className={styles.detailLink}
                          onClick={(e) => {
                            e.preventDefault();
                            manager.updateExistingSchedule(item.id, 'showDetails', true);
                          }}>
                          View Details{' '}
                          <FontAwesomeIcon size="sm" className="ms-0" icon={faCaretRight} />
                        </a>
                      )}
                      {item.importDetails && item.showDetails && (
                        <>
                          <a
                            href="#"
                            className={styles.detailLink}
                            onClick={(e) => {
                              e.preventDefault();
                              manager.updateExistingSchedule(item.id, 'showDetails', false);
                            }}>
                            Hide Details{' '}
                            <FontAwesomeIcon size="sm" className="ms-0" icon={faCaretDown} />
                          </a>
                          <ul className="mt-2 ms-0 ps-3">
                            <li>
                              Due Date/Autopay Date:{' '}
                              {Helpers.toShortDate(item.importDetails.dueDate ?? '')}
                            </li>
                            <li>
                              Expiration Date:{' '}
                              {Helpers.toShortDate(item.importDetails.expirationDate ?? '')}
                            </li>
                            {item.importDetails.showInterestControls && (
                              <>
                                <li>
                                  Interest Calc Date:{' '}
                                  {Helpers.toShortDate(
                                    item.importDetails.interestCalculationDate ?? ''
                                  )}
                                </li>
                                {item.importDetails.demandFee > 0 && (
                                  <>
                                    <li>
                                      Demand Fee:{' '}
                                      {Helpers.formatCurrency(item.importDetails.demandFee)}
                                    </li>
                                    <li>
                                      Date to Apply Demand Fee:{' '}
                                      {Helpers.toShortDate(item.importDetails.demandFeeDate)}
                                    </li>
                                  </>
                                )}
                              </>
                            )}
                          </ul>
                        </>
                      )}
                    </td>
                    <td>{item.filePath}</td>
                    <td>
                      <DatePicker
                        selected={item.runOnDateUtc && new Date(item.runOnDateUtc)}
                        onChange={(date) =>
                          manager.updateExistingSchedule(
                            item.id,
                            'runOnDateUtc',
                            date?.toISOString() ?? null
                          )
                        }
                        showTimeSelect={true}
                        dateFormat="M/d/yyyy h:mm aa"
                        className="form-control"
                        isClearable={false}
                        placeholderText=""
                      />
                      {item.invalidScheduleDate && (
                        <div className="mt-1 alert-danger p-2">
                          Scheduled import date is required.
                        </div>
                      )}
                      {item.invalidScheduleDateTime && (
                        <div className="mt-1 alert-danger p-2">
                          Scheduled import must be at least 30 minutes from now.
                        </div>
                      )}
                      {item.dueDateScheduleDateError && (
                        <div className="mt-1 alert-danger p-2">
                          Scheduled import date must be before the due date.
                        </div>
                      )}
                      {item.autoPayScheduleDateError && (
                        <div className="mt-1 alert-danger p-2">
                          Scheduled import date must be at least 5 days before due date for autopay
                          transactions.
                        </div>
                      )}
                    </td>
                    <td className="text-end">
                      <button
                        className="btn btn-sm text-nowrap"
                        onClick={() => manager.showCancelScheduleDialog(item.id)}>
                        Cancel Import
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="mt-1">
            <button
              className="btn"
              disabled={!manager.hasScheduleChanges()}
              onClick={() => manager.saveScheduleChanges()}>
              Save Schedule Changes
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* File Browser Modal */}
      <Modal
        show={manager.showFileModal}
        onHide={manager.closeFileModal}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered={true}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Select Import File</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <FileBrowser
            uploadApiEndpoint={`/files/containers/${manager.containerPath}/blobs/upload`}
            browseApiEndpoint={`/files/containers/${manager.containerPath}/blobs/directory`}
            imageApiEndpoint={`/files/containers/${manager.containerPath}/file`}
            downloadApiEndpoint={`/files/containers/${manager.containerPath}/blobs/download`}
            selectFileCallback={(fileDetails) => {
              manager.setFileDetails(fileDetails);
              manager.closeFileModal();
            }}
            acceptExtensions=""
            sizeLimit={314572800}
            maxConcurrentUploads={4}
            multipleFiles={true}
          />
        </Modal.Body>
      </Modal>

      {/* Pre-processor Status Modal */}
      <Modal show={manager.showModal} backdrop="static" keyboard={false} size="md" centered={true}>
        <Modal.Header>
          <Modal.Title>Pre-Processing Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {manager.loading && (
              <div className="col-12 text-center">
                <Spinner animation="border" />
              </div>
            )}
            <div className="col-12">
              {manager.preprocessingResult.map((item, index) => {
                return (
                  <div className="row mb-2" key={index}>
                    {index > 0 && <hr />}
                    <div className={styles.resultTxHeading}>{item.transactionName}</div>
                    {item.preprocessResults.map((result, index) => {
                      return (
                        <div className="row" key={index}>
                          {result.label && <div className="col-4 fw-bold">{result.label}</div>}
                          <div className={result.label ? 'col-8' : 'col-12'}>{result.value}</div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <AlertMessage
            className="card-body rounded-0 p-2 mb-0 mt-2"
            visible={manager.preprocessMessage.content.trim() !== ''}
            message={manager.preprocessMessage.content}
            status={manager.preprocessMessage.status}
          />
          {manager.showContinue > 0 && (
            <>
              <div className="form-group mt-4">
                <label>When would you like to run the import?</label>
                <div className={styles.scheduleTypeOptions}>
                  <div className={'form-check ' + styles.scheduleTypeOption}>
                    <label
                      className={
                        !manager.scheduleDetails.runLater
                          ? styles.scheduleTypeLabelActive
                          : styles.scheduleTypeLabel
                      }>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="scheduleType"
                        onChange={() => manager.updateScheduleDetails('runLater', false)}
                        checked={!manager.scheduleDetails.runLater}
                      />
                      Run import now
                    </label>
                  </div>
                  <div className={'form-check ' + styles.scheduleTypeOption}>
                    <label
                      className={
                        manager.scheduleDetails.runLater
                          ? styles.scheduleTypeLabelActive
                          : styles.scheduleTypeLabel
                      }>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="scheduleType"
                        onChange={() => manager.updateScheduleDetails('runLater', true)}
                        checked={manager.scheduleDetails.runLater}
                      />
                      Run at a future time
                    </label>
                  </div>
                </div>
                {manager.scheduleDetails.runLater && (
                  <>
                    <label htmlFor="dueDate">Schedule import for the following date/time:</label>
                    <DatePicker
                      id="dueDate"
                      selected={
                        manager.scheduleDetails.runOnDateUtc &&
                        new Date(manager.scheduleDetails.runOnDateUtc)
                      }
                      onChange={(date) =>
                        manager.updateScheduleDetails('runOnDateUtc', date?.toISOString() ?? null)
                      }
                      showTimeSelect={true}
                      dateFormat="M/d/yyyy h:mm aa"
                      className="form-control"
                      isClearable={false}
                      placeholderText=""
                    />
                    {manager.errors.invalidScheduleDate && (
                      <div className="mt-1 alert-danger p-2">
                        Scheduled import date is required.
                      </div>
                    )}
                    {manager.errors.invalidScheduleDateTime && (
                      <div className="mt-1 alert-danger p-2">
                        Scheduled import must be at least 30 minutes from now.
                      </div>
                    )}
                    {manager.errors.dueDateScheduleDateError && (
                      <div className="mt-1 alert-danger p-2">
                        Scheduled import date must be before the due date.
                      </div>
                    )}
                    {manager.errors.autoPayScheduleDateError && (
                      <div className="mt-1 alert-danger p-2">
                        Scheduled import date must be at least 5 days before due date for autopay
                        transactions.
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <div>
            <button className="btn btn-primary" onClick={() => manager.closeModal()}>
              Cancel
            </button>
          </div>
          <div>
            {manager.showContinue && (
              <>
                {manager.scheduleDetails.runLater && (
                  <button onClick={manager.createScheduledImport} className="btn btn-primary">
                    Schedule Import
                  </button>
                )}
                {!manager.scheduleDetails.runLater && (
                  <button onClick={manager.startParse} className="btn btn-primary">
                    Run Import
                  </button>
                )}
              </>
            )}
          </div>
        </Modal.Footer>
      </Modal>

      <AlertMessage
        className="card-body rounded-0 mb-2"
        visible={manager.message.content.trim() !== ''}
        message={manager.message.content}
        status={manager.message.status}
        scrollTo={true}
        scrollToDelay={250}
      />
      <div className="row">
        <div
          className={
            manager.viewScheduledImports.scheduledImports.length > 0
              ? 'col-xl-9 col-lg-8 col-md-6 col-sm-12'
              : 'col-12'
          }>
          <label htmlFor="customer">
            Customer {!manager.defaultCustomer && <span className="req">*</span>}
          </label>
          {manager.defaultCustomer && (
            <div className="researchCustomerName">{manager.defaultCustomer.name}</div>
          )}
          {!manager.defaultCustomer && (
            <>
              <select
                id="customer"
                className="form-select"
                aria-label="Customer selection"
                value={manager.searchParameters.customerId ?? ''}
                onChange={(e) =>
                  manager.updateSearchParams(
                    'customerId',
                    Helpers.tryParseInt(e.target.value, null)
                  )
                }>
                <option>- Select -</option>
                {manager.customers.map((customer) => {
                  return (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  );
                })}
              </select>
            </>
          )}
        </div>
        {manager.viewScheduledImports.scheduledImports.length > 0 && (
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <label>&nbsp;</label>
            <a
              href="#"
              className="btn w-100"
              onClick={(e) => {
                e.preventDefault();
                manager.setShowScheduledImports(true);
              }}>
              <FontAwesomeIcon size="lg" className="me-1" icon={faClock} />
              View Scheduled Imports
            </a>
          </div>
        )}
      </div>
      <div className="col-12 mt-3">
        <label htmlFor="transaction">
          Transaction {!manager.defaultCustomer && <span className="req">*</span>}
        </label>
        <select
          id="transaction"
          className="form-select"
          aria-label="Transaction selection"
          disabled={(manager.transactions?.length ?? 0) === 0}
          value={manager.searchParameters.transactionId ?? ''}
          onChange={(e) =>
            manager.updateSearchParams('transactionId', Helpers.tryParseInt(e.target.value, null))
          }>
          <option>- Select -</option>
          {manager.transactions?.map((transaction) => {
            return (
              <option
                key={transaction.id}
                value={transaction.id}
                className={transaction.status === TransactionStatus.INACTIVE ? 'inactive' : ''}>
                {transaction.name}
                {transaction.status === TransactionStatus.INACTIVE && ' [inactive]'}
              </option>
            );
          })}
        </select>
        {manager.errors.missingTransaction && (
          <div className="mt-1 alert-danger p-2">Transaction selection is required.</div>
        )}
      </div>
      {!manager.searchParameters.showSecondaryTransaction && (
        <div className="mt-1">
          <a
            href="#"
            className="text-decoration-none"
            onClick={(e) => {
              e.preventDefault();
              manager.toggleShowSecondaryTransaction();
            }}>
            <FontAwesomeIcon size="sm" className="me-1" icon={faPlusCircle} />
            Add a secondary transaction
          </a>
        </div>
      )}
      {manager.searchParameters.showSecondaryTransaction && (
        <>
          <div className="col-12 mt-3">
            <label htmlFor="secondaryTransaction">Secondary Transaction</label>
            <select
              id="secondaryTransaction"
              className="form-select"
              aria-label="Secondary transaction selection"
              disabled={(manager.transactions?.length ?? 0) === 0}
              value={manager.searchParameters.secondaryTransactionId ?? ''}
              onChange={(e) =>
                manager.updateSearchParams(
                  'secondaryTransactionId',
                  Helpers.tryParseInt(e.target.value, null)
                )
              }>
              <option>- Select -</option>
              {manager.transactions?.map((transaction) => {
                return (
                  <option
                    key={transaction.id}
                    value={transaction.id}
                    className={transaction.status === TransactionStatus.INACTIVE ? 'inactive' : ''}>
                    {transaction.name}
                    {transaction.status === TransactionStatus.INACTIVE && ' [inactive]'}
                  </option>
                );
              })}
            </select>
          </div>
          {manager.errors.transactionsNotCompatible && (
            <div className="mt-1 alert-danger p-2">Selected transactions are not compatible.</div>
          )}
        </>
      )}

      <div className="col-12 mt-3">
        <label htmlFor="shortDescription">
          Short Description <span className="req">*</span>
        </label>
        <input
          id="shortDescription"
          type="text"
          className="form-control"
          maxLength={50}
          value={manager.parseRequest.description ?? ''}
          onChange={(e) => manager.updateParseRequest('description', e.target.value)}
        />
        {manager.errors.missingDescription && (
          <div className="mt-1 alert-danger p-2">Description is required.</div>
        )}
      </div>
      <div className="row mt-3">
        <div className="col-xl-4 col-lg-6 col-sm-12">
          <label htmlFor="dueDate">
            Due Date/Autopay Date <span className="req">*</span>
          </label>
          <input
            id="dueDate"
            type="date"
            className="form-control"
            value={Helpers.toInputFieldDate(manager.parseRequest.dueDate)}
            onChange={(e) => manager.updateParseRequest('dueDate', e.target.value)}
          />
          {manager.errors.dueDateError && (
            <div className="mt-1 alert-danger p-2">Due Date/AutoPay date is required.</div>
          )}
          {manager.errors.dueDateBeforeTodayError && (
            <div className="mt-1 alert-danger p-2">
              Due Date/AutoPay date cannot be before today.
            </div>
          )}
          {manager.errors.autoPayDueDateError && (
            <div className="mt-1 alert-danger p-2">
              Due Date/AutoPay date must be {Helpers.toShortDate(manager.autoPayDueDateDisplay)} or
              later for autopay transactions.
            </div>
          )}
          <HelpTip className="mt-2">The date the bills are due.</HelpTip>
        </div>
        <div className="col-xl-4 col-lg-6 col-sm-12">
          <label htmlFor="expirationDate">
            Data Expires On <span className="req">*</span>
          </label>
          <input
            id="expirationDate"
            type="date"
            className="form-control"
            value={Helpers.toInputFieldDate(manager.parseRequest.expirationDate)}
            onChange={(e) => manager.updateParseRequest('expirationDate', e.target.value)}
          />
          {manager.errors.missingExpirationDate && (
            <div className="mt-1 alert-danger p-2">Expiration date is required.</div>
          )}
          {manager.errors.expirationLessThanDueDateError && (
            <div className="mt-1 alert-danger p-2">
              Expiration date must be after the Due Date/AutoPay date.
            </div>
          )}
          <HelpTip className="mt-2">The file will be removed at 12am on the date selected.</HelpTip>
        </div>
      </div>
      {manager.searchParameters.showInterestControls && (
        <>
          <div className="row mt-3">
            <div className="col-xl-4 col-lg-12">
              <label htmlFor="interest">
                Interest Calc Date/Net Payments{' '}
                {(!manager.searchParameters.netOutPayments ||
                  manager.searchParameters.interestTypeId !== InterestType.NONE_0PCT) && (
                  <span className="req">*</span>
                )}
              </label>
              <input
                id="interest"
                type="date"
                className="form-control"
                value={Helpers.toInputFieldDate(manager.parseRequest.interestCalculationDate)}
                onChange={(e) =>
                  manager.updateParseRequest('interestCalculationDate', e.target.value)
                }
              />
              {manager.errors.interestDateError && (
                <div className="mt-1 alert-danger p-2">Interest Calculation Date is required.</div>
              )}
              {manager.errors.interestDateRangeError && (
                <div className="mt-1 alert-danger p-2">
                  Interest calculation date must be after the AutoPay date and before the expiration
                  date on transactions that have interest calculation.
                </div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12">
              <label htmlFor="demandFee">Demand Fee</label>
              <NumberFormat
                id="demandFee"
                className="form-control"
                decimalScale={2}
                prefix="$"
                placeholder="$0.00"
                fixedDecimalScale={true}
                allowNegative={false}
                onFocus={(event) => event.target.select()}
                value={manager.parseRequest.demandFee ?? ''}
                onValueChange={(values) =>
                  manager.updateParseRequest('demandFee', values.floatValue)
                }
              />
              {manager.errors.demandFeeMissingError && (
                <div className="mt-1 alert-danger p-2">
                  Demand fee amount is required on transactions that have interest calculation and a
                  demand fee date.
                </div>
              )}
            </div>
            {Helpers.tryParseFloat(manager.parseRequest.demandFee, 0) > 0 && (
              <div className="col-xl-4 col-lg-8 col-md-7 col-sm-12">
                <label htmlFor="demandFeeDate">
                  Date to Apply Demand Fee <span className="req">*</span>
                </label>
                <input
                  id="demandFeeDate"
                  type="date"
                  className="form-control"
                  value={Helpers.toInputFieldDate(manager.parseRequest.demandFeeDate, '')}
                  onChange={(e) => manager.updateParseRequest('demandFeeDate', e.target.value)}
                />
                {manager.errors.demandFeeDateMissingError && (
                  <div className="mt-1 alert-danger p-2">
                    Demand fee date is required on transactions that have interest calculation and a
                    demand fee.
                  </div>
                )}
                {manager.errors.demandDateLessThanDueDateError && (
                  <div className="mt-1 alert-danger p-2">
                    Demand fee date must be after the Due Date/AutoPay date.
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <label>
            File to Import <span className="req">*</span>
          </label>
          <div className={styles.fileSelectContainer}>
            <div
              className={styles.selectedFileName}
              onClick={() => {
                if (manager.searchParameters.customerId) manager.setShowFileModal(true);
              }}>
              {manager.fileDetails?.name ?? 'No file selected'}
            </div>
            <button
              onClick={() => manager.setShowFileModal(true)}
              className="btn btn-sm"
              disabled={!manager.searchParameters.customerId}>
              Select File
            </button>
          </div>
          {!manager.searchParameters.customerId && (
            <HelpTip className="mt-2">Customer must be selected to enable file selection.</HelpTip>
          )}
          {manager.errors.missingFile && (
            <div className="row">
              <div className="col-12 mt-2">
                <div className="mt-1 alert-danger p-2">Please select a file.</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-12 mt-4">
        <button className="btn btn-primary" onClick={manager.startProcess}>
          Start Pre-Process
        </button>
      </div>
    </>
  );
};

export default ImportConfigure;
