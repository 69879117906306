import PropTypes from 'prop-types';
import useTransactionSelector from './useTransactionSelector';
import { TransactionStatus } from '../../../utils/constants/enums';
import styles from './TransactionSelector.module.css';

export const DisplayMode = {
  STACKED: 0,
  SIDE: 1,
  SIDE_ALT: 2
};

export const TransactionSelector = ({
  activeBrandId = null,
  visible = true,
  displayMode = DisplayMode.SIDE,
  showInactive = false,
  showDeleted = false,
  showInactiveFilter = false,
  showDeletedFilter = false,
  showRequiredAsterisk = false,
  onChange,
  onCustomerChange,
  onTransactionChange
}) => {
  const manager = useTransactionSelector({
    activeBrandId,
    showInactive,
    showDeleted,
    onChange,
    onCustomerChange,
    onTransactionChange
  });

  if (!visible) return;

  let displayClass = '';
  let displayCustomerClass = '';
  let displayTransactionClass = '';

  switch (displayMode) {
    case DisplayMode.SIDE:
    case DisplayMode.SIDE_ALT:
      displayClass = styles.displaySide;
      displayCustomerClass = styles.displaySideCustomer;
      displayTransactionClass = styles.displaySideTransaction;
      break;
    case DisplayMode.STACKED:
      displayClass = styles.displayStacked;
      displayCustomerClass = styles.displayStackedCustomer;
      displayTransactionClass = styles.displayStackedTransaction;
      break;
  }

  const InactiveFilter = () => (
    <div className={styles.filterContainer + ' form-check'}>
      <label className="form-check-label">
        <input
          className="form-check-input"
          type="checkbox"
          onChange={() => manager.toggleInactiveTransactions()}
          checked={manager.showInactiveTransactions}
        />
        <em>Show Inactive</em>
      </label>
    </div>
  );

  const DeletedFilter = () => (
    <div className={styles.filterContainer + ' form-check'}>
      <label className="form-check-label">
        <input
          className="form-check-input"
          type="checkbox"
          onChange={() => manager.toggleDeletedTransactions()}
          checked={manager.showDeletedTransactions}
        />
        <em>Show Deleted</em>
      </label>
    </div>
  );

  return (
    <div className={displayClass}>
      <div className={displayCustomerClass}>
        <label htmlFor="transaction-selector-customer">
          Customer{' '}
          {!manager.defaultCustomer && showRequiredAsterisk && <span className="req">*</span>}
        </label>
        {manager.defaultCustomer && (
          <div className="researchCustomerName">{manager.defaultCustomer.name}</div>
        )}
        {!manager.defaultCustomer && (
          <>
            <select
              id="transaction-selector-customer"
              className="form-select"
              aria-label="Customer selection"
              value={manager.selectedOptions.customerId ?? ''}
              onChange={manager.updateCustomerId}>
              <option>- Select -</option>
              {manager.customers.map((customer) => {
                return (
                  <option key={customer.id} value={customer.id}>
                    {customer.name}
                  </option>
                );
              })}
            </select>
          </>
        )}
      </div>

      <div className={displayTransactionClass}>
        <label htmlFor="transaction-selector-transaction">
          Transaction
          {!manager.defaultCustomer && showRequiredAsterisk && <span className="req">*</span>}
        </label>
        <select
          id="transaction-selector-transaction"
          className="form-select"
          aria-label="Transaction selection"
          disabled={(manager.transactions?.length ?? 0) === 0}
          value={manager.selectedOptions.transactionId ?? ''}
          onChange={manager.updateTransactionId}>
          <option>- Select -</option>
          {manager.transactions?.map((transaction) => {
            return (
              <option
                key={transaction.id}
                value={transaction.id}
                className={
                  transaction.status === TransactionStatus.INACTIVE || transaction.deleted
                    ? 'inactive'
                    : ''
                }>
                {transaction.name}
                {transaction.status === TransactionStatus.INACTIVE && ' [inactive]'}
                {transaction.deleted && ' [deleted]'}
              </option>
            );
          })}
        </select>
        {displayMode !== DisplayMode.SIDE_ALT && (showInactiveFilter || showDeletedFilter) && (
          <div className={styles.transactionFilters}>
            {showInactiveFilter && <InactiveFilter />}
            {showDeletedFilter && <DeletedFilter />}
          </div>
        )}
      </div>
      {displayMode === DisplayMode.SIDE_ALT && (showInactiveFilter || showDeletedFilter) && (
        <div className={styles.transactionFiltersAltView}>
          {showInactiveFilter && <InactiveFilter />}
          {showDeletedFilter && <DeletedFilter />}
        </div>
      )}
    </div>
  );
};

export default TransactionSelector;

TransactionSelector.propTypes = {
  activeBrandId: PropTypes.number,
  visible: PropTypes.bool,
  showInactive: PropTypes.bool,
  showDeleted: PropTypes.bool,
  showInactiveFilter: PropTypes.bool,
  showDeletedFilter: PropTypes.bool,
  showRequiredAsterisk: PropTypes.bool,
  onChange: PropTypes.func,
  onCustomerChange: PropTypes.func,
  onTransactionChange: PropTypes.func,
  displayMode: PropTypes.oneOf([DisplayMode.STACKED, DisplayMode.SIDE, DisplayMode.SIDE_ALT])
};
