import MessageSearch from '../MessageSearch/MessageSearch';
import MessageEditor from '../MessageEditor/MessageEditor';
import useMessageManager from './useMessageManager';
import './MessageManager.css';

export const MessageManager = () => {
  const manager = useMessageManager();

  if (manager.loading) {
    return <div>Loading</div>;
  }

  return (
    <>
      {!manager.showEditor && (
        <MessageSearch
          roles={manager.roles}
          brandCustomers={manager.brandCustomers}
          editMessageCallback={manager.editMessage}
        />
      )}
      {manager.showEditor && (
        <MessageEditor
          messageId={manager.messageId}
          roles={manager.roles}
          brandCustomers={manager.brandCustomers}
          returnToSearchCallback={manager.returnToSearch}
        />
      )}
    </>
  );
};

export default MessageManager;
