import { Api } from '../../../utils/helpers';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../../../context/provider/AuthContext';
import { useInterval } from '../../../utils/hooks';

const useFileImportMonitor = () => {
  const { state } = useLocation();
  const [{ user }] = useAuthContext();
  const customers = user.userResources.brands
    .find((brand) => brand.id === user.activeBrand.id)
    .customers.map((customer) => {
      return { id: customer.id, name: customer.name };
    });
  const defaultCustomer = customers.length === 1 ? customers[0] : null;
  const [pagedResult, setPagedResult] = useState(null);
  const [queryParams] = useSearchParams();
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(state != null && state.selectedCustomerId != null);

  const rowView = useRef([]);
  const isRowViewed = (cpiId) => {
    return rowView.current.includes(cpiId);
  };

  const [searchParameters, setSearchParameters] = useState({
    pageSize: 12,
    page: 1,
    timestamp: null,
    brandId: user.activeBrand.id,
    customerId: state?.selectedCustomerId ?? defaultCustomer?.id,
    transactionId: null
  });

  const checkBoxChanged = () => {
    setAutoRefresh(autoRefresh ? false : true);
  };

  const adminEbpLink = (ebpId) => {
    return `/outbound/ebp-dashboard?EBPID=${ebpId}`;
  };

  const loadData = useCallback(
    async (thisCustomerId) => {
      if (!thisCustomerId) {
        setPagedResult({
          results: []
        });
        setLoading(false);
        return;
      }

      setApiError('');
      setLoading(true);
      searchParameters.customerId = thisCustomerId;
      const searchParams = JSON.stringify(searchParameters);

      const apiResponse = await Api.post(`/data/ImportData/FileParseMonitor`, searchParams);
      if (apiResponse.status.statusCode !== 200) {
        console.error('api error occurred');
        setApiError(
          `${apiResponse.status.statusCode} - ${apiResponse.status.statusDescription}
        ${apiResponse.errors.join(', ')}`
        );
        setLoading(false);
        return;
      }

      setPagedResult(apiResponse.response);
      console.log(apiResponse);
      setLoading(false);
    },
    [searchParameters.customerId, searchParameters.brandId, searchParameters.page]
  );

  const changePage = (page) => {
    let params = { ...searchParameters };
    params.page = page;
    setSearchParameters(params);
  };

  useEffect(() => {
    loadData(searchParameters.customerId);
  }, [loadData, searchParameters.customerId]);

  useInterval(
    () => {
      loadData(searchParameters.customerId);
    },
    autoRefresh ? 30000 : null
  );

  return {
    customers,
    defaultCustomer,
    pagedResult,
    queryParams,
    errors,
    apiError,
    loading,
    searchParameters,
    autoRefresh,
    setAutoRefresh,
    isRowViewed,
    loadData,
    changePage,
    checkBoxChanged,
    adminEbpLink
  };
};

export default useFileImportMonitor;
