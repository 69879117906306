import { DefaultLayout as Layout } from '../../../layouts';
import { BillSearch } from './BillSearch/BillSearch';

export const BillSearchPage = () => {
  return (
    <Layout>
      <BillSearch />
    </Layout>
  );
};
