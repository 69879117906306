import { DefaultLayout as Layout } from '../../../layouts';
import { ScheduledReportManager } from './ScheduledReportManager/ScheduledReportManager';

export const ScheduledReportsPage = () => {
  return (
    <Layout>
      <ScheduledReportManager />
    </Layout>
  );
};
