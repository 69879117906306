export const Permissions = {
  Admin: {
    FILES: 'permission:files',
    FILE_DATES: 'permission:file-dates',
    MODIFY_INTEREST_DATE: 'permission:modify-interest-date',
    MODIFY_DUE_DATE: 'permission:modify-duedate',
    MESSAGES: 'permission:messages',
    TILES: 'permission:tiles',
    ROLES: 'permission:roles',
    RECAPTCHA_OTP: 'permission:recaptcha-otp',
    AUTOMATED_IMPORT: 'permission:automated-import'
  },
  User: {
    MANAGE: 'user:manage',
    EMAIL: 'user:email',
    LOCKOUT: 'user:lockout'
  },
  Research: {
    SEARCH: 'research:search',
    VIEW_RECEIPT: 'research:view-receipt',
    BILL_SEARCH: 'research:bill-search'
  },
  PayPal: {
    ACTIVITY_SEARCH: 'paypal:activity-search',
    INCLUDE_GOVT_CUSTOMERS: 'paypal:include-govt-customers'
  },
  Payment: {
    VOID_SAME_DAY: 'payment:void-same-day',
    REVERSE_VOID: 'payment:reverse-void',
    VOID_BEFORE_ACH: 'payment:void-before-ach',
    CHARGEBACK: 'payment:chargeback',
    REVERSE_CHARGEBACK: 'payment:reverse-chargeback'
  },
  Reports: {
    BANK_RECONCILIATION: 'reports:bank-recon',
    TRANSACTION_DETAIL: 'reports:transaction-detail',
    TRANSACTION_CHARGEBACK: 'reports:transaction-chargeback',
    BANK_DEPOSIT: 'reports:bank-deposit',
    AUTOPAY_RECURRING: 'reports:autopay-recurring',
    MID: 'reports:mid'
  },
  Ebp: {
    DASHBOARD: 'ebp:dashboard',
    DOWNLOAD_LOG: 'ebp:download-log'
  },
  AdminTools: {
    SUPER_TOOLS: 'admin-tools:super-tools',
    ENROLLMENT_LOOKUP: 'admin-tools:enrollment-lookup',
    ADD_CUSTOMER: 'admin-tools:add-customer',
    MANAGE_CUSTOMERS: 'admin-tools:manage-customers',
    ADD_CUSTOMER_GROUP: 'admin-tools:add-customer-group',
    MANAGE_CUSTOMER_GROUPS: 'admin-tools:manage-customer-groups',
    MANAGE_ADMINISTRATORS: 'admin-tools:manage-administrators',
    MANAGE_TRANSACTIONS: 'admin-tools:manage-transactions',
    MANAGE_TRANSACTION_GROUPS: 'admin-tools:manage-transaction-groups',
    MANAGE_CLASSIFICATIONS: 'admin-tools:manage-classifications',
    ORGANIZE_TRANSACTIONS: 'admin-tools:organize-transactions',
    MANAGE_EXPORT_DATA: 'admin-tools:manage-export-data',
    MANAGE_SITE_CONTENT: 'admin-tools:manage-site-content',
    MANAGE_PUBLIC_CONTENT: 'admin-tools:manage-public-content',
    MANAGE_PUBLIC_USERS: 'admin-tools:manage-public-users',
    VIEW_REPORTS: 'admin-tools:view-reports',
    CREDIT_CARD_ERRORS: 'admin-tools:credit-card-errors',
    UNLOCK_GIACT_USER: 'admin-tools:unlock-giact-user',
    USER_GIACT_FAILURES: 'admin-tools:user-giact-failures',
    MANAGE_ACH_HOLIDAYS: 'admin-tools:manage-ach-holidays',
    MANAGE_UNIPAY_ACH_FILES: 'admin-tools:manage-unipay-ach-files',
    MANAGE_CUSTOMER_ACH_FILES: 'admin-tools:manage-customer-ach-files',
    MANAGE_ACH_CHARGEBACK_FILES: 'admin-tools:manage-ach-chargeback-files',
    MANAGE_CUSTOMER_ACH_CHARGEBACK_FILES: 'admin-tools:manage-customer-ach-chargeback-files',
    MANAGE_DATA_HOLIDAYS: 'admin-tools:manage-data-holidays',
    MANAGE_PAYMENT_PLANS: 'admin-tools:manage-payment-plans',
    VIEW_ENROLLMENTS: 'admin-tools:view-enrollments',
    VIEW_MAIL_EVENTS: 'admin-tools:view-mail-events',
    USER_AUDIT_REPORT: 'admin-tools:user-audit-report',
    TRANSACTION_SUMMARY: 'admin-tools:transaction-summary'
  }
};
