import { useState, useCallback, useEffect, useRef } from 'react';
import { Api } from '../../../../utils/helpers';
import { AlertStatus, TokenClaimType, MfaRequirementType } from '../../../../utils/constants/enums';

const defaultRole = {
  id: null,
  name: '',
  description: '',
  sequence: 200,
  claims: [],
  roleAccessIds: [],
  rules: {
    allowMultipleBrands: false,
    allowMultipleCustomers: false,
    mfaRequirementType: MfaRequirementType.NONE
  }
};

const useRoleManager = () => {
  const [rolesLoading, setRolesLoading] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [role, setRole] = useState(defaultRole);
  const [message, setMessage] = useState({
    status: AlertStatus.INFO,
    content: ''
  });

  const isNewRole = role.id === null;

  const getPermissions = useCallback(async () => {
    try {
      const apiResponse = await Api.get('/policy/permissions');
      if (apiResponse.status.statusCode !== 200) {
        return;
      }
      setPermissions(apiResponse.response);
    } catch (e) {
      console.error('api error occurred', e);
    }
  }, []);

  const getRoles = useCallback(async () => {
    setRolesLoading(true);
    try {
      const apiResponse = await Api.get('/policy/roles');
      if (apiResponse.status.statusCode !== 200) {
        setRolesLoading(false);
        return;
      }
      setRoles(apiResponse.response);
    } catch (e) {
      console.error('api error occurred', e);
    } finally {
      setRolesLoading(false);
    }
  }, []);

  const getRoleClaims = useCallback(async (roleId) => {
    try {
      const apiResponse = await Api.get(`/policy/roleclaims/${roleId}`);
      if (apiResponse.status.statusCode !== 200) {
        return [];
      }
      return apiResponse.response;
    } catch (e) {
      console.error('api error occurred', e);
    }
  }, []);

  const getRoleAccessIdList = useCallback(async (roleId) => {
    try {
      const apiResponse = await Api.get(`/policy/RoleAccess/${roleId}`);
      if (apiResponse.status.statusCode !== 200) {
        return [];
      }
      return apiResponse.response;
    } catch (e) {
      console.error('api error occurred', e);
    }
  }, []);

  const loadRoleForEdit = async (roleId) => {
    setErrors({});
    clearMessage();
    if (roleId === null) {
      //new role
      setRole(defaultRole);
      setShowEditor(true);
      return;
    }

    const foundRole = roles.find((role) => role.id === roleId);
    if (!foundRole) {
      setShowEditor(false);
      return;
    }

    let roleToUpdate = { ...role };
    roleToUpdate.id = foundRole.id;
    roleToUpdate.name = foundRole.name;
    roleToUpdate.description = foundRole.description;
    roleToUpdate.sequence = foundRole.sequence;
    roleToUpdate.rules = foundRole.rules;
    roleToUpdate.claims = await getRoleClaims(foundRole.id);
    roleToUpdate.roleAccessIds = await getRoleAccessIdList(foundRole.id);

    setRole(roleToUpdate);
    setShowEditor(true);
  };

  const hasRoleAccess = (roleId) => {
    return role.roleAccessIds.includes(roleId);
  };

  const toggleRoleAccess = (roleId) => {
    let roleToUpdate = { ...role };
    if (hasRoleAccess(roleId)) {
      //remover
      roleToUpdate.roleAccessIds = roleToUpdate.roleAccessIds.filter((id) => id != roleId);
    } else {
      //add
      roleToUpdate.roleAccessIds.push(roleId);
    }
    setRole(roleToUpdate);
  };

  const updateRoleProperty = async (prop, value) => {
    setFieldError(prop, false);
    let roleToUpdate = { ...role };
    roleToUpdate[prop] = value;
    setRole(roleToUpdate);
  };

  const updateRule = async (rule) => {
    let roleToUpdate = { ...role };
    roleToUpdate.rules[rule] = !roleToUpdate.rules[rule];
    setRole(roleToUpdate);
  };

  const updateMfaRule = async (mfaType) => {
    let roleToUpdate = { ...role };
    roleToUpdate.rules.mfaRequirementType = mfaType;
    setRole(roleToUpdate);
  };

  const updatePermissionGroup = async (groupKey, permissionCount) => {
    let roleToUpdate = { ...role };
    const hasAllSelected = hasAllInPermissionGroup(groupKey, permissionCount);

    //remove all
    roleToUpdate.claims = roleToUpdate.claims.filter(
      (claim) => !(claim.type === TokenClaimType.PERMISSION && claim.value.startsWith(groupKey))
    );

    if (!hasAllSelected) {
      //add all
      permissions
        .filter((group) => group.key === groupKey)
        .forEach((group) => {
          group.permissions.forEach((permission) => {
            roleToUpdate.claims.push({
              type: TokenClaimType.PERMISSION,
              value: permission.groupKey + ':' + permission.key
            });
          });
        });
    }
    setRole(roleToUpdate);
  };

  const hasAllInPermissionGroup = (groupKey, permissionCount) => {
    const selectedGroupCount = role.claims.filter(
      (claim) => claim.type === TokenClaimType.PERMISSION && claim.value.startsWith(groupKey)
    ).length;
    return selectedGroupCount === permissionCount;
  };

  const updatePermission = async (permissionKey) => {
    let roleToUpdate = { ...role };
    if (hasPermission(permissionKey)) {
      //remove it
      roleToUpdate.claims = roleToUpdate.claims.filter(
        (claim) => !(claim.type === TokenClaimType.PERMISSION && claim.value === permissionKey)
      );
    } else {
      //add it
      roleToUpdate.claims.push({
        type: TokenClaimType.PERMISSION,
        value: permissionKey
      });
    }
    setRole(roleToUpdate);
  };

  const hasPermission = (permissionKey) => {
    return role.claims.some(
      (claim) => claim.type === TokenClaimType.PERMISSION && claim.value === permissionKey
    );
  };

  const setFieldError = (field, hasError) => {
    let errorsToUpdate = { ...errors };
    errorsToUpdate[field] = hasError;
    setErrors(errorsToUpdate);
  };

  const cancelNewRole = () => {
    setShowEditor(false);
  };

  const deleteRole = async () => {
    if (role.id === null) {
      return;
    }
    const apiResponse = await Api.delete(`/policy/roles/${role.id}`);
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      return;
    }
    await getRoles();
    setShowEditor(false);
    closeDeleteDialog();
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    setDeleteDialogVisible(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const messageTimerRef = useRef(null);
  const showMessage = (message, status = AlertStatus.INFO, timeout = null) => {
    clearTimeout(messageTimerRef.current);
    setMessage({
      status: status,
      content: message
    });

    if (timeout && typeof timeout === 'number') {
      messageTimerRef.current = setTimeout(() => {
        clearMessage();
      }, timeout);
    }
  };

  const clearMessage = () => {
    setMessage({
      status: AlertStatus.INFO,
      content: ''
    });
  };

  const handleSave = async (event) => {
    event.preventDefault();
    clearMessage();

    let formErrors = {};
    if (role.name.trim() === '') {
      formErrors.name = true;
    }

    const hasPermissionSelected = role.claims.some(
      (claim) => claim.type === TokenClaimType.PERMISSION
    );
    if (!hasPermissionSelected) {
      formErrors.permissions = true;
    }
    setErrors(formErrors);

    //do we have any errors?
    if (Object.keys(formErrors).length > 0) {
      return false;
    }
    await saveRole();
  };

  const saveRole = async () => {
    setShowProgressBar(true);
    let roleToSave = { ...role };
    try {
      const apiResponse = await Api.post('/policy/roles', JSON.stringify(roleToSave));
      if (apiResponse.status.statusCode !== 200) {
        showMessage('Error occurred ' + apiResponse.status.errors, AlertStatus.ERROR, 8000);
        return;
      }

      //new user created, update with userid from response
      if (isNewRole) {
        roleToSave.id = apiResponse.response.id;
        setRole(roleToSave);
      }
      showMessage('Role saved successfully!', AlertStatus.SUCCESS, 4000);
    } catch (e) {
      showMessage('Error occurred ' + e, AlertStatus.ERROR, 8000);
    } finally {
      setShowProgressBar(false);
      await getRoles();
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await getRoles();
      await getPermissions();
    };
    loadData();
  }, [getRoles, getPermissions]);

  return {
    rolesLoading,
    errors,
    deleteDialogVisible,
    permissions,
    roles,
    role,
    isNewRole,
    showEditor,
    showProgressBar,
    message,
    handleDelete,
    closeDeleteDialog,
    deleteRole,
    loadRoleForEdit,
    hasRoleAccess,
    toggleRoleAccess,
    updateRoleProperty,
    updateRule,
    updateMfaRule,
    updatePermissionGroup,
    hasAllInPermissionGroup,
    updatePermission,
    hasPermission,
    handleSave,
    cancelNewRole
  };
};

export default useRoleManager;
