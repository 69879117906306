export const ApiResultStatus = {
  SUCCESS: 0,
  ERROR: 1
};

export const Menu = {
  NONE: 0,
  SIDEBAR: 1,
  PROFILE: 2
};

export const OutboundGroup = {
  NONE: 0,
  ADMIN_TOOLS: 1,
  EBP_MANAGER: 2,
  FILE_MANAGEMENT: 3
};

export const SortDirection = {
  ASCENDING: 1,
  DESCENDING: 2,
  RANDOM: 3 //future - not currently implemented
};

export const MessageOptions = {
  NONE: 0,
  HIGH_PRIORITY: 1,
  REQUIRES_ACKNOWLEDGEMENT: 2
};

export const MessageViewType = {
  UNREAD: 0,
  VIEWED: 1,
  ACKNOWLEDGED: 2
};

export const MessageAudienceType = {
  UNKNOWN: 0,
  BRAND_CUSTOMER: 1,
  BRAND_CUSTOMER_CLASSIFICATION: 2,
  ROLE: 3,
  BRAND: 4
};

export const MfaRequirementType = {
  NONE: 0,
  EVERY_LOGIN: 1,
  EVERY_24_HOURS: 2
};

export const MfaNotificationMethod = {
  EMAIL: 0,
  SMS: 1
};

export const NameDisplayOrder = {
  LASTNAME_FIRST: 0,
  FIRSTNAME_FIRST: 0
};

export const AlertStatus = {
  INFO: 0,
  SUCCESS: 1,
  ERROR: 2,
  WARNING: 3
};

export const TokenClaimType = {
  PERMISSION: 'brs_policy_permissions'
};

export const SessionModal = {
  NONE: 0,
  WARNING: 1
};

export const UploadStatus = {
  PENDING: 0,
  UPLOADING: 1,
  COMPLETE: 2,
  FAILED: 3
};

export const UploadResultStatus = {
  SUCCESS: 0,
  FAILURE: 1
};

export const ResearchSearchType = {
  BASIC: 0,
  ADVANCED: 1
};
export const ReportScheduleType = {
  ONE_TIME: 0,
  RECURRING: 1
};
export const ReportDeliveryType = {
  NONE: 0,
  EMAIL_NOTIFICATION: 1,
  EMAIL_ATTACHMENT: 2
};
export const ReportDateRangeType = {
  TODAY: 0,
  YESTERDAY: 1,
  PREVIOUS_3_DAYS: 2,
  PREVIOUS_7_DAYS: 3,
  PREVIOUS_MONTH: 4,
  PREVIOUS_QUARTER: 5,
  PREVIOUS_YEAR: 6,
  CUSTOM: 7,
  BUSINESS_DAILY: 8,
  WEEKLY: 9,
  PREVIOUS_FISCAL_YEAR: 10
};
export const AuditDateRangeType = {
  TODAY: 0,
  YESTERDAY: 1,
  PREVIOUS_3_DAYS: 2,
  PREVIOUS_7_DAYS: 3,
  PREVIOUS_MONTH: 4,
  PREVIOUS_QUARTER: 5,
  PREVIOUS_YEAR: 6,
  CUSTOM: 7,
  YEAR_TO_DATE: 8,
  ANY: 9
};
export const ResearchDateRangeType = {
  TODAY: 0,
  YESTERDAY: 1,
  PREVIOUS_3_DAYS: 2,
  PREVIOUS_MONTH: 3,
  PREVIOUS_QUARTER: 4,
  PREVIOUS_YEAR: 5,
  CUSTOM: 6,
  PREVIOUS_7_DAYS: 7
};

export const ResearchDataSource = {
  BRAND: 0,
  DATA_WAREHOUSE: 1
};

export const PaymentType = {
  UNKNOWN: 0,
  CREDIT_CARD: 1,
  ACH: 2,
  PAYPAL_VENMO: 3
};

export const PaymentConfirmationView = {
  DEFAULT: 0,
  VOID: 1,
  CHARGEBACK: 2
};

export const ReportType = {
  UNKNOWN: 0,
  BANK_RECONCILIATION: 1,
  TRANSACTION_DETAIL: 2,
  TRANSACTION_CHARGEBACK: 3,
  BANK_DEPOSIT: 4,
  AUTOPAY_RECURRING: 5,
  MID: 6
};

export const ReportResponseType = {
  NONE: 0,
  HTML: 1,
  PDF: 2,
  CSV: 4,
  JSON: 8
};

export const ScreenViewType = {
  DEFAULT: 0,
  FULLSCREEN: 1
};

export const TransactionStatus = {
  INACTIVE: 1,
  ACTIVE: 2,
  PAUSED: 3,
  LOCKED: 4
};

export const RecurrenceType = {
  SPECIFIC_DAY: 1,
  FREQUENCY_INTERVAL: 2
};

export const RecurringDaySelectType = {
  MONTH: 1,
  QUARTER: 2,
  YEAR: 3
};

export const FrequencyInterval = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
  LAST: 5
};

export const DaysOfWeek = {
  NONE: 0,
  SUNDAY: 1,
  MONDAY: 2,
  TUESDAY: 4,
  WEDNESDAY: 8,
  THURSDAY: 16,
  FRIDAY: 32,
  SATURDAY: 64
};

export const ImportFrequency = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3
};

export const TransactionSections = {
  CONFIGURATION_SUMMARY: 1,
  AUTOMATED_UPLOADS: 2
};

export const CustomerSections = {
  CUSTOMER_SUMMARY: 0,
  SFTP_ACCOUNTS: 1,
  PAYPAL: 2
};

export const ImportParseType = {
  PREPROCESS: 1,
  IMPORT: 2
};

export const ImportQueueStatus = {
  PENDING: 0,
  COMPLETED: 1,
  FAILED: 2,
  FAILED_PREPROCESS: 3
};

export const InterestType = {
  UNKNOWN: 0,
  MA_EXCISE_12PCT: 1,
  MA_REAL_ESTATE_14PCT: 2,
  MA_WATER_SEWER_14PCT: 3,
  MA_SEWER_USAGE_8PCT: 4,
  FARM_ANIMAL_5PCT: 5,
  NONE_0PCT: 6
};

export const BusinessType = {
  GOVERNMENT: 'Government',
  CORPORATION: 'Corporation',
  NONPROFIT: 'Nonprofit',
  PRIVATE_CORPORATION: 'Private Corporation',
  PROPRIETORSHIP: 'Proprietorship',
  UNSET: ''
};

export const FormComponentStatus = {
  COMPLETE: 0,
  INCOMPLETE: 1
};

export const IdentificationDocumentType = {
  UNSET: 'UNSET',
  SSN: 'SOCIAL_SECURITY_NUMBER',
  ITIN: 'INDIVIDUAL_TAX_IDENTIFICATION_NUMBER',
  EIN: 'EMPLOYER_IDENTIFICATION_NUMBER'
};
