import { DefaultLayout as Layout } from '../../../layouts';
import { RoleManager } from './RoleManager/RoleManager';

export const RoleManagementPage = () => {
  return (
    <Layout>
      <RoleManager />
    </Layout>
  );
};
