import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Responsive, WidthProvider } from 'react-grid-layout';
import useDashboardTiles from './useDashboardTiles';
import { CheckBox } from '../../../../components/ui/CheckBox/CheckBox';
import { DashboardTile } from '../../../../components/ui';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './DashboardTiles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

export const DashboardTiles = ({ showManageTileMenu, hideManageTileMenuCallback }) => {
  const tileManager = useDashboardTiles();

  return (
    <>
      <ResponsiveGridLayout
        className="layout"
        layouts={tileManager.tileLayouts}
        autoSize={true}
        isBounded={true}
        isResizable={false}
        margin={[10, 10]}
        containerPadding={[0, 0]}
        breakpoints={{ md: 996, sm: 768, xs: 480 }}
        cols={{ md: 4, sm: 2, xs: 1 }}
        width={1200}
        rowHeight={200}
        onLayoutChange={tileManager.handleLayoutChange}>
        {tileManager.userTiles
          .filter((tile) => tileManager.isTileVisible(tile.id))
          .map((tile) => {
            return (
              <div key={tile.id}>
                <DashboardTile heading={tile.name} headerRight={tile.headerRight}>
                  {tile.element}
                </DashboardTile>
              </div>
            );
          })}
      </ResponsiveGridLayout>

      <Offcanvas
        className="manage-tile-menu themed-bg-color"
        show={showManageTileMenu}
        onHide={hideManageTileMenuCallback}
        placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Manage Tiles</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {tileManager.manageTileList.map((item) => (
            <CheckBox
              key={item.id}
              label={item.name}
              value={item.id}
              checkOnChange={tileManager.tileVisibilityChange}
              checked={tileManager.isTileVisible(item.id)}
            />
          ))}
          <a
            href="#"
            className="manage-tile-reset"
            title="Reset tiles to their default settings."
            onClick={(e) => {
              e.preventDefault();
              tileManager.resetTiles();
            }}>
            <FontAwesomeIcon size="sm" className="text-secondary me-1" icon={faRepeat} />
            Reset Tile Layout
          </a>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
