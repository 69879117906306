import { DefaultLayout as Layout } from '../../../layouts';
import { useAuthContext } from '../../../context/provider/AuthContext';
import { useOutbound } from '../../../utils/hooks';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

export const OutboundPage = () => {
  const [{ user }] = useAuthContext();
  const { target } = useParams();
  const outbound = useOutbound();

  useEffect(() => {
    if (!user.isLoading) {
      outbound.sendOutbound(target);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, user.isLoading, user.activeBrand.id]);

  return (
    <Layout>
      <div className="titleBar">
        <h1 className="pageTitle">Outbound Redirect</h1>
      </div>
      {outbound.message.trim() !== '' && <div>{outbound.message}</div>}
      {outbound.errorMessage.trim() !== '' && (
        <div className="alert alert-danger">
          <i className="icofont-warning me-2"></i>
          {outbound.errorMessage}
        </div>
      )}
    </Layout>
  );
};
