import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './HelpTip.module.css';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';

export const HelpTip = ({ children, className }) => {
  return (
    <div className={className}>
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          <FontAwesomeIcon className={styles.icon} icon={faLightbulb} />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default HelpTip;
