import { useEffect, useState, useCallback, useRef } from 'react';
import { Api, Helpers } from '../../../../utils/helpers';
import { SortDirection } from '../../../../utils/constants/enums';
import debounce from 'lodash.debounce';
import { useAuthContext } from '../../../../context/provider/AuthContext';

const useUserSearch = ({ brands }) => {
  const [pagedResult, setPagedResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [roleFilters, setRoleFilters] = useState([]);
  const [brandFilters, setBrandFilters] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [searchParameters, setSearchParameters] = useState({
    keywords: '',
    sortDirection: SortDirection.ASCENDING,
    sortField: 'username',
    pageSize: 20,
    page: 1,
    filters: {}
  });
  const [{ user }] = useAuthContext();

  const search = useCallback(async () => {
    setLoading(true);
    const apiResponse = await Api.post(
      `/policy/UserManagement/Search`,
      JSON.stringify(searchParameters)
    );
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }
    setPagedResult(apiResponse.response);
    setLoading(false);
  }, [searchParameters]);

  const exportCSV = async () => {
    const apiResponse = await Api.post(
      `/policy/UserManagement/Users/Export`,
      JSON.stringify(searchParameters)
    );
    if (apiResponse.status.statusCode === 200) {
      window.open(
        `${process.env.REACT_APP_BFF_API_URL}/policy/UserManagement/Users/Export/${apiResponse.response}`
      );
    } else {
      console.log(`Api Error Occurred:  ${apiResponse.response}`);
    }
  };

  const debouncedKeywords = useRef(
    debounce(async (value) => {
      let params = { ...searchParameters };
      params.page = 1; //reset page if filtered by keyword
      params.keywords = value;
      setSearchParameters(params);
      console.log('search parameters changed');
    }, 500)
  ).current;

  useEffect(() => {
    return () => debouncedKeywords.cancel();
  }, [debouncedKeywords]);

  const updateKeywords = (value) => {
    debouncedKeywords(value);
  };

  const updateFilter = async (prop, value) => {
    let params = { ...searchParameters };
    params.filters[prop] = value?.toString();
    params.page = 1; //reset page if filtered
    setSearchParameters(params);
  };

  const updateMessageOptionsFilter = async (value) => {
    let params = { ...searchParameters };
    let currentOption = params.filters.options ? parseInt(params.filters.options) : 0;

    if (Helpers.hasFlag(params.filters.options, value)) {
      currentOption ^= value;
    } else {
      currentOption |= value;
    }

    //set 0 to null so all are returned instead of just 'nones'
    params.filters.options = currentOption === 0 ? null : currentOption.toString();
    params.page = 1; //reset page if filtered by options
    setSearchParameters(params);
  };

  const updateSearchRoleFilters = async (roleFilters) => {
    let params = { ...searchParameters };
    params.filters['roles'] = JSON.stringify(roleFilters);
    params.page = 1; //reset page if filtered
    setSearchParameters(params);
  };

  const updateRoleFilter = async (roleId) => {
    let roleFiltersToUpdate = [...roleFilters];

    if (isRoleFilterChecked(roleId)) {
      //remove it
      roleFiltersToUpdate = roleFilters.filter((x) => x !== roleId);
    } else {
      //add it
      roleFiltersToUpdate.push(roleId);
    }
    setRoleFilters(roleFiltersToUpdate);
    updateSearchRoleFilters(roleFiltersToUpdate);
  };

  const isRoleFilterChecked = (roleId) => {
    return roleFilters.includes(roleId);
  };

  const updateSearchBrandFilters = async (brandFilters) => {
    let params = { ...searchParameters };
    params.filters['brands'] = JSON.stringify(brandFilters);
    params.page = 1; //reset page if filtered
    setSearchParameters(params);
  };

  const updateBrandFilter = async (brandId) => {
    let brandFiltersToUpdate = [...brandFilters];

    if (isBrandFilterChecked(brandId)) {
      //remove it
      brandFiltersToUpdate = brandFilters.filter((x) => x !== brandId);
    } else {
      //add it
      brandFiltersToUpdate.push(brandId);
    }
    setBrandFilters(brandFiltersToUpdate);
    updateCustomerList(
      user.userResources.brands.filter((brand) => brandFiltersToUpdate.includes(brand.id))
    );
    updateSearchBrandFilters(brandFiltersToUpdate);
  };

  const isBrandFilterChecked = (roleId) => {
    return brandFilters.includes(roleId);
  };

  const getBrandNames = (brandIds) => {
    let brandNames = [];

    if (!brandIds || brandIds.length === 0) {
      return <em className="text-danger">No brand assigned</em>;
    }

    if (brandIds.includes('0')) {
      return 'All brands';
    }

    for (const brandId of brandIds) {
      const brand = brands.find((x) => x.id.toString() === brandId.toString());
      if (brand) {
        brandNames.push(brand.name);
      }
    }
    return brandNames.join(', ');
  };

  const updateCustomerList = (selectedBrands) => {
    const customerList = selectedBrands.map((brands) => {
      return brands.customers.map((cust) => {
        return { value: `${brands.id}:${cust.id}`, text: `${cust.name}` };
      });
    });

    updateCustomerFilter('');
    setCustomerList(customerList.flat());
  };

  const updateCustomerFilter = (customer) => {
    let params = { ...searchParameters };
    params.filters['customer'] = customer;
    setSearchParameters(params);
  };

  const updateSorting = (field, direction) => {
    let params = { ...searchParameters };
    params.sortDirection = direction;
    params.sortField = field;
    setSearchParameters(params);
  };

  const changePage = (page) => {
    let params = { ...searchParameters };
    params.page = page;
    setSearchParameters(params);
  };

  useEffect(() => {
    search();
  }, [search, searchParameters]);

  return {
    loading,
    pagedResult,
    searchParameters,
    changePage,
    updateFilter,
    updateKeywords,
    updateSorting,
    updateMessageOptionsFilter,
    updateRoleFilter,
    isRoleFilterChecked,
    brandFilters,
    updateBrandFilter,
    isBrandFilterChecked,
    getBrandNames,
    exportCSV,
    updateCustomerFilter,
    updateCustomerList,
    customerList,
    user
  };
};

export default useUserSearch;
