import React, { useEffect, useState, useCallback } from 'react';
import { Api, Helpers } from '../../../utils/helpers';
import { MessageOptions, MessageViewType } from '../../../utils/constants/enums';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './LatestMessages.css';

export const LatestMessages = () => {
  const [loading, setLoading] = useState(true);
  const [messageResults, setMessageResults] = useState(null);

  const getLatestMessages = useCallback(async () => {
    Helpers.formatTime(33);
    setLoading(true);
    const apiResponse = await Api.get('/policy/Messages/Latest?limit=3');
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }
    setMessageResults(apiResponse.response);
    setLoading(false);
  }, []);

  useEffect(() => {
    getLatestMessages();
  }, [getLatestMessages]);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <div className="latest-messages mt-2">
      {messageResults?.map((message, index) => {
        const rowStyle =
          index >= messageResults.length - 1 ? 'pb-3 mb-3' : 'border-bottom pb-3 mb-3';

        let linkStyle = 'message-widget-link';
        if (
          Helpers.hasFlag(message.messageOptions, MessageOptions.HIGH_PRIORITY) ||
          Helpers.hasFlag(message.messageOptions, MessageOptions.REQUIRES_ACKNOWLEDGEMENT)
        ) {
          linkStyle = 'message-widget-link text-danger';
        }

        return (
          <div key={message.id} className={rowStyle}>
            <div className="d-flex">
              <div className="pe-2 pt-2">
                {message.viewType === MessageViewType.UNREAD && (
                  <div className="message-widget-icon unread"></div>
                )}
                {message.viewType !== MessageViewType.UNREAD && (
                  <div className="message-widget-icon"></div>
                )}
              </div>
              <div className="flex-grow-1 ">
                <Link to={`/messages/${message.id}`} className={linkStyle}>
                  <strong>{message.title}</strong>
                </Link>
                {' - '}
                <em>{Helpers.toShortDate(message.startDateUtc ?? message.dateCreatedUtc)}</em>
                <div>{message.preview}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LatestMessages;
