import { useState, useCallback, useEffect } from 'react';
import { useAuthContext } from '../../../context/provider/AuthContext';
import { Api } from '../../../utils/helpers';
import { sectionMenu } from '../CustomerSections/sectionMenu';

const useCustomerManager = () => {
  const [{ user }] = useAuthContext();
  const [showConfigurationSummary, setConfigurationSummaryDisplay] = useState(false);
  const [viewSection, setViewSection] = useState(0);

  const customers = user.userResources.brands
    .find((brand) => brand.id === user.activeBrand.id)
    .customers.map((customer) => {
      return { id: customer.id, name: customer.name };
    });
  const defaultCustomer = customers.length === 1 ? customers[0] : null;
  const [customerParameters, setCustomerParameters] = useState({
    brandId: user.activeBrand.id,
    customerId: defaultCustomer?.id ?? null
  });

  useEffect(() => {
    //if brand changes, reset customer selection
    setCustomerParameters({
      brandId: user.activeBrand.id,
      customerId: defaultCustomer?.id ?? null
    });
  }, [user.activeBrand.id]);

  const allowedSections = sectionMenu.filter((section) => {
    return section.requiredPermissions.every((p) => user.permissions.includes(p));
  });

  const openSection = (sectionId) => {
    setViewSection(sectionId);
  };

  const updateCustomerParams = (customerId) => {
    if (customerId === 0) customerId = null;
    setCustomerParameters({ ...customerParameters, customerId });
    setConfigurationSummaryDisplay(customerId !== null);
  };

  return {
    viewSection,
    allowedSections,
    customerParameters,
    showConfigurationSummary,
    customers,
    defaultCustomer,
    updateCustomerParams,
    setViewSection,
    openSection
  };
};

export default useCustomerManager;
