import { useState } from 'react';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import { Helpers } from '../../../../../../../utils/helpers/Helpers';

const useRegisteredBusinessAddress = (containerFormData, formStatusCallback) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    businessAddressLine1: containerFormData.businessAddressLine1,
    businessAddressAdminArea2: containerFormData.businessAddressAdminArea2,
    businessAddressAdminArea1: containerFormData.businessAddressAdminArea1,
    businessPostalCode: containerFormData.businessPostalCode
  });

  const isMissingValues = (fields) => {
    return Object.values(fields).some((x) => Helpers.isNullOrWhitespace(x));
  };

  const [componentStatus, setComponentStatus] = useState(
    isMissingValues(formData) ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
  );

  const updateValue = ({ name, value }) => {
    const fields = { ...formData };
    fields[name] = value;
    setFormData(fields);

    setErrors((err) => {
      return { ...err, [name]: Helpers.isNullOrWhitespace(value) };
    });

    const missingValues = isMissingValues(fields);
    setComponentStatus(
      missingValues ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
    );
    formStatusCallback('registeredBusinessAddress', !missingValues, fields);
  };

  return {
    componentStatus,
    formData,
    errors,
    updateValue
  };
};

export default useRegisteredBusinessAddress;
