import { useState } from 'react';
import { DefaultLayout as Layout } from '../../../layouts';
import { useAuthorization } from '../../../utils/hooks';
import { FileBrowser, FileBrowserModal } from '../../../components/ui';

export const FileUploadTestPage = () => {
  useAuthorization();
  const [showFileBrowserModal, setShowFileBrowserModal] = useState(false);

  return (
    <Layout>
      <FileBrowserModal
        showModal={showFileBrowserModal}
        closeModalCallback={() => setShowFileBrowserModal(false)}
        uploadApiEndpoint="/files/content/upload"
        browseApiEndpoint="/files/content/directory"
        imageApiEndpoint="/files/content/file"
        downloadApiEndpoint="/files/content/download"
        selectFileCallback={(fileDetails) => {
          console.log(fileDetails);
        }}
        acceptExtensions=""
        sizeLimit={10485760}
        maxConcurrentUploads={4}
        multipleFiles={true}
      />
      <h1 className="pageTitle">File Browser Tester</h1>
      <button className="btn primary-btn mb-4" onClick={() => setShowFileBrowserModal(true)}>
        Open Modal
      </button>
      <FileBrowser
        uploadApiEndpoint="/files/content/upload"
        browseApiEndpoint="/files/content/directory"
        imageApiEndpoint="/files/content/file"
        downloadApiEndpoint="/files/content/download"
        selectFileCallback={(fileDetails) => {
          console.log(fileDetails);
        }}
        acceptExtensions=""
        sizeLimit={10485760}
        maxConcurrentUploads={4}
        multipleFiles={true}
      />
    </Layout>
  );
};
