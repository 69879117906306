import React from 'react';
import { AccountInformation } from '../Components/AccountInformation/AccountInformation';
import { IdentificationDocuments } from '../Components/IdentificationDocuments/IdentificationDocuments';
import { LegalBusinessInformation } from '../Components/LegalBusinessInformation/LegalBusinessInformation';
import { PrimaryAuthorizedUser } from '../Components/PrimaryAuthorizedUser/PrimaryAuthorizedUser';
import { RegisteredBusinessAddress } from '../Components/RegisteredBusinessAddress/RegisteredBusinessAddress';
import { BeneficialOwner } from '../Components/BeneficialOwner/BeneficialOwner';
import { OfficeBearer } from '../Components/OfficeBearer/OfficeBearer';
import useCorporation from './useCorporation';

export const Corporation = ({ customerRecordParameter, formStatusCallback, errorFormData }) => {
  const manager = useCorporation(customerRecordParameter, formStatusCallback, errorFormData);

  return (
    <>
      <div>
        <AccountInformation
          containerFormData={manager.formData}
          formStatusCallback={manager.componentFormCallback}
        />
        <PrimaryAuthorizedUser
          containerFormData={manager.formData}
          formStatusCallback={manager.componentFormCallback}
        />
        <LegalBusinessInformation
          containerFormData={manager.formData}
          formStatusCallback={manager.componentFormCallback}
        />
        <RegisteredBusinessAddress
          containerFormData={manager.formData}
          formStatusCallback={manager.componentFormCallback}
        />
        <IdentificationDocuments
          containerFormData={manager.formData}
          formStatusCallback={manager.componentFormCallback}
        />
        <BeneficialOwner
          containerFormData={manager.formData}
          formStatusCallback={manager.componentFormCallback}
        />
        <OfficeBearer
          containerFormData={manager.formData}
          formStatusCallback={manager.componentFormCallback}
        />
      </div>
    </>
  );
};

export default Corporation;
