import PropTypes from 'prop-types';
import { HelpTip } from '../../../../components/ui';
import { ImportFrequency, RecurringDaySelectType } from '../../../../utils/constants/enums';
import { DayOfTheWeekSelect, RecurringDaySelect } from '../../../../components/ui';
import styles from './AutomatedUploadRecurrenceOptions.module.css';
import { Helpers } from '../../../../utils/helpers';

export const AutomatedUploadRecurrenceOptions = ({
  visible,
  dateRangeType,
  recurrenceParameters,
  parameterChangeHandler
}) => {
  if (!visible) return;

  const paramChangeByKey = (key, value) => {
    let config = { ...recurrenceParameters };
    config[key] = value;
    parameterChangeHandler(config);
  };

  const recurringDaySelectChange = (values) => {
    let config = { ...recurrenceParameters };
    config.recurrenceType = values.recurrenceType;
    config.dayNumber = values.dayNumber;
    config.frequencyInterval = values.frequencyInterval;
    config.dayOfWeek = values.dayOfWeek;
    config.monthNumber = values.monthNumber;
    parameterChangeHandler(config);
  };

  return (
    <>
      {dateRangeType === ImportFrequency.DAILY && (
        <>
          <div>
            <label>Run automated imports on the following days:</label>
          </div>
          <DayOfTheWeekSelect
            className="mt-2"
            multiSelect={true}
            weekdayOnly={false}
            abbreviatedDayNames={true}
            value={recurrenceParameters.daysOfWeek}
            onChange={(value) => paramChangeByKey('daysOfWeek', value)}
          />
        </>
      )}

      {dateRangeType === ImportFrequency.WEEKLY && (
        <>
          <div>
            <label>Run automated imports every week on the following day:</label>
          </div>
          <DayOfTheWeekSelect
            className="mt-2"
            multiSelect={false}
            weekdayOnly={false}
            abbreviatedDayNames={true}
            value={recurrenceParameters.dayOfWeek}
            onChange={(value) => paramChangeByKey('dayOfWeek', value)}
          />
        </>
      )}

      {dateRangeType === ImportFrequency.MONTHLY && (
        <>
          <div>
            <label>Run automated imports...</label>
          </div>
          <RecurringDaySelect
            className="mt-2"
            recurringDaySelectType={RecurringDaySelectType.MONTH}
            recurrenceType={recurrenceParameters.recurrenceType}
            dayNumber={recurrenceParameters.dayNumber}
            frequencyInterval={recurrenceParameters.frequencyInterval}
            dayOfWeek={recurrenceParameters.dayOfWeek}
            monthNumber={recurrenceParameters.monthNumber}
            onChange={(values) => recurringDaySelectChange(values)}
          />
        </>
      )}
    </>
  );
};

export default AutomatedUploadRecurrenceOptions;

AutomatedUploadRecurrenceOptions.propTypes = {
  visible: PropTypes.bool,
  dateRangeType: PropTypes.number,
  clearErrorForKeysCallback: PropTypes.func,
  recurrenceParameters: PropTypes.shape({
    daysOfWeek: PropTypes.number,
    dayOfWeek: PropTypes.number,
    dayNumber: PropTypes.number,
    frequencyInterval: PropTypes.number,
    monthNumber: PropTypes.number
  })
};
