import { useState, useRef, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faExpand,
  faRightFromBracket,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { ReportResponseType, ScreenViewType } from '../../../utils/constants/enums';
import styles from './ReportViewer.module.css';
import './ReportStyles.css';

export const ReportViewer = ({
  loading,
  viewType,
  searchEnabled = true,
  toggleReportViewCallback,
  reportHtml = '',
  getReportDownloadCallback,
  deleteReportCallback,
  showDeleteButton = false,
  findScrollOffset = 225,
  maxReportHeight
}) => {
  const viewerId = useRef(uuidv4());
  const reportResults = useRef(reportHtml);
  const [displayResults, setDisplayResults] = useState(reportHtml);
  const [findComplete, setFindComplete] = useState(false);
  const [findText, setFindText] = useState('');
  const [findTextCount, setFindTextCount] = useState(0);
  const [currentMatchNumber, setCurrentMatchNumber] = useState(0);
  const findNextIndex = useRef(0);

  const findInReport = (searchText) => {
    findNextIndex.current = 0;
    if (searchText.trim().length === 0) {
      setDisplayResults(reportResults.current);
      setFindTextCount(0);
      setFindComplete(false);
      return;
    }

    const regex = new RegExp('(' + searchText + ')(?!([^<]+)?>)', 'gi');
    const matches = reportResults.current.match(regex)?.length ?? 0;
    const newText = reportResults.current.replace(regex, `<mark class="highlight">$&</mark>`);
    setDisplayResults(newText);
    setFindTextCount(matches);
    setCurrentMatchNumber(1);
    setFindComplete(true);
    setTimeout(() => {
      scrollToHighlight();
    }, 150);
  };

  const findNext = () => {
    let nextIndex = findNextIndex.current + 1;
    if (nextIndex > findTextCount - 1) {
      nextIndex = 0;
    }
    findNextIndex.current = nextIndex;
    setCurrentMatchNumber(findNextIndex.current + 1);
    scrollToHighlight();
  };

  const findKeyDown = (event) => {
    if (event.key === 'Enter') {
      findNext();
    }
  };

  const scrollToHighlight = () => {
    const rootDiv = document.getElementById(viewerId.current);
    const highlightedItems = rootDiv.querySelectorAll('.highlight');

    if (!highlightedItems || highlightedItems.length === 0) {
      return;
    }

    highlightedItems.forEach((item) => {
      item.classList.remove('active');
    });

    const nextItem = highlightedItems[findNextIndex.current];
    nextItem.classList.add('active');

    const pageOffset = -(window.scrollY ? window.scrollY : window.pageYOffset);
    const rect = nextItem.getBoundingClientRect();
    const nextItemOffset = rect.top + rootDiv.scrollTop - pageOffset - findScrollOffset;
    rootDiv.scrollTo({ top: nextItemOffset, behavior: 'smooth' });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedKeywords = useCallback(
    debounce(async (value) => {
      findInReport(value);
    }, 500),
    [reportResults.current]
  );

  const updateKeywords = (value) => {
    setFindComplete(false);
    debouncedKeywords(value);
    setFindText(value);
  };

  useEffect(() => {
    return () => debouncedKeywords.cancel();
  }, [debouncedKeywords]);

  useEffect(() => {
    setFindText('');
    setFindComplete(false);
    setFindTextCount(0);
    reportResults.current = reportHtml;
    setDisplayResults(reportHtml);
  }, [reportHtml]);

  return (
    <div className="card">
      <div className={'card-body ' + styles.reportCardBody}>
        <div className={'themed-header-bar ' + styles.reportTools}>
          {searchEnabled && (
            <div className={styles.reportSearch}>
              <div className={styles.reportSearchInputContainer}>
                <input
                  type="text"
                  placeholder="Find in report"
                  className={'form-control-sm ' + styles.reportSearchInput}
                  disabled={loading || displayResults.length === 0}
                  value={findText}
                  onKeyDown={findKeyDown}
                  onChange={(e) => updateKeywords(e.target.value)}
                />
                {findTextCount > 0 && (
                  <span className={styles.resultCount}>
                    <span>
                      {currentMatchNumber} of {findTextCount}
                    </span>
                  </span>
                )}
                {findComplete && findTextCount === 0 && (
                  <span className={styles.searchNoResults}>No results</span>
                )}
              </div>
              {findTextCount > 1 && (
                <button className="btn btn-primary btn-sm" onClick={() => findNext()}>
                  Next
                </button>
              )}
            </div>
          )}
          <div className={styles.actionButtons}>
            {showDeleteButton && deleteReportCallback && (
              <button
                className={'btn btn-danger btn-sm ' + styles.actionButton}
                disabled={loading || displayResults.length === 0}
                onClick={() => deleteReportCallback()}>
                <FontAwesomeIcon size="sm" className="me-2" icon={faTrash} />
                Delete
              </button>
            )}
            {getReportDownloadCallback && (
              <>
                <button
                  className={'btn btn-primary btn-sm ' + styles.actionButton}
                  disabled={loading || displayResults.length === 0}
                  onClick={() => getReportDownloadCallback(ReportResponseType.PDF)}>
                  <FontAwesomeIcon size="sm" className="me-2" icon={faDownload} />
                  PDF
                </button>
                <button
                  className={'btn btn-primary btn-sm ' + styles.actionButton}
                  disabled={loading || displayResults.length === 0}
                  onClick={() => getReportDownloadCallback(ReportResponseType.CSV)}>
                  <FontAwesomeIcon size="sm" className="me-2" icon={faDownload} />
                  CSV
                </button>
              </>
            )}
            {toggleReportViewCallback && (
              <button
                className={'btn btn-primary btn-sm ' + styles.actionButton}
                onClick={() => toggleReportViewCallback()}>
                {viewType === ScreenViewType.DEFAULT && (
                  <>
                    <FontAwesomeIcon size="sm" className="me-2" icon={faExpand} />
                    View Fullscreen
                  </>
                )}
                {viewType === ScreenViewType.FULLSCREEN && (
                  <>
                    <FontAwesomeIcon size="sm" className="me-2" icon={faRightFromBracket} />
                    Exit Fullscreen
                  </>
                )}
              </button>
            )}
          </div>
        </div>
        {loading && (
          <div className="m-5 text-center">
            <Spinner animation="border" />
          </div>
        )}
        {!loading && (
          <>
            {displayResults.length === 0 && (
              <div
                className={'report-container ' + styles.reportContainer}
                style={maxReportHeight ? { maxHeight: maxReportHeight } : {}}>
                <em className={styles.noReportLoaded}>No report loaded.</em>
              </div>
            )}
            {displayResults.length > 0 && (
              <div
                id={viewerId.current}
                className={'report-container ' + styles.reportContainer}
                style={maxReportHeight ? { maxHeight: maxReportHeight } : {}}
                dangerouslySetInnerHTML={{ __html: displayResults }}></div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ReportViewer;

ReportViewer.propTypes = {
  loading: PropTypes.bool,
  searchEnabled: PropTypes.bool,
  viewType: PropTypes.number,
  toggleReportViewCallback: PropTypes.func,
  reportHtml: PropTypes.string,
  deleteReportCallback: PropTypes.func,
  showDeleteButton: PropTypes.bool,
  getReportDownloadCallback: PropTypes.func,
  findScrollOffset: PropTypes.number,
  maxReportHeight: PropTypes.number
};
