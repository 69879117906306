import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { routeMapping } from './routeMapping';

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routeMapping
          .filter((route) => route.element)
          .map((route) => {
            return <Route key={route.path} path={route.path} element={route.element} />;
          })}
      </Routes>
    </BrowserRouter>
  );
};
export default Routing;
