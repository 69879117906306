import { useState, useCallback, useEffect, useRef } from 'react';
import { useAuthContext } from '../../../../context/provider/AuthContext';
import { Api, Helpers } from '../../../../utils/helpers';
import { ResearchDateRangeType } from '../../../../utils/constants/enums';

const usePayPalActivitySearch = () => {
  const [{ user }] = useAuthContext();
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [accountsLoading, setAccountsLoading] = useState(true);
  const [payPalAccounts, setPayPalAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagedResult, setPagedResult] = useState(null);
  const rowView = useRef([]);

  const [batchDetailsModal, setBatchDetailsModal] = useState({
    batchDate: null,
    payPalId: null
  });

  const isRowViewed = (cpiId) => {
    return rowView.current.includes(cpiId);
  };

  const currentDate = new Date();

  const [searchParameters, setSearchParameters] = useState({
    pageSize: 20,
    page: 1,
    timestamp: null,
    brandId: user.activeBrand.id,
    payPalId: null,
    dateRangeType: ResearchDateRangeType.YESTERDAY,
    fromDate: Helpers.toInputFieldDate(Helpers.addDays(currentDate, -30)),
    toDate: Helpers.toInputFieldDate(Helpers.addDays(currentDate, -1)),
    payorName: ''
  });

  const updateSearchParams = (key, value, options) => {
    //handle clearing custom error messages based on which fields were changed
    switch (key) {
      case 'dateRangeType':
        setErrors((errors) => {
          errors.customDateMissing = false;
          errors.customDateInvalid = false;
          return errors;
        });
        break;
      case 'fromDate':
      case 'toDate':
        setFieldError('customDateInvalid', false);
        break;
      default:
        setFieldError(key, false);
    }

    let params = { ...searchParameters };
    if (options?.numbersOnly) {
      value = value?.replace(/\D/g, '') ?? '';
    }
    params[key] = value;
    setSearchParameters(params);
  };

  const setFieldError = (field, hasError) => {
    let errorsToUpdate = { ...errors };
    errorsToUpdate[field] = hasError;
    setErrors(errorsToUpdate);
  };

  const runPayPalSearch = () => {
    let formErrors = {};

    if (!searchParameters.payPalId) {
      formErrors.payPalId = true;
      setErrors(formErrors);
      return false;
    }

    //if they selected custom date, they need to fill in the dates
    if (searchParameters.dateRangeType === ResearchDateRangeType.CUSTOM) {
      if (!searchParameters.fromDate || !searchParameters.toDate) {
        formErrors.customDateMissing = true;
      } else {
        //check to make sure dates are valid and do not include 'today'
        const currentDate = Helpers.dateWithoutTime(new Date());
        const testFromDate = Helpers.tryParseDate(`${searchParameters.fromDate} 00:00:00`, null);
        const testToDate = Helpers.tryParseDate(`${searchParameters.toDate} 00:00:00`, null);

        if (
          testFromDate === null ||
          testToDate === null ||
          testFromDate.getTime() === currentDate.getTime() ||
          testToDate.getTime() === currentDate.getTime() ||
          testFromDate >= currentDate ||
          testToDate >= currentDate
        ) {
          formErrors.customDateInvalid = true;
        }
      }
    }

    setErrors(formErrors);

    //do we have any errors?
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    let params = { ...searchParameters };
    params.page = 1;
    params.timestamp = Date.now();
    setSearchParameters(params);
  };

  const changePage = (page) => {
    let params = { ...searchParameters };
    params.page = page;
    setSearchParameters(params);
  };

  const resetPayPalSearch = () => {
    let params = { ...searchParameters };
    params.dateRangeType = ResearchDateRangeType.YESTERDAY;
    params.toDate = Helpers.toInputFieldDate(Helpers.addDays(currentDate, -1));
    params.fromDate = Helpers.toInputFieldDate(Helpers.addDays(currentDate, -30));
    params.payorName = '';
    setSearchParameters(params);
    setPagedResult(null);
  };

  const reloadSearch = () => {
    let searchParams = { ...searchParameters };
    searchParams.timestamp = Date.now();
    setSearchParameters(searchParams);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      runPayPalSearch();
    }
  };

  const viewBatchDetails = async (batchDate) => {
    //track this date as viewed
    if (!rowView.current.includes(batchDate)) {
      rowView.current.push(batchDate);
    }
    setBatchDetailsModal({
      batchDate: batchDate,
      payPalId: searchParameters.payPalId
    });
  };

  const closeBatchDetails = () => {
    setBatchDetailsModal({
      batchDate: null,
      payPalId: null
    });
  };

  const getDisplayName = (account) => {
    return account.customerName.trim() === account.friendlyName.trim()
      ? account.friendlyName
      : `${account.customerName} - ${account.friendlyName}`;
  };

  const search = useCallback(async () => {
    setApiError('');
    if (searchParameters.timestamp === null) {
      return;
    }
    setLoading(true);

    const searchParams = JSON.stringify(searchParameters);
    const apiResponse = await Api.post(`/data/paypal/activity`, searchParams);
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setApiError(
        `${apiResponse.status.statusCode} - ${apiResponse.status.statusDescription}
        ${apiResponse.errors.join(', ')}`
      );
      setLoading(false);
      return;
    }

    setPagedResult(apiResponse.response);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameters.searchType, searchParameters.page, searchParameters.timestamp]);

  useEffect(() => {
    //automatically trigger search if page changes or type changes
    search();
  }, [search, searchParameters.page, searchParameters.timestamp]);

  useEffect(() => {
    const loadPayPalAccounts = async () => {
      setAccountsLoading(true);
      const endpoint = `/data/paypal/accounts?brandId=${user.activeBrand.id}`;
      const apiResponse = await Api.get(endpoint);
      if (apiResponse.status.statusCode === 200) {
        setPayPalAccounts(apiResponse.response);

        if (apiResponse.response.length === 1) {
          setSearchParameters((params) => {
            params.payPalId = apiResponse.response[0].payPalId;
            return params;
          });
        }
      }
      setAccountsLoading(false);
    };
    loadPayPalAccounts();
  }, [user.activeBrand.id]);

  return {
    brandId: user.activeBrand.id,
    loading,
    errors,
    apiError,
    accountsLoading,
    payPalAccounts,
    pagedResult,
    searchParameters,
    batchDetailsModal,
    getDisplayName,
    updateSearchParams,
    changePage,
    handleSearchKeyDown,
    reloadSearch,
    isRowViewed,
    runPayPalSearch,
    resetPayPalSearch,
    viewBatchDetails,
    closeBatchDetails
  };
};

export default usePayPalActivitySearch;
