import './LoadingOverlay.css';
import { ProgressBar } from '../../../components/ui';
import logoImage from '../../../assets/images/UniPay-logo-color.png';

export const LoadingOverlay = ({ children }) => {
  return (
    <div className="loading-container">
      <div>
        <img src={logoImage} width="150" alt="UniPay" />
        <ProgressBar
          className="m-0 mt-2"
          bgClass="loading-progress-bg"
          barClass="loading-progress-bar"
        />
        {children}
      </div>
    </div>
  );
};
