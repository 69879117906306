import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

export const PagingControls = ({ page, pageCount, changePageCallback }) => {
  const prevPage = page > 1 ? page - 1 : page;
  const nextPage = page < pageCount ? page + 1 : page;
  const plusOrMinusPagesToShow = 5;
  const firstPageInRange = page - plusOrMinusPagesToShow;
  const lastPageInRange = page + plusOrMinusPagesToShow;

  let pageRange = [];

  if (firstPageInRange > 2) {
    // Add a separator
    pageRange.push({
      number: firstPageInRange - 1,
      label: 'ellipsis'
    });
  }

  for (let i = firstPageInRange; i <= lastPageInRange; i++) {
    // Don't want to add negative pages or pages past pageCount
    if (i < 1 || i > pageCount) {
      continue;
    }
    pageRange.push({
      label: i,
      number: i,
      active: i === page
    });
  }

  if (lastPageInRange < pageCount - 1) {
    // Add a separator
    pageRange.push({
      number: lastPageInRange + 1,
      label: 'ellipsis'
    });
  }

  //no need to show paging if only a single page
  if (pageCount === 1) {
    return <></>;
  }

  //return paging controls
  return (
    <Pagination>
      <Pagination.First onClick={() => changePageCallback(1)} disabled={page <= 1} />
      <Pagination.Prev onClick={() => changePageCallback(prevPage)} disabled={page <= 1} />
      {pageRange.map((pg, index) => {
        if (pg.label === 'ellipsis') {
          return <Pagination.Ellipsis key={index} onClick={() => changePageCallback(pg.number)} />;
        } else {
          return (
            <Pagination.Item
              key={index}
              active={pg.active}
              onClick={() => changePageCallback(pg.number)}>
              {pg.label}
            </Pagination.Item>
          );
        }
      })}
      <Pagination.Next onClick={() => changePageCallback(nextPage)} disabled={page >= pageCount} />
      <Pagination.Last onClick={() => changePageCallback(pageCount)} disabled={page >= pageCount} />
    </Pagination>
  );
};

export default PagingControls;
