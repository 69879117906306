import PropTypes from 'prop-types';
import { propTypes } from 'react-bootstrap/esm/Image';

export const Dropdown = ({
  label,
  labelId,
  className,
  value,
  onChange,
  firstOption,
  firstOptionValue,
  options
}) => {
  return (
    <>
      <label htmlFor={labelId}>{label}</label>
      <select id={labelId} className={className} value={value} onChange={onChange}>
        {firstOption && <option value={firstOptionValue}>{firstOption}</option>}
        {options.map((option, idx) => (
          <option key={idx} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  labelid: propTypes.string,
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  firstOption: PropTypes.string,
  firstOptionValue: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  )
};
