import { DefaultLayout as Layout } from '../../../layouts';

export const NotAuthorizedPage = () => {
  return (
    <Layout>
      <div className="titleBar">
        <h1 className="pageTitle">Not Authorized</h1>
      </div>
    </Layout>
  );
};
