import { DefaultLayout as Layout } from '../../../layouts';
import { CustomerManager } from '../CustomerManager/CustomerManager';

export const CustomerManagementPage = () => {
  return (
    <Layout>
      <CustomerManager />
    </Layout>
  );
};
