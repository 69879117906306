import { useState } from 'react';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import { Helpers } from '../../../../../../../utils/helpers/Helpers';

const useOfficeBearer = (containerFormData, formStatusCallback) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    officeBearerPrefix: containerFormData.officeBearerPrefix,
    officeBearerGivenName: containerFormData.officeBearerGivenName,
    officeBearerSurname: containerFormData.officeBearerSurname,
    officeBearerSuffix: containerFormData.officeBearerSuffix,
    officeBearerAddressLine1: containerFormData.officeBearerAddressLine1,
    officeBearerAddressLine2: containerFormData.officeBearerAddressLine2,
    officeBearerAdminArea1: containerFormData.officeBearerAdminArea1,
    officeBearerAdminArea2: containerFormData.officeBearerAdminArea2,
    officeBearerPostalCode: containerFormData.officeBearerPostalCode,
    officeBearerDOB: containerFormData.officeBearerDOB,
    officeBearerIdentificationDocumentType:
      containerFormData.officeBearerIdentificationDocumentType,
    officeBearerIdentificationDocumentIdNumber:
      containerFormData.officeBearerIdentificationDocumentIdNumber,
    officeBearerPhoneNumber: containerFormData.officeBearerPhoneNumber,
    officeBearerEmailAddress: containerFormData.officeBearerEmailAddress
  });

  const isValidField = (name, value) => {
    //place any custom field validation logic in here
    switch (name) {
      case 'officeBearerSuffix':
        return true; //optional field
      case 'officeBearerAddressLine2':
        return true; //optional field
      case 'officeBearerEmailAddress':
        return Helpers.isValidEmail(value);
      case 'officeBearerDOB':
        return Helpers.isValidUnformattedDob(value);
      case 'officeBearerPhoneNumber':
        return Helpers.isValidUnformattedPhoneNumber(value);
      case 'officeBearerIdentificationDocumentIdNumber':
        return Helpers.isValidIdDocumentNumber(
          Helpers.isNullOrWhitespace(formData.officeBearerIdentificationDocumentType)
            ? 'UNSET'
            : formData.officeBearerIdentificationDocumentType,
          value
        );
      default:
        return !Helpers.isNullOrWhitespace(value);
    }
  };

  const isMissingValues = (fields) => {
    return Object.entries(fields).some(([key, value]) => {
      return !isValidField(key, value);
    });
  };

  const [componentStatus, setComponentStatus] = useState(
    isMissingValues(formData) ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
  );

  const updateValue = ({ name, value }) => {
    const fields = { ...formData };
    fields[name] = value;
    setFormData(fields);

    setErrors((err) => {
      return { ...err, [name]: !isValidField(name, value) };
    });

    const missingValues = isMissingValues(fields);
    setComponentStatus(
      missingValues ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
    );
    formStatusCallback('officeBearer', !missingValues, fields);
  };

  return {
    componentStatus,
    formData,
    errors,
    updateValue
  };
};

export default useOfficeBearer;
