import { useState } from 'react';
import { DefaultLayout as Layout } from '../../../layouts';
import { useAuthorization } from '../../../utils/hooks';
import { Api } from '../../../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

export const ApiTestPage = () => {
  useAuthorization();
  const [endpoint, setEndpoint] = useState('/auth/status');
  const [method, setMethod] = useState('GET');
  const [body, setBody] = useState('{}');
  const [timeResults, setTimeResults] = useState(null);
  const [results, setResults] = useState('');

  const sendApiRequest = async (event) => {
    event.preventDefault();
    const startTime = new Date().getTime();
    const reqBody = method === 'POST' ? body : null;
    const apiResponse = await Api.sendRequest(endpoint, method, reqBody);
    const endTime = new Date().getTime();
    const timeElapsed = (endTime - startTime) / 1000;
    setResults(JSON.stringify(apiResponse, null, '  '));
    setTimeResults(`Response Time: ${timeElapsed} seconds`);
  };

  return (
    <Layout>
      <h1 className="pageTitle">API Tester</h1>
      <form onSubmit={(e) => sendApiRequest(e)}>
        <div className="form-group d-flex">
          <select
            className="form-select flex-shrink-1 w-auto  me-2"
            value={method}
            onChange={(e) => setMethod(e.target.value)}>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="DELETE">DELETE</option>
          </select>
          <input
            className="form-control me-2"
            type="text"
            value={endpoint}
            onChange={(e) => setEndpoint(e.target.value)}
          />
          <button type="submit" className="btn btn-primary">
            Send <FontAwesomeIcon className="ms-2" icon={faPaperPlane} />
          </button>
        </div>
        {method === 'POST' && (
          <div className="form-group">
            <textarea
              className="font-monospace form-control mt-2 h-100"
              value={body}
              onChange={(e) => setBody(e.target.value)}></textarea>
          </div>
        )}
      </form>
      <hr />
      <div>
        <div>
          <strong>{timeResults}</strong>
        </div>
        <pre>{results}</pre>
      </div>
    </Layout>
  );
};
