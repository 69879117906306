import React from 'react';
import PropTypes from 'prop-types';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from 'react-bootstrap';
import { Helpers } from '../../../../../../utils/helpers';
import styles from '../../PayPal.module.css';
import useProcessView from './useProcessView';

export const ProcessView = ({
  customerRecordParameter,
  processViewParameters,
  payPalIdSelection,
  payPalIdStatusCallback
}) => {
  const manager = useProcessView(
    customerRecordParameter,
    processViewParameters,
    payPalIdSelection,
    payPalIdStatusCallback
  );

  return (
    <>
      {manager.loading && (
        <div className="m-2 text-center">
          <Spinner animation="boarder" />
        </div>
      )}
      {!manager.loading && manager.processViewLoaded && (
        <>
          {!manager.payPalIsActive && !manager.passedPayPalVerification && (
            <div className="col-12 mt-4">
              <button className="btn btn-primary" onClick={manager.refreshProcessView}>
                <FontAwesomeIcon size="1x" className="me-2" icon={faArrowsRotate} />
                Refresh
              </button>
            </div>
          )}
          {!manager.payPalIsActive && (
            <div className="col-12 mt-4">
              <div>
                Please review the following onboarding information. When all required information
                has been collected, submitted, and verified, you may activate the customer&apos;s
                PayPal account.
              </div>
              <button
                className="btn btn-primary"
                disabled={!manager.passedPayPalVerification}
                onClick={manager.activatePayPal}>
                Activate PayPal
              </button>
            </div>
          )}
          {manager.payPalIsActive && (
            <div className="col-12 mt-4">
              <div>This PayPal managed account is active.</div>
              <button
                className="btn btn-primary"
                disabled={!manager.passedPayPalVerification}
                onClick={manager.deactivatePayPal}>
                Deactivate PayPal
              </button>
            </div>
          )}
          <br />
          <div id="payPalProcessView">
            <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
              <thead>
                <tr>
                  <th className={styles.paypalOnboardingSectionHeader} colSpan={2}>
                    Process View
                  </th>
                </tr>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>KYC Verification:</td>
                  <td>{manager.kycVerificationStatus}</td>
                </tr>
                <tr>
                  <td>BO Verification:</td>
                  <td>{manager.boVerificationStatus}</td>
                </tr>
              </tbody>
            </table>
            <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
              <thead>
                <tr>
                  <th className={styles.paypalOnboardingSectionHeader} colSpan={2}>
                    PayPal Account Information
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>PayPal Account Id:</td>
                  <td>{manager.processView.accountId}</td>
                </tr>
                <tr>
                  <td>PayPal Soft Descriptor:</td>
                  <td>{manager.processView.softDescriptor}</td>
                </tr>
                <tr>
                  <td>External Id:</td>
                  <td>{manager.processView.externalId}</td>
                </tr>
                <tr>
                  <td>Legal Country Code:</td>
                  <td>{manager.processView.legalCountryCode}</td>
                </tr>
                <tr>
                  <td>Organization:</td>
                  <td>{manager.processView.organization}</td>
                </tr>
                <tr>
                  <td>User Id:</td>
                  <td>{manager.processView.userId}</td>
                </tr>
                <tr>
                  <td>Primary Currency Code:</td>
                  <td>{manager.processView.primaryCurrencyCode}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table className={'table ' + styles.nestedStripedTable}>
                      <thead>
                        <tr colSpan={2}>
                          <th className={styles.nestedStripedTableHeader} colSpan={2}>
                            PayPal Account Information - Financial Instrument
                          </th>
                        </tr>
                      </thead>
                      {manager.hasFinancialInstrument && (
                        <tbody>
                          <tr>
                            <td>Id:</td>
                            <td>{manager.processView.financialInstruments.banks[0].id}</td>
                          </tr>
                          <tr>
                            <td>Bank Name:</td>
                            <td>{manager.processView.financialInstruments.banks[0].bankName}</td>
                          </tr>
                          <tr>
                            <td>Account Holder Name:</td>
                            <td>
                              {
                                manager.processView.financialInstruments.banks[0]
                                  .accountHolderDetails.name
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Account Type:</td>
                            <td>{manager.processView.financialInstruments.banks[0].accountType}</td>
                          </tr>
                          <tr>
                            <td>Account Number:</td>
                            <td>
                              ****{manager.processView.financialInstruments.banks[0].last4Digits}
                            </td>
                          </tr>
                          <tr>
                            <td>Routing Number:</td>
                            <td>
                              {manager.processView.financialInstruments.banks[0].routingNumber}
                            </td>
                          </tr>
                          <tr>
                            <td>Country Code:</td>
                            <td>{manager.processView.financialInstruments.banks[0].countryCode}</td>
                          </tr>
                          <tr>
                            <td>Currency Code:</td>
                            <td>
                              {manager.processView.financialInstruments.banks[0].currencyCode}
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {!manager.hasFinancialInstrument && (
                        <tbody>
                          <tr>
                            <td colSpan={2}>No Financial Institution information provided.</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
              <thead>
                <tr>
                  <th className={styles.paypalOnboardingSectionHeader} colSpan={2}>
                    PayPal Agreements
                  </th>
                </tr>
                <tr>
                  <th>Type</th>
                  <th>Status Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{manager.processView.agreements[0].type}</td>
                  <td>{Helpers.toShortDateTime(manager.processView.agreements[0].acceptedTime)}</td>
                </tr>
              </tbody>
            </table>
            <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
              <thead>
                <tr>
                  <th className={styles.paypalOnboardingSectionHeader} colSpan={2}>
                    PayPal Capabilities
                  </th>
                </tr>
                <tr>
                  <th>Agreement</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{manager.processView.capabilities[0].name}:</td>
                  <td>{manager.processView.capabilities[0].status}</td>
                </tr>
                <tr>
                  <td>{manager.processView.capabilities[1].name}:</td>
                  <td>{manager.processView.capabilities[1].status}</td>
                </tr>
              </tbody>
            </table>
            <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
              <thead>
                <tr>
                  <th className={styles.paypalOnboardingSectionHeader} colSpan={2}>
                    Legal Business Information
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Business Type:</td>
                  <td>{manager.processView.businessEntity.type}</td>
                </tr>
                <tr>
                  <td>Merchant Category Code:</td>
                  <td>{manager.processView.businessEntity.merchantCategoryCode}</td>
                </tr>
                <tr>
                  <td>Business Name:</td>
                  <td>{manager.processView.businessEntity.names[0].name}</td>
                </tr>
                <tr>
                  <td>PayPal Business Entity Names Id:</td>
                  <td>{manager.processView.businessEntity.names[0].id}</td>
                </tr>
                <tr>
                  <td>Business Name Type:</td>
                  <td>{manager.processView.businessEntity.names[0].type}</td>
                </tr>
                <tr>
                  <td>Business Email:</td>
                  <td>{manager.processView.businessEntity.emails[0].emailAddress}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
                      <thead>
                        <tr>
                          <th className={styles.paypalOnboardingSectionHeader} colSpan={2}>
                            Registered Business Address
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Address:</td>
                          <td>
                            {
                              manager.processView.businessEntity.registeredBusinessAddress
                                .addressLine1
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>City:</td>
                          <td>
                            {
                              manager.processView.businessEntity.registeredBusinessAddress
                                .adminArea2
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>State:</td>
                          <td>
                            {
                              manager.processView.businessEntity.registeredBusinessAddress
                                .adminArea1
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Postal Code:</td>
                          <td>
                            {
                              manager.processView.businessEntity.registeredBusinessAddress
                                .postalCode
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Country Code:</td>
                          <td>
                            {
                              manager.processView.businessEntity.registeredBusinessAddress
                                .countryCode
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
                      <thead>
                        <tr>
                          <th className={styles.paypalOnboardingSectionHeader} colSpan={2}>
                            Phone Number
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Country Code:</td>
                          <td>{manager.processView.businessEntity.phoneNumbers[0].countryCode}</td>
                        </tr>
                        <tr>
                          <td>National Number:</td>
                          <td>
                            {manager.processView.businessEntity.phoneNumbers[0].nationalNumber}
                          </td>
                        </tr>
                        <tr>
                          <td>Type:</td>
                          <td>{manager.processView.businessEntity.phoneNumbers[0].type}</td>
                        </tr>
                        <tr>
                          <td>PayPal Business Entity Phones Id:</td>
                          <td>{manager.processView.businessEntity.phoneNumbers[0].id}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                {manager.processView.businessEntity.identificationDocuments.length > 0 && (
                  <tr>
                    <td colSpan={2}>
                      <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
                        <thead>
                          <tr>
                            <th className={styles.paypalOnboardingSectionHeader} colSpan={2}>
                              Identification Documents
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Type:</td>
                            <td>
                              {manager.processView.businessEntity.identificationDocuments[0].type}
                            </td>
                          </tr>
                          <tr>
                            <td>Identification Number:</td>
                            <td>
                              {
                                manager.processView.businessEntity.identificationDocuments[0]
                                  .identificationNumber
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Issuing Country Code:</td>
                            <td>
                              {
                                manager.processView.businessEntity.identificationDocuments[0]
                                  .issuingCountryCode
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>PayPal Business Entity Id Documents Id:</td>
                            <td>
                              {manager.processView.businessEntity.identificationDocuments[0].id}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={2}>
                    <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
                      <thead>
                        <tr>
                          <th className={styles.paypalOnboardingSectionHeader} colSpan={2}>
                            Declarations
                          </th>
                        </tr>
                        <tr></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Name:</td>
                          <td>{manager.processView.businessEntity.declarations[0].name}</td>
                        </tr>
                        <tr>
                          <td>Value:</td>
                          <td>{manager.processView.businessEntity.declarations[0].value}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
                      <thead>
                        <tr>
                          <th className={styles.paypalOnboardingSectionHeader} colSpan={2}>
                            Beneficial Owner
                          </th>
                        </tr>
                        <tr></tr>
                      </thead>
                      {manager.hasBeneficialOwner && (
                        <tbody>
                          <tr>
                            <td>Beneficial Owner ID:</td>
                            <td>
                              {
                                manager.processView.businessEntity.beneficialOwners
                                  .individualOwners[0].id
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Percentage of Ownership:</td>
                            <td>
                              {
                                manager.processView.businessEntity.beneficialOwners
                                  .individualOwners[0].percentageOfOwnership
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>First Name:</td>
                            <td>
                              {
                                manager.processView.businessEntity.beneficialOwners
                                  .individualOwners[0].names[0].givenName
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Last Name:</td>
                            <td>
                              {
                                manager.processView.businessEntity.beneficialOwners
                                  .individualOwners[0].names[0].surname
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Date of Birth:</td>
                            <td>
                              {
                                manager.processView.businessEntity.beneficialOwners
                                  .individualOwners[0].birthDetails.dateOfBirth
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Phone Number:</td>
                            <td>
                              {
                                manager.processView.businessEntity.beneficialOwners
                                  .individualOwners[0].phoneNumbers[0].nationalNumber
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Email Address:</td>
                            <td>
                              {
                                manager.processView.businessEntity.beneficialOwners
                                  .individualOwners[0].emails[0].emailAddress
                              }
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <table className={'table ' + styles.nestedStripedTable}>
                                <thead>
                                  <tr key="business_individual_owner_address" colSpan={2}>
                                    <th className={styles.nestedStripedTableHeader} colSpan={2}>
                                      Beneficial Owner - Primary Residence
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Address Line 1:</td>
                                    <td>
                                      {
                                        manager.processView.businessEntity.beneficialOwners
                                          .individualOwners[0].addresses[0].addressLine1
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Address Line 2:</td>
                                    <td>
                                      {manager.processView.businessEntity.beneficialOwners
                                        .individualOwners[0].addresses[0].addressLine2 ?? ''}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>City:</td>
                                    <td>
                                      {
                                        manager.processView.businessEntity.beneficialOwners
                                          .individualOwners[0].addresses[0].adminArea2
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>State:</td>
                                    <td>
                                      {
                                        manager.processView.businessEntity.beneficialOwners
                                          .individualOwners[0].addresses[0].adminArea1
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Postal Code:</td>
                                    <td>
                                      {
                                        manager.processView.businessEntity.beneficialOwners
                                          .individualOwners[0].addresses[0].postalCode
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Country Code:</td>
                                    <td>
                                      {
                                        manager.processView.businessEntity.beneficialOwners
                                          .individualOwners[0].addresses[0].countryCode
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {!manager.hasBeneficialOwner && (
                        <tbody>
                          <tr>
                            <td colSpan={2}>No Beneficial Owner provided.</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
                      <thead>
                        <tr>
                          <th className={styles.paypalOnboardingSectionHeader} colSpan={2}>
                            Office Bearer
                          </th>
                        </tr>
                        <tr></tr>
                      </thead>
                      {manager.hasOfficeBearer && (
                        <tbody>
                          <tr>
                            <td>Office Bearer ID:</td>
                            <td>{manager.processView.businessEntity.officeBearers[0].id}</td>
                          </tr>
                          <tr>
                            <td>First Name:</td>
                            <td>
                              {
                                manager.processView.businessEntity.officeBearers[0].names[0]
                                  .givenName
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Last Name:</td>
                            <td>
                              {manager.processView.businessEntity.officeBearers[0].names[0].surname}
                            </td>
                          </tr>
                          <tr>
                            <td>Date of Birth:</td>
                            <td>
                              {
                                manager.processView.businessEntity.officeBearers[0].birthDetails
                                  .dateOfBirth
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Phone Number:</td>
                            <td>
                              {
                                manager.processView.businessEntity.officeBearers[0].phoneNumbers[0]
                                  .nationalNumber
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Email Address:</td>
                            <td>
                              {
                                manager.processView.businessEntity.officeBearers[0].emails[0]
                                  .emailAddress
                              }
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <table className={'table ' + styles.nestedStripedTable}>
                                <thead>
                                  <tr key="business_individual_owner_address" colSpan={2}>
                                    <th className={styles.nestedStripedTableHeader} colSpan={2}>
                                      Office Bearer - Primary Residence
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Address Line 1:</td>
                                    <td>
                                      {
                                        manager.processView.businessEntity.officeBearers[0]
                                          .addresses[0].addressLine1
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Address Line 2:</td>
                                    <td>
                                      {manager.processView.businessEntity.officeBearers[0]
                                        .addresses[0].addressLine2 ?? ''}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>City:</td>
                                    <td>
                                      {
                                        manager.processView.businessEntity.officeBearers[0]
                                          .addresses[0].adminArea2
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>State:</td>
                                    <td>
                                      {
                                        manager.processView.businessEntity.officeBearers[0]
                                          .addresses[0].adminArea1
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Postal Code:</td>
                                    <td>
                                      {
                                        manager.processView.businessEntity.officeBearers[0]
                                          .addresses[0].postalCode
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Country Code:</td>
                                    <td>
                                      {
                                        manager.processView.businessEntity.officeBearers[0]
                                          .addresses[0].countryCode
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {!manager.hasOfficeBearer && (
                        <tbody>
                          <tr>
                            <td colSpan={2}>No Office Bearer provided.</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
              <thead>
                <tr>
                  <th className={styles.paypalOnboardingSectionHeader} colSpan={2}>
                    Primary Authorized User
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Primary Authorized User Id:</td>
                  <td>{manager.processView.individualOwners[0].names[0].id}</td>
                </tr>
                <tr>
                  <td>Legal First Name:</td>
                  <td>{manager.processView.individualOwners[0].names[0].givenName}</td>
                </tr>
                <tr>
                  <td>Legal Last Name:</td>
                  <td>{manager.processView.individualOwners[0].names[0].surname}</td>
                </tr>
                <tr>
                  <td>Email Address:</td>
                  {manager.processView.individualOwners[0].emails.length > 0 && (
                    <td>{manager.processView.individualOwners[0].emails[0].emailAddress}</td>
                  )}
                  {manager.processView.individualOwners[0].emails.length <= 0 && (
                    <td>No email provided</td>
                  )}
                </tr>
                <tr>
                  <td>Date of Birth:</td>
                  <td>{manager.processView.individualOwners[0].birthDetails.dateOfBirth}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table className={'table ' + styles.nestedStripedTable}>
                      <thead>
                        <tr key="business_individual_owner_address" colSpan={2}>
                          <th className={styles.nestedStripedTableHeader} colSpan={2}>
                            Primary Authorized User - Primary Residence
                          </th>
                        </tr>
                      </thead>
                      {manager.hasAddress && (
                        <tbody>
                          <tr>
                            <td>Address Line 1:</td>
                            <td>
                              {
                                manager.processView.individualOwners[0].primaryResidence
                                  .addressLine1
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>City:</td>
                            <td>
                              {manager.processView.individualOwners[0].primaryResidence.adminArea2}
                            </td>
                          </tr>
                          <tr>
                            <td>State:</td>
                            <td>
                              {manager.processView.individualOwners[0].primaryResidence.adminArea1}
                            </td>
                          </tr>
                          <tr>
                            <td>Postal Code:</td>
                            <td>
                              {manager.processView.individualOwners[0].primaryResidence.postalCode}
                            </td>
                          </tr>
                          <tr>
                            <td>Country Code:</td>
                            <td>
                              {manager.processView.individualOwners[0].primaryResidence.countryCode}
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {!manager.hasAddress && (
                        <tbody>
                          <tr>
                            <td colSpan={2}>No address provided.</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table className={'table ' + styles.nestedStripedTable}>
                      <thead>
                        <tr>
                          <th className={styles.nestedStripedTableHeader} colSpan={2}>
                            Primary Authorized User - Phone Number
                          </th>
                        </tr>
                      </thead>
                      {manager.hasPhoneNumber && (
                        <tbody>
                          <tr>
                            <td>Country Code:</td>
                            <td>
                              {manager.processView.individualOwners[0].phoneNumbers[0].countryCode}
                            </td>
                          </tr>
                          <tr>
                            <td>Phone Number:</td>
                            <td>
                              {
                                manager.processView.individualOwners[0].phoneNumbers[0]
                                  .nationalNumber
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Type:</td>
                            <td>{manager.processView.individualOwners[0].phoneNumbers[0].type}</td>
                          </tr>
                        </tbody>
                      )}
                      {!manager.hasPhone && (
                        <tbody>
                          <tr>
                            <td colSpan={2}>No phone number provided.</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table className={'table ' + styles.nestedStripedTable}>
                      <thead>
                        <tr>
                          <th className={styles.nestedStripedTableHeader} colSpan={2}>
                            Primary Authorized User - Identification Document
                          </th>
                        </tr>
                      </thead>
                      {manager.hasIdDocument && (
                        <tbody>
                          <tr>
                            <td>Type:</td>
                            <td>
                              {
                                manager.processView.individualOwners[0].identificationDocuments[0]
                                  .type
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Identification Number:</td>
                            <td>
                              {
                                manager.processView.individualOwners[0].identificationDocuments[0]
                                  .identificationNumber
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Issuing Country Code:</td>
                            <td>
                              {
                                manager.processView.individualOwners[0].identificationDocuments[0]
                                  .issuingCountryCode
                              }
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {!manager.hasIdDocument && (
                        <tbody>
                          <tr>
                            <td colSpan={2}>No identification documents provided.</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
      {!manager.loading && !manager.processViewLoaded && (
        <>
          <div>Error fetching process view. Please try again later.</div>
        </>
      )}
    </>
  );
};

export default ProcessView;

ProcessView.propTypes = {
  processViewParameters: PropTypes.shape({
    brandId: PropTypes.number,
    customerId: PropTypes.number
  })
};
