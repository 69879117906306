import React from 'react';
import UserSearch from '../UserSearch/UserSearch';
import UserEditor from '../UserEditor/UserEditor';
import { useAuthContext } from '../../../../context/provider/AuthContext';
import useUserManager from './useUserManager';
import './UserManager.css';

export const UserManager = () => {
  const [{ user }] = useAuthContext();
  const manager = useUserManager();

  if (manager.loading) {
    return <div>Loading</div>;
  }

  return (
    <>
      {!manager.showEditor && (
        <UserSearch
          roles={user.userResources.roles}
          brands={user.userResources.brands}
          editUserCallback={manager.editUser}
        />
      )}
      {manager.showEditor && (
        <UserEditor
          userId={manager.editUserId}
          resourceDefinition={user.resources}
          roles={user.userResources.roles}
          brands={user.userResources.brands}
          returnToSearchCallback={manager.returnToSearch}
        />
      )}
    </>
  );
};
