import { DefaultLayout as Layout } from '../../../layouts';
import { ReportManager } from './ReportManager/ReportManager';

export const ReportingPage = () => {
  return (
    <Layout>
      <ReportManager />
    </Layout>
  );
};
