import { DefaultLayout as Layout } from '../../../layouts';
import { TransactionManager } from '../TransactionManager/TransactionManager';

export const TransactionManagementPage = () => {
  return (
    <Layout>
      <TransactionManager />
    </Layout>
  );
};
