import React from 'react';
import styles from '../../../PayPal.module.css';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import useOfficeBearer from './useOfficeBearer';

export const OfficeBearer = ({ containerFormData, formStatusCallback }) => {
  const manager = useOfficeBearer(containerFormData, formStatusCallback);

  return (
    <>
      {!manager.loading && (
        <div>
          <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
            <thead>
              <tr>
                <th className={styles.paypalOnboardingSectionHeader}>Office Bearer</th>
                {manager.componentStatus === FormComponentStatus.COMPLETE && (
                  <th className={styles.paypalOnboardingCompleteStatusHeader}>Complete</th>
                )}
                {manager.componentStatus === FormComponentStatus.INCOMPLETE && (
                  <th className={styles.paypalOnboardingIncompleteStatusHeader}>Incomplete</th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label htmlFor="officeBearerPrefix">
                    <span className="req">*</span>Courtesy Title:
                  </label>
                </td>
                <td>
                  <select
                    name="officeBearerPrefix"
                    id="officeBearerPrefix"
                    className="form-select"
                    aria-label="Office Bearer courtesy title selection"
                    value={manager.formData.officeBearerPrefix}
                    onChange={(e) => manager.updateValue(e.target)}>
                    <option value="">- Select -</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Ms.">Ms.</option>
                    <option value="Miss">Miss</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="officeBearerGivenName">
                    <span className="req">*</span>Legal First Name:
                  </label>
                </td>
                <td>
                  <input
                    name="officeBearerGivenName"
                    id="officeBearerGivenName"
                    type="text"
                    className="form-control"
                    value={manager.formData.officeBearerGivenName}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.officeBearerGivenName && (
                    <div className="alert-danger mt-1 p-2">Legal First Name is required.</div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="officeBearerSurname">
                    <span className="req">*</span>Legal Last Name:
                  </label>
                </td>
                <td>
                  <input
                    name="officeBearerSurname"
                    id="officeBearerSurname"
                    type="text"
                    className="form-control"
                    value={manager.formData.officeBearerSurname}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.officeBearerSurname && (
                    <div className="alert-danger mt-1 p-2">Legal Last Name is required.</div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="officeBearerSuffix">Suffix:</label>
                </td>
                <td>
                  <input
                    name="officeBearerSuffix"
                    id="officeBearerSuffix"
                    type="text"
                    className="form-control"
                    value={manager.formData.officeBearerSuffix}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="officeBearerDOB">
                    <span className="req">*</span>Date of Birth:
                  </label>
                </td>
                <td>
                  <input
                    name="officeBearerDOB"
                    id="officeBearerDOB"
                    type="text"
                    className="form-control"
                    value={manager.formData.officeBearerDOB}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.officeBearerDOB && (
                    <div className="alert-danger mt-1 p-2">
                      Date of Birth is required. Format: &apos;yyyy-mm-dd&apos;.
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="officeBearerEmailAddress">
                    <span className="req">*</span>Email Address:
                  </label>
                </td>
                <td>
                  <input
                    name="officeBearerEmailAddress"
                    id="officeBearerEmailAddress"
                    type="text"
                    className="form-control"
                    value={manager.formData.officeBearerEmailAddress}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.officeBearerEmailAddress && (
                    <div className="alert-danger mt-1 p-2">Email Address is required.</div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="officeBearerPhoneNumber">
                    <span className="req">*</span>Work Phone Number:
                  </label>
                </td>
                <td>
                  <input
                    name="officeBearerPhoneNumber"
                    id="officeBearerPhoneNumber"
                    type="text"
                    className="form-control"
                    placeholder="Numerical digits only: 5081234567"
                    value={manager.formData.officeBearerPhoneNumber}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.officeBearerPhoneNumber && (
                    <div className="alert-danger mt-1 p-2">
                      Work Phone Number is required. Numerical digits only, no parentheses or
                      dashes.
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table className={'table ' + styles.nestedStripedTable}>
                    <thead>
                      <tr>
                        <th className={styles.nestedStripedTableHeader} colSpan={2}>
                          Office Bearer - Address
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="officeBearerAddressLine1">
                            <span className="req">*</span>Address Line 1:
                          </label>
                        </td>
                        <td>
                          <input
                            name="officeBearerAddressLine1"
                            id="officeBearerAddressLine1"
                            type="text"
                            className="form-control"
                            value={manager.formData.officeBearerAddressLine1}
                            onChange={(e) => manager.updateValue(e.target)}
                            onBlur={(e) => manager.updateValue(e.target)}
                          />
                          {manager.errors.officeBearerAddressLine1 && (
                            <div className="alert-danger mt-1 p-2">Address Line 1 is required.</div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="officeBearerAddressLine2">Address Line 2:</label>
                        </td>
                        <td>
                          <input
                            name="officeBearerAddressLine2"
                            id="officeBearerAddressLine2"
                            type="text"
                            className="form-control"
                            value={manager.formData.officeBearerAddressLine2}
                            onChange={(e) => manager.updateValue(e.target)}
                            onBlur={(e) => manager.updateValue(e.target)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="officeBearerAdminArea2">
                            <span className="req">*</span>City:
                          </label>
                        </td>
                        <td>
                          <input
                            name="officeBearerAdminArea2"
                            id="officeBearerAdminArea2"
                            type="text"
                            className="form-control"
                            value={manager.formData.officeBearerAdminArea2}
                            onChange={(e) => manager.updateValue(e.target)}
                            onBlur={(e) => manager.updateValue(e.target)}
                          />
                          {manager.errors.officeBearerAdminArea2 && (
                            <div className="alert-danger mt-1 p-2">City is required.</div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="officeBearerAdminArea1">
                            <span className="req">*</span>State:
                          </label>
                        </td>
                        <td>
                          <input
                            name="officeBearerAdminArea1"
                            id="officeBearerAdminArea1"
                            type="text"
                            className="form-control"
                            value={manager.formData.officeBearerAdminArea1}
                            onChange={(e) => manager.updateValue(e.target)}
                            onBlur={(e) => manager.updateValue(e.target)}
                          />
                          {manager.errors.officeBearerAdminArea1 && (
                            <div className="alert-danger mt-1 p-2">State is required.</div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="officeBearerPostalCode">
                            <span className="req">*</span>Postal Code:
                          </label>
                        </td>
                        <td>
                          <input
                            name="officeBearerPostalCode"
                            id="officeBearerPostalCode"
                            type="text"
                            className="form-control"
                            value={manager.formData.officeBearerPostalCode}
                            onChange={(e) => manager.updateValue(e.target)}
                            onBlur={(e) => manager.updateValue(e.target)}
                          />
                          {manager.errors.officeBearerPostalCode && (
                            <div className="alert-danger mt-1 p-2">Postal Code is required.</div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table className={'table ' + styles.nestedStripedTable}>
                    <thead>
                      <tr>
                        <th className={styles.nestedStripedTableHeader} colSpan={2}>
                          Office Bearer - Identification Document
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="officeBearerIdentificationDocumentType">
                            <span className="req">*</span>Type:
                          </label>
                        </td>
                        <td>
                          <select
                            name="officeBearerIdentificationDocumentType"
                            id="officeBearerIdentificationDocumentType"
                            className="form-select"
                            aria-label="Identification document type selection"
                            value={manager.formData.officeBearerIdentificationDocumentType}
                            onChange={(e) => manager.updateValue(e.target)}>
                            <option value="">- Select -</option>
                            <option value="SOCIAL_SECURITY_NUMBER">Social Security Number</option>
                            <option value="INDIVIDUAL_TAX_IDENTIFICATION_NUMBER">
                              Individual Tax Identification Number
                            </option>
                          </select>
                          {manager.errors.officeBearerIdentificationDocumentType && (
                            <div className="alert-danger mt-1 p-2">
                              Office Bearer - Identification Document Type is required.
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="officeBearerIdentificationDocumentIdNumber">
                            <span className="req">*</span>Identification Number:
                          </label>
                        </td>
                        <td>
                          <input
                            name="officeBearerIdentificationDocumentIdNumber"
                            id="officeBearerIdentificationDocumentIdNumber"
                            type="text"
                            className="form-control"
                            value={manager.formData.officeBearerIdentificationDocumentIdNumber}
                            onChange={(e) => manager.updateValue(e.target)}
                            onBlur={(e) => manager.updateValue(e.target)}
                          />
                          {manager.errors.officeBearerIdentificationDocumentIdNumber && (
                            <div className="alert-danger mt-1 p-2">
                              Identification Number is required.
                              <br />9 digit numeric-only digits like xxxxxxxxx.
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
