import { DefaultLayout as Layout } from '../../../layouts';
import { useAuthorization } from '../../../utils/hooks';
import { TileLink } from '../../../components/ui';

export const DevToolsHomePage = () => {
  useAuthorization();

  return (
    <Layout>
      <div className="titleBar">
        <h1 className="pageTitle">Dev Tools</h1>
      </div>
      <div className="d-flex flex-wrap justify-content-lg-start justify-content-evenly">
        <TileLink text="API Tools" target="/dev-tools/api" iconClass="icofont-direction-sign" />
        <TileLink
          text="Upload Testing"
          target="/dev-tools/file-upload"
          iconClass="icofont-cloud-upload"
        />
      </div>
    </Layout>
  );
};
