import { Helpers } from '../../../../utils/helpers/Helpers';
import { MessageOptions } from '../../../../utils/constants/enums';
import { PagingControls, SortingHeader } from '../../../../components/ui';
import BrandCustomerSelector from '../BrandCustomerSelector/BrandCustomerSelector';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useMessageSearch from './useMessageSearch';

const MessageSearch = ({ roles, brandCustomers, editMessageCallback }) => {
  const search = useMessageSearch({ roles, brandCustomers });

  return (
    <>
      <div className="titleBar">
        <h1 className="pageTitle">Manage Messages</h1>
        <div className="actions">
          <button className="btn btn-success" onClick={() => editMessageCallback(null)}>
            Add Message
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-9 col-lg-12">
          <div className="card search-results-container">
            <div className="card-body p-0">
              {!search.loading && search?.pagedResult?.results.length === 0 && (
                <div className="p-3">
                  <div className="mb-3">No messages found.</div>
                  <button className="btn btn-success" onClick={() => editMessageCallback(null)}>
                    Add Message
                  </button>
                </div>
              )}

              {!search.loading && search?.pagedResult?.results.length > 0 && (
                <>
                  <table className="table table-striped search-results-table">
                    <thead>
                      <tr>
                        <th>
                          <SortingHeader
                            label="Title"
                            sortField="title"
                            sortDirection={search.searchParameters.sortDirection}
                            onClick={search.updateSorting}
                            active={search.searchParameters.sortField === 'title'}
                          />
                        </th>
                        <th className="d-none d-md-table-cell">
                          <SortingHeader label="Audience" />
                        </th>
                        <th className="d-none d-md-table-cell">
                          <SortingHeader
                            label="Options"
                            sortField="options"
                            sortDirection={search.searchParameters.sortDirection}
                            onClick={search.updateSorting}
                            active={search.searchParameters.sortField === 'options'}
                          />
                        </th>
                        <th className="d-none d-md-table-cell">
                          <SortingHeader
                            label="Start Date"
                            sortField="startdate"
                            sortDirection={search.searchParameters.sortDirection}
                            onClick={search.updateSorting}
                            active={search.searchParameters.sortField === 'startdate'}
                          />
                        </th>
                        <th className="d-none d-md-table-cell">
                          <SortingHeader
                            label="End Date"
                            sortField="enddate"
                            sortDirection={search.searchParameters.sortDirection}
                            onClick={search.updateSorting}
                            active={search.searchParameters.sortField === 'enddate'}
                          />
                        </th>
                        <th className="d-none d-md-table-cell">
                          <SortingHeader
                            label="Last Modified"
                            sortField="lastmodified"
                            sortDirection={search.searchParameters.sortDirection}
                            onClick={search.updateSorting}
                            active={search.searchParameters.sortField === 'lastmodified'}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {search?.pagedResult?.results?.map((message) => (
                        <tr key={message.id}>
                          <td>
                            <a
                              href="#!"
                              className="search-result-title-link"
                              onClick={() => editMessageCallback(message.id)}>
                              {message.title}
                            </a>
                            <div className="search-result-preview">{message.preview}</div>
                            <div>
                              <span className="badge bg-secondary view-count">
                                {message.viewCount} {message.viewCount === 1 ? 'VIEW' : 'VIEWS'}
                              </span>
                              {Helpers.hasFlag(
                                message.messageOptions,
                                MessageOptions.HIGH_PRIORITY
                              ) && (
                                <span
                                  title="High Priority"
                                  className="d-sm-inline-block d-md-none badge bg-warning text-dark message-option">
                                  High
                                </span>
                              )}
                              {Helpers.hasFlag(
                                message.messageOptions,
                                MessageOptions.REQUIRES_ACKNOWLEDGEMENT
                              ) && (
                                <span
                                  title="Requires Acknowledgement"
                                  className="d-sm-inline-block d-md-none badge bg-danger message-option">
                                  Acknowledge
                                </span>
                              )}
                            </div>
                          </td>
                          <td className="d-none d-md-table-cell">
                            {message.audiences.map((aud, index) => (
                              <div className="search-result-audience" key={index}>
                                {search.audienceToText(aud)}
                              </div>
                            ))}
                            {!search.hasBrandCustomerFilter(message.audiences) && (
                              <div className="search-result-audience">All brands and customers</div>
                            )}
                          </td>
                          <td className="d-none d-md-table-cell">
                            {Helpers.hasFlag(
                              message.messageOptions,
                              MessageOptions.HIGH_PRIORITY
                            ) && (
                              <span
                                title="High Priority"
                                className="badge bg-warning text-dark message-option">
                                High
                              </span>
                            )}
                            {Helpers.hasFlag(
                              message.messageOptions,
                              MessageOptions.REQUIRES_ACKNOWLEDGEMENT
                            ) && (
                              <span
                                title="Requires Acknowledgement"
                                className="badge bg-danger message-option">
                                Acknowledge
                              </span>
                            )}
                          </td>
                          <td className="d-none d-md-table-cell">
                            {Helpers.toShortDate(message.startDateUtc, 'Immediately')}
                          </td>
                          <td className="d-none d-md-table-cell">
                            {Helpers.toShortDate(message.endDateUtc, 'Never')}
                          </td>
                          <td className="d-none d-md-table-cell">
                            {Helpers.toShortDateTime(message.lastModifiedUtc)}
                            <br />
                            by {message.lastModifiedBy}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="ps-3 pe-3">
                    <PagingControls
                      page={search.pagedResult.currentPage}
                      pageCount={search.pagedResult.pageCount}
                      changePageCallback={search.changePage}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-12">
          <div className="card admin-card">
            <div className="card-header">Message Filters</div>
            <div className="card-body message-filters">
              <div className="row mb-2">
                <div className="col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search messages"
                      onChange={(e) => search.updateKeywords(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-6">
                  <label>From Date</label>
                  <DatePicker
                    selected={
                      search.searchParameters.filters.startDate &&
                      new Date(search.searchParameters.filters.startDate)
                    }
                    onChange={(date) =>
                      search.updateFilter('startDate', date?.toISOString() ?? null)
                    }
                    showTimeSelect={false}
                    dateFormat="M/d/yyyy"
                    className="form-control"
                    isClearable={true}
                  />
                </div>
                <div className="col-6">
                  <label>To Date</label>
                  <DatePicker
                    selected={
                      search.searchParameters.filters.endDate &&
                      new Date(search.searchParameters.filters.endDate)
                    }
                    onChange={(date) => search.updateFilter('endDate', date?.toISOString() ?? null)}
                    showTimeSelect={false}
                    dateFormat="M/d/yyyy"
                    className="form-control"
                    isClearable={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => search.updateMessageOptionsFilter(1)}
                    checked={Helpers.hasFlag(
                      search.searchParameters.filters.options,
                      MessageOptions.HIGH_PRIORITY
                    )}
                    id="checkHighPriority"
                  />
                  <label className="form-check-label" htmlFor="checkHighPriority">
                    High Priority
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => search.updateMessageOptionsFilter(2)}
                    checked={Helpers.hasFlag(
                      search.searchParameters.filters.options,
                      MessageOptions.REQUIRES_ACKNOWLEDGEMENT
                    )}
                    id="checkRequireAck"
                  />
                  <label className="form-check-label" htmlFor="checkRequireAck">
                    Requires Acknowledgement
                  </label>
                </div>
              </div>

              <div className="mb-3">
                <BrandCustomerSelector
                  brandCustomers={brandCustomers}
                  onChange={search.updateBrandCustomerSelection}
                />
              </div>

              <div className="row">
                <div className="col-12">
                  {roles.map((role) => (
                    <div key={role.id} className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={role.id}
                        onChange={() => search.updateRoleFilter(role.id)}
                        checked={search.isRoleFilterChecked(role.id)}
                        id={role.id}
                      />
                      <label className="form-check-label" htmlFor={role.id}>
                        {role.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageSearch;
