import React from 'react';
import { CustomerSummary, SftpAccounts, PayPal } from '../CustomerSections';
import styles from './CustomerManager.module.css';
import useCustomerManager from './useCustomerManager';
import { CustomerSections } from '../../../utils/constants/enums';

export const CustomerManager = () => {
  const manager = useCustomerManager();

  return (
    <>
      {!manager.defaultCustomer && (
        <div className="titleBar">
          <h1 className="pageTitle">Manage Customers</h1>
        </div>
      )}
      <div className={styles.customerManager}>
        <div>
          {manager.defaultCustomer && (
            <div className={styles.defaultCustomer}>{manager.defaultCustomer.name}</div>
          )}
          {!manager.defaultCustomer && (
            <select
              className="form-select w-auto"
              value={manager.customerParameters.customerId ?? ''}
              onChange={(e) => manager.updateCustomerParams(parseInt(e.currentTarget.value))}>
              <option value={0}>- Select Customer -</option>
              {manager.customers.map((cust) => {
                return (
                  <option key={cust.id} value={cust.id}>
                    {cust.name}
                  </option>
                );
              })}
            </select>
          )}
        </div>
        {manager.customerParameters.customerId !== null && (
          <div className={styles.customerDetails}>
            <div className={styles.sectionMenu}>
              {manager.allowedSections.map((section) => {
                let sectionClass =
                  manager.viewSection === section.id
                    ? styles.sectionMenuItemActive
                    : styles.sectionMenuItem;

                return (
                  <a
                    href="#"
                    key={section.id}
                    className={sectionClass}
                    onClick={(e) => {
                      e.preventDefault();
                      manager.openSection(section.id);
                    }}>
                    {section.name}
                  </a>
                );
              })}
            </div>
            <div className={styles.sectionDisplay}>
              {manager.viewSection === CustomerSections.CUSTOMER_SUMMARY && (
                <div className={styles.customerConfigSection}>
                  <CustomerSummary customerParameters={manager.customerParameters} />
                </div>
              )}
              {manager.viewSection === CustomerSections.SFTP_ACCOUNTS && (
                <SftpAccounts customerParameters={manager.customerParameters} />
              )}
              {manager.viewSection === CustomerSections.PAYPAL && (
                <PayPal customerParameters={manager.customerParameters} />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
