// Actions
export const SET_ISLOADING = 'user/setIsLoaded';
export const SET_USER = 'user/setUser';
export const SET_USER_STATUS = 'user/setUserStatus';
export const SET_ACTIVE_BRAND = 'user/setActiveBrand';
export const SET_USER_RESOURCES = 'user/setUserResources';
export const SET_LOGGING_OUT = 'user/setIsLoggingOut';

// Action Functions
export const setIsLoading = (isLoading) => {
  return { type: SET_ISLOADING, payload: isLoading };
};

export const setUser = (user) => {
  return { type: SET_USER, payload: user };
};

export const setUserStatus = (status) => {
  return { type: SET_USER_STATUS, payload: status };
};

export const setUserResources = (resources) => {
  return { type: SET_USER_RESOURCES, payload: resources };
};

export const setActiveBrand = (brand) => {
  return { type: SET_ACTIVE_BRAND, payload: brand };
};

export const setIsLoggingOut = (value = true) => {
  return { type: SET_LOGGING_OUT, payload: value };
};

// Reducer
const userReducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        userId: action.payload.userId,
        userName: action.payload.userName,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        phoneNumber: action.payload.phoneNumber,
        emailAddress: action.payload.emailAddress,
        tokenExpires: action.payload.tokenExpires,
        tokenRefresh: action.payload.tokenRefresh,
        mfaNotificationMethod: action.payload.mfaNotificationMethod,
        roles: action.payload.roles,
        permissions: action.payload.permissions,
        resources: action.payload.resources
      };

    case SET_USER_STATUS:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        tokenExpires: action.payload.tokenExpires,
        tokenRefresh: action.payload.tokenRefresh
      };

    case SET_USER_RESOURCES:
      return { ...state, userResources: action.payload };

    case SET_ACTIVE_BRAND:
      return { ...state, activeBrand: action.payload };

    case SET_ISLOADING:
      return { ...state, isLoading: action.payload };

    case SET_LOGGING_OUT:
      return { ...state, isLoggingOut: action.payload };

    default:
      return state;
  }
};

export default userReducer;
