import { useState, useEffect, useCallback, useRef } from 'react';
import { Api, Helpers } from '../../../../utils/helpers';
import {
  ImportFrequency,
  AlertStatus,
  DaysOfWeek,
  RecurrenceType,
  FrequencyInterval
} from '../../../../utils/constants/enums';

const useAutomatedUpload = ({ transactionParameters, canImport }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const daysOfWeekDefault =
    DaysOfWeek.SUNDAY |
    DaysOfWeek.MONDAY |
    DaysOfWeek.TUESDAY |
    DaysOfWeek.WEDNESDAY |
    DaysOfWeek.THURSDAY |
    DaysOfWeek.FRIDAY |
    DaysOfWeek.SATURDAY;

  const defaultUploadConfig = {
    transactionId: transactionParameters.transactionId,
    nextProcessDate: new Date(),
    fileName: '',
    fileNameConfiguration: {
      prefix: '',
      extension: '',
      expiration: null,
      expirePrevious: false
    },
    frequency: ImportFrequency.MONTHLY,
    recurrenceParameters: {
      daysOfWeek: daysOfWeekDefault,
      dayOfWeek: DaysOfWeek.MONDAY,
      dayNumber: 1,
      recurrenceType: RecurrenceType.SPECIFIC_DAY,
      frequencyInterval: FrequencyInterval.FIRST,
      monthNumber: 1
    },
    runAtTime: '',
    startDateUtc: null,
    endDateUtc: null,
    successNotifications: '',
    errorNotifications: '',
    active: true
  };

  const [uploadConfig, setUploadConfig] = useState(defaultUploadConfig);

  const [message, setMessage] = useState({
    status: AlertStatus.INFO,
    content: ''
  });

  const messageTimerRef = useRef(null);
  const showMessage = (message, status = AlertStatus.INFO, timeout = null) => {
    clearTimeout(messageTimerRef.current);
    setMessage({
      status: status,
      content: message
    });

    if (timeout && typeof timeout === 'number') {
      messageTimerRef.current = setTimeout(() => {
        clearMessage();
      }, timeout);
    }
  };

  const clearMessage = () => {
    setMessage({
      status: AlertStatus.INFO,
      content: ''
    });
  };

  const formHasError = () => {
    return Object.values(errors).some((x) => x);
  };

  const recurrenceParameterChangeHandler = (value) => {
    setFieldError('dailyInvalid', false);
    let config = Helpers.deepCopy(uploadConfig);
    config.recurrenceParameters = value;
    setUploadConfig(config);
  };

  const constructFileName = useCallback(() => {
    let output = '';
    if (
      Helpers.isNullOrWhitespace(uploadConfig.fileNameConfiguration.prefix) ||
      Helpers.isNullOrWhitespace(uploadConfig.fileNameConfiguration.expiration)
    ) {
      return '';
    }

    const expPrev = uploadConfig.fileNameConfiguration.expirePrevious ? '_XP' : '';

    let ext = uploadConfig.fileNameConfiguration.extension.trim();
    if (ext.length > 0 && ext.substring(0, 1) !== '.') {
      ext = '.' + ext;
    }
    output = `${uploadConfig.fileNameConfiguration.prefix}_${uploadConfig.fileNameConfiguration.expiration}${expPrev}_YYYY-MM-DD${ext}`;

    return output;
  }, [
    uploadConfig.fileNameConfiguration.prefix,
    uploadConfig.fileNameConfiguration.extension,
    uploadConfig.fileNameConfiguration.expiration,
    uploadConfig.fileNameConfiguration.expirePrevious
  ]);

  useEffect(() => {
    const fileName = constructFileName();
    let config = Helpers.deepCopy(uploadConfig);
    config.fileName = fileName;
    setUploadConfig(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    constructFileName,
    uploadConfig.fileNameConfiguration.prefix,
    uploadConfig.fileNameConfiguration.extension,
    uploadConfig.fileNameConfiguration.expiration,
    uploadConfig.fileNameConfiguration.expirePrevious
  ]);

  const getFileNameAsHtml = () => {
    let dateSection = '_YYYY-MM-DD';
    if (uploadConfig.fileName.indexOf(dateSection) === -1) {
      return <>{uploadConfig.fileName}</>;
    }

    let fileSections = uploadConfig.fileName.split(dateSection);
    if (fileSections.length !== 2) {
      return <>{uploadConfig.fileName}</>;
    }

    return (
      <>
        {fileSections[0]}
        <span title="Optional due date">{dateSection}</span>
        {fileSections[1]}
      </>
    );
  };

  const getUploadConfiguration = useCallback(async () => {
    if (!canImport) {
      //no need to load this if import is not enabled
      setLoading(false);
      return;
    }
    setErrors({});
    setLoading(true);
    setUploadConfig(defaultUploadConfig);

    if (
      !transactionParameters ||
      transactionParameters.brandId <= 0 ||
      transactionParameters.customerId <= 0 ||
      transactionParameters.transactionId <= 0
    ) {
      return false;
    }
    setLoading(true);

    const apiResponse = await Api.post(
      '/policy/Transaction/GetAutomatedUploadConfig',
      JSON.stringify(transactionParameters)
    );
    if (apiResponse.status.statusCode !== 200) {
      showMessage('Error occurred ' + apiResponse.status.errors, AlertStatus.ERROR, 8000);
      setLoading(false);
      return;
    }

    setUploadConfig(apiResponse.response);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionParameters, canImport]);

  const updateFileOptions = (key, value) => {
    if (key === 'expiration') {
      value = value.replace(/\D/g, '');
    }
    setFieldError(key, false);
    setFieldError('fileName', false);
    let config = Helpers.deepCopy(uploadConfig);
    config.fileNameConfiguration[key] = value;
    setUploadConfig(config);
  };

  const updateProperty = (key, value) => {
    setFieldError(key, false);
    let config = Helpers.deepCopy(uploadConfig);
    config[key] = value;
    setUploadConfig(config);
  };

  const setFieldError = (field, hasError) => {
    let errorsToUpdate = { ...errors };
    errorsToUpdate[field] = hasError;
    setErrors(errorsToUpdate);
  };

  const clearErrorForKeys = (keys) => {
    let errorsToUpdate = { ...errors };
    keys.forEach((key) => (errorsToUpdate[key] = false));
    setErrors(errorsToUpdate);
  };

  const allEmailsAreValid = (value) => {
    let allValid = true;
    const emails = value.split(/[,;]/);
    for (let i = 0; i < emails.length; i++) {
      const email = emails[i].trim();
      if (!Helpers.isNullOrWhitespace(email) && !Helpers.isValidEmail(email)) {
        allValid = false;
        break;
      }
    }
    return allValid;
  };

  const saveUploadConfig = async () => {
    clearMessage();

    let formErrors = {};
    if (uploadConfig.fileName.trim() === '') {
      formErrors.fileName = true;
    }

    //only need to validate recurrence settings if active
    if (
      uploadConfig.active &&
      uploadConfig.frequency === ImportFrequency.DAILY &&
      uploadConfig.recurrenceParameters.daysOfWeek === DaysOfWeek.NONE
    ) {
      formErrors.dailyInvalid = true;
    }

    if (
      !Helpers.isNullOrWhitespace(uploadConfig.successNotifications) &&
      !allEmailsAreValid(uploadConfig.successNotifications)
    ) {
      formErrors.successNotifications = true;
    }

    if (
      !Helpers.isNullOrWhitespace(uploadConfig.errorNotifications) &&
      !allEmailsAreValid(uploadConfig.errorNotifications)
    ) {
      formErrors.errorNotifications = true;
    }

    setErrors(formErrors);

    //do we have any errors?
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    const config = Helpers.deepCopy(uploadConfig);
    config.brandId = transactionParameters.brandId;
    config.customerId = transactionParameters.customerId;
    config.transactionId = transactionParameters.transactionId;

    if (Helpers.isNullOrWhitespace(config.startDateUtc)) {
      config.startDateUtc = null;
    }
    if (Helpers.isNullOrWhitespace(config.endDateUtc)) {
      config.endDateUtc = null;
    }

    const apiResponse = await Api.post(
      '/policy/Transaction/SaveAutomatedUploadConfig',
      JSON.stringify(config)
    );
    if (apiResponse.status.statusCode !== 200) {
      showMessage('Error occurred ' + apiResponse.status.errors, AlertStatus.ERROR, 8000);
      setLoading(false);
      return;
    }
    showMessage('Configuration saved successfully!', AlertStatus.SUCCESS, 4000);
  };

  const frequencyOptions = () => {
    let options = [];
    for (let item in ImportFrequency) {
      options.push({
        id: ImportFrequency[item],
        name: Helpers.getImportFrequencyAsText(ImportFrequency[item])
      });
    }
    return options;
  };

  useEffect(() => {
    getUploadConfiguration();
  }, [getUploadConfiguration]);

  return {
    errors,
    loading,
    message,
    uploadConfig,
    formHasError,
    frequencyOptions,
    updateFileOptions,
    updateProperty,
    saveUploadConfig,
    recurrenceParameterChangeHandler,
    clearErrorForKeys,
    getFileNameAsHtml
  };
};

export default useAutomatedUpload;
