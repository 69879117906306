import Modal from 'react-bootstrap/Modal';
import { Helpers } from '../../../utils/helpers/Helpers';
import useSessionTimeoutModal from './useSessionTimeoutModal';
import { SessionModal } from '../../../utils/constants/enums';

export const SessionTimeoutModal = ({ authorization }) => {
  const session = useSessionTimeoutModal(authorization);

  return (
    <>
      <Modal
        show={session.showSessionModal === SessionModal.WARNING}
        backdrop="static"
        keyboard={false}
        centered={true}>
        <Modal.Header>
          <Modal.Title>Are you still there?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              Your session has been inactive for a while and will be automatically logged out in{' '}
              <strong>{Helpers.formatTimeAsText(session.timeUntilLogout)}</strong>.
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <div>
            <button className="btn btn-primary" onClick={session.logout}>
              Logout
            </button>
          </div>
          <div>
            <button className="btn btn-primary" onClick={session.refreshSession}>
              I&apos;m still here
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SessionTimeoutModal;
