import { useEffect, useState, useCallback } from 'react';
import { Api } from '../../../../utils/helpers';

const useMessageManager = () => {
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [brandCustomers, setBrandCustomers] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [messageId, setMessageId] = useState(null);

  const getRoles = useCallback(async () => {
    const apiResponse = await Api.get('/policy/Roles');
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      return;
    }
    const data = apiResponse.response;
    setRoles(data);
  }, []);

  const getBrands = useCallback(async () => {
    const apiResponse = await Api.get('/policy/Brands/AllCustomers');
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      return;
    }
    const data = apiResponse.response;
    setBrandCustomers(data);
  }, []);

  const editMessage = async (messageId) => {
    console.log('edit message', messageId);
    setShowEditor(true);
    setMessageId(messageId);
  };

  const returnToSearch = async () => {
    console.log('return to search');
    setShowEditor(false);
    setMessageId(null);
  };

  useEffect(() => {
    const loadData = async () => {
      await getBrands();
      await getRoles();
    };
    setLoading(true);
    loadData();
    setLoading(false);
  }, [getBrands, getRoles]);

  return {
    loading,
    roles,
    brandCustomers,
    showEditor,
    messageId,
    editMessage,
    returnToSearch
  };
};

export default useMessageManager;
