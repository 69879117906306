import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import usePayPalBatchDetailsModal from './usePayPalBatchDetailsModal';
import { Helpers } from '../../../../utils/helpers';
import { LoadingContainer } from '../../../../components/ui';
import { PaymentConfirmationModal } from '../../../Research/ResearchPage/PaymentConfirmationModal/PaymentConfirmationModal';
import './PayPalBatchDetailsModalPrint.css';

export const PayPalBatchDetailsModal = ({ brandId, payPalId, batchDate, onClose }) => {
  const manager = usePayPalBatchDetailsModal({ brandId, payPalId, batchDate, onClose });

  return (
    <>
      <PaymentConfirmationModal
        confirmationParams={manager.modalConfirmationParams}
        userCanVoid={false}
        userCanChargeback={false}
        closeCallback={manager.closeModalConfirmation}
        viewChangeCallback={() => {}}
        reloadSearchCallback={() => {}}
      />
      <Modal
        show={batchDate !== null}
        keyboard={true}
        fullscreen={false}
        size="xl"
        className="paypalDetailsModalContainerX"
        centered={true}
        onHide={manager.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Batch Details for {Helpers.toShortDate(batchDate, '')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row batch-details-button-row">
            <div className="col-12 text-end">
              <button className="btn btn-primary btn-sm" onClick={() => window.print()}>
                <FontAwesomeIcon className="faPrint" icon={faPrint} />
                Print
              </button>
            </div>
          </div>
          <hr className="batch-details-button-row-divider" />
          <div className="row">
            <div className="col-12 paypalBatchResults">
              <LoadingContainer isLoading={manager.loading}>
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Confirmation No.</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Transaction</th>
                      <th scope="col">Payor Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Fees</th>
                      <th scope="col">Date/Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {manager.batchTransactions.map((result) => {
                      return (
                        <tr
                          key={result.cpiId}
                          className={manager.isRowViewed(result.cpiId) ? 'row-viewed' : ''}>
                          <td>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                manager.viewPaymentDetails(result.cpiId, result.confirmationNumber);
                              }}>
                              {result.confirmationNumber}
                            </a>
                          </td>
                          <td>{result.customerName}</td>
                          <td>{result.transactionName}</td>
                          <td>{result.payorName}</td>
                          <td>{result.paymentType}</td>
                          <td>{Helpers.formatCurrency(result.amount)}</td>
                          <td>{Helpers.formatCurrency(result.fees)}</td>
                          <td>{Helpers.toShortDateTime(result.paymentDate)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </LoadingContainer>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

PayPalBatchDetailsModal.propTypes = {
  batchDate: PropTypes.string,
  customerId: PropTypes.number,
  onClose: PropTypes.func.isRequired
};

export default PayPalBatchDetailsModal;
