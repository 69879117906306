import { DefaultLayout as Layout } from '../../../layouts';
import { ViewMessage } from '../../../components/messages';

export const ViewMessagePage = () => {
  return (
    <Layout>
      <ViewMessage />
    </Layout>
  );
};
