import { DefaultLayout as Layout } from '../../../layouts';
import { MessageList } from '../../../components/messages';

export const MessageListPage = () => {
  return (
    <Layout>
      <MessageList />
    </Layout>
  );
};
