import { useAuthContext } from '../../../context/provider/AuthContext';
import { useState, useRef } from 'react';
import * as UserActions from '../../../context/reducers/userReducer';
import { Api, Helpers } from '../../../utils/helpers';
import { useInterval } from '../../../utils/hooks';
import { SessionModal } from '../../../utils/constants/enums';

const useSessionTimeoutModal = (authorization) => {
  const [{ user }, dispatch] = useAuthContext();
  const [timeUntilLogout, setTimeUntilLogout] = useState(null);
  const [showSessionModal, setShowSessionModal] = useState(SessionModal.NONE);
  const pageTitle = useRef(document.title);
  const sessionAlertTimeout = 300; // how many seconds before logout to alert the user

  const refreshSession = async () => {
    //reset page title
    document.title = pageTitle.current;
    try {
      const apiResponse = await Api.get('/auth/refresh');
      if (apiResponse.status.statusCode !== 200) {
        console.error('api error occurred');
        dispatch(
          UserActions.setUserStatus({
            isAuthenticated: false,
            tokenExpires: null,
            tokenRefresh: null
          })
        );
        return;
      }

      const usr = apiResponse.response;
      if (usr.tokenExpires) {
        usr.tokenExpires = new Date(usr.tokenExpires);
      }
      if (usr.tokenRefresh) {
        usr.tokenRefresh = new Date(usr.tokenRefresh);
      }

      dispatch(
        UserActions.setUserStatus({
          isAuthenticated: usr.isAuthenticated,
          tokenExpires: usr.tokenExpires,
          tokenRefresh: usr.tokenRefresh
        })
      );
    } catch {
      dispatch(
        UserActions.setUserStatus({
          isAuthenticated: false,
          tokenExpires: null,
          tokenRefresh: null
        })
      );
    }
  };

  const logout = async () => {
    //reset page title
    document.title = pageTitle.current;
    setShowSessionModal(SessionModal.NONE);
    await authorization.logout();
  };

  const getUserStatus = async () => {
    try {
      const apiResponse = await Api.get('/auth/status');
      if (apiResponse.status.statusCode !== 200) {
        console.error('api error occurred');
        dispatch(
          UserActions.setUserStatus({
            isAuthenticated: false,
            tokenExpires: null,
            tokenRefresh: null
          })
        );
        return;
      }

      const usr = apiResponse.response;
      if (usr.tokenExpires) {
        usr.tokenExpires = new Date(usr.tokenExpires);
      }
      if (usr.tokenRefresh) {
        usr.tokenRefresh = new Date(usr.tokenRefresh);
      }

      dispatch(
        UserActions.setUserStatus({
          isAuthenticated: usr.isAuthenticated,
          tokenExpires: usr.tokenExpires,
          tokenRefresh: usr.tokenRefresh
        })
      );
    } catch {
      dispatch(
        UserActions.setUserStatus({
          isAuthenticated: false,
          tokenExpires: null,
          tokenRefresh: null
        })
      );
    }
  };

  //get user session status every 30 seconds
  useInterval(() => {
    getUserStatus();
  }, 30000);

  useInterval(() => {
    if (!user?.tokenExpires) {
      return;
    }
    const timeRemaining = Math.floor(
      (user.tokenExpires.getTime() - Helpers.getUtcDate().getTime()) / 1000
    );

    if (timeRemaining <= 0) {
      logout();
    } else if (timeRemaining <= sessionAlertTimeout) {
      document.title = `${Helpers.formatTime(timeRemaining)} until logout`;
      setShowSessionModal(SessionModal.WARNING);
    } else {
      document.title = pageTitle.current;
      setShowSessionModal(SessionModal.NONE);
    }
    setTimeUntilLogout(timeRemaining);
  }, 1000);

  return {
    tokenExpires: user.tokenExpires,
    refreshSession,
    logout,
    timeUntilLogout,
    showSessionModal
  };
};

export default useSessionTimeoutModal;
