import { useState } from 'react';
import { useAuthContext } from '../../context/provider/AuthContext';
import { outboundMapping } from '../../routes/outboundMapping';
import { Helpers } from '../helpers';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

export const useOutbound = () => {
  const [{ user }] = useAuthContext();
  const [message, setMessage] = useState('');
  const [errorMessage, setErroressage] = useState('');
  const { target } = useParams();
  const { pathname, state, search } = useLocation();
  const navigate = useNavigate();

  const sendOutbound = (target) => {
    setMessage('');
    setErroressage('');

    if (target === 'return') {
      // returning from the legacy site
      // check for a stored return url and send the user there
      const returnUrl = localStorage.getItem('outboundReturn') ?? '/';
      navigate(returnUrl);
      return;
    }

    const outboundTarget = outboundMapping.find((x) => x.target === target);
    if (
      outboundTarget &&
      outboundTarget.requiredPermissions.every((p) => user.permissions.includes(p))
    ) {
      if (user.activeBrand.domain.trim() === '') {
        setErroressage(`Outbound url not configured for ${user.activeBrand.name}.`);
        return;
      }

      let brandDomainRoot = `https://${user.activeBrand.domain}`;
      //keep for local development testing
      //if (location.hostname === 'localhost' || location.hostname === 'localdev.brs-unipay.com') {
      //  brandDomainRoot = 'http://localhost:9000';
      //}

      let outboundPath = outboundTarget.path;
      if (!Helpers.isNullOrWhitespace(search)) {
        outboundPath = outboundPath + search;
      }

      const targetUrl = `${brandDomainRoot}/IdentityAuthentication/?username=${encodeURIComponent(
        user.userName
      )}&path=${encodeURIComponent(outboundPath)}`;
      setMessage(`Redirecting...please wait.`);

      if (state?.previousPath) {
        localStorage.setItem('outboundReturn', state?.previousPath);
      }

      window.location.replace(targetUrl);
    }
  };

  const getOutboundLinks = (group) => {
    return outboundMapping
      .filter(
        (out) =>
          out.group === group && out.requiredPermissions.every((p) => user.permissions.includes(p))
      )
      .map((out) => {
        const target = out.internalPath ? out.path : `/outbound/${out.target}`;
        return {
          target: target,
          iconClass: out.iconClass,
          text: out.text,
          state: { previousPath: pathname }
        };
      });
  };

  return {
    message,
    errorMessage,
    target,
    sendOutbound,
    getOutboundLinks
  };
};
