import React from 'react';
import { Helpers } from '../../../../utils/helpers';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import './TransactionConfigurationSummary.css';
import useTransactionConfigurationSummary from './useTransactionConfigurationSummary';

export const TransactionConfigurationSummary = (transactionParameters) => {
  const summary = useTransactionConfigurationSummary(transactionParameters);

  return (
    <>
      {summary.loading && (
        <div className="m-2 text-center">
          <Spinner animation="border" />
        </div>
      )}
      {!summary.loading && (
        <>
          {!summary.summaryResult && (
            <>
              <h2>No configuration summary found for this transaction.</h2>
            </>
          )}
          {summary.summaryResult && (
            <>
              <h2 className="transaction-config-heading">
                Configuration Summary for &apos;{summary.summaryResult?.transactionName}&apos;
              </h2>
              <h3 className="transaction-config-time">
                {summary.summaryResult?.reportRequestDate}
              </h3>
              <table className="table table-striped customer-table transaction-summary-table-1">
                <thead>
                  <tr key="customer_information">
                    <th className="transaction-config-section-header" colSpan="2">
                      Customer Information
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr key="customer_information_1">
                    <td>
                      <strong>Customer Name:</strong>
                    </td>
                    <td>{summary.summaryResult?.customerName}</td>
                  </tr>
                  <tr key="customer_information_2">
                    <td>
                      <strong>Account Number:</strong>
                    </td>
                    <td>{summary.summaryResult?.customerAccountNumber}</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-striped basic-info-table transaction-summary-table-3">
                <thead>
                  <tr className="transaction-config-section-header">
                    <th colSpan="6">Basic Information</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key="basic_information_1">
                    <td>
                      <strong>Transaction Name:</strong>
                    </td>
                    <td>{summary.summaryResult?.transactionName}</td>
                    <td>
                      <strong>Scheduled Payments:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.allowScheduledPayments}
                        id="checkAllowScheduledPayments"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <strong>Bill Presentment:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.billPresentmentEnabled}
                        id="checkBillPresentmentEnabled"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr key="basic_information_2">
                    <td>
                      <strong>Description:</strong>
                    </td>
                    <td>{summary.summaryResult?.transactionDescription}</td>
                    <td>
                      <strong>Autopay:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.isAutoPayment}
                        id="checkIsAutoPayment"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <strong>Alias:</strong>
                    </td>
                    <td>{summary.summaryResult?.billPresentmentAlias}</td>
                  </tr>
                  <tr key="basic_information_3">
                    <td>
                      <strong>Transaction Type:</strong>
                    </td>
                    <td>{summary.summaryResult?.transactionType}</td>
                    <td>
                      <strong>Recurring</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.isRecurringPayment}
                        id="checkIsRecurringPayment"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <strong>Requires Login:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.billPresentmentRequireLogin}
                        id="checkBillPresentmentRequireLogin"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr key="basic_information_4">
                    <td>
                      <strong>Classification:</strong>
                    </td>
                    <td>{summary.summaryResult?.classification}</td>
                    <td>
                      <strong>Interest Calc:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.calculateInterest}
                        id="checkCalculateInterest"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <strong>eBill:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.eBill}
                        id="checkEBill"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr key="basic_information_5">
                    <td>
                      <strong>Interest Calc Frequency:</strong>
                    </td>
                    <td>{summary.summaryResult?.interestCalculationFrequency}</td>
                    <td>
                      <strong>Interest Display Type:</strong>
                    </td>
                    <td>{summary.summaryResult?.interestDisplayValue}</td>
                    <td>
                      <strong>Interest Type ID:</strong>
                    </td>
                    <td>{summary.summaryResult?.interestTypeValue}</td>
                  </tr>
                  <tr key="basic_information_6">
                    <td>
                      <strong>Interest Net Out Payments:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.interestNetOutPayments}
                        id="checkInterestNetOutPayments"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <strong>Cooldown Period:</strong>
                    </td>
                    <td>{summary.summaryResult?.cooldownPeriod}</td>
                    <td>
                      <strong>Notification Enabled:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.notificationEnabled}
                        id="checkNotificationEnabled"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr key="basic_information_7">
                    <td>
                      <strong>Notification Key:</strong>
                    </td>
                    <td>{summary.summaryResult?.notificationKey}</td>
                    <td colSpan="4">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-striped communications-table transaction-summary-table-2">
                <thead>
                  <tr>
                    <th className="transaction-config-section-header" colSpan="4">
                      Communications
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr key="communications_1">
                    <td>
                      <strong>Lockbox Notification:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.hasLockboxNotifyOverrideEmail}
                        id="checkLockboxNotification"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <strong>Override Email:</strong>
                    </td>
                    <td>{summary.summaryResult?.lockboxNotifyOverrideEmail}</td>
                  </tr>
                  <tr key="communications_2">
                    <td>
                      <strong>Override Confirmation:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.confirmationOverride}
                        id="checkConfirmationOverride"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <strong>Override Email:</strong>
                    </td>
                    <td>{summary.summaryResult?.confirmationOverrideEmail}</td>
                  </tr>
                  <tr key="communications_3">
                    <td>
                      <strong>Override Chargeback Notification:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.chargebackOverride}
                        id="checkChargebackOverride"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <strong>Override Email:</strong>
                    </td>
                    <td>{summary.summaryResult?.chargebackOverrideEmail}</td>
                  </tr>
                  <tr key="communications_4">
                    <td>
                      <strong>Send Payment Reminders:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.sendReminders}
                        id="checkSendReminders"
                        disabled={true}
                      />
                    </td>
                    <td colSpan="2">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table className="table payment-options-accounts-table">
                <thead>
                  <tr>
                    <th className="transaction-config-section-header">
                      Payment Options &amp; Accounts
                    </th>
                    <th className="transaction-config-section-header">Payment Type Summary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key="payment_options_accounts">
                    <td>
                      <table className="table table-striped payment-options transaction-summary-table-1">
                        <tbody>
                          <tr key="payment_options_accounts_1">
                            <td>
                              <strong>Static Price:</strong>
                            </td>
                            <td>{Helpers.formatCurrency(summary.summaryResult?.staticPrice)}</td>
                          </tr>
                          <tr key="payment_options_accounts_2">
                            <td>
                              <strong>Fee Percentage:</strong>
                            </td>
                            <td>{summary.summaryResult?.feePercentage} %</td>
                          </tr>
                          <tr key="payment_options_accounts_3">
                            <td>
                              <strong>Allow Quantities:</strong>
                            </td>
                            <td>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={summary.summaryResult?.allowQuantity}
                                id="checkAllowQuantity"
                                disabled={true}
                              />
                            </td>
                          </tr>
                          <tr key="payment_options_accounts_4">
                            <td>
                              <strong>Allow Partial Payment:</strong>
                            </td>
                            <td>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={summary.summaryResult?.allowPartialPay}
                                id="checkAllowPartialPay"
                                disabled={true}
                              />
                            </td>
                          </tr>
                          <tr key="payment_options_accounts_5">
                            <td>
                              <strong>Base Merchant Key:</strong>
                            </td>
                            <td>{summary.summaryResult?.baseMerchantKey}</td>
                          </tr>
                          <tr key="payment_options_accounts_6">
                            <td>
                              <strong>Fee Merchant Key:</strong>
                            </td>
                            <td>{summary.summaryResult?.feeMerchantKey}</td>
                          </tr>
                          <tr key="payment_options_accounts_7">
                            <td>
                              <strong>ACH Routing Number:</strong>
                            </td>
                            <td>{summary.summaryResult?.achRoutingNumber}</td>
                          </tr>
                          <tr key="payment_options_accounts_8">
                            <td>
                              <strong>Account Number:</strong>
                            </td>
                            <td>{summary.summaryResult?.achAccountNumber}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <table className="table table-striped payment-types transaction-summary-border-box">
                        <tbody>
                          {summary.summaryResult?.paymentTypes.map((record, i) => (
                            <tr key={'payment_types_' + i}>
                              <td>
                                <strong>{record.description}</strong>
                              </td>
                              <td>
                                <span
                                  className={
                                    record.feeScheduleName === 'Not accepted'
                                      ? 'transaction-summary-gray'
                                      : ''
                                  }>
                                  {record.feeScheduleName}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-striped file-import transaction-summary-table-1">
                <thead>
                  <tr>
                    <th className="transaction-config-section-header" colSpan="2">
                      File Import Settings
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr key="file_import_1">
                    <td>
                      <strong>File Type:</strong>
                    </td>
                    <td>{summary.summaryResult?.parserType}</td>
                  </tr>
                  <tr key="file_import_2">
                    <td>
                      <strong>First Row Contains Headers:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.firstRowHeader}
                        id="checkFirstRowHeader"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr key="file_import_3">
                    <td>
                      <strong>Appendable:</strong>
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={summary.summaryResult?.appendable}
                        id="checkAppendable"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr key="file_import_4">
                    <td>
                      <strong>Look Up Rule:</strong>
                    </td>
                    <td>{summary.summaryResult?.lookupRule}</td>
                  </tr>
                </tbody>
              </table>
              <table className="table import-fields-configuration">
                <thead>
                  <tr>
                    <th className="transaction-config-section-header">
                      Import Fields Configuration
                    </th>
                  </tr>
                </thead>
                {!summary.summaryResult.hasInputFields && (
                  <tbody>
                    <tr>
                      <td>No import fields configured.</td>
                    </tr>
                  </tbody>
                )}
                {summary.summaryResult.hasInputFields && (
                  <>
                    <tbody>
                      <tr key="import_fields">
                        <td>
                          <table className="table table-striped import-fields-table transaction-summary-table-centered">
                            <thead>
                              <tr key="import_fields_header">
                                <th>ID</th>
                                <th>Field Name</th>
                                <th>Start Position</th>
                                <th>Data Length</th>
                                <th>Display</th>
                                <th>Amount</th>
                                <th>Exclude</th>
                                <th>T-Column</th>
                                <th>Allow Edit</th>
                                <th>PadChar</th>
                                <th>Padding</th>
                                <th>Subtract</th>
                                <th>Conf/Report</th>
                                <th>EBPKey</th>
                              </tr>
                            </thead>
                            <tbody>
                              {summary.summaryResult?.inputFields.map((record) => (
                                <tr key={'import_fields_' + record.inputFieldId}>
                                  <td>{record.inputFieldId}</td>
                                  <td>{record.fieldName}</td>
                                  <td>{record.startPosition}</td>
                                  <td>{record.dataLength}</td>
                                  <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={record.displayData}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={record.amountDue}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={record.exclude}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>{record.dataColumnName}</td>
                                  <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={record.allowEdit}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>{record.padChar}</td>
                                  <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={record.padding}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={record.subtract}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={record.showOnConfirmation}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={record.ebpKey}
                                      disabled={true}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </>
                )}
              </table>
              <table className="table custom-fields-configuration">
                <thead>
                  <tr>
                    <th className="transaction-config-section-header">
                      Custom Fields Configuration
                    </th>
                  </tr>
                </thead>
                {!summary.summaryResult.hasCustomFields && (
                  <tbody>
                    <tr>
                      <td>No custom fields configured.</td>
                    </tr>
                  </tbody>
                )}
                {summary.summaryResult.hasCustomFields && (
                  <>
                    <tbody>
                      <tr key="custom_fields">
                        <td>
                          <table className="table table-striped custom-fields-table transaction-summary-table-centered">
                            <thead>
                              <tr key="custom_fields_header">
                                <th>ID</th>
                                <th>Sequence</th>
                                <th>Field Name</th>
                                <th>MaxLength</th>
                                <th>Required</th>
                                <th>Validation</th>
                                <th>MaskFormat</th>
                                <th>HelpText</th>
                                <th>T-Column</th>
                                <th>Type</th>
                                <th>Conf/Report</th>
                                <th>Use Select</th>
                                <th>Options</th>
                              </tr>
                            </thead>
                            <tbody>
                              {summary.summaryResult?.customFields.map((record) => (
                                <tr key={'custom_fields_' + record.customFieldId}>
                                  <td>{record.customFieldId}</td>
                                  <td>{record.sequence}</td>
                                  <td>{record.fieldName}</td>
                                  <td>{record.maxLength}</td>
                                  <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={record.required}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>{record.validation}</td>
                                  <td>{record.maskFormat}</td>
                                  <td>{record.helpText}</td>
                                  <td>{record.tColumn}</td>
                                  <td>{record.customFieldType}</td>
                                  <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={record.showOnConfirmation}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={record.useDataSelect}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>{record.dataSelectOptions}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </>
                )}
              </table>
              <table className="table lookup-fields-configuration">
                <thead>
                  <tr>
                    <th className="transaction-config-section-header">
                      Lookup Fields Configuration
                    </th>
                  </tr>
                </thead>
                {!summary.summaryResult.hasLookupFields && (
                  <tbody>
                    <tr>
                      <td>No lookup fields configured.</td>
                    </tr>
                  </tbody>
                )}
                {summary.summaryResult.hasLookupFields && (
                  <>
                    <tbody>
                      <tr key="lookup_fields">
                        <td>
                          <table className="table table-striped lookup-fields-table transaction-summary-table-centered">
                            <thead>
                              <tr key="lookup_fields_header">
                                <th>ID</th>
                                <th>Sequence</th>
                                <th>Input Field to Match</th>
                                <th>MaxLength</th>
                                <th>Required</th>
                                <th>Validation</th>
                                <th>Mask</th>
                                <th>Help Text</th>
                              </tr>
                            </thead>
                            <tbody>
                              {summary.summaryResult?.lookupFields.map((record) => (
                                <tr key={'lookup_fields_' + record.lookupFieldId}>
                                  <td>{record.lookupFieldId}</td>
                                  <td>{record.sequence}</td>
                                  <td>{record.fieldName}</td>
                                  <td>{record.maxLength}</td>
                                  <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={record.required}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>{record.validation}</td>
                                  <td>{record.maskFormat}</td>
                                  <td>{record.helpText}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </>
                )}
              </table>
              <table className="table table-striped registrations transaction-summary-table-1">
                <thead>
                  <tr>
                    <th className="transaction-config-section-header" colSpan="2">
                      Registrations
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr key="registrations_1">
                    <td>
                      <strong>Maximum Registrations:</strong>
                    </td>
                    <td>{summary.summaryResult?.maxRegistrations}</td>
                  </tr>
                  <tr key="registrations_2">
                    <td>
                      <strong>Start Date:</strong>
                    </td>
                    <td>
                      {Helpers.minimumDateAsNotSet(summary.summaryResult?.registrationStartDate)}
                    </td>
                  </tr>
                  <tr key="registrations_3">
                    <td>
                      <strong>Capacity Message:</strong>
                    </td>
                    <td>{summary.summaryResult?.capacityMessage}</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </>
      )}
    </>
  );
};

TransactionConfigurationSummary.propTypes = {
  transactionParameters: PropTypes.shape({
    brandId: PropTypes.number,
    customerId: PropTypes.number,
    transactionId: PropTypes.number
  })
};

export default TransactionConfigurationSummary;
