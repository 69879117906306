import { CustomerSections } from '../../../utils/constants/enums';
import { Permissions } from '../../../utils/constants/permissions';

export const sectionMenu = [
  {
    id: CustomerSections.CUSTOMER_SUMMARY,
    requiredPermissions: [], //TODO
    name: 'Summary'
  },
  {
    id: CustomerSections.SFTP_ACCOUNTS,
    requiredPermissions: [], //TODO
    name: 'SFTP Account'
  },
  {
    id: CustomerSections.PAYPAL,
    requiredPermissions: [], //TODO
    name: 'PayPal'
  }
];
