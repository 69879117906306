import React, { useEffect, useState, useCallback } from 'react';
import { useAuthContext } from '../../../context/provider/AuthContext';
import { Api, Helpers } from '../../../utils/helpers';
import { MessageOptions } from '../../../utils/constants/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import './MessageNotification.css';

export const MessageNotification = () => {
  const [{ user }] = useAuthContext();
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [mustViewCount, setMustViewCount] = useState(0);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [message, setMessage] = useState(null);

  const getUnreadMessageCount = useCallback(async () => {
    const apiResponse = await Api.get('/policy/Messages/UnreadMessages');
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      return;
    }
    setUnreadMessages(apiResponse.response);
  }, []);

  const acknowledgeMessage = async () => {
    const apiResponse = await Api.get(`/policy/Messages/Acknowledge/${message.id}`);
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      return;
    }
    //remove acknowledged message from unread list
    let messagesToUpdate = [...unreadMessages];

    messagesToUpdate = messagesToUpdate.filter((x) => x.id !== message.id);
    setUnreadMessages(messagesToUpdate);

    //check if any other messages need to be read...if not, close the modal
    const mustViewMessages = messagesToUpdate.filter((x) =>
      Helpers.hasFlag(x.messageOptions, MessageOptions.REQUIRES_ACKNOWLEDGEMENT)
    );
    if (mustViewMessages.length === 0) {
      setShowMessageModal(false);
    }
  };

  useEffect(() => {
    if (!unreadMessages) return;
    const mustViewMessages = unreadMessages.filter((x) =>
      Helpers.hasFlag(x.messageOptions, MessageOptions.REQUIRES_ACKNOWLEDGEMENT)
    );
    setMustViewCount(mustViewMessages.length);

    if (mustViewMessages.length > 0) {
      if (!showMessageModal) {
        setShowMessageModal(true);
      }
      //get the first message
      setMessage(mustViewMessages[0]);
    }
  }, [showMessageModal, unreadMessages]);

  useEffect(() => {
    if (user?.isAuthenticated) {
      getUnreadMessageCount();
    }
  }, [user.isAuthenticated, getUnreadMessageCount]);

  const unreadClass =
    (unreadMessages && unreadMessages.length) > 0 ? 'nav-link notice me-3' : 'nav-link me-3';

  if (!unreadMessages) {
    return <span>none</span>;
  } else {
    return (
      <>
        <a
          className={unreadClass}
          id="navbarDropdownNotification"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false">
          <FontAwesomeIcon size="xl" icon={faBell} />
          <span className="sr-only">Notifications</span>
        </a>
        <div
          className="dropdown-menu dropdown-menu-end message-notification-menu animate slideIn"
          aria-labelledby="navbarDropdownNotification">
          {unreadMessages.length === 0 && <div className="text-center">No unread messages.</div>}
          {unreadMessages.map((message) => (
            <Link
              key={message.id}
              to={`/messages/${message.id}`}
              className="dropdown-item text-left message-notification-item mb-2">
              {message.title}
              <span className="message-notification-preview">{message.preview}</span>
            </Link>
          ))}

          <div className="dropdown-divider"></div>
          <Link to="/messages" className="text-center dropdown-item">
            View All Messages
          </Link>
        </div>

        <Modal show={showMessageModal} backdrop="static" keyboard={false} size="lg" centered={true}>
          <Modal.Header>
            <Modal.Title>{message?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div dangerouslySetInnerHTML={{ __html: message?.body }}></div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={acknowledgeMessage}>
              {mustViewCount > 1 ? 'Ok, view next' : 'Ok'}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
};

export default MessageNotification;
