import { Helpers } from '../../../../utils/helpers/Helpers';
import { MessageOptions } from '../../../../utils/constants/enums';
import { Editor } from '@tinymce/tinymce-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ModalDialog, FileBrowserModal } from '../../../../components/ui';
import BrandCustomerSelector from '../BrandCustomerSelector/BrandCustomerSelector';
import useMessageEditor from './useMessageEditor';

const MessageEditor = (props) => {
  const bffApiUrl = process.env.REACT_APP_BFF_API_URL;
  const editor = useMessageEditor(props);
  const brandCustomerAudiences = editor.getBrandCustomerAudiences();

  if (editor.loading) {
    return (
      <p>
        <em>Loading...</em>
      </p>
    );
  } else {
    return (
      <>
        <FileBrowserModal
          showModal={editor.showFileBrowserModal}
          closeModalCallback={() => editor.setShowFileBrowserModal(false)}
          uploadApiEndpoint="/files/content/upload"
          browseApiEndpoint="/files/content/directory"
          imageApiEndpoint="/files/content/file"
          downloadApiEndpoint="/files/content/download"
          selectFileCallback={(fileDetails) => {
            editor.tinyMceFileCallback(fileDetails.absoluteUrl, { title: fileDetails.name });
            editor.setShowFileBrowserModal(false);
          }}
          acceptExtensions=""
          sizeLimit={10485760}
          maxConcurrentUploads={4}
          multipleFiles={true}
        />
        <ModalDialog
          showDialog={editor.deleteDialogVisible}
          heading="Delete Message"
          message="Are you sure you want to delete this message?"
          okCallback={editor.deleteMessage}
          okButtonText="Yes, delete this message."
          cancelCallback={editor.closeDeleteDialog}
          cancelButtonText="No, don't delete."
        />
        <div className="titleBar">
          <h1 className="pageTitle">{editor.isNewMessage ? 'Add Message' : 'Edit Message'}</h1>
          <div className="actions">
            <button className="btn btn-primary" onClick={props.returnToSearchCallback}>
              {editor.isNewMessage ? 'Cancel Add' : 'Cancel Edit'}
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <form onSubmit={(e) => editor.handleSave(e)}>
              <div className="row">
                <div className="col-xl-8 col-lg-12">
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Message title"
                      name="messageTitle"
                      value={editor.message.title}
                      onChange={(e) => editor.updateMessageProperty('title', e.target.value)}
                    />
                    {editor.errors.title && (
                      <div className="alert-danger mt-1 p-2">Message title required.</div>
                    )}
                  </div>
                  <div className="form-group margin">
                    <Editor
                      tinymceScriptSrc="/tinymce/tinymce.min.js"
                      onInit={(evt, tinyMceEditor) => (editor.editorRef.current = tinyMceEditor)}
                      initialValue={editor.message.body}
                      init={{
                        height: 550,
                        menubar: false,
                        branding: false,
                        plugins: 'code lists paste link autolink image media hr',
                        toolbar:
                          'undo redo | pastetext | link | image | media | styleselect | bold italic | hr | bullist numlist | alignleft aligncenter alignright | removeformat | code',
                        content_style:
                          'body { font-family:"Source Sans Pro", sans-serif; font-size:14px }',
                        // eslint-disable-next-line no-unused-vars
                        file_picker_callback: (callback, value, meta) => {
                          editor.setTinyMceFileCallback(() => callback);
                          editor.setShowFileBrowserModal(true);
                        },
                        images_upload_handler: editor.tinymceImageUploadHandler,
                        images_upload_url: `${bffApiUrl}/files/content/tinymce-upload?path=${encodeURIComponent(
                          'editor-uploads'
                        )}`,
                        images_upload_base_path: `${bffApiUrl}/files/content/file/`,
                        images_upload_credentials: true,
                        images_reuse_filename: true,
                        paste_data_images: true,
                        automatic_uploads: true
                      }}
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-12">
                  <div className="card admin-card mb-2">
                    <div className="card-header">Details</div>
                    <div className="card-body">
                      {!editor.isNewMessage && (
                        <>
                          <div className="row mb-2">
                            <div className="col-4 text-end">Views</div>
                            <div className="col">{editor.message.viewCount}</div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-4 text-end">Created</div>
                            <div className="col">
                              {Helpers.toShortDateTime(editor.message.dateCreatedUtc)}
                              <br />
                              by {editor.message.createdBy}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 text-end">Last Modified</div>
                            <div className="col">
                              {Helpers.toShortDateTime(editor.message.lastModifiedUtc)}
                              <br />
                              by {editor.message.lastModifiedBy}
                            </div>
                          </div>
                        </>
                      )}

                      {editor.isNewMessage && (
                        <div className="row mt-4">
                          <div className="col-12 d-grid gap-2">
                            <input type="submit" className="btn btn-success" value="Save" />
                          </div>
                        </div>
                      )}
                      {!editor.isNewMessage && (
                        <div className="row mt-4">
                          <div className="col-lg-6 col-md-12 d-grid gap-2 mb-1">
                            <button
                              className="btn btn-danger"
                              onClick={(e) => editor.handleDelete(e, editor.message.id)}>
                              <i className="bx bx-trash"></i> Delete
                            </button>
                          </div>
                          <div className="col-lg-6 col-md-12 d-grid gap-2">
                            <input type="submit" className="btn btn-success" value="Save" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="card admin-card">
                    <div className="card-header">Message Options</div>
                    <div className="card-body">
                      <div className="row mb-4">
                        <div className="col-6">
                          <label>Start Date</label>
                          <DatePicker
                            selected={
                              editor.message.startDateUtc && new Date(editor.message.startDateUtc)
                            }
                            onChange={(date) =>
                              editor.updateMessageProperty(
                                'startDateUtc',
                                date?.toISOString() ?? null
                              )
                            }
                            showTimeSelect={true}
                            dateFormat="M/d/yyyy h:mm aa"
                            className="form-control"
                            isClearable={true}
                            placeholderText="Immediately"
                          />
                        </div>
                        <div className="col-6">
                          <label>End Date</label>
                          <DatePicker
                            selected={
                              editor.message.endDateUtc && new Date(editor.message.endDateUtc)
                            }
                            onChange={(date) =>
                              editor.updateMessageProperty(
                                'endDateUtc',
                                date?.toISOString() ?? null
                              )
                            }
                            showTimeSelect={true}
                            dateFormat="M/d/yyyy h:mm aa"
                            className="form-control"
                            isClearable={true}
                            placeholderText="Never"
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={() => editor.updateMessageOptions(1)}
                            checked={Helpers.hasFlag(
                              editor.message.messageOptions,
                              MessageOptions.HIGH_PRIORITY
                            )}
                            id="checkHighPriority"
                          />
                          <label className="form-check-label" htmlFor="checkHighPriority">
                            High Priority
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={() => editor.updateMessageOptions(2)}
                            checked={Helpers.hasFlag(
                              editor.message.messageOptions,
                              MessageOptions.REQUIRES_ACKNOWLEDGEMENT
                            )}
                            id="checkRequireAck"
                          />
                          <label className="form-check-label" htmlFor="checkRequireAck">
                            Requires Acknowledgement
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-xl-4 col-lg-12">
                  <div className="card admin-card">
                    <div className="card-header">Limit to Roles</div>
                    <div className="card-body">
                      {editor.roles.map((role) => (
                        <div key={role.id} className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={role.id}
                            onChange={() => editor.updateRoleInAudience(role.id)}
                            checked={editor.isRoleChecked(role.id)}
                            id={role.id}
                          />
                          <label className="form-check-label" htmlFor={role.id}>
                            {role.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-12">
                  <div className="card admin-card">
                    <div className="card-header">Limit to Brand / Customer</div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12 col-md-10">
                          <BrandCustomerSelector
                            brandCustomers={editor.brandCustomers}
                            displayMode="columns"
                            onChange={editor.setSelectedAudience}
                            allBrandsLabel="- Select Brand -"
                            allCustomersLabel="All Customers"
                            customerPlaceholder=""
                          />
                        </div>
                        <div className="col-sm-12 col-md-2">
                          <button
                            className="btn btn-success"
                            onClick={(e) => editor.addBrandAudience(e)}
                            disabled={
                              !editor.selectedAudience || editor.selectedAudience.value === '0:0'
                            }>
                            Add
                          </button>
                        </div>
                      </div>

                      {brandCustomerAudiences.length === 0 && (
                        <div className="alert alert-primary text-center">
                          Message visible to all brands and customers.
                        </div>
                      )}

                      <table className="table table-striped">
                        <tbody>
                          {brandCustomerAudiences.map((aud) => (
                            <tr key={aud.type + aud.value}>
                              <td>{editor.audienceToText(aud)}</td>
                              <td className="text-end">
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={(e) => editor.removeBrandAudience(e, aud)}>
                                  Remove
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
};

export default MessageEditor;
