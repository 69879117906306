import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Helpers } from '../../../../utils/helpers';
import { TransactionSelector, DisplayMode } from '../../../../components/user';
import useAuditReportManager from './useAuditReportManager';
import '../../ReportingPage/ReportManager/ReportManager.css';
import './AuditReportManager.css';
import { PagingControls } from '../../../../components/ui';
import { AuditDateRangeType, ReportDateRangeType } from '../../../../utils/constants/enums';

export const AuditReportManager = () => {
  const manager = useAuditReportManager();

  return (
    <>
      <div className="titleBar">
        <h1 className="pageTitle">Audit Reports</h1>
      </div>

      <div>
        <div className="row">
          <div className="col-12 col-sm-3">
            <div className="searchContainer border mt-0">
              <div className="g-3 w-100">
                <TransactionSelector
                  displayMode={DisplayMode.STACKED}
                  showRequiredAsterisk={false}
                  showInactiveFilter={true}
                  showDeletedFilter={true}
                  activeBrandId={manager.activeBrandId}
                  onChange={(selectedOptions) => {
                    manager.updateCustomerTransactionFilter(selectedOptions);
                  }}
                />
                <hr />
                <div className="form-group">
                  <label htmlFor="changedBy">Changed By User</label>
                  <input
                    type="text"
                    id="changedBy"
                    className="form-control"
                    aria-label="Changed By Username Entry"
                    value={manager.reportParameters.changedBy}
                    onChange={(e) => manager.updateFilter('changedBy', e.target.value)}></input>
                </div>
                <div className="form-group">
                  <label htmlFor="modifiedUser">Modified User</label>
                  <input
                    type="text"
                    id="modifiedUser"
                    className="form-control"
                    aria-label="Modified User Username Entry"
                    value={manager.reportParameters.modifiedUser}
                    onChange={(e) => manager.updateFilter('modifiedUser', e.target.value)}></input>
                </div>

                <div className="form-group">
                  <label htmlFor="modifiedRole">Modified Role</label>

                  <select
                    id="modifiedRole"
                    className="form-select"
                    aria-label="Modified Role Selection"
                    value={manager.reportParameters.modifiedRole ?? ''}
                    onChange={(e) => manager.updateFilter('modifiedRole', e.target.value)}>
                    <option>All Roles</option>
                    {manager.roles.map((roles, i) => {
                      return (
                        <option key={i} value={roles.id}>
                          {roles.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="row dateRangeWrap">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="date-range" className="text-nowrap">
                        Date Range
                      </label>
                      <select
                        id="date-range"
                        className="form-select"
                        aria-label="Date Range selection"
                        value={manager.reportParameters.dateRangeType}
                        onChange={(e) =>
                          manager.updateFilter(
                            'dateRange',
                            Helpers.tryParseInt(e.target.value, null)
                          )
                        }>
                        <option value={AuditDateRangeType.ANY}>Any</option>
                        <option value={AuditDateRangeType.TODAY}>Today</option>
                        <option value={AuditDateRangeType.YESTERDAY}>Yesterday</option>
                        <option value={AuditDateRangeType.PREVIOUS_3_DAYS}>Previous 3 Days</option>
                        <option value={AuditDateRangeType.PREVIOUS_7_DAYS}>Previous 7 Days</option>
                        <option value={AuditDateRangeType.PREVIOUS_MONTH}>Previous Month</option>
                        <option value={AuditDateRangeType.PREVIOUS_QUARTER}>
                          Previous Quarter
                        </option>
                        <option value={AuditDateRangeType.YEAR_TO_DATE}>This Year</option>
                        <option value={AuditDateRangeType.PREVIOUS_YEAR}>Previous Year</option>
                        <option value={AuditDateRangeType.CUSTOM}>Custom</option>
                      </select>
                    </div>
                  </div>
                </div>
                {manager.reportParameters.dateRangeType === ReportDateRangeType.CUSTOM && (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="fromDate">From:</label>
                          <input
                            type="date"
                            id="fromDate"
                            className="form-control"
                            value={manager.reportParameters.fromDate ?? ''}
                            onChange={(e) => manager.updateFilter('startDate', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="toDate">To:</label>
                          <input
                            type="date"
                            id="toDate"
                            className="form-control"
                            value={manager.reportParameters.toDate ?? ''}
                            onChange={(e) => manager.updateFilter('endDate', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {manager.errors.customDateMissing && (
                      <div className="row">
                        <div className="col-12">
                          <div className="mt-1 alert-danger p-2">
                            Date fields are required for custom date range selection.
                          </div>
                        </div>
                      </div>
                    )}

                    {manager.errors.customDateInvalid && (
                      <div className="row">
                        <div className="col-12">
                          <div className="mt-1 alert-danger p-2">
                            Custom date fields must be before today.
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="mt-3 d-flex justify-content-end">
                  <button className="btn btn-primary" onClick={manager.runReport}>
                    Run Report
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-9">
            {manager.apiError.trim() !== '' && (
              <div className="alert-danger mb-1 p-2">{manager.apiError}</div>
            )}
            <div className="card">
              <div className="card-body report-card-body">
                <div className="report-tools themed-header-bar">
                  <div className="action-buttons">
                    <button
                      className="btn btn-primary btn-sm"
                      disabled={manager.loading || manager.displayResults.length === 0}
                      onClick={() => manager.exportAuditResults()}>
                      <FontAwesomeIcon size="sm" className="me-2" icon={faDownload} />
                      CSV
                    </button>
                  </div>
                </div>
                {manager.loading && (
                  <div className="m-5 text-center">
                    <Spinner animation="border" />
                  </div>
                )}
                {!manager.loading && (
                  <>
                    {!manager.displayResults && (
                      <div className="audit-report-container">
                        <em className="no-report-loaded">No report loaded.</em>
                      </div>
                    )}
                    {manager.displayResults && (
                      <div id="report-container" className="audit-report-container">
                        <table className="table table-striped audit-table-primary">
                          <thead>
                            <tr>
                              <th className="audit-date">Date/Changed By</th>
                              <th className="audit-modified-target">Modified Target</th>
                              <th className="audit-action">Action</th>
                              <th className="audit-modified-values">Modified Values</th>
                            </tr>
                          </thead>
                          <tbody>
                            {manager.displayResults.results.map((record, i) => (
                              <tr key={i}>
                                <td>
                                  {new Date(record.changeDateUtc).toLocaleDateString()}
                                  <br />
                                  {new Date(record.changeDateUtc).toLocaleTimeString()}
                                  <br />
                                  {record.changedBy}
                                </td>
                                <td>
                                  {manager.getKeyValues(record).map((value, index) => {
                                    return (
                                      <div key={index} className={index === 0 ? 'fw-bold' : ''}>
                                        {value}
                                      </div>
                                    );
                                  })}
                                </td>
                                <td colSpan="2" id="no-padding">
                                  <table className="table audit-table-secondary" width="100%">
                                    <tbody>
                                      {record.modifiedTables.map((table, i) => (
                                        <tr key={i}>
                                          {table.added.length > 0 && (
                                            <td className="audit-action">Added to {table.name}</td>
                                          )}
                                          {table.added.length > 0 && (
                                            <td className="audit-modified-values">
                                              {table.added.map((data, i) => (
                                                <div key={i}>
                                                  <strong>{data.name}</strong> - {data.newValue}
                                                </div>
                                              ))}
                                            </td>
                                          )}
                                          {table.deleted.length > 0 && (
                                            <td className="audit-action">
                                              Deleted from {table.name}
                                            </td>
                                          )}
                                          {table.deleted.length > 0 && (
                                            <td className="audit-modified-values">
                                              {table.deleted.map((data, i) => (
                                                <div key={i}>
                                                  <strong>{data.name}</strong> - {data.oldValue}
                                                </div>
                                              ))}
                                            </td>
                                          )}
                                          {table.modified.length > 0 && (
                                            <td className="audit-action">Modified {table.name}</td>
                                          )}
                                          {table.modified.length > 0 && (
                                            <td className="audit-modified-values">
                                              {table.modified.map((data, i) => (
                                                <div key={i}>
                                                  <strong>{data.name}</strong>: {data.oldValue}{' '}
                                                  &rarr; {data.newValue}
                                                </div>
                                              ))}
                                            </td>
                                          )}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    <div className="ps-3 pe-3 pt-3">
                      <PagingControls
                        page={manager.displayResults.currentPage}
                        pageCount={manager.displayResults.pageCount}
                        changePageCallback={manager.changePage}
                      />
                    </div>
                    {/*<pre>{JSON.stringify(manager.reportParameters, null, ' ')}</pre>}
                    {<pre>{JSON.stringify(manager.searchParameters, null, ' ')}</pre>*/}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
