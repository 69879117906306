import { useState, useCallback, useEffect } from 'react';
import Select, { components } from 'react-select';
import { MessageAudienceType } from '../../../../utils/constants/enums';

export const BrandCustomerSelector = ({
  brandCustomers,
  onChange,
  displayMode,
  allBrandsLabel,
  allCustomersLabel,
  customerPlaceholder
}) => {
  const [brandOptions, setBrandOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  allBrandsLabel = allBrandsLabel ?? 'All Brands';
  allCustomersLabel = allCustomersLabel ?? 'All Customers';
  customerPlaceholder = customerPlaceholder ?? 'All Customers';
  const displayClass = displayMode === 'columns' ? 'col-6 pb-2' : 'col-12 pb-2';

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Select brand first</span>
      </components.NoOptionsMessage>
    );
  };

  const defaultCustomerOption = {
    value: `${MessageAudienceType.BRAND_CUSTOMER}:0`,
    label: allCustomersLabel
  };

  const getBrands = useCallback(async () => {
    const defaultBrandOption = {
      value: 0,
      label: allBrandsLabel
    };
    let brandOptions = brandCustomers.map((brand) => {
      return {
        value: brand.id,
        label: brand.name
      };
    });
    brandOptions.unshift(defaultBrandOption);

    setSelectedBrand(defaultBrandOption);
    setBrandOptions(brandOptions);
  }, [brandCustomers, allBrandsLabel]);

  const triggerChange = (audience) => {
    if (typeof onChange === 'function') {
      onChange(audience);
    }
  };

  const selectCustomerOption = (option) => {
    setSelectedCustomer(option);
    triggerChange(convertSelectionsToAudience(selectedBrand.value, option.value));
  };

  const selectBrandOption = (option) => {
    setSelectedBrand(option);
    setSelectedCustomer(defaultCustomerOption);
    triggerChange(convertSelectionsToAudience(option.value, defaultCustomerOption.value));

    //they selected all brands, so clear customer selector
    if (option.value === 0) {
      setSelectedCustomer(null);
      setCustomerOptions([]);
      return;
    }

    //get classifications and customers associated with this brand
    const brand = brandCustomers.find((brand) => brand.id === option.value);

    const classifications = brand.classifications?.map((x) => {
      return {
        value: `${MessageAudienceType.BRAND_CUSTOMER_CLASSIFICATION}:${x.id}`,
        label: x.name
      };
    });
    const customers = brand.customers?.map((x) => {
      return {
        value: `${MessageAudienceType.BRAND_CUSTOMER}:${x.id}`,
        label: x.name
      };
    });

    const customerOptions = [];
    customerOptions.push({
      label: '',
      options: [defaultCustomerOption]
    });
    customerOptions.push({
      label: 'Classification',
      options: classifications
    });
    customerOptions.push({
      label: 'Customer',
      options: customers
    });

    setCustomerOptions(customerOptions);
  };

  const convertSelectionsToAudience = (brand, customer) => {
    if (brand.length === 0 && customer.length < 3) {
      //Invalid selections
      return;
    }
    //determine type of customer selection - parsed from string "t:v" where t=type and v=value
    const type = parseInt(customer.substring(0, 1));
    const value = `${brand}:${customer.substring(2)}`;

    return {
      type: type,
      value: value
    };
  };

  useEffect(() => {
    getBrands();
  }, [getBrands]);

  return (
    <>
      <div className="row">
        <div className={displayClass}>
          <Select value={selectedBrand} options={brandOptions} onChange={selectBrandOption} />
        </div>
        <div className={displayClass}>
          <Select
            value={selectedCustomer}
            options={customerOptions}
            onChange={selectCustomerOption}
            placeholder={customerPlaceholder}
            components={{ NoOptionsMessage }}
          />
        </div>
      </div>
    </>
  );
};

export default BrandCustomerSelector;
