import { DefaultLayout as Layout } from '../../../layouts';
import { useAuthorization } from '../../../utils/hooks';
import { TileLink } from '../../../components/ui';
import { faPalette } from '@fortawesome/free-solid-svg-icons';

export const ToolsHomePage = () => {
  useAuthorization();

  return (
    <Layout>
      <div className="titleBar">
        <h1 className="pageTitle">Site Tools</h1>
      </div>
      <div className="d-flex flex-wrap justify-content-lg-start justify-content-evenly">
        <TileLink text="Style Guide" target="/tools/style-guide" fontAwesomeIcon={faPalette} />
      </div>
    </Layout>
  );
};
