import { Link, useLocation } from 'react-router-dom';
import { Helpers } from '../../utils/helpers';

export const Sidebar = ({ logo, menu }) => {
  const { pathname } = useLocation();
  return (
    <div className="border-end" id="sidebar-wrapper">
      <div>
        <Link to="/" className="brand">
          <img src={logo} alt="UniPay" />
        </Link>
        <ul className="list-group list-group-flush">
          {menu?.map((item) => {
            return (
              <li key={item.path}>
                <Link
                  to={item.path}
                  state={{ previousPath: pathname }}
                  className="list-group-item list-group-item-action">
                  <i className={item.iconClass}></i> {item.text}
                </Link>
              </li>
            );
          })}
          {Helpers.isRunningLocal() && (
            <li className="mt-4">
              <Link
                to="/dev-tools"
                state={{ previousPath: pathname }}
                className="list-group-item list-group-item-action">
                <i className="icofont-gears"></i> Dev Tools
              </Link>
            </li>
          )}
        </ul>
      </div>
      <div className="sidebar-footer">
        <strong>Need Assistance?</strong>
        <br />
        Call <a href="tel:18772271157">1-877-227-1157</a> or email{' '}
        <a href="mailto:support@unipayteam.com">support@unipayteam.com</a>
        <a href="mailto:support@unipayteam.com" className="feedback mt-3">
          Have some feedback? <span>Share it here.</span>
        </a>
      </div>
    </div>
  );
};
