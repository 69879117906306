import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrip } from '@fortawesome/free-solid-svg-icons';
import './DashboardTile.css';

export const DashboardTile = ({ heading, children, headerRight }) => {
  return (
    <div className="card dashboard-tile">
      <div className="dashboard-tile-grip">
        <FontAwesomeIcon
          size="sm"
          color="#fff"
          className="text-secondary dashboard-tile-grip-icon me-2"
          icon={faGrip}
        />
      </div>
      <div className="card-body px-4 py-4">
        <div className="d-flex justify-content-between mt-2">
          <h3 className="dashboard-tile-title">{heading ?? ''}</h3>
          {headerRight ?? <></>}
        </div>
        <div className="dashboard-tile-body">{children}</div>
      </div>
    </div>
  );
};

export default DashboardTile;
