import { Permissions } from '../../../../utils/constants/permissions';
import { Link } from 'react-router-dom';
import { ReportTile, FindPaymentTile } from './tiles';
import { LatestMessages } from '../../../../components/messages';
import { ScheduledReports } from '../../../../components/reports/ScheduledReports/ScheduledReports';

export const availableTiles = [
  {
    id: 'reports',
    name: 'View Reports',
    requiresAnyInPermissionGroup: Permissions.Reports,
    userCanManage: true,
    width: 1,
    height: 2,
    defaultLayouts: {
      md: { x: 2, y: 1 },
      sm: { x: 1, y: 0 },
      xs: { x: 0, y: 4 }
    },
    element: <ReportTile />
  },
  {
    id: 'scheduled-reports',
    name: 'Scheduled Reports',
    headerRight: (
      <Link to="/scheduled-reports" className="text-decoration-none">
        Manage
      </Link>
    ),
    requiresAnyInPermissionGroup: Permissions.Reports,
    userCanManage: true,
    width: 1,
    height: 2,
    defaultLayouts: {
      md: { x: 0, y: 3 },
      sm: { x: 1, y: 0 },
      xs: { x: 0, y: 4 }
    },
    element: <ScheduledReports />
  },
  {
    id: 'find-payment',
    name: 'Find a Payment',
    requiredPermissions: [Permissions.Research.SEARCH],
    userCanManage: true,
    width: 1,
    height: 2,
    defaultLayouts: {
      md: { x: 3, y: 0 },
      sm: { x: 1, y: 3 },
      xs: { x: 0, y: 5 }
    },
    element: <FindPaymentTile />
  },
  {
    id: 'manage-files',
    name: 'Manage My Files',
    requiredPermissions: [Permissions.Admin.FILES],
    userCanManage: true,
    width: 1,
    height: 1,
    defaultLayouts: {
      md: { x: 2, y: 0 },
      sm: { x: 0, y: 2 },
      xs: { x: 0, y: 3 }
    },
    element: (
      <>
        <div>
          <Link to="/files/file-import">File Uploads</Link>
        </div>
        <div>
          <Link to="/outbound/download-lockbox-files">File Downloads</Link>
        </div>
        <div>
          <Link to="/outbound/download-lockbox-packages">Download Lockbox Packages</Link>
        </div>
      </>
    )
  },
  {
    id: 'manage-team',
    name: 'Manage My Team',
    requiredPermissions: [Permissions.User.MANAGE],
    userCanManage: true,
    width: 1,
    height: 1,
    defaultLayouts: {
      md: { x: 0, y: 0 },
      sm: { x: 0, y: 0 },
      xs: { x: 0, y: 0 }
    },
    element: <Link to="/manage-my-team">Users</Link>
  },
  {
    id: 'manage-roles',
    name: 'Manage Role Templates',
    requiredPermissions: [Permissions.Admin.ROLES],
    userCanManage: true,
    width: 1,
    height: 1,
    defaultLayouts: {
      md: { x: 1, y: 0 },
      sm: { x: 0, y: 1 },
      xs: { x: 0, y: 1 }
    },
    element: <Link to="/manage-roles">Role Templates</Link>
  },
  {
    id: 'latest-messages',
    name: 'Latest Messages',
    headerRight: (
      <Link to="/messages" className="text-decoration-none">
        View All
      </Link>
    ),
    requiredPermissions: [],
    userCanManage: false,
    width: 2,
    height: 2,
    defaultLayouts: {
      md: { x: 0, y: 1 },
      sm: { x: 0, y: 4 },
      xs: { x: 0, y: 6 }
    },
    element: <LatestMessages />
  }
];
