import { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { ProgressBar } from '../../../components/ui';
import { UploadStatus, UploadResultStatus } from '../../../utils/constants/enums';
import { Api, Helpers } from '../../../utils/helpers';
import styles from './FileUpload.module.css';
import { useInterval } from '../../../utils/hooks';

export const FileUpload = ({
  children,
  visible = true,
  uploadApiEndpoint,
  maxConcurrentUploads = 10,
  className,
  uploadButtonClassName,
  acceptExtensions = '',
  sizeLimit = 5242880,
  multipleFiles = true,
  uploadCompleteCallback
}) => {
  const [uploadQueue, setUploadQueue] = useState([]);
  const fileInput = useRef(null);
  const dropArea = useRef(null);
  const [dragging, setDragging] = useState(false);

  const pendingCount = uploadQueue.filter((x) => x.status === UploadStatus.PENDING).length;
  const completeCount = uploadQueue.filter((x) => x.status === UploadStatus.COMPLETE).length;
  const inProgressCount = uploadQueue.filter((x) => x.status === UploadStatus.UPLOADING).length;

  const showFilePicker = () => {
    fileInput.current.click();
  };

  const isValidFileType = (filename) => {
    if (Helpers.isNullOrWhitespace(acceptExtensions)) {
      //no restrictions
      return true;
    }
    const acceptable = acceptExtensions.toLowerCase().split(',');
    const extension = Helpers.getExtension(filename).toLowerCase();
    return acceptable.includes(extension);
  };

  const handleSelectedFiles = (selectedFiles) => {
    const files = Array.prototype.slice.call(selectedFiles);
    let uploadItems = [...uploadQueue];
    for (let i = 0; i < files.length; i++) {
      let message = '';
      let status = UploadStatus.PENDING;

      //check file type and size
      const isValidFileSize = files[i].size <= sizeLimit;

      if (!isValidFileType(files[i].name)) {
        status = UploadStatus.FAILED;
        message = 'File type not allowed.';
      } else if (!isValidFileSize) {
        status = UploadStatus.FAILED;
        message = `File exceeds the ${Helpers.toFileSizeText(sizeLimit)} size limit.`;
      }

      uploadItems.push({
        id: uuidv4(),
        name: files[i].name ?? '',
        data: files[i],
        percentage: 0,
        message: message,
        status: status,
        timeCompleted: null
      });
    }
    setUploadQueue(uploadItems);
  };

  const handleProgress = (id, percentage) => {
    let queueToUpdate = [...uploadQueue];
    let item = queueToUpdate.find((x) => x.id === id);
    //the percentage shows the upload percentage to the server, but hangs at 100% while waiting for the server to upload to blob storage
    //once we hit 100%, change to indeterminate progress bar so the user knows something is still happening
    item.percentage = percentage < 100 ? percentage : null;
    setUploadQueue(queueToUpdate);
  };

  const handleComplete = (id, success, response) => {
    let queueToUpdate = [...uploadQueue];
    let item = queueToUpdate.find((x) => x.id === id);

    //determine if the upload was successful
    let isSuccessful = false;
    let apiResponse = null;
    let message = '';

    if (success) {
      try {
        apiResponse = JSON.parse(response);
        isSuccessful =
          apiResponse.status.statusCode === 200 &&
          apiResponse.response[0].status === UploadResultStatus.SUCCESS;
        message = apiResponse.response[0].message;
      } catch (e) {
        message = 'Error processing response.';
      }
    }
    item.status = isSuccessful ? UploadStatus.COMPLETE : UploadStatus.FAILED;
    item.message = message;
    item.timeCompleted = new Date().getTime();
    setUploadQueue(queueToUpdate);
  };

  const removeItem = (e, id) => {
    e.preventDefault();
    let queueToUpdate = [...uploadQueue];
    queueToUpdate = queueToUpdate.filter((x) => x.id !== id);
    setUploadQueue(queueToUpdate);
  };

  const cleanupQueue = () => {
    let queueToUpdate = [...uploadQueue];
    queueToUpdate = queueToUpdate.filter((x) => x.status !== UploadStatus.COMPLETE);
    setUploadQueue(queueToUpdate);
  };

  const processQueue = () => {
    //check for complete items to clean up
    if (completeCount > 0) {
      cleanupQueue();
    }

    //check for max queue process
    if (inProgressCount >= maxConcurrentUploads) {
      return;
    }

    //get next pending item
    let queueToUpdate = [...uploadQueue];
    let nextItem = queueToUpdate.find((x) => x.status === UploadStatus.PENDING);
    if (!nextItem) {
      //nothing left to process
      if (typeof uploadCompleteCallback === 'function') {
        uploadCompleteCallback();
      }
      return;
    }
    nextItem.status = UploadStatus.UPLOADING;
    setUploadQueue(queueToUpdate);

    const formData = new FormData();
    formData.append(`files[0]`, nextItem.data);
    Api.upload(
      uploadApiEndpoint,
      formData,
      (percentage) => handleProgress(nextItem.id, percentage),
      (success, response) => handleComplete(nextItem.id, success, response)
    );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    if (files && files.length) {
      handleSelectedFiles(files);
    }
    setDragging(false);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  useInterval(
    () => {
      processQueue();
    },
    pendingCount > 0 || completeCount > 0 ? 250 : null
  );

  useEffect(() => {
    const drop = dropArea.current;
    if (drop) {
      drop.addEventListener('dragover', handleDragOver);
      drop.addEventListener('drop', handleDrop);
      drop.addEventListener('dragenter', handleDragEnter);
      drop.addEventListener('dragleave', handleDragLeave);
      return () => {
        drop.removeEventListener('dragover', handleDragOver);
        drop.removeEventListener('drop', handleDrop);
        drop.removeEventListener('dragenter', handleDragEnter);
        drop.removeEventListener('dragleave', handleDragLeave);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  if (!visible) return null;

  return (
    <div className={className}>
      <div className={dragging ? styles.fileDragArea : styles.fileDropArea} ref={dropArea}>
        <button className={uploadButtonClassName} onClick={showFilePicker}>
          Select File{multipleFiles ? 's' : ''}
        </button>
        <span className={styles.uploadDragText}>or drag file{multipleFiles ? 's' : ''} here</span>
        <input
          type="file"
          className="d-none"
          ref={fileInput}
          onChange={() => handleSelectedFiles(event.target.files)}
          multiple={multipleFiles}
          accept={acceptExtensions}
        />
      </div>
      <div className="mt-2">
        {uploadQueue.map((item) => {
          const itemComplete = item.status === UploadStatus.COMPLETE;

          if (itemComplete) {
            return null;
          }

          return (
            <div className={styles.uploadCard} key={item.id}>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  {item.name}
                  {item.status === UploadStatus.FAILED && (
                    <span className="badge bg-danger ms-2">
                      {item.message ?? 'Error occurred uploading file.'}
                    </span>
                  )}
                </div>
                {item.status === UploadStatus.FAILED && (
                  <a
                    href="#"
                    className={`${styles.closeButton}`}
                    onClick={(e) => removeItem(e, item.id)}>
                    <i className="icofont-close"></i>
                  </a>
                )}
              </div>
              <ProgressBar
                className="m-0 mt-2"
                percentage={item.percentage}
                showPercentageText={true}
                visible={item.status === UploadStatus.UPLOADING}
              />
              {item.percentage === null && (
                <div className={styles.processingFile}>Processing file</div>
              )}
            </div>
          );
        })}
      </div>
      {children}
    </div>
  );
};

FileUpload.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
  uploadApiEndpoint: PropTypes.string.isRequired,
  maxConcurrentUploads: PropTypes.number,
  className: PropTypes.string,
  uploadButtonClassName: PropTypes.string,
  acceptExtensions: PropTypes.string,
  sizeLimit: PropTypes.number,
  multipleFiles: PropTypes.bool,
  uploadCompleteCallback: PropTypes.func
};

export default FileUpload;
