import { useState } from 'react';

const useUserManager = () => {
  const [showEditor, setShowEditor] = useState(false);
  const [editUserId, setEditUserId] = useState(null);

  const editUser = async (editUserId) => {
    setShowEditor(true);
    setEditUserId(editUserId);
  };

  const returnToSearch = async () => {
    setShowEditor(false);
    setEditUserId(null);
  };

  return {
    showEditor,
    editUserId,
    editUser,
    returnToSearch
  };
};

export default useUserManager;
