import { useState, useEffect, useCallback } from 'react';
import { Api } from '../../../../utils/helpers';

const useTransactionConfigurationSummary = ({ transactionParameters }) => {
  const [loading, setLoading] = useState(true);
  const [summaryResult, setSummaryResult] = useState(null);

  const runReport = useCallback(async () => {
    setLoading(true);
    setSummaryResult(null);

    if (
      transactionParameters.brandId <= 0 ||
      transactionParameters.customerId <= 0 ||
      transactionParameters.transactionId <= 0
    ) {
      return false;
    }
    setLoading(true);

    const apiResponse = await Api.post(
      '/policy/Transaction/ConfigurationSummary',
      JSON.stringify(transactionParameters)
    );

    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }

    let response = apiResponse.response;
    response.hasInputFields = response.inputFields.length > 0;
    response.hasCustomFields = response.customFields.length > 0;
    response.hasLookupFields = response.lookupFields.length > 0;

    setSummaryResult(response);
    setLoading(false);
  }, [transactionParameters]);

  useEffect(() => {
    runReport();
  }, [runReport]);

  return {
    loading,
    summaryResult
  };
};

export default useTransactionConfigurationSummary;
