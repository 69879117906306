import React from 'react';
import { TransactionSelector, DisplayMode } from '../../../components/user';
import { TransactionConfigurationSummary, AutomatedUpload } from '../TransactionSections';
import styles from './TransactionManager.module.css';
import useTransactionManager from './useTransactionManager';
import { TransactionSections } from '../../../utils/constants/enums';

export const TransactionManager = () => {
  const manager = useTransactionManager();

  return (
    <>
      <div className="titleBar">
        <h1 className="pageTitle">Transaction Summary</h1>
      </div>
      <div className={styles.transactionManager}>
        <TransactionSelector
          displayMode={DisplayMode.SIDE_ALT}
          showRequiredAsterisk={false}
          showInactiveFilter={true}
          showDeletedFilter={true}
          activeBrandId={manager.activeBrandId}
          onChange={(selectedOptions) => manager.updateTransactionParams(selectedOptions)}
        />
        {manager.transactionParameters.transactionId !== null && (
          <div className={styles.transactionDetails}>
            <div className={styles.sectionMenu}>
              {manager.allowedSections.map((section) => {
                let sectionClass =
                  manager.viewSection === section.id
                    ? styles.sectionMenuItemActive
                    : styles.sectionMenuItem;

                return (
                  <a
                    href="#"
                    key={section.id}
                    className={sectionClass}
                    onClick={(e) => {
                      e.preventDefault();
                      manager.openSection(section.id);
                    }}>
                    {section.name}
                  </a>
                );
              })}
            </div>
            <div className={styles.sectionDisplay}>
              {manager.viewSection === TransactionSections.CONFIGURATION_SUMMARY && (
                <div className={styles.transactionConfigSection}>
                  <TransactionConfigurationSummary
                    transactionParameters={manager.transactionParameters}
                  />
                </div>
              )}
              {manager.viewSection === TransactionSections.AUTOMATED_UPLOADS && (
                <AutomatedUpload
                  canImport={manager.transactionDetails.canImport}
                  transactionParameters={manager.transactionParameters}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
