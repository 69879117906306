import React from 'react';
import { AccountInformation } from '../Components/AccountInformation/AccountInformation';
import { LegalBusinessInformation } from '../Components/LegalBusinessInformation/LegalBusinessInformation';
import { PrimaryAuthorizedUser } from '../Components/PrimaryAuthorizedUser/PrimaryAuthorizedUser';
import { RegisteredBusinessAddress } from '../Components/RegisteredBusinessAddress/RegisteredBusinessAddress';
import useProprietorship from './useProprietorship';

export const Proprietorship = ({ customerRecordParameter, formStatusCallback, errorFormData }) => {
  const manager = useProprietorship(customerRecordParameter, formStatusCallback, errorFormData);

  return (
    <>
      <AccountInformation
        containerFormData={manager.formData}
        formStatusCallback={manager.componentFormCallback}
      />
      <PrimaryAuthorizedUser
        containerFormData={manager.formData}
        formStatusCallback={manager.componentFormCallback}
      />
      <LegalBusinessInformation
        containerFormData={manager.formData}
        formStatusCallback={manager.componentFormCallback}
      />
      <RegisteredBusinessAddress
        containerFormData={manager.formData}
        formStatusCallback={manager.componentFormCallback}
      />
    </>
  );
};

export default Proprietorship;
