import { DefaultLayout as Layout } from '../../../layouts';
import { useOutbound } from '../../../utils/hooks';
import { OutboundGroup } from '../../../utils/constants/enums';
import { TileLink } from '../../../components/ui';

export const UniBankAdminToolsPage = () => {
  const outbound = useOutbound();

  return (
    <Layout>
      <div className="titleBar">
        <h1 className="pageTitle">UniBank Admin Tools</h1>
      </div>
      <div className="d-flex flex-wrap justify-content-lg-start justify-content-evenly">
        {outbound.getOutboundLinks(OutboundGroup.ADMIN_TOOLS).map((out, index) => {
          return (
            <TileLink
              key={index}
              text={out.text}
              target={out.target}
              state={out.state}
              iconClass={out.iconClass}
            />
          );
        })}
      </div>
    </Layout>
  );
};
