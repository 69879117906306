import React from 'react';
import { Helpers } from '../../../../utils/helpers/Helpers';
import { NameDisplayOrder } from '../../../../utils/constants/enums';
import { PagingControls, SortingHeader } from '../../../../components/ui';
import { Spinner } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import useUserSearch from './useUserSearch';
import { UserFilter } from './UserFilter';

const UserSearch = (props) => {
  const search = useUserSearch(props);

  return (
    <>
      <h1 className="pageTitle">Manage My Team</h1>
      <button className="btn btn-primary" onClick={() => search.exportCSV()}>
        Export Results (CSV)
      </button>
      <div className="row">
        <div className="col-lg-9 col-md-12">
          <div className="searchContainer">
            <div className="searchWrap">
              <label htmlFor="searchUsers" className="form-label sr-only">
                Search users
              </label>
              <input
                id="searchUsers"
                type="text"
                className="form-control"
                placeholder="Search users"
                onChange={(e) => search.updateKeywords(e.target.value)}
              />
            </div>
            <button className="btn btn-success" onClick={() => props.editUserCallback(null)}>
              Add User
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-9 col-lg-12">
          <div className="card">
            <div className="card-body p-0">
              {!search.loading && search?.pagedResult?.results.length === 0 && (
                <div className="p-3">
                  <div className="mb-3">No users found.</div>
                </div>
              )}

              {search.loading && <Spinner animation="border" />}

              {!search.loading && search?.pagedResult?.results.length > 0 && (
                <>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>
                          <SortingHeader
                            label="Username"
                            sortField="username"
                            sortDirection={search.searchParameters.sortDirection}
                            onClick={search.updateSorting}
                            active={search.searchParameters.sortField === 'username'}
                          />
                        </th>
                        <th className="d-none d-md-table-cell">
                          <SortingHeader
                            label="Name"
                            sortField="lastname"
                            sortDirection={search.searchParameters.sortDirection}
                            onClick={search.updateSorting}
                            active={search.searchParameters.sortField === 'lastname'}
                          />
                        </th>
                        <th className="d-none d-md-table-cell">
                          <SortingHeader
                            label="Email / Phone"
                            sortField="email"
                            sortDirection={search.searchParameters.sortDirection}
                            onClick={search.updateSorting}
                            active={search.searchParameters.sortField === 'email'}
                          />
                        </th>
                        <th className="d-none d-md-table-cell">
                          <SortingHeader
                            label="Role"
                            sortField="role"
                            sortDirection={search.searchParameters.sortDirection}
                            onClick={search.updateSorting}
                            active={search.searchParameters.sortField === 'role'}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {search?.pagedResult?.results?.map((user) => (
                        <tr key={user.id}>
                          <td>
                            <a href="#!" onClick={() => props.editUserCallback(user.id)}>
                              <strong>{user.userName}</strong>
                            </a>
                          </td>
                          <td className="d-none d-md-table-cell">
                            {Helpers.nameDisplay(
                              user.lastName,
                              user.firstName,
                              user.userName,
                              NameDisplayOrder.LASTNAME_FIRST
                            )}
                          </td>
                          <td className="d-none d-md-table-cell">
                            <div>
                              <a href={'mailto:' + user.emailAddress}>{user.emailAddress}</a>
                            </div>
                            {user.phoneNumber}
                          </td>
                          <td className="d-none d-md-table-cell">
                            {user.roles.length === 0 && (
                              <div>
                                <em className="text-danger">No role assigned</em>
                              </div>
                            )}
                            {user.roles.map((r) => (
                              <div key={r.id}>{r.name}</div>
                            ))}
                            <div>{search.getBrandNames(user.brandIds)}</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="ps-3 pe-3">
                    <PagingControls
                      page={search.pagedResult.currentPage}
                      pageCount={search.pagedResult.pageCount}
                      changePageCallback={search.changePage}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-12">
          <UserFilter roles={props.roles} brands={props.brands} search={search} />
        </div>
      </div>
    </>
  );
};

export default UserSearch;
