import { useState, useCallback, useEffect } from 'react';
import { useAuthContext } from '../../../context/provider/AuthContext';
import { Api } from '../../../utils/helpers';
import { sectionMenu } from '../TransactionSections/sectionMenu';
import { TransactionSections } from '../../../utils/constants/enums';

const useTransactionManager = () => {
  const [{ user }] = useAuthContext();
  const [transactionDetails, setTransactionDetails] = useState({
    canImport: false
  });
  const [showConfigurationSummary, setConfigurationSummaryDisplay] = useState(false);
  const [viewSection, setViewSection] = useState(TransactionSections.CONFIGURATION_SUMMARY);
  const [transactionParameters, setTransactionParameters] = useState({
    brandId: null,
    customerId: null,
    transactionId: null
  });

  const allowedSections = sectionMenu.filter((section) => {
    return section.requiredPermissions.every((p) => user.permissions.includes(p));
  });

  const getTransactionConfiguration = useCallback(async () => {
    if (
      !transactionParameters ||
      transactionParameters.brandId <= 0 ||
      transactionParameters.customerId <= 0 ||
      transactionParameters.transactionId <= 0
    ) {
      setTransactionDetails({ canImport: false });
      return;
    }

    const apiResponse = await Api.post(
      '/policy/Transaction/TransactionCanImport',
      JSON.stringify(transactionParameters)
    );
    if (apiResponse.status.statusCode !== 200) {
      setTransactionDetails({ canImport: false });
      return;
    }
    setTransactionDetails({ canImport: apiResponse.response });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionParameters]);

  const openSection = (sectionId) => {
    setViewSection(sectionId);
  };

  const updateTransactionParams = (selectedOptions) => {
    setTransactionParameters(selectedOptions);
    setConfigurationSummaryDisplay(selectedOptions.transactionId !== null);
  };

  useEffect(() => {
    getTransactionConfiguration();
  }, [getTransactionConfiguration, transactionParameters.transactionId]);

  useEffect(() => {
    setTransactionParameters({
      brandId: null,
      customerId: null,
      transactionId: null
    });
    setViewSection(TransactionSections.CONFIGURATION_SUMMARY);
  }, [user.activeBrand.id]);

  return {
    viewSection,
    allowedSections,
    transactionParameters,
    showConfigurationSummary,
    transactionDetails,
    activeBrandId: user.activeBrand.id,
    updateTransactionParams,
    setViewSection,
    openSection
  };
};

export default useTransactionManager;
