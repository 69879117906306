import React from 'react';
import styles from '../../../PayPal.module.css';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import useAccountInformation from './useAccountInformation';

export const AccountInformation = ({ containerFormData, formStatusCallback }) => {
  const manager = useAccountInformation(containerFormData, formStatusCallback);

  return (
    <>
      <div>
        <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
          <thead>
            <tr>
              <th className={styles.paypalOnboardingSectionHeader}>PayPal Account Information</th>
              {manager.componentStatus === FormComponentStatus.COMPLETE && (
                <th className={styles.paypalOnboardingCompleteStatusHeader}>Complete</th>
              )}
              {manager.componentStatus === FormComponentStatus.INCOMPLETE && (
                <th className={styles.paypalOnboardingIncompleteStatusHeader}>Incomplete</th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label htmlFor="payPalExternalId">
                  <span className="req">*</span>External Id:
                </label>
              </td>
              <td>
                <input
                  name="payPalExternalId"
                  id="payPalExternalId"
                  type="text"
                  className="form-control"
                  value={manager.formData.payPalExternalId}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.payPalExternalId && (
                  <div className="alert-danger mt-1 p-2">External Id is required.</div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="payPalUserId">
                  <span className="req">*</span>User Id:
                </label>
              </td>
              <td>
                <input
                  name="payPalUserId"
                  id="payPalUserId"
                  type="text"
                  className="form-control"
                  value={manager.formData.payPalUserId}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.payPalUserId && (
                  <div className="alert-danger mt-1 p-2">User Id is required.</div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="softDescriptor">
                  <span className="req">*</span>Soft Descriptor:
                </label>
              </td>
              <td>
                <input
                  name="softDescriptor"
                  id="softDescriptor"
                  type="text"
                  className="form-control"
                  value={manager.formData.softDescriptor}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.softDescriptor && (
                  <div className="alert-danger mt-1 p-2">
                    Soft Descriptor is required and must be between 2 and 11 alphanumeric
                    characters.
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="bankName">
                  <span className="req">*</span>Bank Name:
                </label>
              </td>
              <td>
                <input
                  name="bankName"
                  id="bankName"
                  type="text"
                  className="form-control"
                  value={manager.formData.bankName}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.bankName && (
                  <div className="alert-danger mt-1 p-2">Bank Name is required.</div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="bankAccountNumber">
                  <span className="req">*</span>Bank Account Number:
                </label>
              </td>
              <td>
                <input
                  name="bankAccountNumber"
                  id="bankAccountNumber"
                  type="text"
                  className="form-control"
                  value={manager.formData.bankAccountNumber}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.bankAccountNumber && (
                  <div className="alert-danger mt-1 p-2">Bank Account Number is required.</div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="bankAccountType">
                  <span className="req">*</span>Bank Account Type:
                </label>
              </td>
              <td>
                <select
                  name="bankAccountType"
                  id="bankAccountType"
                  className="form-select"
                  aria-label="Bank account type selection"
                  value={manager.formData.bankAccountType}
                  onChange={(e) => manager.updateValue(e.target)}>
                  <option value="checking">Checking</option>
                  <option value="savings">Savings</option>
                </select>
                {manager.errors.bankAccountType && (
                  <div className="alert-danger mt-1 p-2">Bank Account Type is required.</div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="bankAccountRoutingNumber">
                  <span className="req">*</span>Bank Account Routing Number:
                </label>
              </td>
              <td>
                <input
                  name="bankAccountRoutingNumber"
                  id="bankAccountRoutingNumber"
                  type="text"
                  className="form-control"
                  value={manager.formData.bankAccountRoutingNumber}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.bankAccountRoutingNumber && (
                  <div className="alert-danger mt-1 p-2">
                    Bank Account Routing Number is required.
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="bankLocation">
                  <span className="req">*</span>Branch Location:
                </label>
              </td>
              <td>
                <input
                  name="bankLocation"
                  id="bankLocation"
                  type="text"
                  className="form-control"
                  value={manager.formData.bankLocation}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.bankLocation && (
                  <div className="alert-danger mt-1 p-2">Branch Location is required.</div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AccountInformation;
