import { Permissions } from '../utils/constants/permissions';
import { OutboundGroup } from '../utils/constants/enums';

export const outboundMapping = [
  //RESEARCH
  {
    group: OutboundGroup.NONE,
    target: 'research',
    path: '/CustomerResearcher/Research.aspx',
    requiredPermissions: [Permissions.Research.SEARCH]
  },
  {
    group: OutboundGroup.FILE_MANAGEMENT,
    target: 'maintain-import-file-dates',
    path: '/files/maintain-import-file-dates',
    iconClass: 'icofont-ui-calendar',
    text: 'Maintain Import File Dates',
    requiredPermissions: [Permissions.Admin.FILE_DATES],
    internalPath: true
  },
  {
    group: OutboundGroup.FILE_MANAGEMENT,
    target: 'download-lockbox-files',
    path: '/CustomerFileManager/ExportData.aspx',
    iconClass: 'icofont-files-stack',
    text: 'Download Lockbox Files',
    requiredPermissions: [Permissions.Admin.FILES]
  },
  {
    group: OutboundGroup.FILE_MANAGEMENT,
    target: 'generate-lockbox-packages',
    path: '/CustomerFileManager/ExportPackage.aspx',
    iconClass: 'icofont-box',
    text: 'Generate Lockbox Packages',
    requiredPermissions: [Permissions.Admin.FILES]
  },
  {
    group: OutboundGroup.FILE_MANAGEMENT,
    target: 'download-lockbox-packages',
    path: '/CustomerFileManager/ExportPackageStatus.aspx',
    iconClass: 'icofont-dropbox',
    text: 'Download Lockbox Packages',
    requiredPermissions: [Permissions.Admin.FILES]
  },

  //EBP MANAGER
  {
    group: OutboundGroup.EBP_MANAGER,
    target: 'ebp-bill-designer',
    path: '/UniAdmin/EBP/default.aspx',
    iconClass: 'icofont-ruler-pencil-alt-2',
    text: 'EBP Bill Designer',
    requiredPermissions: [Permissions.Ebp.DASHBOARD]
  },
  {
    group: OutboundGroup.EBP_MANAGER,
    target: 'ebp-manager',
    path: '/UniAdmin/EBP/ebptx.aspx',
    iconClass: 'icofont-files-stack',
    text: 'EBP Manager',
    requiredPermissions: [Permissions.Ebp.DASHBOARD]
  },
  {
    group: OutboundGroup.EBP_MANAGER,
    target: 'ebp-dashboard',
    path: '/CustomerFileManager/EBPdashboard.aspx',
    iconClass: 'icofont-dashboard-web',
    text: 'EBP Dashboard',
    requiredPermissions: [Permissions.Ebp.DASHBOARD]
  },
  {
    group: OutboundGroup.EBP_MANAGER,
    target: 'ebp-download-log',
    path: '/CustomerFileManager/EBPDownloadLog.aspx',
    iconClass: 'icofont-data',
    text: 'EBP Download Log',
    requiredPermissions: [Permissions.Ebp.DOWNLOAD_LOG]
  },

  //UNIBANK ADMIN TOOLS
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'super-admin-tools',
    path: '/UniAdmin/SuperAdminTools.aspx',
    iconClass: 'icofont-tools-alt-2',
    text: 'Super Admin Tools',
    requiredPermissions: [Permissions.AdminTools.SUPER_TOOLS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'view-alerts-history',
    path: '/UniAdmin/ViewUserEnrollments.aspx',
    iconClass: 'icofont-search-user',
    text: 'User Enrollment Lookup',
    requiredPermissions: [Permissions.AdminTools.ENROLLMENT_LOOKUP]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'add-customer',
    iconClass: 'icofont-user-alt-2',
    path: '/UniAdmin/EditCustomer.aspx',
    text: 'Add Customer',
    requiredPermissions: [Permissions.AdminTools.ADD_CUSTOMER]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-customers',
    iconClass: 'icofont-users-alt-2',
    path: '/UniAdmin/CustomerManager.aspx',
    text: 'Manage Customers',
    requiredPermissions: [Permissions.AdminTools.MANAGE_CUSTOMERS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'edit-customer-group',
    iconClass: 'icofont-ui-user-group',
    path: '/UniAdmin/EditCustomerGroup.aspx',
    text: 'Add Customer Group',
    requiredPermissions: [Permissions.AdminTools.ADD_CUSTOMER_GROUP]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-customer-groups',
    iconClass: 'icofont-ui-user-group',
    path: '/UniAdmin/CustomerGroupManager.aspx',
    text: 'Manage Customer Groups',
    requiredPermissions: [Permissions.AdminTools.MANAGE_CUSTOMER_GROUPS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-administrators',
    iconClass: 'icofont-user-suited',
    path: '/UniAdmin/AdminManager.aspx',
    text: 'Manage Administrators',
    requiredPermissions: [Permissions.AdminTools.MANAGE_ADMINISTRATORS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-transactions',
    iconClass: 'icofont-connection',
    path: '/UniAdmin/TransactionManager.aspx',
    text: 'Manage Transactions',
    requiredPermissions: [Permissions.AdminTools.MANAGE_TRANSACTIONS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-transaction-groups',
    iconClass: 'icofont-connection',
    path: '/UniAdmin/TransactionGroupManager.aspx',
    text: 'Manage Transaction Groups',
    requiredPermissions: [Permissions.AdminTools.MANAGE_TRANSACTION_GROUPS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-classifications',
    iconClass: 'icofont-label',
    path: '/UniAdmin/ClassificationManager.aspx',
    text: 'Manage Classifications',
    requiredPermissions: [Permissions.AdminTools.MANAGE_CLASSIFICATIONS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'organize-transactions',
    iconClass: 'icofont-connection',
    path: '/UniAdmin/TransactionOrganization.aspx',
    text: 'Organize Transactions',
    requiredPermissions: [Permissions.AdminTools.ORGANIZE_TRANSACTIONS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-export-data',
    iconClass: 'icofont-database',
    path: '/UniAdmin/ExportManager.aspx',
    text: 'Manage Export Data',
    requiredPermissions: [Permissions.AdminTools.MANAGE_EXPORT_DATA]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-site-content',
    iconClass: 'icofont-page',
    path: '/UniAdmin/ContentManager.aspx',
    text: 'Manage Site Content',
    requiredPermissions: [Permissions.AdminTools.MANAGE_SITE_CONTENT]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-public-content',
    iconClass: 'icofont-page',
    path: '/UniAdmin/PublicContentManager.aspx',
    text: 'Manage Public Site Content',
    requiredPermissions: [Permissions.AdminTools.MANAGE_PUBLIC_CONTENT]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-public-users',
    iconClass: 'icofont-users-alt-2',
    path: '/UniAdmin/PublicUserManager.aspx',
    text: 'Manage Public Users',
    requiredPermissions: [Permissions.AdminTools.MANAGE_PUBLIC_USERS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'view-reports',
    iconClass: 'icofont-chart-histogram',
    path: '/UniAdmin/AdminReports.aspx',
    text: 'View Reports',
    requiredPermissions: [Permissions.AdminTools.VIEW_REPORTS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'credit-card-errors',
    iconClass: 'icofont-credit-card',
    path: '/UniAdmin/CreditCardErrors.aspx',
    text: 'Credit Card Errors',
    requiredPermissions: [Permissions.AdminTools.CREDIT_CARD_ERRORS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'unlock-giact-user',
    iconClass: 'icofont-unlock',
    path: '/UniAdmin/UnlockGiactFailures.aspx',
    text: 'Unlock a GIACT User Account',
    requiredPermissions: [Permissions.AdminTools.UNLOCK_GIACT_USER]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'user-giact-failures',
    iconClass: 'icofont-warning',
    path: '/UniAdmin/UserGiactFailures.aspx',
    text: 'User GIACT Failures',
    requiredPermissions: [Permissions.AdminTools.USER_GIACT_FAILURES]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-ach-holidays',
    iconClass: 'icofont-snow-alt',
    path: '/UniAdmin/ACHManager.aspx',
    text: 'Manage ACH Holidays',
    requiredPermissions: [Permissions.AdminTools.MANAGE_ACH_HOLIDAYS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-unipay-ach-files',
    iconClass: 'icofont-ui-file',
    path: '/UniAdmin/ACHFileManager.aspx',
    text: 'Manage Unipay ACH Files',
    requiredPermissions: [Permissions.AdminTools.MANAGE_UNIPAY_ACH_FILES]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-customer-ach-files',
    iconClass: 'icofont-ui-file',
    path: '/UniAdmin/ACHFileManagerCustomer.aspx',
    text: 'Manage Customer ACH Files',
    requiredPermissions: [Permissions.AdminTools.MANAGE_CUSTOMER_ACH_FILES]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-ach-chargeback-files',
    iconClass: 'icofont-lightning-ray',
    path: '/UniAdmin/ACHChargebackFileManager.aspx',
    text: 'Manage ACH Chargeback Files',
    requiredPermissions: [Permissions.AdminTools.MANAGE_ACH_CHARGEBACK_FILES]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-customer-ach-chargeback-files',
    iconClass: 'icofont-lightning-ray',
    path: '/UniAdmin/ACHChargebackFileManagerCustomer.aspx',
    text: 'Manage Customer ACH Chargeback Files',
    requiredPermissions: [Permissions.AdminTools.MANAGE_CUSTOMER_ACH_CHARGEBACK_FILES]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-data-maintenance-holidays',
    iconClass: 'icofont-snow-alt',
    path: '/UniAdmin/DMHolidayManager.aspx',
    text: 'Manage Data Maintenance Holidays',
    requiredPermissions: [Permissions.AdminTools.MANAGE_DATA_HOLIDAYS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'manage-payment-plans',
    iconClass: 'icofont-pay',
    path: '/UniAdmin/PaymentPlanManager.aspx',
    text: 'Manage Payment Plans',
    requiredPermissions: [Permissions.AdminTools.MANAGE_PAYMENT_PLANS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'view-enrollments',
    iconClass: 'icofont-checked',
    path: '/UniAdmin/ViewEnrollments.aspx',
    text: 'View Enrollments',
    requiredPermissions: [Permissions.AdminTools.VIEW_ENROLLMENTS]
  },
  {
    group: OutboundGroup.ADMIN_TOOLS,
    target: 'view-mail-events',
    iconClass: 'icofont-mail-box',
    path: '/UniAdmin/MailEvents.aspx',
    text: 'View Mail Events',
    requiredPermissions: [Permissions.AdminTools.VIEW_MAIL_EVENTS]
  }
];
