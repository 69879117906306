import React from 'react';
import { SortDirection } from '../../../utils/constants/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import './SortingHeader.css';

const SortingDirectionIcon = ({ active, sortDirection }) => {
  const activeUpClass = active && sortDirection === SortDirection.ASCENDING ? ' active' : '';
  const activeDownClass = active && sortDirection === SortDirection.DESCENDING ? ' active' : '';
  return (
    <div className="d-flex align-items-start flex-column sortingArrows ps-1">
      <FontAwesomeIcon size="sm" icon={faCaretUp} className={`up${activeUpClass}`} />
      <FontAwesomeIcon size="sm" icon={faCaretDown} className={`down${activeDownClass}`} />
    </div>
  );
};

export const SortingHeader = ({ label, sortField, sortDirection, onClick, active }) => {
  const isClickable = typeof onClick === 'function';

  const handleClick = (e) => {
    e.preventDefault();
    if (!isClickable) {
      return;
    }
    //invert sort direction
    sortDirection =
      sortDirection === SortDirection.ASCENDING
        ? SortDirection.DESCENDING
        : SortDirection.ASCENDING;
    onClick(sortField, sortDirection);
  };

  if (!isClickable) {
    return <div className="sort-header-btn">{label}</div>;
  } else {
    return (
      <button className="sort-header-btn" onClick={handleClick}>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">{label}</div>
          <div className="ps-1">
            <SortingDirectionIcon active={active} sortDirection={sortDirection} />
          </div>
        </div>
      </button>
    );
  }
};

export default SortingHeader;
