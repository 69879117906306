import { useState, useEffect, useCallback } from 'react';
import { Api, Helpers } from '../../../../../../utils/helpers';

const useProcessView = (
  customerRecordParameter,
  processViewParameters,
  payPalIdSelection,
  payPalIdStatusCallback
) => {
  const [loading, setLoading] = useState(true);
  const [processView, setProcessView] = useState(null);
  const [customerRecord, setCustomerRecord] = useState(customerRecordParameter);
  const [processViewLoaded, setProcessViewLoaded] = useState(false);
  const [payPalIsActive, setPayPalActiveFlag] = useState(false);
  const [passedPayPalVerification, setPayPalVerification] = useState(false);
  const [hasPAUAddress, setPAUAddressFlag] = useState(false);
  const [hasPAUPhoneNumber, setPAUPhoneFlag] = useState(false);
  const [hasPAUIdDocument, setPAUIdDocumentFlag] = useState(false);
  const [hasBeneficialOwner, setBeneficialOwnerFlag] = useState(false);
  const [hasOfficeBearer, setOfficeBearerFlag] = useState(false);
  const [hasFinancialInstrument, setFinancialInstrumentFlag] = useState(false);
  const [kycVerificationStatus, setKYCVerificationStatus] = useState('NEED_MORE_DATA');
  const [boVerificationStatus, setBOVerificationStatus] = useState('NEED_MORE_DATA');

  const getProcessView = useCallback(async () => {
    setLoading(true);
    setProcessViewLoaded(false);

    if (processViewParameters.brandId <= 0 || processViewParameters.customerId <= 0) {
      setLoading(false);
      return false;
    }

    const apiResponse = await Api.get(
      `/paypal/Onboarding/ManagedAccount/ProcessView/${processViewParameters.brandId}/${processViewParameters.customerId}/${payPalIdSelection}`
    );

    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }

    let response = apiResponse.response;

    setProcessView(response);
    setPayPalActiveFlag(Boolean(response.isActive));

    let kycNode = response.processView.processes.filter(
      (val) => val.name === 'MANAGED_PATH_KYC_VERIFICATION'
    );
    let boNode = response.processView.processes.filter(
      (val) => val.name === 'MANAGED_PATH_BO_VERIFICATION'
    );

    setKYCVerificationStatus(kycNode[0].status);
    setBOVerificationStatus(boNode[0].status);

    if (kycNode[0].status === 'COMPLETED' && boNode[0].status === 'COMPLETED') {
      setPayPalVerification(true);
    }

    setPAUAddressFlag(
      !Helpers.isNullOrWhitespace(response.individualOwners[0].primaryResidence.addressLine1)
    );
    setPAUPhoneFlag(response.individualOwners[0].phoneNumbers.length > 0);
    setPAUIdDocumentFlag(response.individualOwners[0].identificationDocuments.length > 0);
    setBeneficialOwnerFlag(
      response.businessEntity.beneficialOwners &&
        response.businessEntity.beneficialOwners.individualOwners.length > 0
    );
    setOfficeBearerFlag(
      response.businessEntity.officeBearers && response.businessEntity.officeBearers.length > 0
    );
    setFinancialInstrumentFlag(
      response.financialInstruments && response.financialInstruments.banks.length > 0
    );

    setProcessViewLoaded(true);
    setLoading(false);
  }, [processViewParameters, processView, payPalIdSelection]);

  const refreshProcessView = async () => {
    setLoading(true);
    getProcessView();
  };

  const activatePayPal = async (event) => {
    event.preventDefault();
    setLoading(true);

    const apiResponse = await Api.post(
      `/paypal/Onboarding/ManagedAccount/Activate/${processViewParameters.brandId}/${processViewParameters.customerId}/${payPalIdSelection}`
    );

    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }

    let response = apiResponse.response;

    setCustomerRecord(response);
    setPayPalActiveFlag(response.isActive);
    setLoading(false);
    const targetPayPal = response.payPalIdSelect.find((x) => x.value === payPalIdSelection);
    payPalIdStatusCallback(payPalIdSelection, targetPayPal.label);
  };

  const deactivatePayPal = async (event) => {
    event.preventDefault();
    setLoading(true);

    const apiResponse = await Api.post(
      `/paypal/Onboarding/ManagedAccount/Deactivate/${processViewParameters.brandId}/${processViewParameters.customerId}/${payPalIdSelection}`
    );

    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }

    let response = apiResponse.response;

    setCustomerRecord(response);
    setPayPalActiveFlag(response.isActive);
    setLoading(false);
    const targetPayPal = response.payPalIdSelect.find((x) => x.value === payPalIdSelection);
    payPalIdStatusCallback(payPalIdSelection, targetPayPal.label);
  };

  useEffect(() => {
    if (!processViewLoaded) {
      getProcessView();
    }
  }, [processViewLoaded, getProcessView]);

  return {
    loading,
    processView,
    customerRecord,
    processViewLoaded,
    payPalIsActive,
    passedPayPalVerification,
    hasPAUAddress,
    hasPAUPhoneNumber,
    hasPAUIdDocument,
    hasBeneficialOwner,
    hasOfficeBearer,
    hasFinancialInstrument,
    kycVerificationStatus,
    boVerificationStatus,
    refreshProcessView,
    activatePayPal,
    deactivatePayPal
  };
};

export default useProcessView;
