import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import Select from 'react-select';
import styles from './PayPal.module.css';
import usePayPal from './usePayPal';
import { Government } from './Forms/Government/Government';
import { Corporation } from './Forms/Corporation/Corporation';
import { Nonprofit } from './Forms/Nonprofit/Nonprofit';
import { Proprietorship } from './Forms/Proprietorship/Proprietorship';
import { ProcessView } from './Forms/ProcessView/ProcessView';
import { BusinessType } from '../../../../utils/constants/enums';

export const PayPal = ({ customerParameters }) => {
  const manager = usePayPal({ customerParameters });

  return (
    <>
      <h2 className={styles.payPalOnboardingHeader}>PayPal Account Overview</h2>
      {manager.loading && (
        <div className="m-2 text-center">
          <Spinner animation="border" />
        </div>
      )}
      {!manager.loading && (
        <>
          <div className="col-12 mt-4">
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => manager.addManagedAccount(e)}>
              Add New Managed Account
            </button>
          </div>
          <br />
          {!manager.loadingCustomer && manager.hasPayPalIds && (
            <>
              <Select
                name="customerPayPalIds"
                id="customerPayPalIds"
                value={manager.currentSelectValue}
                options={manager.customerRecord.payPalIdSelect}
                onChange={manager.updateSelect}
                styles={manager.payPalIdSelectStyle}
              />
            </>
          )}
          {!manager.loadingCustomer &&
            manager.customerRecord.businessType === BusinessType.GOVERNMENT &&
            manager.addManagedAccountFlag && (
              <>
                {!manager.governmentSubmissionError && (
                  <div className="mb-3">
                    Please provide the following information to complete PayPal account onboarding:
                  </div>
                )}
                {manager.governmentSubmissionError && (
                  <div className="alert-danger mt-1 p-2 lineBreaks">
                    {manager.governmentSubmissionError}
                  </div>
                )}
                <Government
                  customerRecordParameter={manager.customerRecord}
                  formStatusCallback={manager.governmentFormStatusCallback}
                  errorFormData={manager.governmentErrorForm}
                />
                <div className="col-12 mt-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => manager.submitGovernmentForm(e)}
                    disabled={!manager.governmentFormStatus}>
                    Submit
                  </button>
                </div>
              </>
            )}
          {!manager.loadingCustomer &&
            (manager.customerRecord.businessType === BusinessType.CORPORATION ||
              manager.customerRecord.businessType === BusinessType.PRIVATE_CORPORATION) &&
            manager.addManagedAccountFlag && (
              <>
                {!manager.corporationSubmissionError && (
                  <div className="mb-3">
                    Please provide the following information to complete PayPal account onboarding:
                  </div>
                )}
                {manager.corporationSubmissionError && (
                  <div className="alert-danger mt-1 p-2 lineBreaks">
                    {manager.corporationSubmissionError}
                  </div>
                )}
                <Corporation
                  customerRecordParameter={manager.customerRecord}
                  formStatusCallback={manager.corporationFormStatusCallback}
                  errorFormData={manager.corporationErrorForm}
                />
                <div className="col-12 mt-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => manager.submitCorporationForm(e)}
                    disabled={!manager.corporationFormStatus}>
                    Submit
                  </button>
                </div>
              </>
            )}
          {!manager.loadingCustomer &&
            manager.customerRecord.businessType === BusinessType.NONPROFIT &&
            manager.addManagedAccountFlag && (
              <>
                {!manager.nonprofitSubmissionError && (
                  <div className="mb-3">
                    Please provide the following information to complete PayPal account onboarding:
                  </div>
                )}
                {manager.nonprofitSubmissionError && (
                  <div className="alert-danger mt-1 p-2 lineBreaks">
                    {manager.nonprofitSubmissionError}
                  </div>
                )}
                <Nonprofit
                  customerRecordParameter={manager.customerRecord}
                  formStatusCallback={manager.nonprofitFormStatusCallback}
                  errorFormData={manager.nonprofitErrorForm}
                />
                <div className="col-12 mt-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => manager.submitNonprofitForm(e)}
                    disabled={!manager.nonprofitFormStatus}>
                    Submit
                  </button>
                </div>
              </>
            )}
          {!manager.loadingCustomer &&
            manager.customerRecord.businessType === BusinessType.PROPRIETORSHIP &&
            manager.addManagedAccountFlag && (
              <>
                {!manager.proprietorshipSubmissionError && (
                  <div className="mb-3">
                    Please provide the following information to complete PayPal account onboarding:
                  </div>
                )}
                {manager.proprietorshipSubmissionError && (
                  <div className="alert-danger mt-1 p-2 lineBreaks">
                    {manager.proprietorshipSubmissionError}
                  </div>
                )}
                <Proprietorship
                  customerRecordParameter={manager.customerRecord}
                  formStatusCallback={manager.proprietorshipFormStatusCallback}
                  errorFormData={manager.proprietorshipErrorForm}
                />
                <div className="col-12 mt-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => manager.submitProprietorshipForm(e)}
                    disabled={!manager.proprietorshipFormStatus}>
                    Submit
                  </button>
                </div>
              </>
            )}
          {manager.showProcessView && (
            <>
              <ProcessView
                customerRecordParameter={manager.customerRecord}
                processViewParameters={customerParameters}
                payPalIdSelection={manager.payPalIdSelection}
                payPalIdStatusCallback={manager.processViewStatusCallback}
              />
            </>
          )}
          {!manager.loadingCustomer && manager.customerRecord.businessType === BusinessType.UNSET && (
            <>
              <div>This business type does not support PayPal transactions.</div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PayPal;

PayPal.propTypes = {
  customerParameters: PropTypes.shape({
    brandId: PropTypes.number,
    customerId: PropTypes.number
  })
};
