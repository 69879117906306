import { Spinner } from 'react-bootstrap';
import useLoginForm from './useLoginForm';

export const LoginForm = () => {
  const login = useLoginForm();

  return (
    <>
      <h2>Welcome back!</h2>
      <p>Please sign in.</p>

      {window.location.hash === '#auth-expired' && (
        <div className="alert alert-primary">
          Your authorization request has expired. Please try logging in again.
        </div>
      )}

      <form onSubmit={(e) => login.handleSubmit(e)}>
        <div className="form-group">
          <label htmlFor="userName">User Name</label>
          <input
            type="text"
            disabled={login.loggingIn}
            className="form-control"
            id="userName"
            value={login.username}
            autoFocus={true}
            onChange={(e) => login.updateUsername(e.target.value)}
          />
        </div>
        {login.invalidUser && (
          <div className="alert alert-danger" role="alert">
            Account not found.
          </div>
        )}

        <div className="d-grid gap-2">
          <button
            type="submit"
            className="btn"
            disabled={login.username.trim().length === 0 || login.loggingIn}>
            Log In {login.loggingIn && <Spinner size="sm" animation="border" className="ms-2" />}
          </button>
        </div>
      </form>
    </>
  );
};
