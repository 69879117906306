import { useState, useRef, useEffect } from 'react';
import { useAuthContext } from '../../../../context/provider/AuthContext';
import * as SettingsActions from '../../../../context/reducers/settingsReducer';
import { Api } from '../../../../utils/helpers';
import { availableTiles } from './availableTiles';

const useDashboardTiles = () => {
  const [{ user, settings }, dispatch] = useAuthContext();
  const [tilesVisible, setTilesVisible] = useState(null);
  const [tileLayouts, setTileLayouts] = useState({});
  const lastSavedLayout = useRef(settings.dashboardTileLayout);

  //filter available tiles to only those accessible to the user
  const userTiles = useRef(
    availableTiles.filter((tile) => {
      return (
        (tile.requiredPermissions &&
          tile.requiredPermissions.every((p) => user.permissions.includes(p))) ||
        (tile.requiresAnyInPermissionGroup &&
          Object.values(tile.requiresAnyInPermissionGroup).some((p) =>
            user.permissions.includes(p)
          ))
      );
    })
  );

  //create list for 'manage tile' menu
  const manageTileList = userTiles.current
    .filter((tile) => tile.userCanManage)
    .map((tile) => {
      return {
        id: tile.id,
        name: tile.name
      };
    });

  const isTileVisible = (id) => tilesVisible?.includes(id) ?? false;

  const tileVisibilityChange = (e) => {
    const tileId = e.target.value;
    let tiles = tilesVisible ? [...tilesVisible] : [];
    if (!isTileVisible(tileId)) {
      tiles.push(tileId);
    } else {
      tiles = tiles.filter((id) => id !== tileId);
    }
    setTilesVisible(tiles);
  };

  const syncLayoutDimensions = (layout) => {
    layout.forEach((layoutTile) => {
      const sourceTile = availableTiles.find((t) => t.id === layoutTile.i);
      if (sourceTile) {
        layoutTile.w = sourceTile.width;
        layoutTile.h = sourceTile.height;
      }
    });
    return layout;
  };

  const handleLayoutChange = (layout, layouts) => {
    //handleLayoutChange gets triggered before we have the tiles loaded
    //so ignore layout changes until if they are not loaded yet
    if (!tileLayouts?.md) return;

    //if the tile is hidden then restored, we need to make sure it retains the width/height
    layouts.md = syncLayoutDimensions(layouts.md);
    layouts.sm = syncLayoutDimensions(layouts.sm);
    layouts.xs = syncLayoutDimensions(layouts.xs);

    setTileLayouts(layouts);
  };

  const resetTiles = () => {
    setTilesVisible(userTiles.current.map((tile) => tile.id));
    setTileLayouts(createDefaultLayouts());
  };

  const createDefaultLayouts = () => {
    let mdLayouts = [];
    let smLayouts = [];
    let xsLayouts = [];

    userTiles.current.forEach((tile) => {
      const baseLayout = {
        i: tile.id,
        w: tile.width,
        h: tile.height,
        moved: false,
        static: false
      };

      //add medium layout
      mdLayouts.push({
        ...baseLayout,
        x: tile.defaultLayouts.md.x,
        y: tile.defaultLayouts.md.y
      });

      //add small layout
      smLayouts.push({
        ...baseLayout,
        x: tile.defaultLayouts.sm.x,
        y: tile.defaultLayouts.sm.y
      });

      //add x-small layout
      xsLayouts.push({
        ...baseLayout,
        x: tile.defaultLayouts.xs.x,
        y: tile.defaultLayouts.xs.y
      });
    });

    return {
      md: mdLayouts,
      sm: smLayouts,
      xs: xsLayouts
    };
  };

  useEffect(() => {
    if (!tilesVisible || !tileLayouts) return;

    //if tile visibility or layout changes, automatically save to localstorage
    const tileData = {
      tilesVisible: tilesVisible,
      tileLayouts: tileLayouts
    };

    const layoutToSave = JSON.stringify(tileData);

    if (lastSavedLayout.current !== layoutToSave) {
      lastSavedLayout.current = layoutToSave;
      dispatch(SettingsActions.setDashboardTileLayout(layoutToSave));
      Api.post('/policy/UserSettings/DashboardTile', JSON.stringify(layoutToSave));
    }
  }, [dispatch, tilesVisible, tileLayouts]);

  useEffect(() => {
    if (!userTiles.current) return;

    //initial render - try to load tile data from user settings
    let tileData = null;
    try {
      tileData = JSON.parse(settings.dashboardTileLayout);
    } catch (e) {
      //ignore
    }

    //check for visible tile array
    if (tileData?.tilesVisible && Array.isArray(tileData.tilesVisible)) {
      setTilesVisible(tileData?.tilesVisible);
    } else {
      //nothing stored, default to all visible
      setTilesVisible(userTiles.current.map((tile) => tile.id));
    }

    //check for tile layout array
    if (tileData?.tileLayouts?.md) {
      setTileLayouts(tileData?.tileLayouts);
    } else {
      //nothing stored, create default layout
      setTileLayouts(createDefaultLayouts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    tilesVisible,
    tileLayouts,
    userTiles: userTiles.current,
    manageTileList,
    isTileVisible,
    tileVisibilityChange,
    handleLayoutChange,
    resetTiles
  };
};

export default useDashboardTiles;
