import { useEffect, useState, useCallback } from 'react';
import { useAuthContext } from '../../../../context/provider/AuthContext';
import { AlertStatus, ScreenViewType, ReportResponseType } from '../../../../utils/constants/enums';
import { Api, Helpers } from '../../../../utils/helpers';
import { availableReports } from '../../ReportingPage/ReportManager/availableReports';

const useGeneratedReports = ({ reportId, generatedId, closeCallback }) => {
  const [{ user }] = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState(false);
  const [viewType, setViewType] = useState(ScreenViewType.DEFAULT);
  const [generatedReportId, setGeneratedReportId] = useState(null);
  const [reportDetails, setReportDetails] = useState({});
  const [reportHtml, setReportHtml] = useState('');
  const [message, setMessage] = useState({
    status: AlertStatus.INFO,
    content: ''
  });
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const deleteReport = async () => {
    if (generatedReportId === null) {
      return;
    }
    const apiResponse = await Api.delete(
      `/data/ReportScheduling/GeneratedReports/${generatedReportId}`
    );
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      return;
    }
    closeDeleteDialog();
    await getGeneratedReports();
  };

  const confirmDeleteReport = () => {
    setDeleteDialogVisible(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const handleCloseModal = () => {
    closeCallback();
  };

  const getGeneratedReports = useCallback(async () => {
    if (!reportId) return;

    //reset some things
    setReportHtml('');
    setMessage({
      status: AlertStatus.INFO,
      content: ''
    });
    setLoading(true);

    const apiResponse = await Api.get(`/data/ReportScheduling/GeneratedReports/${reportId}`);
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      setMessage({
        status: AlertStatus.ERROR,
        content: `Unable to load report details (${apiResponse.status.statusCode})`
      });
      return;
    }

    let loadDefaultId = null;

    //load the report based on the url
    if (apiResponse.response.generatedReports.length > 0 && generatedId) {
      const defaultReport = apiResponse.response.generatedReports.find((x) => x.id === generatedId);
      if (defaultReport) {
        loadDefaultId = defaultReport.id;
      }
    }

    //not found in the url, so load the first one
    if (!loadDefaultId && apiResponse.response.generatedReports.length > 0) {
      loadDefaultId = apiResponse.response.generatedReports[0].id;
    }

    if (loadDefaultId) {
      loadReport(loadDefaultId);
    }

    setReportDetails(apiResponse.response);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, generatedId]);

  const loadReport = async (generatedReportId) => {
    if (reportLoading) return;
    setReportLoading(true);
    setGeneratedReportId(generatedReportId);

    const apiResponse = await Api.get(
      `/data/ReportScheduling/GeneratedReport/${generatedReportId}/html`
    );
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      setMessage({
        status: AlertStatus.ERROR,
        content: `Unable to load report details (${apiResponse.status.statusCode})`
      });
      return;
    }

    if (!Helpers.isNullOrWhitespace(apiResponse.response)) {
      setReportHtml(apiResponse.response);
    } else {
      setReportHtml('Empty report was returned.');
    }
    setReportLoading(false);
  };

  const getReportName = (reportType) => {
    return availableReports.find((r) => r.id === reportType)?.name ?? 'Unknown';
  };

  const toggleReportView = () => {
    setViewType(
      viewType === ScreenViewType.DEFAULT ? ScreenViewType.FULLSCREEN : ScreenViewType.DEFAULT
    );
  };

  const getReportDownload = (downloadType) => {
    let pathDownloadType = '';
    switch (downloadType) {
      case ReportResponseType.PDF:
        pathDownloadType = 'pdf';
        break;
      case ReportResponseType.CSV:
        pathDownloadType = 'csv';
        break;
    }

    if (Helpers.isNullOrWhitespace(pathDownloadType)) return;

    const downloadUrl = `${process.env.REACT_APP_BFF_API_URL}/data/ReportScheduling/GeneratedReport/${generatedReportId}/${pathDownloadType}`;
    window.open(downloadUrl, '_blank');
  };

  useEffect(() => {
    const loadData = async () => {
      await getGeneratedReports();
    };
    loadData();
  }, [getGeneratedReports]);

  return {
    isReportOwner: user.userId === reportDetails.userId,
    loading,
    reportLoading,
    generatedReportId,
    viewType,
    message,
    reportDetails,
    reportHtml,
    deleteDialogVisible,
    loadReport,
    handleCloseModal,
    toggleReportView,
    getReportDownload,
    getReportName,
    confirmDeleteReport,
    closeDeleteDialog,
    deleteReport
  };
};

export default useGeneratedReports;
