import { DefaultLayout as Layout } from '../../../layouts';

export const NotFoundPage = () => {
  return (
    <Layout>
      <div className="titleBar">
        <h1 className="pageTitle">Page Not Found</h1>
      </div>
    </Layout>
  );
};
