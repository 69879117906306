import { LoginLayout as Layout } from '../../../layouts';
import { LoginForm } from '../LoginForm/LoginForm';

export const LoginPage = () => {
  return (
    <Layout>
      <LoginForm />
    </Layout>
  );
};
