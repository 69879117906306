import { DefaultLayout as Layout } from '../../../layouts';
import { useOutbound } from '../../../utils/hooks';
import { OutboundGroup } from '../../../utils/constants/enums';
import { TileLink } from '../../../components/ui';

export const FileManagementPage = () => {
  const outbound = useOutbound();

  return (
    <Layout>
      <div className="titleBar">
        <h1 className="pageTitle">Manage My Files</h1>
      </div>
      <div className="d-flex flex-wrap justify-content-lg-start justify-content-evenly">
        <TileLink
          text={'Import Files'}
          target={'/files/file-import'}
          iconClass={'icofont-cloud-upload'}
        />
        <TileLink
          text={'Import Monitor'}
          target={'/files/file-import-monitor'}
          iconClass={'icofont-heart-beat'}
        />
        {outbound.getOutboundLinks(OutboundGroup.FILE_MANAGEMENT).map((out, index) => {
          return (
            <TileLink
              key={index}
              text={out.text}
              target={out.target}
              state={out.state}
              iconClass={out.iconClass}
            />
          );
        })}
      </div>
    </Layout>
  );
};
