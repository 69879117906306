import { isDate, isValid, parseISO, format } from 'date-fns';

import {
  MessageAudienceType,
  NameDisplayOrder,
  MfaNotificationMethod,
  MfaRequirementType,
  PaymentType,
  PaymentConfirmationView,
  DaysOfWeek,
  FrequencyInterval,
  ReportDateRangeType,
  ReportDeliveryType,
  ReportScheduleType,
  RecurrenceType,
  ImportFrequency,
  IdentificationDocumentType
} from '../constants/enums';

export class Helpers {
  static getUtcDate = (date = new Date()) => {
    const utcStr = date.toISOString();
    return new Date(utcStr);
  };

  static isRunningLocal = () => {
    return (
      window.location.hostname === 'localhost' ||
      window.location.hostname === 'localdev.brs-unipay.com'
    );
  };

  static deepCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  static tryParseInt = (value, defaultValue) => {
    let result = defaultValue;
    if (!Helpers.isNullOrWhitespace(value) && !Number.isNaN(parseInt(value))) {
      result = parseInt(value);
    }
    return result;
  };

  static tryParseFloat = (value, defaultValue) => {
    let result = defaultValue;
    if (!Helpers.isNullOrWhitespace(value) && !Number.isNaN(parseFloat(value))) {
      result = parseFloat(value);
    }
    return result;
  };

  static tryParseCurrency = (value, defaultValue) => {
    let result = defaultValue;
    if (!Helpers.isNullOrWhitespace(value) && !Number.isNaN(parseFloat(value))) {
      result = parseFloat(value).toFixed(2);
    }
    return result;
  };

  static tryParseDate = (value, defaultValue) => {
    if (value !== null && value.length > 0) {
      const result = Date.parse(value);
      if (!Number.isNaN(parseInt(result))) {
        return new Date(value);
      }
    }
    return defaultValue;
  };

  static addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  static addMinutes = (date, minutes) => {
    var dateCopy = new Date(date.getTime());
    dateCopy.setMinutes(dateCopy.getMinutes() + minutes);
    return dateCopy;
  };

  static addMonths = (date, months) => {
    var dateCopy = new Date(date.getTime());
    dateCopy.setMonth(dateCopy.getMonth() + months);
    return dateCopy;
  };

  static dateWithoutTime = (date) => {
    date.setHours(0, 0, 0, 0);
    return date;
  };

  static sanitizeFolderName = (name) => {
    return name.replace(/[/\\?%*:|"<>]/g, '');
  };

  static formatCurrency = (num, decimalPlaces = 2) => {
    if (typeof num !== 'number') {
      return num;
    }
    return '$' + num.toFixed(decimalPlaces).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  static formatTime = (seconds) => {
    //returns seconds formatted to HH:MM:SS
    let negativeSign = '';
    if (seconds < 0) {
      seconds *= -1;
      negativeSign = '-';
    }

    // get hours, minutes and seconds
    let hrs = Math.floor(seconds / 3600);
    let mins = Math.floor((seconds % 3600) / 60);
    let secs = Math.floor(seconds % 60);

    // build output
    var ret = negativeSign;
    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }

    if (mins > 0) {
      ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    }

    ret += secs;
    return ret;
  };

  static formatTimeAsText = (seconds) => {
    //returns seconds formatted to X hours X minutes X seconds
    let negativeSign = '';
    if (seconds < 0) {
      seconds *= -1;
      negativeSign = '-';
    }

    // get hours, minutes and seconds
    let hrs = Math.floor(seconds / 3600);
    let mins = Math.floor((seconds % 3600) / 60);
    let secs = Math.floor(seconds % 60);

    // build output
    var ret = negativeSign;
    if (hrs > 0) {
      ret += '' + hrs + ' hours';
    }

    if (mins > 0) {
      ret += ' ' + mins + ` minute${mins === 1 ? '' : 's'}`;
    }

    ret += ' ' + secs + ` second${secs === 1 ? '' : 's'}`;
    return ret;
  };

  static hasFlag = (value, flag) => {
    if (value === 0) return flag === 0;
    return (value & flag) === flag;
  };

  static nameDisplay = (
    lastName,
    firstName,
    username,
    displayOrder = NameDisplayOrder.LASTNAME_FIRST
  ) => {
    if (
      lastName.trim().length === 0 &&
      firstName.trim().length === 0 &&
      username.trim().length === 0
    ) {
      return 'Unnamed';
    }
    if (
      lastName.trim().length === 0 &&
      firstName.trim().length === 0 &&
      username.trim().length > 0
    ) {
      return username;
    }
    if (lastName.trim().length === 0 && firstName.trim().length > 0) {
      return firstName;
    }
    if (lastName.trim().length > 0 && firstName.trim().length === 0) {
      return lastName;
    }
    if (displayOrder === NameDisplayOrder.LASTNAME_FIRST) {
      return `${lastName}, ${firstName}`;
    }
    return `${firstName} ${lastName}`;
  };

  static convertToDate = (value) => {
    if (value === null || typeof value === 'undefined') return null;
    if (isDate(value)) return value;
    if (isValid(parseISO(value))) return parseISO(value);
    if (isValid(new Date(value))) return new Date(value);
    return null;
  };

  static toLongDate = (date, defaultValue = '') => {
    date = Helpers.convertToDate(date);
    if (date) {
      return format(date, 'MMMM d, yyyy');
    } else {
      return defaultValue;
    }
  };

  static toShortDate = (date, defaultValue = '') => {
    date = Helpers.convertToDate(date);
    if (date) {
      return format(date, 'M/d/yyyy');
    } else {
      return defaultValue;
    }
  };

  static toInputFieldDate = (date, defaultValue = '') => {
    date = Helpers.convertToDate(date);
    if (date) {
      return format(date, 'yyyy-MM-dd');
    } else {
      return defaultValue;
    }
  };

  static toShortDateTime = (date, defaultValue = '') => {
    date = Helpers.convertToDate(date);
    if (date) {
      return format(date, 'M/d/yyyy h:mm a');
    } else {
      return defaultValue;
    }
  };

  static toShortTime = (date, defaultValue = '') => {
    date = Helpers.convertToDate(date);
    if (date) {
      return format(date, 'h:mm a');
    } else {
      return defaultValue;
    }
  };

  static to24HourTime = (date, defaultValue = '') => {
    date = Helpers.convertToDate(date);
    if (date) {
      return format(date, 'HH:mm');
    } else {
      return defaultValue;
    }
  };

  static dateRangeDescription = (startDate, endDate) => {
    const formatString = 'M/d/yyyy h:mm aaa';
    startDate = Helpers.convertToDate(startDate);
    endDate = Helpers.convertToDate(endDate);

    if (startDate && endDate) {
      return `${format(startDate, formatString)} - ${format(endDate, formatString)}`;
    } else if (startDate && !endDate) {
      return `From ${format(startDate, formatString)} until forever`;
    } else if (!startDate && endDate) {
      return `Display until ${format(endDate, formatString)}`;
    } else {
      return 'Always';
    }
  };

  static paymentTypeAsText = (paymentType) => {
    switch (paymentType) {
      case PaymentType.CREDIT_CARD:
        return 'Credit Card';
      case PaymentType.ACH:
        return 'ACH';
      case PaymentType.PAYPAL_VENMO:
        return 'PayPal/Venmo';
      default:
        return 'Unknown';
    }
  };

  static mfaNotificationMethodAsText = (mfaNotificationMethod) => {
    switch (mfaNotificationMethod) {
      case MfaNotificationMethod.SMS:
        return 'Text';
      case MfaNotificationMethod.EMAIL:
        return 'Email';
      default:
        return 'Unknown';
    }
  };

  static mfaRequirementAsText = (mfaRequirement) => {
    switch (mfaRequirement) {
      case MfaRequirementType.EVERY_24_HOURS:
        return 'Every 24 Hours';
      case MfaRequirementType.EVERY_LOGIN:
        return 'Every Login';
      default:
        return 'Never';
    }
  };

  static paymentConfirmationViewAsText = (paymentView) => {
    switch (paymentView) {
      case PaymentConfirmationView.VOID:
        return 'Void';
      case PaymentConfirmationView.CHARGEBACK:
        return 'Chargeback';
      default:
        return '';
    }
  };

  static audienceToText = (audience, roles, brandCustomers) => {
    if (!audience || brandCustomers.length === 0) return '';

    if (audience.type === MessageAudienceType.BRAND) {
      //this is a special audience type used for 'all customers' brand filtering
      //no need to show anything
      return '';
    } else if (audience.type === MessageAudienceType.ROLE) {
      const role = roles.find((x) => x.id === audience.value);
      return role?.name ?? 'Unknown role';
    } else if (
      audience.type === MessageAudienceType.BRAND_CUSTOMER ||
      audience.type === MessageAudienceType.BRAND_CUSTOMER_CLASSIFICATION
    ) {
      if (audience.value.indexOf(':') === -1) {
        return 'Invalid value for brand customer';
      }

      let brandName = 'All Brands';
      let custName = 'All Customers';
      const valueSections = audience.value.split(':');

      if (parseInt(valueSections[0]) !== 0) {
        const brand = brandCustomers.find((brand) => brand.id === parseInt(valueSections[0]));
        brandName = brand?.name ?? 'Unknown Brand';

        if (parseInt(valueSections[1]) !== 0) {
          if (audience.type === MessageAudienceType.BRAND_CUSTOMER) {
            const customer = brand.customers.find((cust) => cust.id === parseInt(valueSections[1]));
            custName = customer?.name ?? 'Unknown Customer';
          } else if (audience.type === MessageAudienceType.BRAND_CUSTOMER_CLASSIFICATION) {
            const classification = brand.classifications.find(
              (cust) => cust.id === parseInt(valueSections[1])
            );
            custName = classification?.name ?? 'Unknown Classification';
          }
        }
      }
      return `${brandName} - ${custName}`;
    }
    return `Unknown audience type (${audience.type})`;
  };

  static isValidPhoneNumber = (phoneNumber) => {
    const phoneRegEx = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return phoneRegEx.test(phoneNumber);
  };

  static isValidUnformattedPhoneNumber = (phoneNumber) => {
    const phoneRegEx = /^[0-9]{10}$/;
    return phoneRegEx.test(phoneNumber);
  };

  static isValidEmail = (emailAddress) => {
    const emailRegEx = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    return emailRegEx.test(emailAddress);
  };

  static isValidUnformattedDob = (dateOfBirth) => {
    const dobRegex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    return dobRegex.test(dateOfBirth);
  };

  static isValidIdDocumentNumber = (type, number) => {
    const defaultRegex = /^[0-9]{9}$/;
    const ssnRegex = /^[0-9]{9}$/;
    const itinRegex = /^[0-9]{9}$/;
    const einRegex = /^\d{2}[-]\d{7}$/;
    switch (type) {
      case IdentificationDocumentType.UNSET:
        return defaultRegex.test(number);
      case IdentificationDocumentType.SSN:
        return ssnRegex.test(number);
      case IdentificationDocumentType.ITIN:
        return itinRegex.test(number);
      case IdentificationDocumentType.EIN:
        return einRegex.test(number);
      default:
        return !this.isNullOrWhitespace(number);
    }
  };

  static isValidSoftDescriptor = (value) => {
    const pattern = /^[A-Za-z\d\s-]{2,11}$/;
    return pattern.test(value);
  };

  static stringToArray = (value) => {
    if (Array.isArray(value)) {
      return value;
    }
    if (typeof value === 'string' && value.length > 0) {
      if (value.indexOf(',') !== -1) {
        return value.split(',');
      }
      return [value];
    }
    return [];
  };

  static sleep = async (ms) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  };

  static isNullOrWhitespace = (str) => {
    return str === null || typeof str === 'undefined' || str.toString().trim() === '';
  };

  static emptyToNull = (value) => {
    if (this.isNullOrWhitespace(value)) return null;
    return value;
  };

  static round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };

  static getExtension = (filename) => {
    return `.${filename.split('.').pop()}`;
  };

  static toFileSizeText = (bytes, decimalPlaces = 1) => {
    if (Math.abs(bytes) < 1024) {
      return bytes + ' B';
    }

    const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10 ** decimalPlaces;

    do {
      bytes /= 1024;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= 1024 && u < units.length - 1);

    return bytes.toFixed(decimalPlaces) + ' ' + units[u];
  };

  static getMonths = () => {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        value: i + 1,
        name: new Date(0, i).toLocaleString('en-US', { month: 'long' })
      };
    });
  };

  static getDaysOfTheWeek = (weekdayOnly = false) => {
    let days = [];
    for (const day in DaysOfWeek) {
      if (
        DaysOfWeek[day] > 0 &&
        (!weekdayOnly || (weekdayOnly && DaysOfWeek[day] > 1 && DaysOfWeek[day] < 64))
      ) {
        days.push({
          name: day.charAt(0) + day.slice(1).toLowerCase(),
          shortName: day.charAt(0) + day.slice(1, 3).toLowerCase(),
          value: DaysOfWeek[day]
        });
      }
    }
    return days;
  };

  static getDayOfWeekAsText = (value, shortName = false) => {
    for (const day in DaysOfWeek) {
      if (value === DaysOfWeek[day]) {
        if (shortName) {
          return day.charAt(0) + day.slice(1, 3).toLowerCase();
        } else {
          return day.charAt(0) + day.slice(1).toLowerCase();
        }
      }
    }
    return 'Unknown';
  };

  static dayOfWeekFlagToText = (dayValue) => {
    if (dayValue === 0) return 'None';
    if (dayValue === 127) return 'every day';
    if (dayValue === 62) return 'every weekday';

    let textValues = [];
    for (const day in DaysOfWeek) {
      if (DaysOfWeek[day] > 0 && this.hasFlag(dayValue, DaysOfWeek[day])) {
        textValues.push(day.charAt(0) + day.slice(1).toLowerCase());
      }
    }

    if (textValues.length === 1) {
      return textValues[0];
    }
    const oxfordComma = textValues.length > 2 ? ', ' : '';
    const last = textValues.pop();
    return `${textValues.join(', ')}${oxfordComma} and ${last}`;
  };

  static getFrequencyIntervals = () => {
    let intervals = [];
    for (const interval in FrequencyInterval) {
      intervals.push({
        name: interval.charAt(0) + interval.slice(1).toLowerCase(),
        value: FrequencyInterval[interval]
      });
    }
    return intervals;
  };

  static getFrequencyIntervalAsText = (value) => {
    for (const interval in FrequencyInterval) {
      if (value === FrequencyInterval[interval]) {
        return interval.charAt(0) + interval.slice(1).toLowerCase();
      }
    }
    return 'Unknown';
  };

  static getScheduledReportDateRangeAsText = (type) => {
    switch (type) {
      case ReportDateRangeType.TODAY:
        return 'Today';
      case ReportDateRangeType.YESTERDAY:
        return 'Previous Day';
      case ReportDateRangeType.PREVIOUS_3_DAYS:
        return 'Previous 3 Days';
      case ReportDateRangeType.PREVIOUS_7_DAYS:
        return 'Previous 7 Days';
      case ReportDateRangeType.PREVIOUS_MONTH:
        return 'Previous Month';
      case ReportDateRangeType.PREVIOUS_QUARTER:
        return 'Previous Quarter';
      case ReportDateRangeType.PREVIOUS_YEAR:
        return 'Previous Year';
      case ReportDateRangeType.CUSTOM:
        return 'Custom';
      case ReportDateRangeType.BUSINESS_DAILY:
        return 'Business Daily';
      case ReportDateRangeType.WEEKLY:
        return 'Weekly';
      case ReportDateRangeType.PREVIOUS_FISCAL_YEAR:
        return 'Previous Fiscal Year';
      default:
        return 'Unknown';
    }
  };

  static getReportDateRangeAsText = (startDateUtc, endDateUtc) => {
    let dateRangeText = '';

    if (this.isNullOrWhitespace(startDateUtc)) {
      dateRangeText = 'Now';
    } else {
      dateRangeText = this.toShortDate(startDateUtc);
    }

    dateRangeText += ' until ';

    if (this.isNullOrWhitespace(endDateUtc)) {
      dateRangeText += 'forever';
    } else {
      dateRangeText += this.toShortDate(endDateUtc);
    }

    return dateRangeText;
  };

  static getReportDeliveryTypeAsText = (deliveryType) => {
    switch (deliveryType) {
      case ReportDeliveryType.NONE:
        return "Don't notify me, I will login to view my generated reports.";
      case ReportDeliveryType.EMAIL_NOTIFICATION:
        return 'Send an email when the report is ready.';
      case ReportDeliveryType.EMAIL_ATTACHMENT:
        return 'Send an email with the report attached.';
      default:
        return 'Unknown';
    }
  };

  static getRecurringScheduleAsText = (reportConfig) => {
    let reportRange = this.getScheduledReportDateRangeAsText(
      reportConfig.reportParameters.dateRangeType
    );
    let recurringDescription = '';

    const frequencyIntervalText = this.getFrequencyIntervalAsText(
      reportConfig.recurrenceParameters.frequencyInterval
    ).toLowerCase();
    const daysOfWeekText = this.dayOfWeekFlagToText(reportConfig.recurrenceParameters.daysOfWeek);
    const dayOfWeekText = this.getDayOfWeekAsText(reportConfig.recurrenceParameters.dayOfWeek);
    const monthNameText = new Date(
      0,
      reportConfig.recurrenceParameters.monthNumber - 1
    ).toLocaleString('en-US', { month: 'long' });

    if (reportConfig.scheduleType === ReportScheduleType.ONE_TIME) {
      recurringDescription = `one time on ${this.toShortDate(reportConfig.sendDateUtc)}`;
    } else if (reportConfig.scheduleType === ReportScheduleType.RECURRING) {
      switch (reportConfig.reportParameters.dateRangeType) {
        case ReportDateRangeType.YESTERDAY:
        case ReportDateRangeType.BUSINESS_DAILY:
          recurringDescription = `on ${daysOfWeekText}`;
          break;
        case ReportDateRangeType.PREVIOUS_7_DAYS:
        case ReportDateRangeType.WEEKLY:
          recurringDescription = `every week on ${dayOfWeekText}`;
          break;
        case ReportDateRangeType.PREVIOUS_MONTH:
        case ReportDateRangeType.PREVIOUS_QUARTER:
          {
            const timespanLabel =
              reportConfig.reportParameters.dateRangeType === ReportDateRangeType.PREVIOUS_MONTH
                ? 'every month'
                : 'each quarter';
            if (reportConfig.recurrenceParameters.recurrenceType === RecurrenceType.SPECIFIC_DAY) {
              recurringDescription = `on day ${reportConfig.recurrenceParameters.dayNumber} of ${timespanLabel}`;
            } else {
              recurringDescription = `on the ${frequencyIntervalText} ${dayOfWeekText} of ${timespanLabel}`;
            }
          }
          break;
        case ReportDateRangeType.PREVIOUS_YEAR:
        case ReportDateRangeType.PREVIOUS_FISCAL_YEAR:
          if (reportConfig.recurrenceParameters.recurrenceType === RecurrenceType.SPECIFIC_DAY) {
            recurringDescription = `on ${monthNameText} ${reportConfig.recurrenceParameters.dayNumber}`;
          } else {
            recurringDescription = `on the ${frequencyIntervalText} ${dayOfWeekText} of ${monthNameText}`;
          }
          break;
      }
    }

    const dateRangeText = this.getReportDateRangeAsText(
      reportConfig.startDateUtc ?? reportConfig.recurrenceParameters.startDateUtc,
      reportConfig.endDateUtc ?? reportConfig.recurrenceParameters.endDateUtc
    ).toLowerCase();

    let output = `Run the '${reportRange}' report ${recurringDescription}`;
    if (reportConfig.scheduleType === ReportScheduleType.RECURRING) {
      output += ` from ${dateRangeText}`;
    }
    return `${output}.`;
  };

  static minimumDateAsNotSet = (date) => {
    let format = date;
    if (format === '1900-01-01T00:00:00' || format == '0001-01-01T00:00:00') format = 'Not Set';
    return format;
  };

  static getImportFrequencyAsText = (freqType) => {
    switch (freqType) {
      case ImportFrequency.DAILY:
        return 'Daily';
      case ImportFrequency.WEEKLY:
        return 'Weekly';
      case ImportFrequency.MONTHLY:
        return 'Monthly';
      case ImportFrequency.BUSINESS_DAILY:
        return 'Business Daily';
      case ImportFrequency.BUSINESS_WEEKLY:
        return 'Business Weekly';
      case ImportFrequency.BUSINESS_MONTHLY:
        return 'Business Monthly';
      default:
        return 'Unknown';
    }
  };

  static getSiteCode = (brandId) => {
    switch (brandId) {
      case 1:
        return 'UPG';
      case 2:
        return 'UPD';
      case 5:
        return 'UNB';
      case 6:
        return 'FFB';
      default:
        return '';
    }
  };
}
