import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './TileLink.module.css';

export const TileLink = ({ target, iconClass, fontAwesomeIcon, text, state }) => {
  return (
    <Link
      to={target}
      state={state}
      className={
        'd-inline-flex flex-column justify-content-center px-3 py-2 m-2 ' + styles.tileLink
      }>
      {iconClass && (
        <div className={styles.tileIcon}>
          <i className={iconClass}></i>
        </div>
      )}
      {!iconClass && fontAwesomeIcon && (
        <div className={styles.tileIcon}>
          <FontAwesomeIcon className="mb-2" icon={fontAwesomeIcon} />
        </div>
      )}
      <div className={styles.tileText}>{text}</div>
    </Link>
  );
};

export default TileLink;

TileLink.propTypes = {
  target: PropTypes.string.isRequired,
  iconClass: PropTypes.string,
  fontAwesomeIcon: PropTypes.object,
  text: PropTypes.string,
  state: PropTypes.object
};
