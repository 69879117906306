import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../../../../context/provider/AuthContext';
import { Permissions } from '../../../../../../utils/constants/permissions';
import { availableReports } from '../../../../../Reporting/ReportingPage/ReportManager/availableReports';

export const ReportTile = () => {
  const [{ user }] = useAuthContext();
  const reportPermissions = Object.keys(Permissions.Reports).map((key) => Permissions.Reports[key]);
  const hasAnyReport = user.permissions.some((p) => reportPermissions.includes(p));

  const hasAllCustomerAccess =
    user.resources.brands
      .find((brand) => brand.id === user.activeBrand.id)
      ?.customers.some((x) => x.id === 0) ?? false;

  if (!hasAnyReport) return null;

  const allowedReports = availableReports.filter((report) => {
    const hasRequiredPermission = report.requiredPermissions.every((p) =>
      user.permissions.includes(p)
    );
    const hasRequiredCustomerAccess =
      !hasAllCustomerAccess || (hasAllCustomerAccess && report.accessibleToAllCustomerUser);

    return hasRequiredPermission && hasRequiredCustomerAccess;
  });

  return allowedReports.map((report) => {
    return (
      <div key={report.id}>
        <Link to={`/reporting?report=${report.id}`}>{report.name}</Link>
      </div>
    );
  });
};

export default ReportTile;
