import { useState } from 'react';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import { Helpers } from '../../../../../../../utils/helpers/Helpers';
import { BusinessType } from '../../../../../../../utils/constants/enums';

const usePrimaryAuthorizedUser = (containerFormData, formStatusCallback) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    primaryAuthorizedUserGivenName: containerFormData.primaryAuthorizedUserGivenName,
    primaryAuthorizedUserSurname: containerFormData.primaryAuthorizedUserSurname,
    primaryAuthorizedUserEmail: containerFormData.primaryAuthorizedUserEmail,
    primaryAuthorizedUserBirthDetailDOB: containerFormData.primaryAuthorizedUserBirthDetailDOB,
    primaryAuthorizedUserAddressLine1: containerFormData.primaryAuthorizedUserAddressLine1,
    primaryAuthorizedUserAddressLine2: containerFormData.primaryAuthorizedUserAddressLine2,
    primaryAuthorizedUserAdminArea2: containerFormData.primaryAuthorizedUserAdminArea2,
    primaryAuthorizedUserAdminArea1: containerFormData.primaryAuthorizedUserAdminArea1,
    primaryAuthorizedUserPostalCode: containerFormData.primaryAuthorizedUserPostalCode,
    primaryAuthorizedUserPhoneNumber: containerFormData.primaryAuthorizedUserPhoneNumber,
    primaryAuthorizedUserPhoneType: containerFormData.primaryAuthorizedUserPhoneType,
    primaryAuthorizedUserOccupation: containerFormData.primaryAuthorizedUserOccupation,
    primaryAuthorizedUserIdentificationDocumentType:
      containerFormData.primaryAuthorizedUserIdentificationDocumentType,
    primaryAuthorizedUserIdentificationDocumentNumber:
      containerFormData.primaryAuthorizedUserIdentificationDocumentNumber
  });
  const isProprietorship = containerFormData.businessType === BusinessType.PROPRIETORSHIP;

  const isValidField = (name, value) => {
    //place any custom field validation logic in here
    if (isProprietorship) {
      switch (name) {
        case 'primaryAuthorizedUserEmail':
          return Helpers.isValidEmail(value);
        case 'primaryAuthorizedUserBirthDetailDOB':
          return Helpers.isValidUnformattedDob(value);
        case 'primaryAuthorizedUserOccupation':
          return true; //optional field
        case 'primaryAuthorizedUserAddressLine2':
          return true; //optional field
        case 'primaryAuthorizedUserPhoneNumber':
          return Helpers.isValidUnformattedPhoneNumber(value);
        case 'primaryAuthorizedUserIdentificationDocumentNumber':
          return Helpers.isValidIdDocumentNumber(
            Helpers.isNullOrWhitespace(formData.primaryAuthorizedUserIdentificationDocumentType)
              ? 'UNSET'
              : formData.primaryAuthorizedUserIdentificationDocumentType,
            value
          );
        default:
          return !Helpers.isNullOrWhitespace(value);
      }
    } else {
      switch (name) {
        case 'primaryAuthorizedUserEmail':
          return true; //optional field
        case 'primaryAuthorizedUserBirthDetailDOB':
          return Helpers.isValidUnformattedDob(value);
        case 'primaryAuthorizedUserAddressLine1':
          return true; //optional field
        case 'primaryAuthorizedUserAddressLine2':
          return true; //optional field
        case 'primaryAuthorizedUserAdminArea2':
          return true; //optional field
        case 'primaryAuthorizedUserAdminArea1':
          return true; //optional field
        case 'primaryAuthorizedUserPostalCode':
          return true; //optional field
        case 'primaryAuthorizedUserPhoneNumber':
          return true; //optional field
        case 'primaryAuthorizedUserPhoneType':
          return true; //optional field
        case 'primaryAuthorizedUserIdentificationDocumentType':
          return true; //optional field
        case 'primaryAuthorizedUserIdentificationDocumentNumber':
          return true; //optional field
        default:
          return !Helpers.isNullOrWhitespace(value);
      }
    }
  };

  const isMissingValues = (fields) => {
    return Object.entries(fields).some(([key, value]) => {
      return !isValidField(key, value);
    });
  };

  const [componentStatus, setComponentStatus] = useState(
    isMissingValues(formData) ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
  );

  const updateValue = ({ name, value }) => {
    const fields = { ...formData };
    fields[name] = value;
    setFormData(fields);

    setErrors((err) => {
      return { ...err, [name]: !isValidField(name, value) };
    });

    const missingValues = isMissingValues(fields);
    setComponentStatus(
      missingValues ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
    );
    formStatusCallback('primaryAuthorizedUser', !missingValues, fields);
  };

  return {
    componentStatus,
    formData,
    isProprietorship,
    errors,
    updateValue
  };
};

export default usePrimaryAuthorizedUser;
