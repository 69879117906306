import React, { useEffect, useState, useCallback } from 'react';
import { Api, Helpers } from '../../../utils/helpers';
import { useParams, useNavigate } from 'react-router-dom';

export const ViewMessage = () => {
  const navigate = useNavigate();
  const { messageId } = useParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);

  const getMessage = useCallback(async (messageId) => {
    setLoading(true);
    const apiResponse = await Api.get(`/policy/Messages/${messageId}`);
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }
    setMessage(apiResponse.response);
    setLoading(false);
  }, []);

  useEffect(() => {
    getMessage(messageId);
  }, [messageId, getMessage]);

  if (loading) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <button onClick={() => navigate(-1)} className="btn btn-primary d-inline-block me-2 mb-3">
        <i className="icofont-arrow-left"></i> Back
      </button>
      <h1>{message.title}</h1>
      <div>
        <em>{Helpers.toShortDate(message.startDateUtc ?? message.dateCreatedUtc)}</em>
      </div>
      <div dangerouslySetInnerHTML={{ __html: message.body }}></div>
    </div>
  );
};
