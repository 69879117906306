import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import { AlertMessage, HelpTip, ModalDialog } from '../../../../components/ui';
import useSftpAccounts from './useSftpAccounts';
import styles from './SftpAccounts.module.css';

export const SftpAccounts = ({ customerParameters }) => {
  const manager = useSftpAccounts({ customerParameters });

  return (
    <>
      <ModalDialog
        showDialog={manager.deleteDialogVisible}
        heading="Delete SFTP Account"
        message="All services using this account will no longer be able to transfer files. Are you sure you want to delete this SFTP account?"
        okCallback={manager.deleteAccount}
        okButtonText="Yes, delete this account."
        cancelCallback={manager.closeDeleteDialog}
        cancelButtonText="No, don't delete."
      />
      <ModalDialog
        showDialog={manager.resetDialogVisible}
        heading="Reset SFTP Password"
        message="All services using this account will no longer be able to transfer files using the existing password. Are you sure you want to reset the password?"
        okCallback={manager.resetPassword}
        okButtonText="Yes, reset the password."
        cancelCallback={manager.closeResetDialog}
        cancelButtonText="No, don't reset."
      />
      <h2 className={styles.sftpConfigHeading}>SFTP Account</h2>
      {manager.loading && (
        <div className="m-2 text-center">
          <Spinner animation="border" />
        </div>
      )}
      {!manager.loading && !manager.sftpAccount && (
        <>
          <div className={styles.descriptionMessage}>
            SFTP accounts can be used to transfer files for import processing.
          </div>
          <div className={styles.noAccountMessage}>
            <strong>
              An account has not yet been set up for this customer. You can set up a new account
              below.
            </strong>
          </div>
          <AlertMessage
            className="rounded-0"
            visible={manager.message.content.trim() !== ''}
            message={manager.message.content}
            status={manager.message.status}
          />
          <div className="form-group mb-2">
            <label htmlFor="userName" className="col-form-label">
              Username
            </label>
            <input
              type="text"
              className="form-control"
              id="userName"
              placeholder="username"
              value={manager.userName}
              maxLength={64}
              onChange={(e) => manager.filterUserName(e.target.value)}
            />
            {manager.errors.userName && (
              <div className="alert-danger mt-1 p-2">
                Username is required and must be at least 3 characters.
              </div>
            )}
          </div>
          <HelpTip className="mt-2 mb-3">
            Username can only contain lowercase letters and numbers and must be at least 3
            characters.
          </HelpTip>
          <div className="form-group mb-2">
            <button className="btn btn-primary" onClick={manager.createSftpAccount}>
              Create SFTP Account
            </button>
          </div>
        </>
      )}
      {!manager.loading && manager.sftpAccount && (
        <>
          <div className={styles.descriptionMessage}>
            Your SFTP account information can be found below. You can use this account to transfer
            files for import processing.
          </div>
          <div className={styles.userContainer}>
            <div>
              <label>Username</label>
              <div className={styles.usernameDisplay}>{manager.sftpAccount.userName}</div>
            </div>
            <div>
              <label>Password</label>
              {manager.newPassword && (
                <div className={styles.passwordDisplay}>{manager.newPassword}</div>
              )}
              {!manager.newPassword && (
                <>
                  <div className={styles.fakePasswordDisplay}>***********</div>
                  <div>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={manager.confirmResetPassword}>
                      Reset Password
                    </button>
                  </div>
                </>
              )}
            </div>
            <div>
              <label>Server</label>
              <div className={styles.serverDisplay}>{manager.sftpAccount.host}</div>
            </div>
            <div>
              <label>Port</label>
              <div className={styles.serverDisplay}>{manager.sftpAccount.port}</div>
            </div>
          </div>
          {manager.newPassword && (
            <div className="alert alert-success mt-3">
              Your new password has been generated and will only be displayed once. Please copy your
              password to a secure location for future reference. If you lose this password, you
              will need to reset your password.
            </div>
          )}
          <div className={styles.deleteBtnContainer}>
            If you no longer require SFTP access to this customer, you may
            <button className="btn btn-danger btn-sm ms-2" onClick={manager.confirmDeleteAccount}>
              Delete This Account
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default SftpAccounts;

SftpAccounts.propTypes = {
  customerParameters: PropTypes.shape({
    brandId: PropTypes.number,
    customerId: PropTypes.number
  })
};
