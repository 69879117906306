import { useState, useRef, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAuthContext } from '../../../../context/provider/AuthContext';
import { Api, Helpers } from '../../../../utils/helpers';
import { availableReports } from '../../ReportingPage/ReportManager/availableReports';
import {
  AlertStatus,
  SortDirection,
  ReportType,
  ReportScheduleType,
  DaysOfWeek,
  RecurrenceType,
  FrequencyInterval
} from '../../../../utils/constants/enums';
import styles from './ScheduledReportManager.module.css';

const useScheduledReportManager = () => {
  const [{ user }] = useAuthContext();
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    status: AlertStatus.INFO,
    content: ''
  });
  const [loading, setLoading] = useState(false);
  const [showModalProgressBar, setShowModalProgressBar] = useState(false);
  const [pagedResult, setPagedResult] = useState(null);
  const [showMoreReports, setShowMoreReports] = useState([]);

  const defaultSearchParameters = {
    keywords: '',
    sortDirection: SortDirection.ASCENDING,
    sortField: 'reportname',
    pageSize: 20,
    page: 1,
    filters: { brandId: user.activeBrand.id.toString() },
    timestamp: null
  };

  const daysOfWeekDefault =
    DaysOfWeek.MONDAY |
    DaysOfWeek.TUESDAY |
    DaysOfWeek.WEDNESDAY |
    DaysOfWeek.THURSDAY |
    DaysOfWeek.FRIDAY;

  const defaultScheduleConfig = {
    id: null,
    name: '',
    customerName: '',
    reportParameters: {
      brandId: user.activeBrand.id,
      reportType: ReportType.UNKNOWN,
      dateRangeType: null,
      customerId: null,
      transactionIds: [],
      allTransactions: false,
      includeDeletedTransactions: false
    },
    deliveryType: null,
    deliveryEmail: user.emailAddress,
    scheduleType: ReportScheduleType.ONE_TIME,
    recurrenceParameters: {
      daysOfWeek: daysOfWeekDefault,
      dayOfWeek: DaysOfWeek.MONDAY,
      dayNumber: 1,
      recurrenceType: RecurrenceType.SPECIFIC_DAY,
      frequencyInterval: FrequencyInterval.FIRST,
      monthNumber: 1,
      startDateUtc: null,
      endDateUtc: null
    },
    sendDateUtc: Helpers.addDays(new Date(), 1),
    showModal: false
  };

  const [searchParameters, setSearchParameters] = useState(defaultSearchParameters);
  const deleteReportId = useRef(null);
  const [scheduleConfig, setScheduleConfig] = useState(defaultScheduleConfig);
  const [searchFilters, setSearchFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({
    customers: [],
    reportTypes: [],
    statuses: []
  });
  const [viewGeneratedReportId, setViewGeneratedReportId] = useState(null);

  const getReportName = (reportType) => {
    return availableReports.find((r) => r.id === reportType)?.name ?? 'Unknown';
  };

  const getCustomerNameById = (customerId) => {
    const customerName =
      user.userResources.brands
        .find((brand) => brand.id === user.activeBrand.id)
        ?.customers.find((c) => c.id === customerId)?.name ?? 'None assigned';
    return customerName;
  };

  const getTransactionNamesByIds = (customerId, transactionIds) => {
    const txNames = user.userResources.brands
      .find((brand) => brand.id === user.activeBrand.id)
      ?.customers.find((c) => c.id === customerId)
      ?.transactions.filter((tx) => transactionIds.includes(tx.id))
      .map((tx) => tx.name);
    if (!txNames) return [];
    return txNames;
  };

  const isShowingMore = (reportId) => {
    return showMoreReports.includes(reportId);
  };

  const showMoreTransactionsToggle = (reportId) => {
    let showMoreItems = [...showMoreReports];
    if (showMoreItems.includes(reportId)) {
      showMoreItems = showMoreItems.filter((id) => id !== reportId);
    } else {
      showMoreItems.push(reportId);
    }
    setShowMoreReports(showMoreItems);
  };

  const getTransactionListDisplay = (report) => {
    const showMoreTransactionLimit = 3;
    const transactionNames = getTransactionNamesByIds(
      report.reportParameters.customerId,
      report.reportParameters.transactionIds
    );
    const totalTransactions = transactionNames.length;
    const showingMore = isShowingMore(report.id);
    const moreCount = totalTransactions - showMoreTransactionLimit;

    const allTx = report.reportParameters.allTransactions;
    const includeDeleted = report.reportParameters.includeDeletedTransactions;

    if (allTx) {
      return (
        <div className={styles.transactionName}>
          All transactions, {includeDeleted ? 'including' : 'excluding '} deleted.
        </div>
      );
    }

    return (
      <>
        <div>
          {transactionNames.map((txName, index) => {
            let txClass = styles.transactionName;
            if (index >= showMoreTransactionLimit && !showingMore) {
              txClass = styles.hideTransactionName;
            }

            return (
              <div key={index} className={txClass}>
                {txName}
              </div>
            );
          })}
        </div>
        {moreCount > 0 && (
          <>
            <a
              href="#"
              className={styles.showMoreToggleLink}
              onClick={(e) => {
                e.preventDefault();
                showMoreTransactionsToggle(report.id);
              }}>
              {!showingMore && (
                <>
                  <FontAwesomeIcon size="sm" className="me-1" icon={faChevronDown} />
                  Show {moreCount} more
                </>
              )}
              {showingMore && (
                <>
                  <FontAwesomeIcon size="sm" className="me-1" icon={faChevronUp} />
                  Hide
                </>
              )}
            </a>
          </>
        )}
      </>
    );
  };

  const getSearchFilters = useCallback(async () => {
    const apiResponse = await Api.get(
      `/data/ReportScheduling/SearchFilters/${user.activeBrand.id}`
    );
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      return;
    }

    let customerFilters = apiResponse.response.customerIds.map((customerId) => {
      return {
        id: customerId,
        name: getCustomerNameById(customerId)
      };
    });
    let reportTypeFilters = apiResponse.response.reportTypes.map((reportTypeId) => {
      return {
        id: reportTypeId,
        name: availableReports.find((r) => r.id === reportTypeId)?.name ?? 'Unknown'
      };
    });

    let filters = {
      customers: customerFilters.sort((a, b) => (a.name === b.name ? 0 : a.name > b.name ? 1 : -1)),
      reportTypes: reportTypeFilters.sort((a, b) =>
        a.name === b.name ? 0 : a.name > b.name ? 1 : -1
      )
    };

    setSearchFilters(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.activeBrand.id]);

  useEffect(() => {
    getSearchFilters();
  }, [user.activeBrand.id, getSearchFilters]);

  const updateFilter = (key, value) => {
    let filters = { ...selectedFilters };
    if (filters[key].includes(value)) {
      filters[key] = filters[key].filter((x) => x !== value);
    } else {
      filters[key].push(value);
    }
    setSelectedFilters(filters);

    let params = { ...searchParameters };
    params.filters[key] = JSON.stringify(filters[key]);
    params.page = 1; //reset page if filtered
    setSearchParameters(params);
  };

  const isFilterChecked = (key, value) => selectedFilters[key].includes(value);

  const resetModalState = () => {
    //reset any modal errors
    setModalMessage({
      status: AlertStatus.INFO,
      content: ''
    });
    setShowModalProgressBar(false);
  };

  const addNewScheduledReport = () => {
    resetModalState();
    //clear all values and set defaults for new schedule
    let config = { ...defaultScheduleConfig };
    config.showModal = true;
    setScheduleConfig(config);
  };

  const editScheduledReport = (id) => {
    resetModalState();

    const itemToEdit = pagedResult?.results?.find((x) => x.id === id);
    if (!itemToEdit) return;

    let config = {
      id: itemToEdit.id,
      name: itemToEdit.name ?? '',
      active: itemToEdit.active ?? false,
      reportParameters: {
        brandId: user.activeBrand.id,
        reportType: itemToEdit.reportParameters.reportType ?? ReportType.UNKNOWN,
        dateRangeType: itemToEdit.reportParameters.dateRangeType,
        customerId: itemToEdit.reportParameters.customerId,
        transactionIds: itemToEdit.reportParameters.transactionIds,
        allTransactions: itemToEdit.reportParameters.allTransactions ?? false,
        includeDeletedTransactions: itemToEdit.reportParameters.includeDeletedTransactions ?? false
      },
      deliveryType: itemToEdit.deliveryType,
      deliveryEmail: itemToEdit.deliveryEmail,
      scheduleType: itemToEdit.scheduleType ?? ReportScheduleType.ONE_TIME,
      recurrenceParameters: {
        daysOfWeek: itemToEdit.recurrenceParameters.daysOfWeek ?? 62,
        dayOfWeek: itemToEdit.recurrenceParameters.dayOfWeek ?? daysOfWeekDefault,
        dayNumber: itemToEdit.recurrenceParameters.dayNumber ?? 1,
        recurrenceType:
          itemToEdit.recurrenceParameters.recurrenceType ?? RecurrenceType.SPECIFIC_DAY,
        frequencyInterval:
          itemToEdit.recurrenceParameters.frequencyInterval ?? FrequencyInterval.FIRST,
        monthNumber: itemToEdit.recurrenceParameters.monthNumber ?? 1,
        startDateUtc: itemToEdit.startDateUtc,
        endDateUtc: itemToEdit.endDateUtc
      },
      sendDateUtc: itemToEdit.sendDateUtc,
      showModal: true
    };
    setScheduleConfig(config);
  };

  const modalSaveHandler = async (configValue) => {
    setShowModalProgressBar(true);
    configValue.brandId = user.activeBrand.id;

    configValue.recurrenceParameters.sendDateUtc = Helpers.emptyToNull(
      configValue.recurrenceParameters.sendDateUtc
    );
    configValue.startDateUtc = Helpers.emptyToNull(configValue.recurrenceParameters.startDateUtc);
    configValue.endDateUtc = Helpers.emptyToNull(configValue.recurrenceParameters.endDateUtc);
    configValue.customerName = getCustomerNameById(configValue.reportParameters.customerId);

    const apiResponse = await Api.post(
      `/data/ReportScheduling/Reports`,
      JSON.stringify(configValue)
    );
    if (apiResponse.status.statusCode !== 200) {
      setModalMessage({
        status: AlertStatus.ERROR,
        content: `An error occurred while saving your report. (${apiResponse.status.statusCode})`
      });
      setShowModalProgressBar(false);
      return false;
    }

    setShowModalProgressBar(false);
    reloadSearch();
    getSearchFilters();
    return true;
  };

  const hasFilters = () => {
    return searchFilters.customers?.length > 1 || searchFilters.reportTypes?.length > 1;
  };

  const togglePosting = useRef(false);
  const toggleActive = async (reportId) => {
    if (togglePosting.current) return;
    togglePosting.current = true;

    let reportResults = Helpers.deepCopy(pagedResult);
    let itemToUpdate = reportResults?.results?.find((x) => x.id === reportId);
    if (!itemToUpdate) return;

    itemToUpdate.active = !itemToUpdate.active;

    const apiResponse = await Api.post(
      `/data/ReportScheduling/ReportStatus/${reportId}`,
      JSON.stringify(itemToUpdate.active)
    );
    if (apiResponse.status.statusCode !== 200 || !apiResponse.response) {
      console.error('api error occurred');
      togglePosting.current = false;
      return;
    }
    togglePosting.current = false;
    setPagedResult(reportResults);
  };

  const handleReportDelete = async (id) => {
    deleteReportId.current = id;
    setDeleteDialogVisible(true);
  };

  const closeDeleteDialog = () => {
    deleteReportId.current = null;
    setDeleteDialogVisible(false);
  };

  const deleteReport = async () => {
    const apiResponse = await Api.delete(
      `/data/ReportScheduling/Reports/${deleteReportId.current}`
    );
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      return;
    }
    closeDeleteDialog();
    closeReportConfigModal();
    reloadSearch();
  };

  const closeReportConfigModal = () => {
    let config = { ...scheduleConfig };
    config.id = null;
    config.showModal = false;
    setScheduleConfig(config);
  };

  const viewGeneratedReports = (reportId) => {
    setViewGeneratedReportId(reportId);
  };

  const closeGeneratedReportsModal = () => {
    setViewGeneratedReportId(null);
  };

  const debouncedKeywords = useRef(
    debounce(async (value) => {
      let params = { ...searchParameters };
      params.page = 1; //reset page if filtered by keyword
      params.keywords = value;
      params.timestamp = Date.now();
      setSearchParameters(params);
    }, 500)
  ).current;

  useEffect(() => {
    return () => debouncedKeywords.cancel();
  }, [debouncedKeywords]);

  const updateKeywords = (value) => {
    debouncedKeywords(value);
  };

  const updateSorting = (field, direction) => {
    let params = { ...searchParameters };
    params.sortDirection = direction;
    params.sortField = field;
    params.timestamp = Date.now();
    setSearchParameters(params);
  };

  const changePage = (page) => {
    let params = { ...searchParameters };
    params.page = page;
    params.timestamp = Date.now();
    setSearchParameters(params);
  };

  const search = useCallback(async () => {
    setLoading(true);
    const apiResponse = await Api.post(
      `/data/ReportScheduling/Search`,
      JSON.stringify(searchParameters)
    );
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }
    setPagedResult(apiResponse.response);
    setLoading(false);
  }, [searchParameters]);

  const reloadSearch = () => {
    let searchParams = { ...searchParameters };
    searchParams.timestamp = Date.now();
    setSearchParameters(searchParams);
  };

  useEffect(() => {
    if (searchParameters.timestamp === null) return;
    search();
  }, [search, searchParameters.timestamp]);

  useEffect(() => {
    //if brand id changes, reset everything
    let searchParameters = { ...defaultSearchParameters };
    searchParameters.timestamp = Date.now();
    setSearchParameters(searchParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.activeBrand.id]);

  return {
    userId: user.userId,
    loading,
    searchFilters,
    searchParameters,
    deleteDialogVisible,
    modalMessage,
    pagedResult,
    scheduleConfig,
    showModalProgressBar,
    viewGeneratedReportId,
    updateKeywords,
    changePage,
    updateSorting,
    addNewScheduledReport,
    editScheduledReport,
    closeReportConfigModal,
    reloadSearch,
    modalSaveHandler,
    handleReportDelete,
    deleteReport,
    closeDeleteDialog,
    getReportName,
    getCustomerNameById,
    getTransactionNamesByIds,
    getTransactionListDisplay,
    hasFilters,
    isFilterChecked,
    updateFilter,
    toggleActive,
    viewGeneratedReports,
    closeGeneratedReportsModal
  };
};

export default useScheduledReportManager;
