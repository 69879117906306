import { useState, useEffect } from 'react';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import { Helpers } from '../../../../../../../utils/helpers/Helpers';

const useIdentificationDocuments = (containerFormData, formStatusCallback) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    businessIdentificationDocumentType: containerFormData.businessIdentificationDocumentType,
    businessIdentificationDocumentIdNumber: containerFormData.businessIdentificationDocumentIdNumber
  });

  const isValidField = (name, value) => {
    //place any custom field validation logic in here
    switch (name) {
      case 'businessIdentificationDocumentIdNumber':
        return Helpers.isValidIdDocumentNumber(formData.businessIdentificationDocumentType, value);
      default:
        return !Helpers.isNullOrWhitespace(value);
    }
  };

  const isMissingValues = (fields) => {
    return Object.entries(fields).some(([key, value]) => {
      return !isValidField(key, value);
    });
  };

  const [componentStatus, setComponentStatus] = useState(
    isMissingValues(formData) ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
  );

  const updateValue = ({ name, value }) => {
    const fields = { ...formData };
    fields[name] = value;
    setFormData(fields);

    setErrors((err) => {
      return { ...err, [name]: !isValidField(name, value) };
    });

    const missingValues = isMissingValues(fields);
    setComponentStatus(
      missingValues ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
    );
    formStatusCallback('identificationDocuments', !missingValues, fields);
  };

  useEffect(() => {
    //document number can be set without specifying the type
    //trip validation on the number when type is changed
    let idDocNumber = {
      name: 'businessIdentificationDocumentIdNumber',
      value: formData.businessIdentificationDocumentIdNumber
    };
    updateValue(idDocNumber);
  }, [formData.businessIdentificationDocumentType]);

  return {
    componentStatus,
    formData,
    errors,
    updateValue
  };
};

export default useIdentificationDocuments;
