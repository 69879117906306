import React from 'react';

export const CheckBox = ({ label, value, checkOnChange, checked }) => {
  return (
    <div className="form-check">
      <label className="form-check-label">
        <input
          className="form-check-input"
          type="checkbox"
          value={value}
          onChange={checkOnChange}
          checked={checked}
        />
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
