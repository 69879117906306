import { useEffect, useState, useCallback, useRef } from 'react';
import { MessageAudienceType } from '../../../../utils/constants/enums';
import { Api, Helpers } from '../../../../utils/helpers';

const useMessageEditor = ({ messageId, returnToSearchCallback, roles, brandCustomers }) => {
  const bffApiUrl = process.env.REACT_APP_BFF_API_URL;
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [showFileBrowserModal, setShowFileBrowserModal] = useState(false);
  const [tinyMceFileCallback, setTinyMceFileCallback] = useState(null);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({
    id: null,
    title: '',
    body: '',
    startDateUtc: null,
    endDateUtc: null,
    messageOptions: 0,
    audiences: [],
    dateCreatedUtc: null,
    lastModifiedUtc: null,
    createdBy: '',
    lastModifiedBy: ''
  });

  const isNewMessage = message.id === null;

  const loadMessage = useCallback(async (messageId) => {
    setLoading(true);
    if (messageId === null) {
      setLoading(false);
      return;
    }
    const apiResponse = await Api.get(`/policy/MessageManager/Messages/${messageId}`);
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred', JSON.stringify(apiResponse));
      return;
    }
    const message = apiResponse.response;
    setMessage(message);
    setLoading(false);
  }, []);

  const handleSave = async (event) => {
    event.preventDefault();

    //clear previous errors
    setErrors({});

    if (message.title.trim() === '') {
      setFieldError('title', true);
      return false;
    }
    await postMessage();
  };

  const setFieldError = (field, hasError) => {
    let errorsToUpdate = { ...errors };
    errorsToUpdate[field] = hasError;
    setErrors(errorsToUpdate);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    setDeleteDialogVisible(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const deleteMessage = async () => {
    const apiResponse = await Api.delete(`/policy/MessageManager/Messages/${message.id}`);
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      return;
    }
    returnToSearchCallback();
  };

  const updateMessageProperty = async (prop, value) => {
    setFieldError(prop, false);
    let messageToUpdate = { ...message };
    messageToUpdate[prop] = value;
    setMessage(messageToUpdate);
  };

  const updateMessageOptions = async (value) => {
    let messageToUpdate = { ...message };
    if (Helpers.hasFlag(messageToUpdate.messageOptions, value)) {
      messageToUpdate.messageOptions ^= value; //remove flag
    } else {
      messageToUpdate.messageOptions |= value; //add flag
    }
    setMessage(messageToUpdate);
  };

  const updateRoleInAudience = async (roleId) => {
    let messageToUpdate = { ...message };
    const roleExists = messageToUpdate.audiences.some(
      (x) => x.type === MessageAudienceType.ROLE && x.value === roleId
    );

    if (roleExists) {
      messageToUpdate.audiences = messageToUpdate.audiences.filter(
        (x) => !(x.type === MessageAudienceType.ROLE && x.value === roleId)
      );
    } else {
      messageToUpdate.audiences.push({
        type: MessageAudienceType.ROLE,
        value: roleId
      });
    }
    setMessage(messageToUpdate);
  };

  const isRoleChecked = (roleId) => {
    return message.audiences.some((x) => x.type === MessageAudienceType.ROLE && x.value === roleId);
  };

  const getBrandCustomerAudiences = () => {
    return message.audiences.filter(
      (x) =>
        x.type === MessageAudienceType.BRAND_CUSTOMER ||
        x.type === MessageAudienceType.BRAND_CUSTOMER_CLASSIFICATION
    );
  };

  const audienceToText = (audience) => {
    return Helpers.audienceToText(audience, roles, brandCustomers);
  };

  const postMessage = async () => {
    let messageToSave = { ...message };
    if (editorRef.current) {
      messageToSave.body = editorRef.current.getContent();
    }
    setMessage(messageToSave);

    const apiResponse = await Api.post(
      '/policy/MessageManager/Messages',
      JSON.stringify(messageToSave)
    );
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred', JSON.stringify(apiResponse));
      return;
    }
    returnToSearchCallback();
  };

  const addBrandAudience = (e) => {
    e.preventDefault();

    if (!selectedAudience) {
      return;
    }

    let messageToUpdate = { ...message };

    const audienceExists = messageToUpdate.audiences.find(
      (aud) => aud.type === selectedAudience.type && aud.value === selectedAudience.value
    );
    if (!audienceExists) {
      //add to message audience
      messageToUpdate.audiences.push({
        type: selectedAudience.type,
        value: selectedAudience.value
      });
      setMessage(messageToUpdate);
    }
  };

  const removeBrandAudience = (e, aud) => {
    e.preventDefault();
    let messageToUpdate = { ...message };
    messageToUpdate.audiences = messageToUpdate.audiences.filter(
      (x) => !(x.type === aud.type && x.value === aud.value)
    );
    setMessage(messageToUpdate);
  };

  const tinymceImageUploadHandler = (blobInfo, success, failure, progress) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open(
      'POST',
      `${bffApiUrl}/files/content/tinymce-upload?path=${encodeURIComponent('editor-uploads')}`
    );

    xhr.upload.onprogress = function (e) {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = () => {
      if (xhr.status === 403) {
        failure('HTTP Error: ' + xhr.status, { remove: true });
        return;
      }
      if (xhr.status < 200 || xhr.status >= 300) {
        failure('HTTP Error: ' + xhr.status);
        return;
      }
      const json = JSON.parse(xhr.responseText);
      if (!json || typeof json.location !== 'string') {
        failure('Invalid JSON: ' + xhr.responseText);
        return;
      }
      success(`${bffApiUrl}/files/content/file?path=${encodeURIComponent(json.location)}`);
    };
    xhr.onerror = function () {
      failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };
    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());
    xhr.send(formData);
  };

  useEffect(() => {
    loadMessage(messageId);
  }, [loadMessage, messageId]);

  return {
    loading,
    message,
    isNewMessage,
    editorRef,
    errors,
    roles,
    brandCustomers,
    selectedAudience,
    handleSave,
    handleDelete,
    setSelectedAudience,
    updateMessageProperty,
    updateMessageOptions,
    updateRoleInAudience,
    addBrandAudience,
    removeBrandAudience,
    isRoleChecked,
    getBrandCustomerAudiences,
    audienceToText,
    deleteDialogVisible,
    deleteMessage,
    closeDeleteDialog,
    showFileBrowserModal,
    setShowFileBrowserModal,
    tinyMceFileCallback,
    setTinyMceFileCallback,
    tinymceImageUploadHandler
  };
};

export default useMessageEditor;
