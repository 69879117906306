import PropTypes from 'prop-types';
import { HelpTip } from '../../../../components/ui';
import { ReportDateRangeType } from '../../../../utils/constants/enums';

export const DateRangeHelpTips = ({ dateRangeType }) => {
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  return (
    <>
      {dateRangeType === ReportDateRangeType.YESTERDAY && (
        <>
          <HelpTip className="mt-2">
            Previous day reports will contain data for the day preceding the report creation date.
            For example, if a report is generated on Wednesday, it will contain data for Tuesday.
          </HelpTip>
        </>
      )}

      {dateRangeType === ReportDateRangeType.BUSINESS_DAILY && (
        <>
          <HelpTip className="mt-2">
            Business Daily reports are generated on weekdays (Monday-Friday). Reports generated on
            Monday will include the entire preceding weekend (Friday-Sunday). All other days will
            report on the previous day.
          </HelpTip>
        </>
      )}

      {dateRangeType === ReportDateRangeType.WEEKLY && (
        <>
          <HelpTip className="mt-2">
            Weekly reports contain data for the previous calendar week (Sunday-Saturday).
          </HelpTip>
        </>
      )}

      {dateRangeType === ReportDateRangeType.PREVIOUS_7_DAYS && (
        <>
          <HelpTip className="mt-2">
            Previous 7 Days reports contains data for the previous rolling 7 days. For example,
            reports generated on Tuesday would include data for the preceding 7 days
            (Tuesday-Monday).
          </HelpTip>
        </>
      )}

      {dateRangeType === ReportDateRangeType.PREVIOUS_MONTH && (
        <>
          <HelpTip className="mt-2">
            Monthly reports contain data for the previous calendar month. For example, reports
            generated in June would contain data for the entirety of May.
          </HelpTip>
        </>
      )}

      {dateRangeType === ReportDateRangeType.PREVIOUS_QUARTER && (
        <>
          <HelpTip className="mt-2">
            Previous Quarter reports contain data for the previous calendar quarter. For example,
            reports generated in the second quarter (April-June) would contain data for the first
            quarter (January-March).
          </HelpTip>
        </>
      )}

      {dateRangeType === ReportDateRangeType.PREVIOUS_YEAR && (
        <>
          <HelpTip className="mt-2">
            Previous Year reports contain data for the previous calendar year. For example, reports
            generated in {currentYear} would contain data for {previousYear}.
          </HelpTip>
        </>
      )}

      {dateRangeType === ReportDateRangeType.PREVIOUS_FISCAL_YEAR && (
        <>
          <HelpTip className="mt-2">
            Previous Fiscal Year reports contain data for the previous fiscal year (July 1 - June
            30). For example, reports generated on August 1, {currentYear} would contain data for
            July 1, {previousYear} through June 30, {currentYear}.
          </HelpTip>
        </>
      )}
    </>
  );
};

export default DateRangeHelpTips;

DateRangeHelpTips.propTypes = {
  dateRangeType: PropTypes.number
};
