import { DefaultLayout as Layout } from '../../../layouts';
import { ImportConfigure } from '../ImportConfigure/ImportConfigure';
import styles from './FileImportPage.module.css';

export const FileImportPage = () => {
  return (
    <Layout>
      <div className="titleBar">
        <h1 className="pageTitle">Import Files</h1>
      </div>
      <div className={styles.fileImportContainer}>
        <div className="row">
          <div className="col-12">
            <ImportConfigure />
          </div>
        </div>
      </div>
    </Layout>
  );
};
