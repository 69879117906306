import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProgressBar.module.css';

export const ProgressBar = ({
  visible = true,
  className,
  percentage = null,
  showPercentageText = false,
  bgClass = '',
  barClass = ''
}) => {
  if (!visible) return <></>;

  const hasPercentage = percentage !== null && percentage >= 0;

  // if percentage is not provided, it will display as an indeterminate progress bar
  return (
    <div className={className}>
      <div className={styles.progressBar + ' ' + bgClass}>
        {!hasPercentage && <div className={styles.progressBarIndeterminate + ' ' + barClass}></div>}
        {hasPercentage && (
          <div
            className={styles.progressBarValue + ' ' + barClass}
            style={{ width: (percentage ?? 100) + '%' }}></div>
        )}
      </div>
      {hasPercentage && showPercentageText && (
        <div className={styles.percentageText}>{percentage}%</div>
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  visible: PropTypes.bool,
  percentage: PropTypes.number,
  showPercentageText: PropTypes.bool,
  className: PropTypes.string
};

export default ProgressBar;
