import { useEffect, useState } from 'react';
import { Helpers } from '../../../../../../utils/helpers';

const useNonprofit = (customerRecordParameter, formStatusCallback, errorFormData) => {
  const [customerRecord] = useState(customerRecordParameter);
  const [formData, setFormData] = useState({
    payPalExternalId: errorFormData.payPalExternalId ?? '',
    payPalUserId: errorFormData.payPalUserId ?? '',
    softDescriptor: errorFormData.softDescriptor ?? '',
    bankName: errorFormData.bankName ?? customerRecord.bankName,
    bankAccountNumber: errorFormData.bankAccountNumber ?? '',
    bankAccountType: errorFormData.bankAccountType ?? 'checking',
    bankAccountRoutingNumber:
      errorFormData.bankAccountRoutingNumber ?? customerRecord.bankAccountRoutingNumber,
    bankLocation: errorFormData.bankLocation ?? customerRecord.bankLocation,
    primaryAuthorizedUserGivenName: errorFormData.primaryAuthorizedUserGivenName ?? '',
    primaryAuthorizedUserSurname: errorFormData.primaryAuthorizedUserSurname ?? '',
    primaryAuthorizedUserOccupation: errorFormData.primaryAuthorizedUserOccupation ?? '',
    primaryAuthorizedUserEmail: errorFormData.primaryAuthorizedUserEmail ?? '',
    primaryAuthorizedUserBirthDetailDOB: errorFormData.primaryAuthorizedUserBirthDetailDOB ?? '',
    primaryAuthorizedUserAddressLine1: errorFormData.primaryAuthorizedUserAddressLine1 ?? '',
    primaryAuthorizedUserAddressLine2: errorFormData.primaryAuthorizedUserAddressLine2 ?? '',
    primaryAuthorizedUserAdminArea2: errorFormData.primaryAuthorizedUserAdminArea2 ?? '',
    primaryAuthorizedUserAdminArea1: errorFormData.primaryAuthorizedUserAdminArea1 ?? '',
    primaryAuthorizedUserPostalCode: errorFormData.primaryAuthorizedUserPostalCode ?? '',
    primaryAuthorizedUserCountryCode: errorFormData.primaryAuthorizedUserCountryCode ?? '',
    primaryAuthorizedUserPhoneNumber:
      errorFormData.primaryAuthorizedUserPhoneNumber ?? customerRecord.phone,
    primaryAuthorizedUserPhoneType: errorFormData.primaryAuthorizedUserPhoneType ?? '',
    primaryAuthorizedUserIdentificationDocumentType:
      errorFormData.primaryAuthorizedUserIdentificationDocumentType ?? '',
    primaryAuthorizedUserIdentificationDocumentNumber:
      errorFormData.primaryAuthorizedUserIdentificationDocumentNumber ?? '',
    businessType: errorFormData.businessType ?? customerRecord.businessType,
    businessMerchantCategoryCode: errorFormData.businessMerchantCategoryCode ?? '',
    businessLegalName: errorFormData.businessLegalName ?? customerRecord.name,
    businessEmail: errorFormData.businessEmail ?? customerRecord.contactEmail,
    businessWebsite: errorFormData.businessWebsite ?? '',
    businessPhoneNumber: errorFormData.businessPhoneNumber ?? customerRecord.phone,
    businessAddressLine1: errorFormData.businessAddressLine1 ?? customerRecord.address1,
    businessAddressAdminArea2: errorFormData.businessAddressAdminArea2 ?? customerRecord.city,
    businessAddressAdminArea1: errorFormData.businessAddressAdminArea1 ?? customerRecord.state,
    businessPostalCode: errorFormData.businessPostalCode ?? customerRecord.postalCode,
    businessIdentificationDocumentType: errorFormData.businessIdentificationDocumentType ?? '',
    businessIdentificationDocumentIdNumber:
      errorFormData.businessIdentificationDocumentIdNumber ?? customerRecord.taxId,
    officeBearerPrefix: errorFormData.officeBearerPrefix ?? '',
    officeBearerGivenName: errorFormData.officeBearerGivenName ?? '',
    officeBearerSurname: errorFormData.officeBearerSurname ?? '',
    officeBearerSuffix: errorFormData.officeBearerSuffix ?? '',
    officeBearerAddressLine1: errorFormData.officeBearerAddressLine1 ?? '',
    officeBearerAddressLine2: errorFormData.officeBearerAddressLine2 ?? '',
    officeBearerAdminArea1: errorFormData.officeBearerAdminArea1 ?? '',
    officeBearerAdminArea2: errorFormData.officeBearerAdminArea2 ?? '',
    officeBearerPostalCode: errorFormData.officeBearerPostalCode ?? '',
    officeBearerDOB: errorFormData.officeBearerDOB ?? '',
    officeBearerIdentificationDocumentType:
      errorFormData.officeBearerIdentificationDocumentType ?? '',
    officeBearerIdentificationDocumentIdNumber:
      errorFormData.officeBearerIdentificationDocumentIdNumber ?? '',
    officeBearerPhoneNumber: errorFormData.officeBearerPhoneNumber ?? '',
    officeBearerPhoneType: errorFormData.officeBearerPhoneType ?? '',
    officeBearerEmailAddress: errorFormData.officeBearerEmailAddress ?? ''
  });

  const hasRegisteredBusinessAddress =
    !Helpers.isNullOrWhitespace(formData.businessAddressLine1) &&
    !Helpers.isNullOrWhitespace(formData.businessAddressAdminArea2) &&
    !Helpers.isNullOrWhitespace(formData.businessAddressAdminArea1) &&
    !Helpers.isNullOrWhitespace(formData.businessPostalCode);

  const [formStatus, setFormStatus] = useState({
    accountInformation: false,
    primaryAuthorizedUser: false,
    legalBusinessInformation: false,
    registeredBusinessAddress: hasRegisteredBusinessAddress,
    identificationDocuments: false,
    officeBearer: false
  });

  useEffect(() => {
    if (errorFormData.payPalExternalId) {
      for (const key in formStatus) {
        formStatus[key] = true;
      }
    }
  }, [errorFormData, formStatus]);

  const componentFormCallback = (componentName, componentStatus, componentData) => {
    const fs = { ...formStatus };
    fs[componentName] = componentStatus;
    setFormStatus(fs);

    const fd = { ...formData };
    for (const key in componentData) {
      const value = componentData[key];
      fd[key] = value;
    }
    setFormData(fd);

    const allFormsValid = !Object.values(fs).some((x) => !x);
    formStatusCallback(allFormsValid, formData);
  };

  return {
    formData,
    componentFormCallback
  };
};

export default useNonprofit;
