import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { PagingControls, SortingHeader, ModalDialog } from '../../../../components/ui';
import { Helpers } from '../../../../utils/helpers';
import { ScheduledReportConfigModal } from '../ScheduledReportConfigModal/ScheduledReportConfigModal';
import { GeneratedReportsModal } from '../../GeneratedReportsPage/GeneratedReports/GeneratedReportsModal';
import styles from './ScheduledReportManager.module.css';
import useScheduledReportManager from './useScheduledReportManager';
import { ReportType } from '../../../../utils/constants/enums';

export const ScheduledReportManager = () => {
  const manager = useScheduledReportManager();

  return (
    <>
      <ScheduledReportConfigModal
        loading={manager.showModalProgressBar}
        message={manager.modalMessage}
        scheduleConfig={manager.scheduleConfig}
        saveChangesCallback={manager.modalSaveHandler}
        closeCallback={manager.closeReportConfigModal}
        reloadSearchCallback={manager.reloadSearch}
        deleteReportCallback={manager.handleReportDelete}
      />
      <GeneratedReportsModal
        reportId={manager.viewGeneratedReportId}
        closeCallback={manager.closeGeneratedReportsModal}
      />
      <ModalDialog
        showDialog={manager.deleteDialogVisible}
        heading="Delete Scheduled Report"
        message="Are you sure you want to delete this scheduled report? All previously generated reports for this scheduled report will also be deleted."
        okCallback={manager.deleteReport}
        okButtonText="Yes, delete this report."
        cancelCallback={manager.closeDeleteDialog}
        cancelButtonText="No, don't delete."
      />
      <div className="titleBar">
        <h1 className="pageTitle">Scheduled Reports</h1>
      </div>

      <div className="row">
        <div className="col-12">
          <div className={styles.searchContainer}>
            <div className={styles.searchWrap}>
              <label htmlFor="searchReports" className="form-label sr-only">
                Search reports
              </label>
              <input
                id="searchReports"
                type="text"
                className="form-control"
                placeholder="Search scheduled reports"
                onChange={(e) => manager.updateKeywords(e.target.value)}
              />
            </div>
            <button className="btn btn-success" onClick={() => manager.addNewScheduledReport()}>
              Add Scheduled Report
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-9 col-lg-12">
          <div className="card">
            <div className="card-body p-0">
              {!manager.loading && manager?.pagedResult?.results.length === 0 && (
                <div className="p-3">
                  <div className="mt-3 mb-3 text-center">
                    <em>No scheduled reports found.</em>
                  </div>
                </div>
              )}

              {manager.loading && (
                <div className="p-3">
                  <Spinner animation="border" />
                </div>
              )}

              {!manager.loading && manager?.pagedResult?.results.length > 0 && (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="d-none d-md-table-cell">
                          <SortingHeader
                            label="Active"
                            sortField="active"
                            sortDirection={manager.searchParameters.sortDirection}
                            onClick={manager.updateSorting}
                            active={manager.searchParameters.sortField === 'active'}
                          />
                        </th>
                        <th>
                          <SortingHeader
                            label="Name"
                            sortField="reportname"
                            sortDirection={manager.searchParameters.sortDirection}
                            onClick={manager.updateSorting}
                            active={manager.searchParameters.sortField === 'reportname'}
                          />
                        </th>
                        <th className="d-none d-md-table-cell">
                          <SortingHeader label="Customer / Transactions" />
                        </th>
                        <th className="d-none d-md-table-cell">
                          <SortingHeader label="Report / Schedule" />
                        </th>
                        <th>
                          <SortingHeader
                            label="Last Modified"
                            sortField="lastmodified"
                            sortDirection={manager.searchParameters.sortDirection}
                            onClick={manager.updateSorting}
                            active={manager.searchParameters.sortField === 'lastmodified'}
                          />
                        </th>
                        <th className="d-none d-md-table-cell">
                          <SortingHeader label="Generated Reports" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {manager?.pagedResult?.results?.map((report) => (
                        <tr key={report.id}>
                          <td className="d-md-table-cell align-top">
                            {report.userId === manager.userId && (
                              <div className={'form-check form-switch mt-1 ' + styles.switchButton}>
                                <label className="form-check-label">
                                  <input
                                    className={
                                      'form-check-input ' +
                                      (report.active ? styles.switchActive : '')
                                    }
                                    type="checkbox"
                                    aria-label={report.active ? 'Active' : 'Inactive'}
                                    checked={report.active}
                                    onChange={() => {
                                      manager.toggleActive(report.id);
                                    }}
                                  />
                                </label>
                              </div>
                            )}
                            {report.userId !== manager.userId && (
                              <div
                                className={
                                  report.active ? styles.activeIndicator : styles.inactiveIndicator
                                }></div>
                            )}
                          </td>
                          <td className={'align-top ' + styles.reportNameCell}>
                            {report.userId === manager.userId && (
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                  manager.editScheduledReport(report.id);
                                }}>
                                <strong>{report.name}</strong>
                              </a>
                            )}
                            {report.userId !== manager.userId && <strong>{report.name}</strong>}
                          </td>
                          <td className="d-md-table-cell align-top">
                            {report.userId === manager.userId && (
                              <>
                                {report.reportParameters.reportType === ReportType.MID && (
                                  <>
                                    <div className={styles.customerName}>All Customers</div>
                                    All Transactions
                                  </>
                                )}
                                {report.reportParameters.reportType !== ReportType.MID && (
                                  <>
                                    <div className={styles.customerName}>
                                      {manager.getCustomerNameById(
                                        report.reportParameters.customerId
                                      )}
                                    </div>
                                    {manager.getTransactionListDisplay(report)}
                                  </>
                                )}
                              </>
                            )}
                            {report.userId !== manager.userId && (
                              <>
                                <div className={styles.sharedReportText}>Shared Report</div>
                                <div className={styles.viewDetailsText}>
                                  View report to view customer/transaction details.
                                </div>
                              </>
                            )}
                          </td>
                          <td className="d-md-table-cell align-top">
                            <div className={styles.reportTypeName}>
                              {manager.getReportName(report.reportParameters.reportType)}
                            </div>
                            <div className={styles.scheduleText}>
                              <FontAwesomeIcon size="sm" className="me-1" icon={faCalendarDays} />
                              {Helpers.getRecurringScheduleAsText(report)}
                            </div>
                            <div className={styles.scheduleText}>
                              <FontAwesomeIcon size="sm" className="me-1" icon={faPaperPlane} />
                              {Helpers.getReportDeliveryTypeAsText(report.deliveryType)}
                            </div>
                          </td>
                          <td className="d-md-table-cell align-top text-nowrap">
                            {Helpers.toShortDate(report.lastModifiedUtc)}
                            <br />
                            {Helpers.toShortTime(report.lastModifiedUtc)}
                          </td>
                          <td className="d-md-table-cell align-top">
                            {report.generatedReportCount === 0 && (
                              <em>No reports generated yet.</em>
                            )}
                            {report.generatedReportCount > 0 && (
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  manager.viewGeneratedReports(report.id);
                                }}
                                className="text-nowrap">
                                View Reports ({report.generatedReportCount})
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="ps-3 pe-3">
                    <PagingControls
                      page={manager.pagedResult.currentPage}
                      pageCount={manager.pagedResult.pageCount}
                      changePageCallback={manager.changePage}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-12">
          <div className="card admin-card">
            <div className="card-header">Report Filters</div>
            <div className={'card-body ' + styles.filterContainer}>
              <div className="row mb-3">
                <div className="col-12">
                  <strong>Status</strong>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={1}
                        onChange={() => manager.updateFilter('statuses', true)}
                        checked={manager.isFilterChecked('statuses', true)}
                      />
                      Active
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={0}
                        onChange={() => manager.updateFilter('statuses', false)}
                        checked={manager.isFilterChecked('statuses', false)}
                      />
                      Inactive
                    </label>
                  </div>
                </div>
              </div>
              {manager.searchFilters.customers?.length > 0 && (
                <div className="row mb-3">
                  <div className="col-12">
                    <strong>Customer</strong>
                    {manager.searchFilters.customers?.map((customer) => (
                      <div key={customer.id} className="form-check">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={customer.id}
                            onChange={() => manager.updateFilter('customers', customer.id)}
                            checked={manager.isFilterChecked('customers', customer.id)}
                          />
                          {customer.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {manager.searchFilters.reportTypes?.length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <strong>Report Type</strong>
                    {manager.searchFilters.reportTypes?.map((reportType) => (
                      <div key={reportType.id} className="form-check">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={reportType.id}
                            onChange={() => manager.updateFilter('reportTypes', reportType.id)}
                            checked={manager.isFilterChecked('reportTypes', reportType.id)}
                          />
                          {reportType.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
