import React from 'react';
import { Helpers } from '../../../../utils/helpers/Helpers';
import useUserEditor from './useUserEditor';
import { AlertMessage, ModalDialog, ProgressBar } from '../../../../components/ui';
import NumberFormat from 'react-number-format';
import { Spinner } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {
  MfaRequirementType,
  MfaNotificationMethod,
  TransactionStatus
} from '../../../../utils/constants/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleXmark,
  faCircleCheck,
  faTriangleExclamation,
  faCircleDot
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

const UserEditor = (props) => {
  const editor = useUserEditor(props);

  return (
    <>
      <ModalDialog
        showDialog={editor.deleteDialogVisible}
        heading="Delete User"
        message="Are you sure you want to delete this user?"
        okCallback={editor.deleteUser}
        okButtonText="Yes, delete this user."
        cancelCallback={editor.closeDeleteDialog}
        cancelButtonText="No, don't delete."
      />
      <ModalDialog
        showDialog={editor.roleChangeDialogVisible}
        heading="Confirm Role Change"
        message="The selected user resources do not meet the rule requirements for the selected role. Changing the role will reset the resource access selections. Are you sure you want to continue?"
        okCallback={editor.applyNewRole}
        okButtonText="Yes, change the role."
        cancelCallback={editor.closeRoleChangeDialog}
        cancelButtonText="No, keep the current role."
      />
      <div className="titleBar">
        <h2 className="pageTitle">
          <button className="btn btn-primary" onClick={props.returnToSearchCallback}>
            <i className="icofont-arrow-left"></i> Return to users
          </button>
        </h2>
        <div className="actions"></div>
      </div>

      {editor.loading && <Spinner animation="border" />}
      {!editor.loading && (
        <div className="card admin-card">
          <div className="card-header">
            <div className="titleBar mb-0">
              <h2 className="pageTitle">
                {editor.user.userName.trim().length > 0 ? editor.user.userName : 'User name'}
              </h2>
              {!editor.readOnly && (
                <div className="actions">
                  {!editor.isNewUser && (
                    <button className="btn btn-danger me-2" onClick={editor.handleDelete}>
                      Delete
                    </button>
                  )}
                  <button className="btn btn-success" onClick={editor.handleSave}>
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>

          <ProgressBar className="m-0" visible={editor.showProgressBar} />
          <AlertMessage
            className="card-body rounded-0"
            visible={editor.message.content.trim() !== ''}
            message={editor.message.content}
            status={editor.message.status}
          />

          <div className="card-body">
            <div className="row">
              <div className="col-xl-4 col-lg-12">
                <div className="form-group mb-3">
                  <label htmlFor="username" className="col-form-label">
                    User Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    readOnly={!editor.isNewUser}
                    placeholder="User name"
                    value={editor.user.userName}
                    onChange={(e) => editor.updateUserProperty('userName', e.target.value)}
                  />
                  {editor.checkingUsername && (
                    <div className="check-field-status">
                      <Spinner animation="border" size="sm" />
                    </div>
                  )}
                  {!editor.checkingUsername &&
                    editor.usernameValid !== null &&
                    editor.usernameValid && (
                      <div className="check-field-status">
                        <FontAwesomeIcon
                          className="status-icon text-success"
                          icon={faCircleCheck}
                        />
                      </div>
                    )}
                  {!editor.checkingUsername &&
                    editor.usernameValid !== null &&
                    !editor.usernameValid && (
                      <div className="check-field-status">
                        <FontAwesomeIcon
                          className="status-icon text-danger"
                          icon={faTriangleExclamation}
                        />
                      </div>
                    )}
                  {editor.errors.userName && (
                    <div className="alert-danger mt-1 p-2">User name required.</div>
                  )}
                  {editor.errors.userNameTaken && (
                    <div className="alert-danger mt-1 p-2">User name already taken.</div>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="emailAddress" className="col-form-label">
                    Email Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="emailAddress"
                    placeholder="Email address"
                    readOnly={editor.readOnly}
                    value={editor.user.emailAddress}
                    onChange={(e) => editor.updateUserProperty('emailAddress', e.target.value)}
                  />
                  {editor.checkingEmail && (
                    <div className="check-field-status">
                      <Spinner animation="border" size="sm" />
                    </div>
                  )}
                  {!editor.checkingEmail && editor.emailValid !== null && editor.emailValid && (
                    <div className="check-field-status">
                      <FontAwesomeIcon className="status-icon text-success" icon={faCircleCheck} />
                    </div>
                  )}
                  {!editor.checkingEmail && editor.emailValid !== null && !editor.emailValid && (
                    <div className="check-field-status">
                      <FontAwesomeIcon
                        className="status-icon text-danger"
                        icon={faTriangleExclamation}
                      />
                    </div>
                  )}
                  {editor.errors.emailAddress && (
                    <div className="alert-danger mt-1 p-2">Valid email address required.</div>
                  )}
                  {editor.errors.emailTaken && (
                    <div className="alert-danger mt-1 p-2">Email address already in use.</div>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="firstName" className="col-form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder="First Name"
                    readOnly={editor.readOnly}
                    value={editor.user.firstName}
                    onChange={(e) => editor.updateUserProperty('firstName', e.target.value)}
                  />
                  {editor.errors.firstName && (
                    <div className="alert-danger mt-1 p-2">First name required.</div>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="lastName" className="col-form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder="Last Name"
                    readOnly={editor.readOnly}
                    value={editor.user.lastName}
                    onChange={(e) => editor.updateUserProperty('lastName', e.target.value)}
                  />
                  {editor.errors.lastName && (
                    <div className="alert-danger mt-1 p-2">Last name required.</div>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="phoneNumber" className="col-form-label">
                    Phone Number
                  </label>
                  <NumberFormat
                    id="phoneNumber"
                    className="form-control"
                    format="(###) ###-####"
                    mask="_"
                    allowEmptyFormatting={true}
                    readOnly={editor.readOnly}
                    value={editor.user.phoneNumber}
                    onChange={(e) => editor.updateUserProperty('phoneNumber', e.target.value)}
                  />
                  {editor.errors.phoneNumber && (
                    <div className="alert-danger mt-1 p-2">Valid phone number required.</div>
                  )}
                </div>

                {editor.isMfaRequired() && (
                  <div className="form-group mb-3">
                    <fieldset
                      className={'mfaWrap' + (editor.readOnly ? ' alert alert-secondary' : '')}>
                      <legend>MFA:</legend>
                      {editor.isMfaRequired() && editor.readOnly && (
                        <>
                          <div>
                            <FontAwesomeIcon
                              size="sm"
                              className="text-secondary me-2"
                              icon={
                                editor.user.mfaNotificationMethod === MfaNotificationMethod.EMAIL
                                  ? faCircleDot
                                  : faCircle
                              }
                            />
                            Email
                          </div>
                          <div>
                            <FontAwesomeIcon
                              size="sm"
                              className="text-secondary me-2"
                              icon={
                                editor.user.mfaNotificationMethod === MfaNotificationMethod.SMS
                                  ? faCircleDot
                                  : faCircle
                              }
                            />
                            Text
                          </div>
                        </>
                      )}
                      {editor.isMfaRequired() && !editor.readOnly && (
                        <>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="MFA"
                              id="MFA_Email"
                              readOnly={editor.readOnly}
                              onChange={() => editor.updateMfaMethod(MfaNotificationMethod.EMAIL)}
                              checked={
                                editor.user.mfaNotificationMethod === MfaNotificationMethod.EMAIL
                              }
                            />
                            <label className="form-check-label" htmlFor="MFA_Email">
                              Email
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="MFA"
                              id="MFA_Text"
                              onChange={() => editor.updateMfaMethod(MfaNotificationMethod.SMS)}
                              checked={
                                editor.user.mfaNotificationMethod === MfaNotificationMethod.SMS
                              }
                            />
                            <label className="form-check-label" htmlFor="MFA_Text">
                              Text
                            </label>
                          </div>
                        </>
                      )}
                    </fieldset>
                  </div>
                )}

                {!editor.isNewUser && (
                  <div className="form-group mb-3">
                    <div>
                      <strong>First Password Email Sent:&nbsp;</strong>
                      {Helpers.toShortDateTime(editor.user.lastPasswordEmailSent, 'Never')}
                    </div>
                    {editor.user.hasPassword && (
                      <div>
                        <strong>Last Login:&nbsp;</strong>
                        {Helpers.toShortDateTime(editor.user.lastLogin, 'Never')}
                      </div>
                    )}
                    <div className="alert alert-secondary mt-2" role="alert">
                      {editor.user.lockoutEnabled && (
                        <>
                          <i className="icofont-lock"></i>{' '}
                          <strong>
                            Account is <span className="text-danger">Locked</span>
                          </strong>
                        </>
                      )}
                      {!editor.user.lockoutEnabled && editor.user.hasPassword && (
                        <>
                          <i className="icofont-unlocked"></i>{' '}
                          <strong>
                            Account is <span className="text-success">Unlocked</span>
                          </strong>
                        </>
                      )}

                      {!editor.readOnly && !editor.user.hasPassword && (
                        <div className="mt-2">
                          <button
                            onClick={editor.sendFirstPasswordEmail}
                            className="btn btn-primary"
                            disabled={editor.unlocking}>
                            Send First Password Email
                            {editor.unlocking && (
                              <Spinner size="sm" className="ms-1" animation="border" />
                            )}
                          </button>
                        </div>
                      )}

                      {!editor.readOnly && editor.user.hasPassword && (
                        <div className="mt-2">
                          <button
                            onClick={editor.sendPasswordReset}
                            className="btn btn-primary"
                            disabled={editor.unlocking}>
                            Send password reset email
                            {editor.unlocking && (
                              <Spinner size="sm" className="ms-1" animation="border" />
                            )}
                          </button>
                        </div>
                      )}
                      {editor.unlockMessage && <div className="mt-2">{editor.unlockMessage}</div>}
                    </div>
                  </div>
                )}

                {window.location.host.startsWith('local') && (
                  <div className="form-group mb-3">
                    <pre>{JSON.stringify(editor.user, null, ' ')}</pre>
                  </div>
                )}
              </div>

              <div className="col-xl-8 col-lg-12">
                <div className="form-group mb-3">
                  <label htmlFor="roleTemplate" className="col-form-label">
                    Role Template
                  </label>
                  <select
                    id="roleTemplate"
                    className="form-select"
                    value={editor.user.role ?? ''}
                    disabled={editor.readOnly}
                    onChange={(e) => editor.roleChangeVerify(e.target.value)}>
                    <option value="">- Select a role template -</option>
                    {editor.roles
                      .filter((role) => editor.isAssignableRole(role))
                      .map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                  </select>
                  {editor.errors.role && (
                    <div className="alert-danger mt-1 p-2">Role selection required.</div>
                  )}
                </div>

                {!editor.user.role && (
                  <div>
                    <em>Select a role template to assign resource access.</em>
                  </div>
                )}

                {editor.user.role && (
                  <>
                    {!editor.readOnly && editor.resourcesChanged && (
                      <div className="alert-warning mt-1 p-2">
                        Notice: Resources were removed from this user due to updates to your
                        resource access. If you save, these changes will be applied.
                      </div>
                    )}
                    <label className="col-form-label d-flex">
                      <div className="flex-grow-1">Resource Access </div>
                      <div>
                        {editor.role.rules.allowMultipleBrands && (
                          <span className="badge bg-secondary">multi brand</span>
                        )}
                        {editor.role.rules.allowMultipleCustomers && (
                          <span className="badge bg-secondary ms-1 me-1">multi customer</span>
                        )}
                      </div>
                    </label>
                    {editor.errors.resources && (
                      <div className="alert-danger mt-1 p-2">
                        At least one brand, customer, and transaction must be selected.
                      </div>
                    )}
                    <Accordion alwaysOpen defaultActiveKey={editor.brands.length <= 1 ? 0 : null}>
                      {editor.brands.map((brand, index) => (
                        <Accordion.Item key={brand.id} eventKey={index}>
                          <Accordion.Header className={editor.brands.length <= 1 ? 'd-none' : ''}>
                            {editor.role.rules.allowMultipleBrands && (
                              <input
                                className="d-inline-block me-2"
                                type="checkbox"
                                onClick={(e) => editor.toggleSelectedBrand(e, brand.id)}
                                checked={editor.isBrandChecked(brand.id)}
                                readOnly={true}
                                disabled={editor.readOnly}
                              />
                            )}
                            {!editor.role.rules.allowMultipleBrands && (
                              <input
                                className="d-inline-block me-2"
                                name="brandSelect"
                                type="radio"
                                onClick={(e) => editor.selectSingleBrand(e, brand.id)}
                                checked={editor.isBrandChecked(brand.id)}
                                readOnly={true}
                                disabled={editor.readOnly}
                              />
                            )}
                            {brand.name}
                            {editor.brandSelectionDetails(brand.id)}
                          </Accordion.Header>
                          <Accordion.Body className="themed-bg-color">
                            {!editor.isBrandChecked(brand.id) && (
                              <div>
                                <em>Select brand to add customer assignments.</em>
                              </div>
                            )}

                            {editor.isBrandChecked(brand.id) && (
                              <>
                                {editor.role.rules.allowMultipleCustomers &&
                                  editor.hasAccessToAllCustomers(brand.id) && (
                                    <div className="form-group">
                                      <div className="form-check mb-2 ps-0">
                                        <label className="form-check-label">
                                          <input
                                            type="checkbox"
                                            onClick={(e) => editor.toggleAllCustomers(e, brand.id)}
                                            checked={editor.isAllCustomersChecked(brand.id)}
                                            readOnly={true}
                                            disabled={editor.readOnly}
                                          />
                                          <span> All &apos;{brand.name}&apos; customers</span>
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                {!editor.readOnly && !editor.isAllCustomersChecked(brand.id) && (
                                  <div className="d-flex mb-2">
                                    <select
                                      className="form-select w-auto me-1"
                                      value={editor.brandCustomerSelect[brand.id] ?? ''}
                                      onChange={(e) =>
                                        editor.updateBrandCustomerSelect(brand.id, e.target.value)
                                      }>
                                      <option value={null}>- Select -</option>
                                      {brand.customers
                                        .filter(
                                          (customer) =>
                                            !editor.isCustomerSelected(brand.id, customer.id)
                                        )
                                        .map((customer) => (
                                          <option key={customer.id} value={customer.id}>
                                            {customer.name}
                                            {customer.mfaRequirement !== MfaRequirementType.NONE
                                              ? ' [mfa]'
                                              : ''}
                                          </option>
                                        ))}
                                    </select>
                                    <button
                                      className="btn btn-success"
                                      onClick={() =>
                                        editor.addCustomer(
                                          brand.id,
                                          editor.brandCustomerSelect[brand.id]
                                        )
                                      }
                                      disabled={
                                        !editor.role.rules.allowMultipleCustomers &&
                                        editor.brandCustomerCount(brand.id) > 0
                                      }>
                                      Add
                                    </button>
                                  </div>
                                )}

                                <Accordion
                                  alwaysOpen
                                  activeKey={editor.activeAccordians
                                    .filter((x) => x.brandId === brand.id)
                                    .map((x) => x.customerId)}>
                                  {brand.customers
                                    .filter((customer) =>
                                      editor.isCustomerSelected(brand.id, customer.id)
                                    )
                                    .map((customer) => (
                                      <Accordion.Item key={customer.id} eventKey={customer.id}>
                                        <Accordion.Header
                                          onClick={() =>
                                            editor.toggleCustomerAccordian(brand.id, customer.id)
                                          }>
                                          {customer.name}{' '}
                                          {customer.mfaRequirement !== MfaRequirementType.NONE
                                            ? ' [mfa]'
                                            : ''}
                                          {!editor.readOnly && (
                                            <a
                                              href="#!"
                                              className="text-danger delete"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              title="Remove customer"
                                              onClick={(e) =>
                                                editor.removeCustomer(e, brand.id, customer.id)
                                              }>
                                              <FontAwesomeIcon icon={faCircleXmark} />{' '}
                                              <span className="sr-only">remove</span>
                                            </a>
                                          )}
                                        </Accordion.Header>
                                        <Accordion.Body className="pt-3">
                                          {!editor.readOnly && (
                                            <>
                                              {editor.hasAccessToAllTransactions(
                                                brand.id,
                                                customer.id
                                              ) && (
                                                <div className="form-group">
                                                  <div className="form-check mb-2 ps-0">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        onClick={(e) =>
                                                          editor.toggleAllTransactions(
                                                            e,
                                                            brand.id,
                                                            customer.id
                                                          )
                                                        }
                                                        checked={editor.isAllTransactionsChecked(
                                                          brand.id,
                                                          customer.id
                                                        )}
                                                        readOnly={true}
                                                        disabled={editor.readOnly}
                                                      />
                                                      <span className="d-inlineblock ms-2">
                                                        Select All &apos;{customer.name}&apos;
                                                        Transactions
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>
                                              )}
                                              {!editor.isAllTransactionsChecked(
                                                brand.id,
                                                customer.id
                                              ) && (
                                                <>
                                                  <div className="transaction-select-message">
                                                    Select individual transactions from the list
                                                    below for this user:
                                                  </div>
                                                  <div className="transaction-select-all d-none">
                                                    <div className="form-check">
                                                      <label className="form-check-label">
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          onChange={() =>
                                                            editor.selectAllTransactions(
                                                              brand.id,
                                                              customer.id
                                                            )
                                                          }
                                                          checked={editor.hasAllTransactions(
                                                            brand.id,
                                                            customer.id
                                                          )}
                                                        />
                                                        <em>Select All</em>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div className="transaction-container">
                                                    <div className="transaction-list-container">
                                                      {customer.transactions
                                                        .filter(
                                                          (transaction) => !transaction.deleted
                                                        )
                                                        .map((transaction) => (
                                                          <div
                                                            key={transaction.id}
                                                            className="form-check">
                                                            <label className="form-check-label">
                                                              <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                onChange={() =>
                                                                  editor.toggleTransaction(
                                                                    brand.id,
                                                                    customer.id,
                                                                    transaction.id
                                                                  )
                                                                }
                                                                checked={editor.isTransactionSelected(
                                                                  brand.id,
                                                                  customer.id,
                                                                  transaction.id
                                                                )}
                                                              />
                                                              {transaction.name}
                                                              {transaction.status ===
                                                                TransactionStatus.INACTIVE &&
                                                                ' [inactive]'}
                                                            </label>
                                                          </div>
                                                        ))}
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                          {editor.readOnly && (
                                            <>
                                              {customer.transactions
                                                .filter(
                                                  (transaction) =>
                                                    !transaction.deleted &&
                                                    editor.isTransactionSelected(
                                                      brand.id,
                                                      customer.id,
                                                      transaction.id
                                                    )
                                                )
                                                .map((transaction) => (
                                                  <div
                                                    className="d-flex transaction-item"
                                                    key={transaction.id}>
                                                    <div>
                                                      {transaction.name}
                                                      {transaction.status ===
                                                        TransactionStatus.INACTIVE && ' [inactive]'}
                                                    </div>
                                                  </div>
                                                ))}
                                            </>
                                          )}
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    ))}
                                </Accordion>
                              </>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserEditor;
