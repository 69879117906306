import logoImage from '../../assets/images/UniPay-logo-color.png';
import { useAuthorization, useBodyClass } from '../../utils/hooks';

export const LoginLayout = ({ children }) => {
  useBodyClass('is-Login');
  useAuthorization();
  const currentYear = new Date().getFullYear();

  return (
    <>
      <a className="sr-only" href="#mainContent">
        Skip to main content
      </a>
      <div className="d-flex" id="wrapper">
        <div className="container">
          <div className="row">
            <div className="col col1">
              <div>
                <h1>
                  Secure online payment solutions{' '}
                  <span>for banks, municipalities, and partner organizations.</span>
                </h1>
              </div>
            </div>
            <div className="col col2">
              <div>
                <div className="login">
                  <div className="brand">
                    <img src={logoImage} alt="UniPay" />
                  </div>
                  {children}
                </div>
                <div className="footer">
                  <div>
                    <strong>Need Assistance?</strong> Call{' '}
                    <a href="tel:18772271157">1-877-227-1157</a> or email{' '}
                    <a href="mailto:support@unipayteam.com">support@unipayteam.com</a>
                  </div>
                  <div>&copy; {currentYear} UniBank</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
