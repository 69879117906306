import { useState } from 'react';
import { DefaultLayout as Layout } from '../../../layouts';
import { DashboardTiles } from './DashboardTiles/DashboardTiles';
import { Permissions } from '../../../utils/constants/permissions';
import { RequiresPermission } from '../../../components/authorization';

export const DashboardPage = () => {
  const [showManageTileMenu, setShowManageTileMenu] = useState(false);
  const toggleManageTiles = (e) => {
    e.preventDefault();
    setShowManageTileMenu(!showManageTileMenu);
  };

  return (
    <Layout>
      <div className="titleBar">
        <h1 className="pageTitle">Dashboard</h1>
        <div className="actions">
          <RequiresPermission permission={Permissions.Admin.TILES}>
            <a href="#!" className="manageTiles" onClick={toggleManageTiles}>
              <i className="icofont-layout"></i> Manage Tiles
            </a>
          </RequiresPermission>
        </div>
      </div>
      <DashboardTiles
        showManageTileMenu={showManageTileMenu}
        hideManageTileMenuCallback={() => setShowManageTileMenu(false)}
      />
    </Layout>
  );
};
