import { useState } from 'react';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import { Helpers } from '../../../../../../../utils/helpers/Helpers';

const useAccountInformation = (containerFormData, formStatusCallback) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    payPalExternalId: containerFormData.payPalExternalId,
    payPalUserId: containerFormData.payPalUserId,
    softDescriptor: containerFormData.softDescriptor,
    bankName: containerFormData.bankName,
    bankAccountNumber: containerFormData.bankAccountNumber,
    bankAccountType: containerFormData.bankAccountType,
    bankAccountRoutingNumber: containerFormData.bankAccountRoutingNumber,
    bankLocation: containerFormData.bankLocation
  });

  const isValidField = (name, value) => {
    switch (name) {
      case 'softDescriptor':
        return Helpers.isValidSoftDescriptor(value);
      case 'bankAccountNumber':
        return !Number.isNaN(value);
      case 'bankAccountRoutingNumber':
        return !Number.isNaN(value);
      default:
        return !Helpers.isNullOrWhitespace(value);
    }
  };

  const isMissingValues = (fields) => {
    return Object.entries(fields).some(([key, value]) => {
      return !isValidField(key, value);
    });
  };

  const [componentStatus, setComponentStatus] = useState(
    isMissingValues(formData) ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
  );

  const updateValue = ({ name, value }) => {
    const fields = { ...formData };
    fields[name] = value;
    setFormData(fields);

    setErrors((err) => {
      return { ...err, [name]: !isValidField(name, value) };
    });

    const missingValues = isMissingValues(fields);
    setComponentStatus(
      missingValues ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
    );
    formStatusCallback('accountInformation', !missingValues, fields);
  };

  return {
    componentStatus,
    formData,
    errors,
    updateValue
  };
};

export default useAccountInformation;
