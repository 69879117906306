import { useState, useCallback, useEffect, useRef } from 'react';
import { useAuthContext } from '../../../../context/provider/AuthContext';
import { Api, Helpers } from '../../../../utils/helpers';
import { AlertStatus } from '../../../../utils/constants/enums';
import { Permissions } from '../../../../utils/constants/permissions';
import styles from './MaintainImportFileDates.module.css';

const useMaintainImportFileDates = () => {
  const [{ user }] = useAuthContext();
  const hasModifyDueDatePermission =
    user && user.isAuthenticated && user.permissions.includes(Permissions.Admin.MODIFY_DUE_DATE);
  const hasModifyInterestDatePermission =
    user &&
    user.isAuthenticated &&
    user.permissions.includes(Permissions.Admin.MODIFY_INTEREST_DATE);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [fileDateResults, setFileDateResults] = useState([]);
  const [message, setMessage] = useState({
    status: AlertStatus.INFO,
    content: ''
  });

  const customers = user.userResources.brands
    .find((brand) => brand.id === user.activeBrand.id)
    .customers.map((customer) => {
      return { id: customer.id, name: customer.name };
    });
  const defaultCustomer = customers.length === 1 ? customers[0] : null;
  const [customerParameters, setCustomerParameters] = useState({
    brandId: user.activeBrand.id,
    customerId: defaultCustomer?.id ?? null
  });

  const transactions = user.userResources.brands
    .find((brand) => brand.id === user.activeBrand.id)
    .customers.find((customer) => customer.id === customerParameters.customerId)
    ?.transactions?.map((transaction) => {
      return {
        id: transaction.id,
        name: transaction.name,
        status: transaction.status,
        calculateInterest: transaction.calculateInterest
      };
    });

  const loadCustomerFileDates = useCallback(async () => {
    setLoading(true);
    setFileDateResults([]);

    if (customerParameters.brandId <= 0 || customerParameters.customerId <= 0) {
      setLoading(false);
      return false;
    }
    const fileDateEndpoint = `/policy/brand/${customerParameters.brandId}/customer/${customerParameters.customerId}/import-file-dates`;
    const apiResponse = await Api.get(fileDateEndpoint);
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }

    //set some default properties and store the original values for comparison
    for (let row of apiResponse.response) {
      const transactionInfo = transactions.find((tx) => tx.id === row.transactionId);

      row.errors = [];
      row.hasChanged = false;
      row.dueDateX = Helpers.tryParseDate(row.dueDate, null);
      row.expiresX = Helpers.tryParseDate(row.expires, null);
      row.interestDemandFeeDateX = Helpers.tryParseDate(row.interestDemandFeeDate, null);
      row.interestCalculationDateX = Helpers.tryParseDate(row.interestCalculationDate, null);
      row.calculateInterest = transactionInfo?.calculateInterest ?? false;
    }
    setFileDateResults(apiResponse.response);
    setLoading(false);
  }, [customerParameters.brandId, customerParameters.customerId]);

  const updateCustomerParams = (customerId) => {
    if (customerId === 0) customerId = null;
    setCustomerParameters({ ...customerParameters, customerId });
  };

  const rowChangeByKey = (rowId, key, value) => {
    let rows = [...fileDateResults];
    let rowToUpdate = rows.find((x) => x.rowId === rowId);
    if (rowToUpdate) {
      rowToUpdate[key] = Helpers.isNullOrWhitespace(value) ? null : value;
      rowToUpdate.hasChanged = true;
      rowToUpdate.errors = validateDateSet(rowToUpdate);
      setFileDateResults(rows);
    }
  };

  const getFieldClass = (rowId, fieldName) => {
    const compareField = `${fieldName}X`;
    const row = fileDateResults.find((x) => x.rowId === rowId);
    if (!row) return 'form-control';

    const testValue = Helpers.tryParseDate(row[fieldName], null);

    if (
      (testValue === null && row[compareField] !== null) ||
      (testValue !== null && row[compareField] === null) ||
      (testValue !== null &&
        row[compareField] !== null &&
        testValue.getTime() !== row[compareField].getTime())
    ) {
      return 'form-control ' + styles.fieldHasChanges;
    }
    return 'form-control';
  };

  const validateFileDates = () => {
    let hasError = false;
    let rows = [...fileDateResults];
    for (let row of rows.filter((x) => x.hasChanged)) {
      row.errors = validateDateSet(row);
      if (row.errors.length > 0) {
        hasError = true;
      }
    }
    setFileDateResults(rows);
    return hasError;
  };

  const validateDateSet = (row) => {
    let errors = [];

    const currentDate = new Date();
    const currentUtcDate = Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const dueDateLimit = Helpers.addDays(currentUtcDate, 5);
    const dueDateDisplay = Helpers.addDays(currentDate, 5);

    const testDueDate = Helpers.tryParseDate(`${row.dueDate}`, null);
    const testExpDate = Helpers.tryParseDate(`${row.expires}`, null);
    const testDemandDate = Helpers.tryParseDate(`${row.interestDemandFeeDate}`, null);
    const testInterestDate = Helpers.tryParseDate(`${row.interestCalculationDate}`, null);

    if (testExpDate === null) {
      errors.push(`Expiration date is required.`);
    }
    if (testDueDate === null && row.dueDateX !== null) {
      errors.push(`Due Date/AutoPay date is required.`);
    }

    //no need to continue if we don't have the basics
    if (errors.length > 0) return errors;

    //if they changed the due date, we need to check the new value
    if (
      hasModifyDueDatePermission &&
      row.dueDateX !== null &&
      testDueDate.getTime() !== row.dueDateX.getTime() &&
      testDueDate.getTime() < dueDateLimit.getTime()
    ) {
      errors.push(`Due date must be ${Helpers.toShortDate(dueDateDisplay)} or later.`);
    }

    //if the due date or interest date changes, recheck interest date
    //interest date must be after due date
    if (
      hasModifyInterestDatePermission &&
      testInterestDate &&
      testDueDate &&
      testInterestDate.getTime() !== (row.interestCalculationDateX?.getTime() ?? 0) &&
      testInterestDate.getTime() <= testDueDate.getTime()
    ) {
      errors.push(`Begin Interest Date must be after the due date.`);
    }

    //if demand fee date or interest date had a previous value, it must still have a value. (copying legacy logic)
    if (testDemandDate === null && row.interestDemandFeeDateX !== null) {
      errors.push(`Demand Fee Date must be provided if previously set.`);
    }
    //if demand fee date or interest date had a previous value, it must still have a value. (copying legacy logic)
    if (testInterestDate === null && row.interestCalculationDateX !== null) {
      errors.push(`Begin Interest Date must be provided if previously set.`);
    }

    return errors;
  };

  const saveChanges = async () => {
    const hasErrors = validateFileDates();
    if (hasErrors || saving) {
      return;
    }
    setSaving(true);

    let rowsToSave = [];
    let rows = [...fileDateResults];
    for (let row of rows.filter((x) => x.hasChanged)) {
      rowsToSave.push({
        rowId: row.rowId,
        transactionId: row.transactionId,
        expires: row.expires,
        interestDemandFeeDate: row.interestDemandFeeDate,
        dueDate: row.dueDate,
        interestCalculationDate: row.interestCalculationDate
      });
    }

    try {
      const fileDateEndpoint = `/policy/brand/${customerParameters.brandId}/customer/${customerParameters.customerId}/import-file-dates`;
      const apiResponse = await Api.post(fileDateEndpoint, JSON.stringify(rowsToSave));
      if (apiResponse.status.statusCode !== 200) {
        showMessage('Error occurred ' + apiResponse.status.errors, AlertStatus.ERROR, 8000);
        return;
      }
      if (!apiResponse.response) {
        showMessage(
          'Unable to save file dates. Please check your values and try again.',
          AlertStatus.ERROR,
          8000
        );
        return;
      }
      showMessage('Changes saved successfully!', AlertStatus.SUCCESS, 4000);
      loadCustomerFileDates();
      return;
    } catch (e) {
      showMessage('Error occurred ' + e, AlertStatus.ERROR, 8000);
    } finally {
      setSaving(false);
    }
  };

  const messageTimerRef = useRef(null);
  const showMessage = (message, status = AlertStatus.INFO, timeout = null) => {
    clearTimeout(messageTimerRef.current);
    setMessage({
      status: status,
      content: message
    });

    if (timeout && typeof timeout === 'number') {
      messageTimerRef.current = setTimeout(() => {
        clearMessage();
      }, timeout);
    }
  };

  const clearMessage = () => {
    setMessage({
      status: AlertStatus.INFO,
      content: ''
    });
  };

  useEffect(() => {
    setCustomerParameters({ ...customerParameters, customerId: defaultCustomer?.id ?? null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.activeBrand.id]);

  useEffect(() => {
    loadCustomerFileDates();
  }, [loadCustomerFileDates, customerParameters.customerId]);

  return {
    loading,
    saving,
    customerParameters,
    customers,
    defaultCustomer,
    fileDateResults,
    formHasChanges: fileDateResults.some((x) => x.hasChanged),
    message,
    hasModifyDueDatePermission,
    hasModifyInterestDatePermission,
    getFieldClass,
    updateCustomerParams,
    saveChanges,
    rowChangeByKey
  };
};

export default useMaintainImportFileDates;
