import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../../../context/provider/AuthContext';
import { Permissions } from '../../../../../../utils/constants/permissions';
import { Helpers } from '../../../../../../utils/helpers';

export const FindPaymentTile = () => {
  const [searchParameters, setSearchParameters] = useState({
    confirmationNumber: '',
    emailAddress: ''
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [{ user }] = useAuthContext();
  const hasPermission =
    user && user.isAuthenticated && user.permissions.includes(Permissions.Research.SEARCH);
  if (!hasPermission) return '';

  const updateSearchParams = (key, value, options) => {
    setFieldError(key, false);

    //handle clearing custom error messages based on which fields were changed
    switch (key) {
      case 'emailAddress':
      case 'confirmationNumber':
        setFieldError('basicSearchMissing', false);
        break;
    }

    let params = { ...searchParameters };
    if (options?.numbersOnly) {
      value = value.replace(/\D/g, '');
    }
    params[key] = value;
    setSearchParameters(params);
  };

  const setFieldError = (field, hasError) => {
    let errorsToUpdate = { ...errors };
    errorsToUpdate[field] = hasError;
    setErrors(errorsToUpdate);
  };

  const runBasicSearch = () => {
    let formErrors = {};
    if (
      !Helpers.isNullOrWhitespace(searchParameters.emailAddress) &&
      !Helpers.isValidEmail(searchParameters.emailAddress)
    ) {
      formErrors.emailAddress = true;
    }

    if (
      Helpers.isNullOrWhitespace(searchParameters.confirmationNumber) &&
      Helpers.isNullOrWhitespace(searchParameters.emailAddress)
    ) {
      formErrors.basicSearchMissing = true;
    }

    setErrors(formErrors);

    //do we have any errors?
    if (Object.keys(formErrors).length > 0) {
      return;
    }
    navigate({
      pathname: '/research',
      search: `?confirmationNumber=${encodeURIComponent(
        searchParameters.confirmationNumber
      )}&email=${encodeURIComponent(searchParameters.emailAddress)}`
    });
  };

  return (
    <>
      <div className="g-3 w-100">
        <div className="form-group">
          <label htmlFor="confirmationNumber">Confirmation Number (numbers only)</label>
          <input
            type="text"
            id="confirmationNumber"
            className="form-control form-control-sm"
            onChange={(e) =>
              updateSearchParams('confirmationNumber', e.target.value, {
                numbersOnly: true
              })
            }
            value={searchParameters.confirmationNumber}
            placeholder=""
          />
        </div>
        <div className="form-group">
          <label htmlFor="emailAddress">Email Address</label>
          <input
            placeholder=""
            type="text"
            className="form-control form-control-sm"
            id="emailAddress"
            onChange={(e) => updateSearchParams('emailAddress', e.target.value)}
            value={searchParameters.emailAddress}
          />
          {errors.emailAddress && (
            <div className="alert-danger mt-1 p-2">
              This is not a valid address. Please enter a valid email address.
            </div>
          )}
        </div>
        {errors.basicSearchMissing && (
          <div className="alert-danger mt-1 p-2">
            Confirmation number or email address required.
          </div>
        )}
        <div className="mt-3 d-flex justify-content-between">
          <button className="searchSubmit btn btn-primary" onClick={runBasicSearch}>
            Search
          </button>
        </div>
      </div>
    </>
  );
};

export default FindPaymentTile;
