import { DefaultLayout as Layout } from '../../../layouts';
import { useAuthorization } from '../../../utils/hooks';
import { useAuthContext } from '../../../context/provider/AuthContext';
import { MfaRequirementType } from '../../../utils/constants/enums';
import { Helpers } from '../../../utils/helpers';

export const UserProfilePage = () => {
  const auth = useAuthorization();
  const [{ user }] = useAuthContext();
  const identityUrl = process.env.REACT_APP_IDENTITY_URL;
  const displayName = `${user.firstName} ${user.lastName}`;

  const changePassword = (e) => {
    e.preventDefault();
    const returnUrl = window.location.href;
    window.location.href = `${identityUrl}/Identity/Account/ChangePassword?ReturnURL=${encodeURIComponent(
      returnUrl
    )}&username=${encodeURIComponent(user.userName)}`;
  };

  const getMfaRequirement = () => {
    let mfaType = MfaRequirementType.NONE;

    //check role first
    for (const role of user.roles) {
      const roleDetail = user.userResources.roles.find((r) => r.id === role.id);
      if (roleDetail && roleDetail.rules.mfaRequirementType > MfaRequirementType.NONE) {
        mfaType = roleDetail.rules.mfaRequirementType;
        //if role is set to every login, no need to check any other roles
        if (mfaType === MfaRequirementType.EVERY_LOGIN) {
          return mfaType;
        }
      }
    }

    //check brands/customers
    for (const brand of user.userResources.brands) {
      for (const customer of brand.customers) {
        if (customer.mfaRequirement > mfaType) {
          mfaType = customer.mfaRequirement;
        }
        //if we find an every login requirement, no need to continue
        if (mfaType === MfaRequirementType.EVERY_LOGIN) {
          return mfaType;
        }
      }
    }

    return mfaType;
  };

  const mfaType = getMfaRequirement();
  let mfaDisplayText = 'Not Required';
  if (mfaType !== MfaRequirementType.NONE) {
    mfaDisplayText = `${Helpers.mfaRequirementAsText(
      mfaType
    )} via ${Helpers.mfaNotificationMethodAsText(user.mfaNotificationMethod)}`;
  }

  return (
    <Layout>
      <div className="titleBar">
        <h1 className="pageTitle">User Profile</h1>
        <div className="actions">
          <button
            id="login-submit"
            onClick={auth.logout}
            type="submit"
            className="w-100 btn btn-lg btn-primary">
            Logout
          </button>
        </div>
      </div>
      <div className="mt-4">
        <div className="row">
          <div className="col-12 col-sm-3">
            <div className="form-group">
              <label htmlFor="userName">Name</label>
              <input
                type="text"
                className="form-control"
                id="userName"
                value={displayName}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="emailAddress">Email Address</label>
              <input
                type="text"
                className="form-control"
                id="emailAddress"
                value={user.emailAddress}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="phone"
                className="form-control"
                id="phoneNumber"
                value={user.phoneNumber}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="mfaReqs">MFA Requirements</label>
              <input
                type="text"
                className="form-control"
                value={mfaDisplayText}
                id="mfaReqs"
                disabled
              />
            </div>
          </div>

          <div className="col-12 col-sm-3">
            <div className="form-group">
              <label htmlFor="roleTemplate">Role Template</label>
              <input
                type="text"
                className="form-control"
                id="roleTemplate"
                value={user.roles[0]?.name}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="userName">User Name</label>
              <input
                type="text"
                className="form-control"
                value={user.userName}
                id="userName"
                disabled
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <button className="w-100 btn btn-lg btn btn-primary" onClick={changePassword}>
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
