import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../context/provider/AuthContext';
import { Helpers } from '../../../utils/helpers';
import { TransactionStatus } from '../../../utils/constants/enums';

const useTransactionSelector = ({
  activeBrandId = null,
  showInactive = false,
  showDeleted = false,
  onChange,
  onCustomerChange,
  onTransactionChange
}) => {
  const [{ user }] = useAuthContext();
  const [showInactiveTransactions, setShowInactiveTransactions] = useState(showInactive);
  const [showDeletedTransactions, setShowDeletedTransactions] = useState(showDeleted);

  const customers = user.userResources.brands
    .find((brand) => brand.id === user.activeBrand.id)
    .customers.map((customer) => {
      return { id: customer.id, name: customer.name };
    });

  const defaultCustomer = customers.length === 1 ? customers[0] : null;

  const [selectedOptions, setSelectedOptions] = useState({
    brandId: user.activeBrand.id,
    customerId: defaultCustomer?.id,
    transactionId: null
  });

  const transactions = user.userResources.brands
    .find((brand) => brand.id === user.activeBrand.id)
    .customers.find((customer) => customer.id === selectedOptions.customerId)
    ?.transactions.filter(
      (transaction) =>
        (showInactiveTransactions ||
          (!showInactiveTransactions && transaction.status !== TransactionStatus.INACTIVE)) &&
        (showDeletedTransactions || (!showDeletedTransactions && !transaction.deleted))
    )
    .map((transaction) => {
      return {
        id: transaction.id,
        name: transaction.name,
        status: transaction.status,
        deleted: transaction.deleted
      };
    });

  const updateCustomerId = (e) => {
    const customerId = Helpers.tryParseInt(e.target.value, null);
    setSelectedOptions({ ...selectedOptions, customerId: customerId, transactionId: null });
    if (onCustomerChange) onCustomerChange(customerId);
  };

  const updateTransactionId = (e) => {
    const txId = Helpers.tryParseInt(e.target.value, null);
    setSelectedOptions((selectedOptions) => ({ ...selectedOptions, transactionId: txId }));
    if (onTransactionChange) onTransactionChange(txId);
  };

  const toggleInactiveTransactions = () => {
    //if hiding inactive transactions, unselect any that are inactive
    let isValidSelection =
      transactions?.some(
        (transaction) =>
          transaction.id === selectedOptions.transactionId &&
          transaction.status !== TransactionStatus.INACTIVE
      ) ?? false;

    if (!showInactiveTransactions && !isValidSelection) {
      setSelectedOptions({ ...selectedOptions, transactionId: null });
    }
    setShowInactiveTransactions(!showInactiveTransactions);
  };

  const toggleDeletedTransactions = () => {
    //if hiding deleted transactions, unselect any that are deleted
    let isValidSelection =
      transactions?.some(
        (transaction) => transaction.id === selectedOptions.transactionId && !transaction.deleted
      ) ?? false;
    if (!showDeletedTransactions && !isValidSelection) {
      setSelectedOptions({ ...selectedOptions, transactionId: null });
    }
    setShowDeletedTransactions(!showDeletedTransactions);
  };

  useEffect(() => {
    if (onChange) onChange(selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  useEffect(() => {
    setSelectedOptions({
      brandId: activeBrandId,
      customerId: null,
      transactionId: null
    });
  }, [activeBrandId]);

  return {
    user,
    defaultCustomer,
    customers,
    transactions,
    selectedOptions,
    showInactiveTransactions,
    showDeletedTransactions,
    updateCustomerId,
    updateTransactionId,
    toggleInactiveTransactions,
    toggleDeletedTransactions
  };
};

export default useTransactionSelector;

useTransactionSelector.propTypes = {
  showInactive: PropTypes.bool,
  showDeleted: PropTypes.bool,
  onChange: PropTypes.func,
  onCustomerChange: PropTypes.func,
  onTransactionChange: PropTypes.func
};
