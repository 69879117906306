import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { AlertMessage, ProgressBar, ReportViewer, ModalDialog } from '../../../../components/ui';
import { ScreenViewType } from '../../../../utils/constants/enums';
import styles from './GeneratedReports.module.css';
import useGeneratedReports from './useGeneratedReports';
import { Helpers } from '../../../../utils/helpers';
import { Link } from 'react-router-dom';

export const GeneratedReports = () => {
  const { reportId, generatedId } = useParams();
  const manager = useGeneratedReports({ reportId, generatedId });

  if (!manager.loading && !manager.reportDetails.isReportAccessible) {
    return (
      <div>
        <h1>Report Not Available</h1>
        You do not have access to this report or your access has been removed.
        <br />
        Please contact the owner of the scheduled report to add your email address to the
        notification list.
      </div>
    );
  }

  return (
    <>
      <ModalDialog
        showDialog={manager.deleteDialogVisible}
        heading="Delete Report"
        message="Are you sure you want to delete this generated report?"
        okCallback={manager.deleteReport}
        okButtonText="Yes, delete this report."
        cancelCallback={manager.closeDeleteDialog}
        cancelButtonText="No, don't delete."
      />
      <div
        className={`container ${
          manager.viewType === ScreenViewType.FULLSCREEN
            ? 'fullscreen-report themed-bg-color'
            : 'mt-0'
        }`}>
        <div>
          {!manager.loading && (
            <>
              <div className="titleBar mb-0">
                <h1 className="pageTitle">
                  {manager.getReportName(manager.reportDetails.reportParameters.reportType)} Report
                  &mdash;{' '}
                  {Helpers.getScheduledReportDateRangeAsText(
                    manager.reportDetails.reportParameters.dateRangeType
                  )}
                </h1>
                <div className="actions">
                  <Link to={`/scheduled-reports`} className="btn btn-primary mt-2">
                    View All Scheduled Reports
                  </Link>
                </div>
              </div>
              <div className={styles.reportDetailName}>{manager.reportDetails.name}</div>
            </>
          )}
        </div>
        {manager.loading && (
          <div className="m-5 text-center">
            <Spinner animation="border" />
          </div>
        )}
        {!manager.loading && (
          <>
            <div className="row">
              <div className="col-xl-2 col-md-12">
                {manager.reportDetails.generatedReports.map((item) => {
                  return (
                    <a
                      key={item.id}
                      href="#"
                      className={
                        manager.generatedReportId === item.id
                          ? styles.viewReportLinkActive
                          : styles.viewReportLink
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        manager.loadReport(item.id);
                      }}>
                      {Helpers.toLongDate(item.dateCreatedUtc)}
                    </a>
                  );
                })}
              </div>
              <div className="col-xl-10 col-md-12 pt-0">
                <ReportViewer
                  loading={manager.reportLoading}
                  viewType={manager.viewType}
                  searchEnabled={true}
                  toggleReportViewCallback={manager.toggleReportView}
                  reportHtml={manager.reportHtml}
                  getReportDownloadCallback={manager.getReportDownload}
                  deleteReportCallback={manager.confirmDeleteReport}
                  showDeleteButton={manager.isReportOwner}
                  findScrollOffset={270}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

GeneratedReports.propTypes = {
  reportId: PropTypes.string
};

export default GeneratedReports;
