import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Helpers } from '../../../../utils/helpers';
import { PagingControls, LoadingContainer } from '../../../../components/ui';
import usePayPalActivitySearch from './usePayPalActivitySearch';
import './PayPalActivitySearch.css';
import { ResearchDateRangeType } from '../../../../utils/constants/enums';
import PayPalBatchDetailsModal from '../PayPalBatchDetailsModal/PayPalBatchDetailsModal';

export const PayPalActivitySearch = () => {
  const search = usePayPalActivitySearch();

  return (
    <>
      <PayPalBatchDetailsModal
        brandId={search.brandId}
        payPalId={search.batchDetailsModal.payPalId}
        batchDate={search.batchDetailsModal.batchDate}
        onClose={search.closeBatchDetails}
      />

      <div className="titleBar">
        <h1 className="pageTitle">PayPal Activity</h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-4">
            <div className="payPalSearchContainer border">
              <div className="g-3 heading">
                <div className="col-auto">
                  {search.defaultCustomer ? (
                    <h2 className="h6">Select a date range and optional payor name:</h2>
                  ) : (
                    <h2 className="h6">
                      Select a customer and date range and optional payor name:
                    </h2>
                  )}
                </div>
              </div>
              <div className="g-3 w-100">
                <div className="form-group">
                  <label htmlFor="payPalId" className="d-block">
                    Customer {search.payPalAccounts.length !== 1 && <span className="req">*</span>}
                  </label>
                  <LoadingContainer spinnerSize="sm" isLoading={search.accountsLoading}>
                    <>
                      {search.payPalAccounts.length === 1 && (
                        <div className="researchCustomerName">
                          {search.getDisplayName(search.payPalAccounts[0])}
                        </div>
                      )}
                      {search.payPalAccounts.length > 1 && (
                        <>
                          <select
                            id="payPalId"
                            className="form-select"
                            aria-label="PayPal account selection"
                            value={search.searchParameters.payPalId ?? ''}
                            onChange={(e) => search.updateSearchParams('payPalId', e.target.value)}>
                            <option value="">- Select -</option>
                            {search.payPalAccounts.map((account) => {
                              return (
                                <option
                                  key={account.payPalId + '-' + account.friendlyName}
                                  value={account.payPalId}
                                  style={
                                    account.friendlyName.includes('(INACTIVE)')
                                      ? { color: '#b00' }
                                      : { color: 'black' }
                                  }>
                                  {search.getDisplayName(account)}
                                </option>
                              );
                            })}
                          </select>
                          {search.errors.payPalId && (
                            <div className="mt-1 alert-danger p-2">
                              Customer account selection required.
                            </div>
                          )}
                        </>
                      )}
                    </>
                  </LoadingContainer>
                </div>

                <div className="row dateRangeWrap">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="date-range" className="text-nowrap">
                        Date Range
                      </label>
                      <select
                        id="date-range"
                        className="form-select"
                        aria-label="Date Range selection"
                        value={search.searchParameters.dateRangeType}
                        onChange={(e) =>
                          search.updateSearchParams(
                            'dateRangeType',
                            Helpers.tryParseInt(e.target.value, null)
                          )
                        }>
                        <option value={ResearchDateRangeType.YESTERDAY}>Yesterday</option>
                        <option value={ResearchDateRangeType.PREVIOUS_3_DAYS}>
                          Previous 3 Days
                        </option>
                        <option value={ResearchDateRangeType.PREVIOUS_7_DAYS}>
                          Previous 7 Days
                        </option>
                        <option value={ResearchDateRangeType.PREVIOUS_MONTH}>Previous Month</option>
                        <option value={ResearchDateRangeType.PREVIOUS_QUARTER}>
                          Previous Quarter
                        </option>
                        <option value={ResearchDateRangeType.PREVIOUS_YEAR}>Previous Year</option>
                        <option value={ResearchDateRangeType.CUSTOM}>Custom</option>
                      </select>
                    </div>
                  </div>
                  {search.searchParameters.dateRangeType === ResearchDateRangeType.CUSTOM && (
                    <>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex flex-wrap align-items-center mb-1">
                            <div className="me-3 paypal-date-label">
                              <label htmlFor="fromDate">From:</label>
                            </div>
                            <input
                              type="date"
                              id="fromDate"
                              className="form-control paypal-search-date"
                              value={search.searchParameters.fromDate ?? ''}
                              onChange={(e) =>
                                search.updateSearchParams('fromDate', e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex flex-wrap align-items-center">
                            <div className="me-3 paypal-date-label">
                              <label htmlFor="toDate">To:</label>
                            </div>
                            <input
                              type="date"
                              id="toDate"
                              className="form-control paypal-search-date"
                              value={search.searchParameters.toDate ?? ''}
                              onChange={(e) => search.updateSearchParams('toDate', e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {search.errors.customDateMissing && (
                  <div className="row">
                    <div className="col-12">
                      <div className="mt-1 alert-danger p-2">
                        Date fields are required for custom date range selection.
                      </div>
                    </div>
                  </div>
                )}

                {search.errors.customDateInvalid && (
                  <div className="row">
                    <div className="col-12">
                      <div className="mt-1 alert-danger p-2">
                        Date range fields must be before today.
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={`row ${
                    search.searchParameters.dateRangeType === ResearchDateRangeType.CUSTOM
                      ? 'mt-3'
                      : ''
                  }`}>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="payor-name">Payor Name (optional):</label>
                      <input
                        type="text"
                        id="payor-name"
                        className="form-control"
                        maxLength={100}
                        value={search.searchParameters.payorName}
                        onKeyDown={(e) => search.handleSearchKeyDown(e)}
                        onChange={(e) => search.updateSearchParams('payorName', e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-between">
                  <button
                    className="searchSubmit btn btn-primary"
                    onClick={search.resetPayPalSearch}>
                    Reset
                  </button>
                  <button className="searchSubmit btn btn-primary" onClick={search.runPayPalSearch}>
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-8">
            {search.apiError.trim() !== '' && (
              <div className="alert-danger mb-1 p-2">{search.apiError}</div>
            )}
            <div className="card">
              <div className="card-body">
                {search.loading && <Spinner animation="border" />}
                {!search.loading && (
                  <div className="table-responsive">
                    {!search.pagedResult && (
                      <>
                        <h2>Search for Payments</h2>
                        <div>Use the search forms to find payments.</div>
                      </>
                    )}
                    {search.pagedResult && search.pagedResult.results.length === 0 && (
                      <>
                        {search.pagedResult.resultsFiltered && (
                          <>
                            <h2>Payment Details Not Available</h2>
                            <div>
                              Due to your user permissions, you do not have access to this payment.
                            </div>
                          </>
                        )}
                        {!search.pagedResult.resultsFiltered && (
                          <>
                            <h2>No Results for Your Search</h2>
                            <div>Please update your search criteria and try again.</div>
                          </>
                        )}
                      </>
                    )}
                    {search.pagedResult?.results.length > 0 && (
                      <>
                        <div className="resultCount">
                          {search.pagedResult.resultCount} result
                          {search.pagedResult.resultCount === 1 ? '' : 's'} found.
                        </div>
                        <h2>Results</h2>
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Batch Date</th>
                              <th scope="col">Customer</th>
                              <th scope="col">Seller Amount</th>
                              <th scope="col">Fees</th>
                            </tr>
                          </thead>
                          <tbody>
                            {search.pagedResult?.results.map((result) => {
                              return (
                                <tr
                                  key={result.batchDate}
                                  className={
                                    search.isRowViewed(result.batchDate) ? 'row-viewed' : ''
                                  }>
                                  <td>
                                    <a
                                      href="#!"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        search.viewBatchDetails(result.batchDate);
                                      }}>
                                      {Helpers.toShortDate(result.batchDate)}
                                    </a>
                                  </td>
                                  <td>{result.customerName}</td>
                                  <td>{Helpers.formatCurrency(result.sellerAmount)}</td>
                                  <td>{Helpers.formatCurrency(result.fees)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div>
                          <PagingControls
                            page={search.pagedResult.currentPage}
                            pageCount={search.pagedResult.pageCount}
                            changePageCallback={search.changePage}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
