import { DefaultLayout as Layout } from '../../../layouts';
import { ResearchSearch } from './ResearchSearch/ResearchSearch';

export const ResearchPage = () => {
  return (
    <Layout>
      <ResearchSearch />
    </Layout>
  );
};
