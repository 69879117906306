import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faCircleCheck,
  faCircleDot
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import { AlertMessage, ProgressBar, HelpTip } from '../../../../components/ui';
import { RecurrenceOptions } from '../RecurrenceOptions/RecurrenceOptions';
import { DateRangeHelpTips } from '../DateRangeHelpTips/DateRangeHelpTips';
import { ScheduledReportReview } from '../ScheduledReportReview/ScheduledReportReview';
import styles from './ScheduledReportConfigModal.module.css';
import useScheduledReportConfigModal from './useScheduledReportConfigModal';
import {
  ReportDateRangeType,
  ReportType,
  TransactionStatus,
  ReportScheduleType,
  ReportDeliveryType
} from '../../../../utils/constants/enums';
import { Helpers } from '../../../../utils/helpers';

export const ScheduledReportConfigModal = ({
  loading,
  message,
  scheduleConfig,
  closeCallback,
  saveChangesCallback,
  deleteReportCallback
}) => {
  const editor = useScheduledReportConfigModal({
    scheduleConfig,
    saveChangesCallback,
    closeCallback
  });

  return (
    <>
      <Modal
        show={scheduleConfig.showModal}
        backdrop="static"
        keyboard={true}
        fullscreen={false}
        size="lg"
        dialogClassName={styles.scheduleReportModal}
        centered={false}
        onHide={editor.handleCloseModal}>
        <Modal.Header closeButton={true} className={styles.modalHeader}>
          <Modal.Title>{scheduleConfig.id ? 'Update' : 'Create'} Your Scheduled Report</Modal.Title>
          {scheduleConfig.id && (
            <button
              className="btn btn-danger btn-sm ms-3"
              onClick={() => deleteReportCallback(scheduleConfig.id)}>
              Delete Report
            </button>
          )}
        </Modal.Header>
        <ProgressBar className="m-0" visible={loading} />
        <AlertMessage
          className="rounded-0"
          visible={message.content.trim() !== ''}
          message={message.content}
          status={message.status}
          scrollTo={true}
        />
        <Modal.Body>
          <div className="row">
            <div className={'col-xl-3 col-md-12 ' + styles.wizardStepContainer}>
              {editor.wizardSteps.map((stepName, index) => {
                let stepClass = styles.wizardStep;
                let iconClass = styles.wizardStepIcon;
                if (index === editor.currentStep) {
                  stepClass = styles.wizardStepActive;
                }
                let icon = <FontAwesomeIcon className={iconClass} icon={faCircle} />;
                const stepIsCompleted = editor.isStepCompleted(index);

                if (!stepIsCompleted && editor.currentStep > index) {
                  iconClass = styles.wizardStepWarningIcon;
                  icon = <FontAwesomeIcon className={iconClass} icon={faCircleDot} />;
                } else if (stepIsCompleted) {
                  iconClass = styles.wizardStepCompleteIcon;
                  icon = <FontAwesomeIcon className={iconClass} icon={faCircleCheck} />;
                }

                return (
                  <a
                    href="#"
                    key={index}
                    onClick={(e) => editor.gotoWizardStep(e, index)}
                    className={stepClass}>
                    {icon}
                    <span className={styles.stepNumber}>{index + 1}</span>
                    <span className={styles.stepName}>{stepName}</span>
                  </a>
                );
              })}
              {window.location.host.startsWith('local') && (
                <>
                  <a
                    href="#"
                    style={{ color: '#aaa', fontSize: '12px', textDecoration: 'none' }}
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById('debugReportConfig').classList.toggle('d-none');
                    }}>
                    debug info
                  </a>
                  <pre
                    id="debugReportConfig"
                    className="d-none"
                    style={{ fontSize: '0.8rem', marginTop: '1rem' }}>
                    {JSON.stringify(editor.reportConfig, null, ' ')}
                  </pre>
                </>
              )}
            </div>
            <div className="col-xl-9 col-md-12">
              {/* STEP: REPORT NAME */}
              {editor.currentStep === 0 && (
                <div className="form-group mb-3">
                  <label htmlFor="reportName" className="col-form-label">
                    Report Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="reportName"
                    placeholder=""
                    maxLength={100}
                    value={editor.reportConfig.name}
                    onChange={(e) => editor.updateConfigProperty('name', e.target.value)}
                  />
                  {editor.errors.name && (
                    <div className="alert-danger mt-1 p-2">Report name required.</div>
                  )}
                  <HelpTip className="mt-2">
                    Add a friendly name to this scheduled report to make it easier to recognize in
                    the future.
                  </HelpTip>
                </div>
              )}
              {/* STEP: REPORT PARAMETERS */}
              {editor.currentStep === 1 && (
                <>
                  <div className="form-group">
                    <label htmlFor="reportType">
                      Which report would you like to schedule? <span className="req">*</span>
                    </label>
                    <select
                      id="reportType"
                      className="form-select"
                      aria-label="Report selection"
                      value={editor.reportConfig.reportParameters.reportType ?? ReportType.UNKNOWN}
                      onChange={(e) =>
                        editor.updateReportParams(
                          'reportType',
                          Helpers.tryParseInt(e.target.value, null)
                        )
                      }>
                      <option value="0">- Select -</option>
                      {editor.allowedReports.map((report) => {
                        return (
                          <option key={report.id} value={report.id}>
                            {report.name}
                          </option>
                        );
                      })}
                    </select>
                    {editor.errors.reportType && (
                      <div className="mt-1 alert-danger p-2">Report selection required.</div>
                    )}
                  </div>

                  {editor.showCustomerSelect && (
                    <>
                      <div className="form-group">
                        <label htmlFor="customer">
                          {editor.defaultCustomer ? 'Customer' : 'Select a customer: '}
                          {!editor.defaultCustomer && <span className="req">*</span>}
                        </label>
                        {editor.defaultCustomer && (
                          <div className="researchCustomerName">{editor.defaultCustomer.name}</div>
                        )}
                        {!editor.defaultCustomer && (
                          <>
                            <select
                              id="customer"
                              className="form-select"
                              aria-label="Customer selection"
                              value={editor.reportConfig.reportParameters.customerId ?? ''}
                              onChange={(e) =>
                                editor.updateReportParams(
                                  'customerId',
                                  Helpers.tryParseInt(e.target.value, null)
                                )
                              }>
                              <option>- Select -</option>
                              {editor.customers.map((customer) => {
                                return (
                                  <option key={customer.id} value={customer.id}>
                                    {customer.name}
                                  </option>
                                );
                              })}
                            </select>
                            {editor.errors.customerId && (
                              <div className="mt-1 alert-danger p-2">
                                Customer selection required.
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {editor.transactions?.length > 0 && (
                        <div className="form-group">
                          <label>Select transactions: </label>{' '}
                          <small>
                            ({editor.reportConfig.reportParameters.transactionIds.length} selected)
                          </small>
                          <div className={styles.transactionOptions}>
                            <div className={styles.showAllTransactions}>
                              <div className="form-check me-2">
                                <label className="form-check-label">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={() => editor.selectAllTransactions()}
                                    checked={editor.reportConfig.reportParameters.allTransactions}
                                  />
                                  <em>Select All Transactions</em>
                                </label>
                              </div>
                            </div>

                            <div className={styles.showTransactionOptions}>
                              <div className="form-check ms-2 me-2">
                                <label className="form-check-label">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={() => editor.toggleDeletedTransactions()}
                                    checked={
                                      editor.reportConfig.reportParameters
                                        .includeDeletedTransactions
                                    }
                                  />
                                  <em>Show Deleted</em>
                                </label>
                              </div>
                            </div>
                          </div>
                          {editor.transactions?.length > 0 && (
                            <>
                              <div className="transaction-container">
                                {editor.transactions?.map((transaction) => {
                                  const txVisible =
                                    (editor.showInactiveTransactions ||
                                      (!editor.showInactiveTransactions &&
                                        transaction.status !== TransactionStatus.INACTIVE)) &&
                                    (editor.reportConfig.reportParameters
                                      .includeDeletedTransactions ||
                                      (!editor.reportConfig.reportParameters
                                        .includeDeletedTransactions &&
                                        !transaction.deleted));

                                  const txRowClass = !txVisible ? 'd-none' : '';

                                  return (
                                    <div
                                      key={transaction.id}
                                      className={'form-check ms-0 ' + txRowClass}>
                                      <label
                                        className={`form-check-label${
                                          (transaction.status === TransactionStatus.INACTIVE ||
                                            transaction.deleted) &&
                                          ' inactive-transaction'
                                        }`}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          onChange={() => editor.updateTransaction(transaction.id)}
                                          checked={editor.reportConfig.reportParameters.transactionIds.includes(
                                            transaction.id
                                          )}
                                        />
                                        {transaction.name}
                                        {transaction.status === TransactionStatus.INACTIVE &&
                                          ' [inactive]'}
                                        {transaction.deleted && ' [deleted]'}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                          {editor.errors.transactionIds && (
                            <div className="mt-1 alert-danger p-2">
                              Transaction selection required.
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              {/* STEP: REPORT SCHEDULE */}
              {editor.currentStep === 2 && (
                <>
                  <div className="form-group">
                    <label htmlFor="date-range" className="text-nowrap">
                      What date range would you like to report on?
                    </label>
                    <select
                      id="date-range"
                      className="form-select"
                      aria-label="Date Range selection"
                      value={editor.reportConfig.reportParameters.dateRangeType ?? ''}
                      onChange={(e) =>
                        editor.updateReportParams(
                          'dateRangeType',
                          Helpers.tryParseInt(e.target.value, null)
                        )
                      }>
                      <option value="">- Select -</option>
                      <option value={ReportDateRangeType.YESTERDAY}>Previous Day</option>
                      <option value={ReportDateRangeType.BUSINESS_DAILY}>Business Daily</option>
                      <option value={ReportDateRangeType.WEEKLY}>Weekly</option>
                      <option value={ReportDateRangeType.PREVIOUS_7_DAYS}>Previous 7 Days</option>
                      <option value={ReportDateRangeType.PREVIOUS_MONTH}>Previous Month</option>
                      <option value={ReportDateRangeType.PREVIOUS_QUARTER}>Previous Quarter</option>
                      <option value={ReportDateRangeType.PREVIOUS_YEAR}>Previous Year</option>
                      <option value={ReportDateRangeType.PREVIOUS_FISCAL_YEAR}>
                        Previous Fiscal Year
                      </option>
                    </select>
                    {editor.errors.dateRangeType && (
                      <div className="mt-1 alert-danger p-2">Date range selection required.</div>
                    )}
                    <DateRangeHelpTips
                      dateRangeType={editor.reportConfig.reportParameters.dateRangeType}
                    />
                  </div>
                  {!Helpers.isNullOrWhitespace(
                    editor.reportConfig.reportParameters.dateRangeType
                  ) && (
                    <>
                      <div className="form-group mt-4">
                        <label>How often do you want to run this report?</label>
                        <div className={styles.scheduleTypeOptions}>
                          <div className={'form-check ' + styles.scheduleTypeOption}>
                            <label className={editor.oneTimeLabelStyle}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="scheduleType"
                                onChange={() =>
                                  editor.updateScheduleType(ReportScheduleType.ONE_TIME)
                                }
                                checked={
                                  editor.reportConfig.scheduleType === ReportScheduleType.ONE_TIME
                                }
                              />
                              One time
                            </label>
                          </div>
                          <div className={'form-check ' + styles.scheduleTypeOption}>
                            <label className={editor.recurringLabelStyle}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="scheduleType"
                                onChange={() =>
                                  editor.updateScheduleType(ReportScheduleType.RECURRING)
                                }
                                checked={
                                  editor.reportConfig.scheduleType === ReportScheduleType.RECURRING
                                }
                              />
                              On a recurring schedule
                            </label>
                          </div>
                        </div>
                      </div>
                      {editor.reportConfig.scheduleType === ReportScheduleType.ONE_TIME && (
                        <>
                          <div className="d-flex align-items-center">
                            <label htmlFor="sendDateUtc">Run this report on</label>
                            <input
                              type="date"
                              id="sendDateUtc"
                              className={'form-control ms-2 ' + styles.dateSelect}
                              value={Helpers.toInputFieldDate(editor.reportConfig.sendDateUtc)}
                              onChange={(e) =>
                                editor.updateConfigProperty('sendDateUtc', e.target.value)
                              }
                            />
                          </div>
                          {editor.errors.sendDateUtc && (
                            <div className="alert-danger mt-1 p-2">
                              Run date is required and must be tomorrow or later.
                            </div>
                          )}
                        </>
                      )}

                      <RecurrenceOptions
                        visible={editor.reportConfig.scheduleType === ReportScheduleType.RECURRING}
                        dateRangeType={editor.reportConfig.reportParameters.dateRangeType}
                        recurrenceParameters={editor.reportConfig.recurrenceParameters}
                        parameterChangeHandler={editor.recurrenceParameterChangeHandler}
                        errors={editor.errors}
                        clearErrorForKeysCallback={editor.clearErrorForKeys}
                      />
                    </>
                  )}
                </>
              )}
              {/* STEP: DELIVERY */}
              {editor.currentStep === 3 && (
                <>
                  <div className="form-group mt-2">
                    <label htmlFor="notificationMethod">
                      When the report is generated, how would you like to be notified?
                    </label>
                    <select
                      id="notificationMethod"
                      className="form-select"
                      aria-label="Date Range selection"
                      value={editor.reportConfig.deliveryType ?? ''}
                      onChange={(e) =>
                        editor.updateConfigProperty(
                          'deliveryType',
                          Helpers.tryParseInt(e.target.value, null)
                        )
                      }>
                      <option value="">- Select -</option>
                      <option value={ReportDeliveryType.EMAIL_NOTIFICATION}>
                        {Helpers.getReportDeliveryTypeAsText(ReportDeliveryType.EMAIL_NOTIFICATION)}
                      </option>
                      <option value={ReportDeliveryType.NONE}>
                        {Helpers.getReportDeliveryTypeAsText(ReportDeliveryType.NONE)}
                      </option>
                      {/* FUTURE */}
                      {/*
                      <option value={ReportDeliveryType.EMAIL_ATTACHMENT}>
                        {Helpers.getReportDeliveryTypeAsText(ReportDeliveryType.EMAIL_ATTACHMENT)}
                      </option>
                      */}
                    </select>
                    {editor.errors.deliveryType && (
                      <div className="mt-1 alert-danger p-2">Notification selection required.</div>
                    )}
                    {editor.reportConfig.deliveryType === ReportDeliveryType.EMAIL_NOTIFICATION && (
                      <>
                        <div className="form-group mt-3 mb-3">
                          <label htmlFor="deliveryEmail">
                            Send notifications to the following email addresses:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="deliveryEmail"
                            placeholder=""
                            maxLength={500}
                            value={editor.reportConfig.deliveryEmail}
                            onChange={(e) =>
                              editor.updateConfigProperty('deliveryEmail', e.target.value)
                            }
                          />
                          {editor.errors.deliveryEmail && (
                            <div className="alert-danger mt-1 p-2">Email address required.</div>
                          )}
                          {editor.errors.invalidDeliveryEmail && (
                            <div className="alert-danger mt-1 p-2">
                              One or more email addresses are invalid.
                            </div>
                          )}
                          <HelpTip className="mt-2">
                            Multiple email addresses should be separated by commas. Please note that
                            an admin portal account with a matching email address is required to
                            view generated reports.
                          </HelpTip>
                        </div>
                      </>
                    )}
                    {editor.reportConfig.deliveryType === ReportDeliveryType.NONE && (
                      <HelpTip className="mt-2">
                        Only a limited number of generated reports are retained in your account.
                        Don&apos;t forget to download your reports before they expire.
                      </HelpTip>
                    )}
                  </div>
                </>
              )}
              {/* STEP: REVIEW */}
              {editor.currentStep === 4 && (
                <>
                  <div className="mt-2">
                    <ScheduledReportReview
                      reportConfig={editor.reportConfig}
                      customers={editor.customers}
                      transactions={editor.transactions}
                      setStepCallback={editor.setCurrentStep}
                      allStepsCompleted={editor.areAllStepsCompleted()}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <div className="d-flex justify-content-between flex-grow-1">
            <button
              className="btn btn-primary"
              onClick={editor.getPreviousWizardStep}
              disabled={loading || editor.currentStep === 0}>
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> Previous
            </button>
            <button
              className="btn btn-primary"
              onClick={editor.getNextWizardStep}
              disabled={loading || editor.currentStep === editor.wizardSteps.length - 1}>
              Next <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
            </button>
          </div>
          {(scheduleConfig.id || editor.areAllStepsCompleted()) && (
            <div>
              <button className="btn btn-primary ms-4" onClick={editor.saveScheduledReport}>
                Save Changes
              </button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

ScheduledReportConfigModal.propTypes = {
  scheduleConfig: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired
  }),
  closeCallback: PropTypes.func.isRequired,
  saveChangesCallback: PropTypes.func.isRequired,
  deleteReportCallback: PropTypes.func
};

export default ScheduledReportConfigModal;
