import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { AlertMessage, ProgressBar, ReportViewer, ModalDialog } from '../../../../components/ui';
import { ScreenViewType } from '../../../../utils/constants/enums';
import styles from './GeneratedReportsModal.module.css';
import useGeneratedReports from './useGeneratedReports';
import { Helpers } from '../../../../utils/helpers';

export const GeneratedReportsModal = ({ reportId, closeCallback }) => {
  const manager = useGeneratedReports({ reportId, closeCallback });

  return (
    <>
      <ModalDialog
        showDialog={manager.deleteDialogVisible}
        heading="Delete Report"
        message="Are you sure you want to delete this generated report?"
        okCallback={manager.deleteReport}
        okButtonText="Yes, delete this report."
        cancelCallback={manager.closeDeleteDialog}
        cancelButtonText="No, don't delete."
      />
      <Modal
        show={reportId !== null}
        backdrop="static"
        keyboard={true}
        fullscreen={manager.viewType === ScreenViewType.FULLSCREEN}
        size="lg"
        dialogClassName={
          manager.viewType === ScreenViewType.FULLSCREEN ? '' : styles.scheduleReportModal
        }
        centered={false}
        onHide={manager.handleCloseModal}>
        <Modal.Header closeButton={true}>
          <Modal.Title>
            {!manager.loading && (
              <>
                {manager.getReportName(manager.reportDetails.reportParameters.reportType)} Report
                &mdash;{' '}
                {Helpers.getScheduledReportDateRangeAsText(
                  manager.reportDetails.reportParameters.dateRangeType
                )}
              </>
            )}
            {manager.loading && 'Loading Report'}
          </Modal.Title>
        </Modal.Header>
        {!manager.loading && (
          <div className={'themed-bg-dark ' + styles.reportDetailRow}>
            <div>
              <strong>{manager.reportDetails.name}</strong>
            </div>
            <div></div>
          </div>
        )}
        <ProgressBar className="m-0" visible={manager.loading} />
        <AlertMessage
          className="rounded-0 mb-0"
          visible={!Helpers.isNullOrWhitespace(manager.message.content)}
          message={manager.message.content}
          status={manager.message.status}
          scrollTo={true}
        />
        <Modal.Body>
          {manager.loading && <div>loading</div>}
          {!manager.loading && (
            <>
              <div className="row">
                <div className={'col-xl-2 col-md-12 ' + styles.generatedReportList}>
                  {manager.reportDetails.generatedReports.map((item) => {
                    return (
                      <a
                        key={item.id}
                        href="#"
                        className={
                          manager.generatedReportId === item.id
                            ? styles.viewReportLinkActive
                            : styles.viewReportLink
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          manager.loadReport(item.id);
                        }}>
                        {Helpers.toLongDate(item.dateCreatedUtc)}
                      </a>
                    );
                  })}
                </div>
                <div className="col-xl-10 col-md-12 pt-0">
                  <ReportViewer
                    loading={manager.reportLoading}
                    viewType={manager.viewType}
                    searchEnabled={true}
                    toggleReportViewCallback={manager.toggleReportView}
                    reportHtml={manager.reportHtml}
                    getReportDownloadCallback={manager.getReportDownload}
                    deleteReportCallback={() => manager.confirmDeleteReport()}
                    showDeleteButton={manager.isReportOwner}
                    findScrollOffset={200}
                    maxReportHeight={400}
                  />
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

GeneratedReportsModal.propTypes = {
  reportId: PropTypes.string,
  closeCallback: PropTypes.func.isRequired
};

export default GeneratedReportsModal;
