import { useEffect, useRef, useState, useCallback } from 'react';
import { Api, Helpers } from '../../../utils/helpers';
import { AlertStatus, SortDirection } from '../../../utils/constants/enums';

const useFileBrowser = ({
  browseApiEndpoint,
  imageApiEndpoint,
  downloadApiEndpoint,
  selectFileCallback
}) => {
  const bffApiUrl = process.env.REACT_APP_BFF_API_URL;
  const [currentDirectory, setCurrentDirectory] = useState('/');
  const [directoryLoading, setDirectoryLoading] = useState(false);
  const [directoryContents, setDirectoryContents] = useState([]);
  const [createFolderPromptVisible, setCreateFolderPromptVisible] = useState(false);
  const [renamePromptVisible, setRenamePromptVisible] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [fileDetails, setFileDetails] = useState(null);
  const [modalDialog, setModalDialog] = useState({
    visible: false,
    heading: '',
    message: '',
    okButtonText: '',
    cancelButtonText: '',
    okCallback: () => {},
    cancelCallback: () => {}
  });

  const FileBrowserSortType = {
    NAME: 0,
    SIZE: 1
  };
  const directorySort = useRef({
    type: FileBrowserSortType.NAME,
    direction: SortDirection.ASCENDING
  });

  const [promptMessage, setPromptMessage] = useState({
    status: AlertStatus.INFO,
    content: ''
  });
  const [message, setMessage] = useState({
    status: AlertStatus.INFO,
    content: ''
  });

  const clearMessage = () => {
    setMessage({
      status: AlertStatus.INFO,
      content: ''
    });
  };

  const buildPathSegments = () => {
    const segments = currentDirectory.split('/');
    let results = [];
    let path = '';

    //add home
    results.push({
      path: `/`,
      name: 'Home'
    });

    for (let i = 1; i < segments.length - 1; i++) {
      path += `/${segments[i]}`;
      results.push({
        path: `${path}/`,
        name: segments[i]
      });
    }

    return results;
  };
  const pathSegments = buildPathSegments();

  const clearPromptMessage = () => {
    setPromptMessage({
      status: AlertStatus.INFO,
      content: ''
    });
  };

  const uploadCompleteCallback = () => {
    loadDirectory();
  };

  const toggleUploadForm = (show) => {
    //if specified, set to requested state
    if (show !== null) {
      setShowUpload(show);
    }
    //otherwise toggle
    setShowUpload(!showUpload);
  };

  const showRenamePrompt = (e) => {
    e.preventDefault();
    setRenamePromptVisible(true);
  };

  const closeRenamePrompt = () => {
    clearPromptMessage();
    setRenamePromptVisible(false);
  };

  const renameItem = async (name) => {
    //clear any previous errors
    clearPromptMessage();
    clearMessage();

    //make sure they entered something
    if (Helpers.isNullOrWhitespace(name)) {
      setPromptMessage({
        status: AlertStatus.ERROR,
        content: 'Name required.'
      });
      return;
    }
    //sanitize name and make sure we have a value afterwards
    name = Helpers.sanitizeFolderName(name);
    if (Helpers.isNullOrWhitespace(name)) {
      setPromptMessage({
        status: AlertStatus.ERROR,
        content: 'Invalid name.'
      });
      return;
    }

    try {
      const apiResponse = await Api.put(
        `${browseApiEndpoint}?path=${encodeURIComponent(currentDirectory + fileDetails.name)}`,
        JSON.stringify(name)
      );

      if (apiResponse.status.statusCode !== 200) {
        setMessage({
          status: AlertStatus.ERROR,
          content: 'Error renaming file/folder.'
        });
      }
    } catch (error) {
      setMessage({
        status: AlertStatus.ERROR,
        content: `Error renaming file/folder. (${error})`
      });
    } finally {
      closeRenamePrompt();
      reload();
    }
  };

  const showCreateFolderPrompt = () => {
    setCreateFolderPromptVisible(true);
  };

  const closeCreateFolderPrompt = () => {
    clearPromptMessage();
    setCreateFolderPromptVisible(false);
  };

  const createFolder = async (name) => {
    //clear any previous errors
    clearPromptMessage();
    clearMessage();

    //make sure they entered something
    if (Helpers.isNullOrWhitespace(name)) {
      setPromptMessage({
        status: AlertStatus.ERROR,
        content: 'Folder name required.'
      });
      return;
    }

    //sanitize name and make sure we have a value afterwards
    name = Helpers.sanitizeFolderName(name);
    if (Helpers.isNullOrWhitespace(name)) {
      setPromptMessage({
        status: AlertStatus.ERROR,
        content: 'Invalid folder name.'
      });
      return;
    }
    try {
      const apiResponse = await Api.post(
        `${browseApiEndpoint}?path=${encodeURIComponent(currentDirectory + name)}`
      );
      if (apiResponse.status.statusCode !== 200) {
        setMessage({
          status: AlertStatus.ERROR,
          content: 'Error creating new folder.'
        });
      }
    } catch (error) {
      setMessage({
        status: AlertStatus.ERROR,
        content: `Error creating new folder. (${error})`
      });
    } finally {
      closeCreateFolderPrompt();
      loadDirectory();
    }
  };

  const reload = () => {
    setFileDetails(null);
    loadDirectory();
  };

  const changeDirectory = (path) => {
    setFileDetails(null);
    setCurrentDirectory(path);
  };

  const dblClickItem = (e, item) => {
    e.preventDefault();
    if (item.isDirectory) {
      openDirectory(item);
    } else if (selectFileCallback && typeof selectFileCallback === 'function') {
      selectFileCallback(getSelectedFile());
    } else {
      downloadFile(fileDetails.webPath);
    }
  };

  const openDirectory = (item) => {
    changeDirectory(`${currentDirectory}${item.name}/`);
  };

  const selectItem = (e, item) => {
    e.preventDefault();
    setFileDetails(item);
  };

  const goUpFolder = () => {
    const upFolder = pathSegments[pathSegments.length - 2];
    if (upFolder) {
      changeDirectory(upFolder.path);
    } else {
      changeDirectory('/');
    }
  };

  const loadDirectory = useCallback(async () => {
    clearMessage();
    setDirectoryLoading(true);

    try {
      const apiResponse = await Api.get(
        `${browseApiEndpoint}?path=${encodeURIComponent(currentDirectory)}`
      );
      if (apiResponse.status.statusCode !== 200) {
        setMessage({
          status: AlertStatus.ERROR,
          content: `Error retrieving folder.`
        });
        //if there was an error loading the directory, send them back home
        setCurrentDirectory('/');
        return;
      }

      const results = sortDirectoryContents(apiResponse.response);
      setDirectoryContents(results);
    } catch (error) {
      setMessage({
        status: AlertStatus.ERROR,
        content: `Error retrieving folder. (${error})`
      });
      //if there was an error loading the directory, send them back home
      setCurrentDirectory('/');
    } finally {
      setDirectoryLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browseApiEndpoint, currentDirectory]);

  const sortBy = (sortType) => {
    let sortParam = { ...directorySort.current };
    sortParam.type = sortType;

    if (sortParam.type === sortType) {
      sortParam.direction =
        sortParam.direction === SortDirection.ASCENDING
          ? SortDirection.DESCENDING
          : SortDirection.ASCENDING;
    }
    directorySort.current = sortParam;

    let sortedContents = [...directoryContents];
    sortedContents = sortDirectoryContents(sortedContents);
    setDirectoryContents(sortedContents);
  };

  const sortDirectoryContents = (directoryContents) => {
    switch (directorySort.current.type) {
      case FileBrowserSortType.NAME:
        directoryContents = directoryContents.sort((a, b) => {
          if (directorySort.current.direction === SortDirection.ASCENDING) {
            if (a.isDirectory === b.isDirectory) {
              return a.name === b.name ? 0 : a.name > b.name ? -1 : 1;
            } else {
              return a.isDirectory === b.isDirectory ? 0 : a.isDirectory > b.isDirectory ? -1 : 1;
            }
          } else {
            if (a.isDirectory === b.isDirectory) {
              return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
            } else {
              return a.isDirectory === b.isDirectory ? 0 : a.isDirectory < b.isDirectory ? -1 : 1;
            }
          }
        });

        break;
      case FileBrowserSortType.SIZE:
        directoryContents = directoryContents.sort((a, b) => {
          if (directorySort.current.direction === SortDirection.ASCENDING) {
            return a.length === b.length ? 0 : a.length < b.length ? -1 : 1;
          } else {
            return a.length === b.length ? 0 : a.length > b.length ? -1 : 1;
          }
        });
        break;
    }
    return directoryContents;
  };

  const downloadFile = (path) => {
    const downloadPath = `${bffApiUrl}${downloadApiEndpoint}?path=${encodeURIComponent(path)}`;
    window.location.href = downloadPath;
  };

  const deletePath = async () => {
    if (Helpers.isNullOrWhitespace(fileDetails.name)) {
      console.error('No path selected');
      return;
    }
    try {
      const apiResponse = await Api.delete(
        `${browseApiEndpoint}?path=${encodeURIComponent(currentDirectory + fileDetails.name)}`
      );
      if (apiResponse.status.statusCode === 200) {
        reload();
      } else {
        setMessage({
          status: AlertStatus.ERROR,
          content: `Error deleting path.`
        });
      }
    } catch (error) {
      setMessage({
        status: AlertStatus.ERROR,
        content: `Error deleting path. (${error})`
      });
    } finally {
      closeModalDialog();
    }
  };

  const showDeleteFileDialog = () => {
    setModalDialog({
      visible: true,
      heading: 'Delete File',
      message: 'Are you sure you want to delete this file? This cannot be undone.',
      okButtonText: 'Yes, delete this file.',
      cancelButtonText: "No, don't delete.",
      okCallback: deletePath,
      cancelCallback: closeModalDialog
    });
  };

  const showDeleteFolderDialog = () => {
    setModalDialog({
      visible: true,
      heading: 'Delete Folder',
      message:
        'Are you sure you want to delete this folder and all subfolders/files? This cannot be undone.',
      okButtonText: 'Yes, delete this folder.',
      cancelButtonText: "No, don't delete.",
      okCallback: deletePath,
      cancelCallback: closeModalDialog
    });
  };

  const closeModalDialog = () => {
    setModalDialog({ ...modalDialog, visible: false });
  };

  const getSelectedFile = () => {
    const apiEndpoint = fileDetails.isImage ? imageApiEndpoint : downloadApiEndpoint;
    let returnDetails = { ...fileDetails };
    returnDetails.absoluteUrl = `${bffApiUrl}${apiEndpoint}?path=${encodeURIComponent(
      fileDetails.webPath
    )}`;
    return returnDetails;
  };

  useEffect(() => {
    loadDirectory();
  }, [loadDirectory]);

  return {
    bffApiUrl,
    directoryLoading,
    uploadCompleteCallback,
    currentDirectory,
    pathSegments,
    sortBy,
    directoryContents,
    renamePromptVisible,
    showRenamePrompt,
    closeRenamePrompt,
    renameItem,
    createFolderPromptVisible,
    showCreateFolderPrompt,
    closeCreateFolderPrompt,
    createFolder,
    message,
    promptMessage,
    reload,
    changeDirectory,
    showUpload,
    toggleUploadForm,
    dblClickItem,
    openDirectory,
    selectItem,
    goUpFolder,
    fileDetails,
    downloadFile,
    modalDialog,
    showDeleteFileDialog,
    deletePath,
    showDeleteFolderDialog,
    getSelectedFile,
    FileBrowserSortType,
    directorySort: directorySort.current
  };
};

export default useFileBrowser;
