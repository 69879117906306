import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AlertStatus } from '../../../utils/constants/enums';

export const AlertMessage = ({
  visible,
  message,
  status,
  className,
  scrollTo,
  scrollToDelay = 0
}) => {
  const alertRef = useRef(null);

  useEffect(() => {
    if (!scrollTo || !alertRef.current || !visible) {
      return;
    }
    setTimeout(() => {
      alertRef.current.scrollIntoView({ behavior: 'smooth' });
    }, scrollToDelay);
  }, [scrollTo, visible, message, status, scrollToDelay]);

  if (!visible) return <></>;

  let classNames;
  switch (status) {
    case AlertStatus.SUCCESS:
      classNames = 'alert alert-success';
      break;
    case AlertStatus.ERROR:
      classNames = 'alert alert-danger';
      break;
    case AlertStatus.WARNING:
      classNames = 'alert alert-warning';
      break;
    default:
      classNames = 'alert alert-primary';
  }

  if (className && className.trim().length > 0) {
    classNames += ' ' + className;
  }

  return (
    <div className={classNames} ref={alertRef}>
      {message}
    </div>
  );
};

export default AlertMessage;

AlertMessage.propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.string,
  status: PropTypes.number,
  className: PropTypes.string,
  scrollTo: PropTypes.bool
};
