import { DefaultLayout as Layout } from '../../../layouts';
import { GeneratedReports } from './GeneratedReports/GeneratedReports';

export const GeneratedReportsPage = () => {
  return (
    <Layout>
      <GeneratedReports />
    </Layout>
  );
};
