import { AuthProvider } from './context/provider/AuthContext';
import Routing from './routes/Routing';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/styles.css';
import './assets/css/themes/unibank.css';
import './assets/css/themes/unipaydirect.css';
import './assets/css/themes/unitedbank.css';
import './assets/css/themes/firstfederal.css';

const App = () => {
  return (
    <AuthProvider>
      <Routing />
    </AuthProvider>
  );
};

export default App;
