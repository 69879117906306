import { DefaultLayout as Layout } from '../../../layouts';
import { MaintainImportFileDates } from './MaintainImportFileDates/MaintainImportFileDates';

export const MaintainImportFileDatesPage = () => {
  return (
    <Layout>
      <div className="titleBar">
        <h1 className="pageTitle">Maintain Import File Dates</h1>
      </div>
      <MaintainImportFileDates />
    </Layout>
  );
};
