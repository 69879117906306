import { SessionTimeoutModal } from '../../components/authorization';
import { useAuthorization, useBodyClass } from '../../utils/hooks';
import { LoadingOverlay } from '../../components/ui';
import { Sidebar } from './Sidebar';
import { TopNav } from './TopNav';

export const DefaultLayout = ({ children }) => {
  const auth = useAuthorization();
  useBodyClass(auth.themeClass);

  if (auth.isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <a className="sr-only" href="#mainContent">
        Skip to main content
      </a>
      <div className="d-flex" id="wrapper">
        <Sidebar logo={auth.themeLogo} menu={auth.sidebarMenu} />
        <div id="page-content-wrapper">
          <TopNav authorization={auth} />
          <SessionTimeoutModal authorization={auth} />
          <div id="mainContent">
            <div className="container-fluid">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
