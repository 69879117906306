import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PatternFormat from 'react-number-format';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Helpers } from '../../../../utils/helpers';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import styles from './CustomerSummary.module.css';
import useCustomerSummary from './useCustomerSummary';
import { Link } from 'react-router-dom';

export const CustomerSummary = (customerParameters) => {
  const summary = useCustomerSummary(customerParameters);

  return (
    <>
      {summary.loading && (
        <div className="m-2 text-center">
          <Spinner animation="border" />
        </div>
      )}
      {!summary.loading && (
        <>
          {!summary.summaryResult && (
            <>
              <div>No summary found for this customer.</div>
            </>
          )}
          {summary.summaryResult && (
            <>
              <Link to="/outbound/manage-customers" className="btn btn-primary btn-sm float-end">
                Manage this customer
                <FontAwesomeIcon size="sm" className="ms-2" icon={faUpRightFromSquare} />
              </Link>
              <h2 className={styles.customerConfigHeading}>{summary.summaryResult.customerName}</h2>
              <div className={styles.addressContainer}>
                <div>{summary.summaryResult.address1}</div>
                {!Helpers.isNullOrWhitespace(summary.summaryResult.address2) && (
                  <div>{summary.summaryResult.address2}</div>
                )}
                <div>
                  {summary.summaryResult.city}, {summary.summaryResult.state}{' '}
                  {summary.summaryResult.postalCode}
                </div>
                {!Helpers.isNullOrWhitespace(summary.summaryResult.phone) && (
                  <div>
                    Phone:{' '}
                    <PatternFormat
                      format="(###) ###-####"
                      displayType="text"
                      value={summary.summaryResult.phone}
                      allowEmptyFormatting
                      mask="_"
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <table className="table table-striped">
                    <tbody>
                      {!Helpers.isNullOrWhitespace(summary.summaryResult.description) && (
                        <tr>
                          <th scope="col">Description</th>
                          <td>{summary.summaryResult.description}</td>
                        </tr>
                      )}
                      {!Helpers.isNullOrWhitespace(summary.summaryResult.groupName) && (
                        <tr>
                          <th scope="col">Customer Group</th>
                          <td>{summary.summaryResult.groupName}</td>
                        </tr>
                      )}
                      {!Helpers.isNullOrWhitespace(summary.summaryResult.classificationName) && (
                        <tr>
                          <th scope="col">Classification</th>
                          <td>{summary.summaryResult.classificationName}</td>
                        </tr>
                      )}
                      {!Helpers.isNullOrWhitespace(summary.summaryResult.inceptionDate) && (
                        <tr>
                          <th scope="col">Inception Date</th>
                          <td>{Helpers.toShortDate(summary.summaryResult.inceptionDate)}</td>
                        </tr>
                      )}
                      <tr>
                        <th scope="col">MFA Required</th>
                        <td>
                          {Helpers.mfaRequirementAsText(summary.summaryResult.mfaRequirementType)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={summary.summaryResult.hideFromCustomerList}
                      disabled={true}
                    />
                    Hide from Customer List
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={summary.summaryResult.ebpEnabled}
                      disabled={true}
                    />
                    EBP Enabled
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={summary.summaryResult.thirdPartyBillingEnabled}
                      disabled={true}
                    />
                    Third Party Billing
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

CustomerSummary.propTypes = {
  customerParameters: PropTypes.shape({
    brandId: PropTypes.number,
    customerId: PropTypes.number
  })
};

export default CustomerSummary;
