import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsRotate,
  faChevronRight,
  faPlus,
  faUpload,
  faCircleUp,
  faFile,
  faTrashAlt,
  faDownload,
  faXmark,
  faPenToSquare
} from '@fortawesome/free-solid-svg-icons';
import { faFolder, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import {
  FileUpload,
  ProgressBar,
  ModalPrompt,
  AlertMessage,
  ModalDialog,
  SortingHeader
} from '../../../components/ui';
import { Helpers } from '../../../utils/helpers';
import styles from './FileBrowser.module.css';
import useFileBrowser from './useFileBrowser';

export const FileBrowser = ({
  isModal = false,
  closeButtonCallback,
  uploadApiEndpoint,
  browseApiEndpoint,
  imageApiEndpoint,
  downloadApiEndpoint,
  selectFileCallback,
  maxConcurrentUploads = 3,
  acceptExtensions = '',
  sizeLimit = 5242880,
  multipleFiles = true
}) => {
  const browser = useFileBrowser({
    browseApiEndpoint,
    imageApiEndpoint,
    downloadApiEndpoint,
    selectFileCallback
  });

  return (
    <>
      <ModalDialog
        showDialog={browser.modalDialog.visible}
        heading={browser.modalDialog.heading}
        message={browser.modalDialog.message}
        okCallback={browser.modalDialog.okCallback}
        okButtonText={browser.modalDialog.okButtonText}
        cancelCallback={browser.modalDialog.cancelCallback}
        cancelButtonText={browser.modalDialog.cancelButtonText}
      />
      <ModalPrompt
        showPrompt={browser.createFolderPromptVisible}
        heading="Create New Folder"
        message=""
        alertMessage={browser.promptMessage.content}
        alertMessageType={browser.promptMessage.status}
        maxLength={100}
        okCallback={browser.createFolder}
        okButtonText="Create"
        cancelCallback={browser.closeCreateFolderPrompt}
        cancelButtonText="Cancel"
      />
      <ModalPrompt
        showPrompt={browser.renamePromptVisible}
        heading={`Rename '${browser.fileDetails?.name ?? ''}' to...`}
        message=""
        value={browser.fileDetails?.name ?? ''}
        alertMessage={browser.promptMessage.content}
        alertMessageType={browser.promptMessage.status}
        maxLength={100}
        okCallback={browser.renameItem}
        okButtonText="Save"
        cancelCallback={browser.closeRenamePrompt}
        cancelButtonText="Cancel"
      />
      <div className={styles.fileBrowserContainer}>
        <div className={styles.actionButtonRow}>
          <div className="flex-grow-1">
            <button className={`btn primary-btn ${styles.actionButton}`} onClick={browser.reload}>
              <FontAwesomeIcon size="1x" className="me-2" icon={faArrowsRotate} />
              Reload
            </button>
            <button
              className={`btn primary-btn ${styles.actionButton}`}
              onClick={browser.showCreateFolderPrompt}>
              <FontAwesomeIcon size="1x" className="me-2" icon={faPlus} />
              New Folder
            </button>
            <button
              className={`btn primary-btn ${styles.actionButton}`}
              onClick={browser.toggleUploadForm}>
              <FontAwesomeIcon size="1x" className="me-2" icon={faUpload} />
              Upload Files
            </button>
          </div>
          {closeButtonCallback && (
            <div className="flex-shrink-1">
              <button
                className={`btn primary-btn ${styles.actionButton}`}
                onClick={closeButtonCallback}>
                <FontAwesomeIcon size="1x" className="me-2" icon={faXmark} />
                Close
              </button>
            </div>
          )}
        </div>
        <ProgressBar className="m-0" visible={browser.directoryLoading} />
        <div>{browser.message.content}</div>
        <AlertMessage
          className="rounded-0"
          visible={browser.message.content.trim() !== ''}
          message={browser.message.content}
          status={browser.message.status}
        />
        <FileUpload
          visible={browser.showUpload}
          className="m-2"
          uploadButtonClassName={`btn primary-btn ${styles.actionButton}`}
          uploadApiEndpoint={`${uploadApiEndpoint}?path=${encodeURIComponent(
            browser.currentDirectory
          )}`}
          acceptExtensions={acceptExtensions}
          maxConcurrentUploads={maxConcurrentUploads}
          sizeLimit={sizeLimit}
          multipleFiles={multipleFiles}
          uploadCompleteCallback={browser.uploadCompleteCallback}
        />

        <div className={styles.pathSegmentContainer}>
          <button
            className={styles.pathSegment}
            onClick={() => browser.goUpFolder()}
            disabled={browser.pathSegments.length <= 1}>
            <FontAwesomeIcon size="1x" className="me-2" icon={faCircleUp} />
            Up
          </button>
          {browser.pathSegments.map((item, index) => {
            return (
              <button
                key={index}
                className={styles.pathSegment}
                onClick={() => browser.changeDirectory(item.path)}>
                {item.name}
                <FontAwesomeIcon size="1x" className="ms-2" icon={faChevronRight} />
              </button>
            );
          })}
        </div>

        <div className={styles.directoryContentsContainer}>
          <div className={styles.directoryContents}>
            {browser.directoryContents.length === 0 && (
              <div className="text-center mt-4 mb-4">
                <em>No files in this folder.</em>
              </div>
            )}

            {browser.directoryContents.length > 0 && (
              <div className={styles.itemHeader}>
                <div className="flex-grow-1">
                  <SortingHeader
                    label="Name"
                    sortDirection={browser.directorySort.direction}
                    onClick={() => browser.sortBy(browser.FileBrowserSortType.NAME)}
                    active={browser.directorySort.type === browser.FileBrowserSortType.NAME}
                  />
                </div>
                <div className="flex-shrink-1">
                  <SortingHeader
                    label="Size"
                    sortDirection={browser.directorySort.direction}
                    onClick={() => browser.sortBy(browser.FileBrowserSortType.SIZE)}
                    active={browser.directorySort.type === browser.FileBrowserSortType.SIZE}
                  />
                </div>
              </div>
            )}
            <div className={isModal ? styles.modalFileList : styles.fileList}>
              {browser.directoryContents.map((item, index) => {
                let itemClass = styles.itemRow;
                if (browser.fileDetails?.name === item.name && !item.isDirectory) {
                  itemClass += ` ${styles.itemRowActive}`;
                }

                return (
                  <a
                    href="#"
                    key={index}
                    className={itemClass}
                    onDoubleClick={(e) => browser.dblClickItem(e, item)}
                    onClick={(e) => browser.selectItem(e, item)}>
                    <FontAwesomeIcon
                      size="1x"
                      className="me-2"
                      icon={item.isDirectory ? faFolder : faFile}
                    />
                    <div className={styles.itemName}>{item.name}</div>
                    {!item.isDirectory && (
                      <div className="flex-shrink-1">{Helpers.toFileSizeText(item.length)}</div>
                    )}
                  </a>
                );
              })}
            </div>
          </div>
          {browser.fileDetails && browser.fileDetails.isDirectory && (
            <div className={styles.fileDetails}>
              <div className="flex-grow-1">
                <FontAwesomeIcon size="4x" className="mb-2" icon={faFolder} />
                <div className={styles.fileDetailsName}>
                  {browser.fileDetails.name}
                  <a
                    href="#"
                    className="d-inline-block ms-2"
                    onClick={(e) => browser.showRenamePrompt(e)}>
                    <FontAwesomeIcon size="1x" icon={faPenToSquare} />
                  </a>
                </div>
                <div>
                  Created on {Helpers.toShortDateTime(browser.fileDetails.lastWriteTimeUtc)}
                </div>
              </div>
              <div className="d-flex mt-4 mb-2 justify-content-between flex-shrink-1">
                <button
                  className="btn btn-primary green flex-grow-1 me-1"
                  onClick={() => browser.openDirectory(browser.fileDetails)}>
                  <FontAwesomeIcon size="1x" className="me-2" icon={faFolderOpen} />
                  Open
                </button>
                <button
                  title="Delete Folder"
                  className="btn btn-primary red flex-shrink-1"
                  onClick={() => browser.showDeleteFolderDialog()}>
                  <FontAwesomeIcon size="1x" icon={faTrashAlt} />
                </button>
              </div>
            </div>
          )}

          {browser.fileDetails && !browser.fileDetails.isDirectory && (
            <div className={styles.fileDetails}>
              <div className="flex-grow-1">
                {browser.fileDetails.isImage && (
                  <div>
                    <img
                      src={`${browser.bffApiUrl}${imageApiEndpoint}?path=${encodeURIComponent(
                        browser.fileDetails.webPath
                      )}`}
                      className={styles.previewImage}
                    />
                  </div>
                )}
                {!browser.fileDetails.isImage && (
                  <div className={styles.genericFile}>{browser.fileDetails.extension}</div>
                )}
                <div className={styles.fileDetailsName}>
                  {browser.fileDetails.name}
                  <a
                    href="#"
                    className="d-inline-block ms-2"
                    onClick={(e) => browser.showRenamePrompt(e)}>
                    <FontAwesomeIcon size="1x" icon={faPenToSquare} />
                  </a>
                </div>
                <div>
                  Uploaded on {Helpers.toShortDateTime(browser.fileDetails.lastWriteTimeUtc)}
                </div>
              </div>
              <div className="d-flex mt-4 mb-2 flex-shrink-1">
                <button
                  className="btn btn-primary flex-grow-1 me-1"
                  onClick={() => browser.downloadFile(browser.fileDetails.webPath)}>
                  <FontAwesomeIcon size="1x" className="me-2" icon={faDownload} />
                  Download
                </button>
                <button
                  title="Delete File"
                  className="btn btn-primary red flex-shrink-1"
                  onClick={() => browser.showDeleteFileDialog()}>
                  <FontAwesomeIcon size="1x" icon={faTrashAlt} />
                </button>
              </div>

              {selectFileCallback && (
                <div className="d-grid gap-2 mt-3">
                  <button
                    className="btn btn-primary green"
                    onClick={() => selectFileCallback(browser.getSelectedFile())}>
                    Select File
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

FileBrowser.propTypes = {
  isModal: PropTypes.bool,
  closeButtonCallback: PropTypes.func,
  uploadApiEndpoint: PropTypes.string.isRequired,
  browseApiEndpoint: PropTypes.string.isRequired,
  imageApiEndpoint: PropTypes.string.isRequired,
  downloadApiEndpoint: PropTypes.string.isRequired,
  selectFileCallback: PropTypes.func,
  maxConcurrentUploads: PropTypes.number,
  className: PropTypes.string,
  acceptExtensions: PropTypes.string,
  sizeLimit: PropTypes.number,
  multipleFiles: PropTypes.bool
};

export default FileBrowser;
