import { Dropdown } from '../../../../components/ui';

export const UserFilter = ({ roles, brands, search }) => {
  return (
    <div className="card admin-card">
      <div className="card-header">User Filters</div>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-12">
            <strong>Role</strong>
            {roles.map((role) => (
              <div key={role.id} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={role.id}
                  onChange={() => search.updateRoleFilter(role.id)}
                  checked={search.isRoleFilterChecked(role.id)}
                  id={role.id}
                />
                <label className="form-check-label" htmlFor={role.id}>
                  {role.name}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <strong>Brand</strong>
            {brands?.map((brand) => (
              <div key={brand.id} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={brand.id}
                  onChange={() => search.updateBrandFilter(brand.id)}
                  checked={search.isBrandFilterChecked(brand.id)}
                  id={brand.id}
                />
                <label className="form-check-label" htmlFor={brand.id}>
                  {brand.name}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {search.brandFilters.length > 0 && (
              <Dropdown
                label="Customer"
                labelId="ddCustomerList"
                className="form-select w-100 me-1"
                value={search.searchParameters.filters['customer'] ?? '0'}
                onChange={(e) => search.updateCustomerFilter(e.target.value)}
                firstOption="- All Customers -"
                firstOptionValue={0}
                options={search.customerList}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
