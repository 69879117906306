import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { propTypes } from 'react-bootstrap/esm/Image';
import { useEffect, useState } from 'react';
import { Helpers } from '../../../utils/helpers';

export const TimeSelect = ({
  label,
  className = '',
  selectClassName = '',
  startTime = '00:00',
  endTime = '24:00',
  minuteStep = 30,
  value,
  onChange
}) => {
  const controlId = uuidv4();
  const [timeOptions, setTimeOptions] = useState([]);

  useEffect(() => {
    const timezoneOffset = new Date().getTimezoneOffset();
    let startDate = new Date(`01 Jan 2000 ${startTime}:00`);
    let endDate = new Date(`01 Jan 2000 ${endTime}:00`);

    if (startDate > endDate) {
      return;
    }

    let options = [];
    let currentCount = 0;
    let countLimit = 24 * 60;
    let currentDate = startDate;

    while (currentDate < endDate) {
      options.push({
        text: Helpers.toShortTime(currentDate),
        value: Helpers.to24HourTime(Helpers.addMinutes(currentDate, timezoneOffset))
      });
      currentDate = Helpers.addMinutes(currentDate, minuteStep);
      //infinite loop check
      if (currentCount > countLimit) break;
      currentCount++;
    }
    setTimeOptions(options);
  }, [startTime, endTime, minuteStep]);

  return (
    <div className={'form-group ' + className}>
      {label && <label htmlFor={controlId}>{label}</label>}
      <select
        id={controlId}
        className={'form-select ' + selectClassName}
        value={value}
        onChange={onChange}>
        {timeOptions?.map((option, index) => (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

TimeSelect.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  selectClassName: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  minuteStep: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func
};
