// Actions
export const SET_USER_SETTINGS = 'settings/setUserSettings';
export const SET_DASHBOARD_TILE_LAYOUT = 'settings/setDashboardTileLayout';

// Action Functions
export const setUserSettings = (value) => {
  return { type: SET_USER_SETTINGS, payload: value };
};

export const setDashboardTileLayout = (layout) => {
  return { type: SET_DASHBOARD_TILE_LAYOUT, payload: layout };
};

// Reducer
const settingsReducer = (state, action) => {
  switch (action.type) {
    case SET_USER_SETTINGS:
      return {
        ...state,
        dashboardTileLayout: action.payload.dashboardTileLayout
      };
    case SET_DASHBOARD_TILE_LAYOUT:
      return {
        ...state,
        dashboardTileLayout: action.payload
      };

    default:
      return state;
  }
};

export default settingsReducer;
