import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Helpers } from '../../../utils/helpers';
import styles from './DayOfTheWeekSelect.module.css';
import { DaysOfWeek } from '../../../utils/constants/enums';

export const DayOfTheWeekSelect = ({
  groupName = `dayOfWeek-${uuidv4()}`,
  value = 0,
  className,
  multiSelect = false,
  weekdayOnly = false,
  abbreviatedDayNames = false,
  onChange
}) => {
  const everyDayValue = weekdayOnly ? 62 : 127;

  const isDayOfWeekSelected = (value, dayOfWeek) => {
    if (value === DaysOfWeek.NONE) return false;
    return Helpers.hasFlag(value, dayOfWeek);
  };
  const daysOfTheWeek = Helpers.getDaysOfTheWeek(weekdayOnly);

  const changeDaySelection = (e) => {
    //call parent onchange function
    onChange(parseInt(e.target.value));
  };
  const toggleDaySelection = (e) => {
    const checkValue = parseInt(e.target.value);
    if (Helpers.hasFlag(value, checkValue)) {
      value ^= checkValue; //remove flag
    } else {
      value |= checkValue; //add flag
    }
    //call parent onchange function
    onChange(value);
  };
  const toggleEveryDaySelection = () => {
    onChange(value === everyDayValue ? 0 : everyDayValue);
  };

  return (
    <div className={styles.wrapper + ' ' + className}>
      {multiSelect && (
        <div className={styles.everyDayOptionContainer}>
          <label className={styles.dayLabel}>
            <input
              type="checkbox"
              onChange={toggleEveryDaySelection}
              checked={value === everyDayValue}
              className={styles.dayInput}
            />
            Every day
          </label>
        </div>
      )}
      <div className={styles.dayWrapper}>
        {daysOfTheWeek.map((day) => {
          return (
            <div
              key={day.value}
              className={abbreviatedDayNames ? styles.daySelectShort : styles.daySelect}>
              <label className={styles.dayLabel}>
                {multiSelect && (
                  <input
                    type="checkbox"
                    value={day.value}
                    onChange={toggleDaySelection}
                    checked={isDayOfWeekSelected(value, day.value)}
                    className={styles.dayInput}
                  />
                )}
                {!multiSelect && (
                  <input
                    type="radio"
                    name={groupName}
                    value={day.value}
                    onChange={changeDaySelection}
                    checked={value === day.value}
                    className={styles.dayInput}
                  />
                )}
                {abbreviatedDayNames ? day.shortName : day.name}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DayOfTheWeekSelect;

DayOfTheWeekSelect.propTypes = {
  groupName: PropTypes.string,
  value: PropTypes.number,
  className: PropTypes.string,
  multiSelect: PropTypes.bool,
  weekdayOnly: PropTypes.bool,
  abbreviatedDayNames: PropTypes.bool,
  onChange: PropTypes.func
};
