import React from 'react';
import { Helpers } from '../../../../utils/helpers';
import useReportManager from './useReportManager';
import './ReportManager.css';
import {
  ReportDateRangeType,
  ReportType,
  ScreenViewType,
  TransactionStatus
} from '../../../../utils/constants/enums';
import { ReportViewer } from '../../../../components/ui';

export const ReportManager = () => {
  const manager = useReportManager();

  return (
    <>
      <div className="titleBar">
        <h1 className="pageTitle">Reports</h1>
      </div>

      <div
        className={`container ${
          manager.viewType === ScreenViewType.FULLSCREEN
            ? 'fullscreen-report themed-bg-color'
            : 'mt-4'
        }`}>
        <div className="row">
          <div className="col-12 col-sm-3">
            <div className="searchContainer border mt-0">
              <div className="g-3 w-100">
                <div className="form-group">
                  <label htmlFor="reportType">
                    Report <span className="req">*</span>
                  </label>
                  <select
                    id="reportType"
                    className="form-select"
                    aria-label="Report selection"
                    value={manager.reportParameters.reportType ?? ReportType.UNKNOWN}
                    onChange={(e) =>
                      manager.updateReportParams(
                        'reportType',
                        Helpers.tryParseInt(e.target.value, null)
                      )
                    }>
                    <option value="0">- Select -</option>
                    {manager.allowedReports.map((report) => {
                      return (
                        <option key={report.id} value={report.id}>
                          {report.name}
                        </option>
                      );
                    })}
                  </select>
                  {manager.errors.reportType && (
                    <div className="mt-1 alert-danger p-2">Report selection required.</div>
                  )}
                </div>

                {manager.showCustomerSelect && (
                  <div className="form-group">
                    <label htmlFor="customer">
                      Customer {!manager.defaultCustomer && <span className="req">*</span>}
                    </label>
                    {manager.defaultCustomer && (
                      <div className="researchCustomerName">{manager.defaultCustomer.name}</div>
                    )}
                    {!manager.defaultCustomer && (
                      <>
                        <select
                          id="customer"
                          className="form-select"
                          aria-label="Customer selection"
                          value={manager.reportParameters.customerId ?? ''}
                          onChange={(e) =>
                            manager.updateReportParams(
                              'customerId',
                              Helpers.tryParseInt(e.target.value, null)
                            )
                          }>
                          <option>- Select -</option>
                          {manager.customers.map((customer) => {
                            return (
                              <option key={customer.id} value={customer.id}>
                                {customer.name}
                              </option>
                            );
                          })}
                        </select>
                        {manager.errors.customerId && (
                          <div className="mt-1 alert-danger p-2">Customer selection required.</div>
                        )}
                      </>
                    )}
                  </div>
                )}

                {manager.showDateSelect && (
                  <>
                    <div className="row dateRangeWrap">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="date-range" className="text-nowrap">
                            Date Range
                          </label>
                          <select
                            id="date-range"
                            className="form-select"
                            aria-label="Date Range selection"
                            value={manager.reportParameters.dateRangeType}
                            onChange={(e) =>
                              manager.updateReportParams(
                                'dateRangeType',
                                Helpers.tryParseInt(e.target.value, null)
                              )
                            }>
                            <option value={ReportDateRangeType.TODAY}>Today</option>
                            <option value={ReportDateRangeType.YESTERDAY}>Yesterday</option>
                            <option value={ReportDateRangeType.PREVIOUS_3_DAYS}>
                              Previous 3 Days
                            </option>
                            <option value={ReportDateRangeType.PREVIOUS_7_DAYS}>
                              Previous 7 Days
                            </option>
                            <option value={ReportDateRangeType.PREVIOUS_MONTH}>
                              Previous Month
                            </option>
                            <option value={ReportDateRangeType.PREVIOUS_QUARTER}>
                              Previous Quarter
                            </option>
                            <option value={ReportDateRangeType.PREVIOUS_YEAR}>Previous Year</option>
                            <option value={ReportDateRangeType.CUSTOM}>Custom</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {manager.reportParameters.dateRangeType === ReportDateRangeType.CUSTOM && (
                      <>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="fromDate">From:</label>
                              <input
                                type="date"
                                id="fromDate"
                                className="form-control"
                                value={manager.reportParameters.fromDate ?? ''}
                                onChange={(e) =>
                                  manager.updateReportParams('fromDate', e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="toDate">To:</label>
                              <input
                                type="date"
                                id="toDate"
                                className="form-control"
                                value={manager.reportParameters.toDate ?? ''}
                                onChange={(e) =>
                                  manager.updateReportParams('toDate', e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {manager.errors.customDateMissing && (
                          <div className="row">
                            <div className="col-12">
                              <div className="mt-1 alert-danger p-2">
                                Date fields are required for custom date range selection.
                              </div>
                            </div>
                          </div>
                        )}

                        {manager.errors.customDateInvalid && (
                          <div className="row">
                            <div className="col-12">
                              <div className="mt-1 alert-danger p-2">
                                Custom date fields cannot include today&apos;s date in the date
                                range.
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {manager.showCustomerSelect && (
                  <>
                    <div className="form-group">
                      <label>Transactions </label>{' '}
                      <small>({manager.reportParameters.transactionIds.length} selected)</small>
                      {manager.transactions?.length > 0 && (
                        <>
                          <div className="transaction-select-all">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={() => manager.selectAllTransactions()}
                                  checked={manager.hasAllTransactions()}
                                />
                                <em>Select All Transactions</em>
                              </label>
                            </div>
                          </div>
                          <div className="transaction-container">
                            {manager.transactions?.map((transaction) => {
                              return (
                                <div key={transaction.id} className="form-check ms-0">
                                  <label
                                    className={`form-check-label${
                                      (transaction.status === TransactionStatus.INACTIVE ||
                                        transaction.deleted) &&
                                      ' inactive-transaction'
                                    }`}>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={() => manager.updateTransaction(transaction.id)}
                                      checked={manager.reportParameters.transactionIds.includes(
                                        transaction.id
                                      )}
                                    />
                                    {transaction.name}
                                    {transaction.status === TransactionStatus.INACTIVE &&
                                      ' [inactive]'}
                                    {transaction.deleted && ' [deleted]'}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {manager.errors.transactionIds && (
                        <div className="row">
                          <div className="col-12">
                            <div className="mt-1 alert-danger p-2">
                              No transactions were selected. Please select one or more transactions
                              to run report.
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="transaction-show-inactive">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={() => manager.toggleInactiveTransactions()}
                              checked={manager.showInactiveTransactions}
                            />
                            <em>Show Inactive</em>
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={() => manager.toggleDeletedTransactions()}
                              checked={manager.showDeletedTransactions}
                            />
                            <em>Show Deleted</em>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="mt-3 d-flex justify-content-end">
                  <button className="btn btn-primary" onClick={manager.runReport}>
                    Run Report
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-9">
            {!Helpers.isNullOrWhitespace(manager.apiError) && (
              <div className="alert-danger mb-1 p-2">{manager.apiError}</div>
            )}
            <ReportViewer
              loading={manager.loading}
              viewType={manager.viewType}
              searchEnabled={true}
              toggleReportViewCallback={manager.toggleReportView}
              reportHtml={manager.displayResults}
              getReportDownloadCallback={manager.getReportDownload}
            />
            {!manager.loading &&
              !Helpers.isNullOrWhitespace(manager.displayResults) &&
              !manager.showInactiveTransactions && (
                <div className="alert alert-danger py-1 mt-3">
                  Not seeing expected payments? Try checking the &quot;Show Inactive&quot; box and
                  running the report again.
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
