import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

export const useQueue = (initialValue = []) => {
  const queue = useRef(initialValue);
  const [count, setCount] = useState(0);

  const enqueue = (item) => {
    queue.current.push(item);
    setCount(count + 1);
  };

  const dequeue = () => {
    const nextItem = queue.current.shift();
    setCount(count + 1);
    return nextItem;
  };

  const peek = () => {
    const [nextItem] = queue.current;
    return nextItem;
  };

  const getItems = () => {
    return queue.current;
  };

  const length = () => {
    return queue.current.length;
  };

  return {
    enqueue,
    dequeue,
    peek,
    getItems,
    length
  };
};

useQueue.propTypes = {
  initialValue: PropTypes.array
};
