import React from 'react';
import styles from './ProgressCircle.module.css';

export const ProgressCircle = ({
  visible,
  className,
  size,
  strokeWidth,
  percentage,
  color,
  trailColor,
  children
}) => {
  if (!visible) return <></>;

  size = size ?? 50;
  strokeWidth = strokeWidth ?? 5;

  percentage = percentage ?? 0;
  //percentage = 100 - percentage;

  color = color ?? '#000000';
  trailColor = trailColor ?? '#ffffff';

  const midPoint = size / 2;
  const endPoint = size - strokeWidth;
  const negEndPoint = endPoint * -1;
  const strokeMid = strokeWidth / 2;
  const midOffset = midPoint - strokeMid;
  const strokeDashArray = (size - strokeWidth) * Math.PI;
  const strokeDashOffset = strokeDashArray * (percentage / 100) - strokeDashArray;

  const draw = `m ${midPoint},${strokeMid} a ${midOffset},${midOffset} 0 0,1 0,${endPoint} a ${midOffset},${midOffset} 0 0,1 0,${negEndPoint}`;

  return (
    <div className={className}>
      <div className={styles.circleWrapper} style={{ width: `${size}px`, height: `${size}px` }}>
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
          <path d={draw} fill="none" stroke={trailColor} strokeWidth={strokeWidth}></path>
          <path
            d={draw}
            fill="none"
            stroke={color}
            strokeLinecap="butt"
            strokeWidth={strokeWidth}
            strokeDasharray={strokeDashArray}
            strokeDashoffset={strokeDashOffset}></path>
        </svg>
        <div className={styles.circleContent}>{children}</div>
      </div>
    </div>
  );
};

export default ProgressCircle;
