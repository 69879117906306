import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { AlertMessage } from '../../../components/ui';

export const ModalPrompt = ({
  showPrompt,
  heading,
  message,
  alertMessage,
  alertMessageType,
  defaultValue = '',
  value = '',
  placeholderText = '',
  maxLength = 200,
  okCallback,
  okButtonText,
  cancelCallback,
  cancelButtonText
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const inputRef = useRef();

  if (typeof okCallback !== 'function') {
    okCallback = () => {};
  }
  if (typeof cancelCallback !== 'function') {
    cancelCallback = () => {};
  }

  const setInputFocus = () => {
    inputRef.current.focus();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleOkCallback();
    }
  };

  const handleOkCallback = () => {
    okCallback(inputValue);
    //clear input value for next time
    setInputValue(defaultValue);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <>
      <Modal
        show={showPrompt}
        backdrop="static"
        keyboard={false}
        size="md"
        centered={true}
        onEntered={setInputFocus}>
        <Modal.Header>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage
            className=""
            visible={alertMessage.trim() !== ''}
            message={alertMessage}
            status={alertMessageType}
          />
          <div className="row">
            <div className="col-12">{message}</div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <input
                  ref={inputRef}
                  type="text"
                  className="form-control"
                  placeholder={placeholderText}
                  value={inputValue}
                  maxLength={maxLength}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          {typeof cancelCallback === 'function' && (
            <div>
              <button className="btn btn-primary" onClick={cancelCallback}>
                {cancelButtonText ?? 'Cancel'}
              </button>
            </div>
          )}
          {typeof okCallback === 'function' && (
            <div>
              <button className="btn btn-primary" onClick={handleOkCallback}>
                {okButtonText ?? 'Ok'}
              </button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

ModalPrompt.propTypes = {
  showPrompt: PropTypes.bool,
  heading: PropTypes.string,
  message: PropTypes.string,
  alertMessage: PropTypes.string,
  alertMessageType: PropTypes.number,
  defaultValue: PropTypes.string,
  placeholderText: PropTypes.string,
  maxLength: PropTypes.number,
  okCallback: PropTypes.func,
  okButtonText: PropTypes.string,
  cancelCallback: PropTypes.func,
  cancelButtonText: PropTypes.string
};

export default ModalPrompt;
