import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import { AlertMessage, HelpTip, TimeSelect } from '../../../../components/ui';
import { AutomatedUploadRecurrenceOptions } from '../AutomatedUploadRecurrenceOptions/AutomatedUploadRecurrenceOptions';
import styles from './AutomatedUpload.module.css';
import useAutomatedUpload from './useAutomatedUpload';
import { Helpers } from '../../../../utils/helpers';

export const AutomatedUpload = ({ transactionParameters, canImport }) => {
  const manager = useAutomatedUpload({ transactionParameters, canImport });

  if (!canImport) {
    return (
      <>
        <h2 className="transaction-config-heading">Automated Import Configuration</h2>
        <div>
          <em>Imports are not enabled for this transaction.</em>
        </div>
      </>
    );
  }

  return (
    <>
      <h2 className="transaction-config-heading">Automated Import Configuration</h2>
      {manager.loading && (
        <div className="m-2 text-center">
          <Spinner animation="border" />
        </div>
      )}
      {!manager.loading && manager.uploadConfig && (
        <>
          <AlertMessage
            className="rounded-0"
            visible={manager.message.content.trim() !== ''}
            message={manager.message.content}
            status={manager.message.status}
            scrollTo={true}
          />
          <fieldset className={styles.section}>
            <legend className={styles.sectionLegend}>Import Schedule</legend>
            <div className="row mb-2">
              <div className="col-lg-12">
                <div className={'form-check form-switch mt-1 ' + styles.switchButton}>
                  <label className="form-check-label">
                    <input
                      className={
                        'form-check-input ' +
                        (manager.uploadConfig.active ? styles.switchActive : '')
                      }
                      type="checkbox"
                      aria-label={manager.uploadConfig.active ? 'Active' : 'Inactive'}
                      checked={manager.uploadConfig.active}
                      onChange={() => {
                        manager.updateProperty('active', !manager.uploadConfig.active);
                      }}
                    />
                    {manager.uploadConfig.active && (
                      <>
                        Automated imports are <span className="text-success fw-bold">enabled</span>.
                      </>
                    )}
                    {!manager.uploadConfig.active && (
                      <>
                        Automated imports are <span className="text-danger fw-bold">disabled</span>.
                      </>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-2">
                  <label htmlFor="importFrequency">
                    How often should we check for import files?
                  </label>
                  <select
                    id="importFrequency"
                    className="form-select"
                    value={manager.uploadConfig.frequency ?? ''}
                    onChange={(e) => manager.updateProperty('frequency', parseInt(e.target.value))}>
                    {manager.frequencyOptions().map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-8">
                <AutomatedUploadRecurrenceOptions
                  visible={true}
                  dateRangeType={manager.uploadConfig.frequency}
                  recurrenceParameters={manager.uploadConfig.recurrenceParameters}
                  parameterChangeHandler={manager.recurrenceParameterChangeHandler}
                  errors={manager.errors}
                  clearErrorForKeysCallback={manager.clearErrorForKeys}
                />
                {manager.errors.dailyInvalid && (
                  <div className="alert-danger mt-1 p-2">You must select at least one day.</div>
                )}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-12">
                <TimeSelect
                  label="At the following time:"
                  className=""
                  selectClassName="w-auto"
                  startTime="00:00"
                  endTime="24:00"
                  minuteStep={30}
                  value={manager.uploadConfig.runAtTime}
                  onChange={(e) => manager.updateProperty('runAtTime', e.target.value)}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-12">
                <div className="mt-2">
                  <label>Limit automated imports to the following date range:</label>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <label htmlFor="importStartDateUtc" className={styles.dateLabel}>
                    From
                  </label>
                  <input
                    type="date"
                    id="importStartDateUtc"
                    className={'form-control ' + styles.dateSelect}
                    value={Helpers.toInputFieldDate(manager.uploadConfig.startDateUtc)}
                    onChange={(e) => manager.updateProperty('startDateUtc', e.target.value)}
                  />

                  <label htmlFor="importEndDateUtc" className={styles.dateLabel}>
                    until
                  </label>
                  <input
                    type="date"
                    id="importEndDateUtc"
                    className={'form-control ' + styles.dateSelect}
                    value={Helpers.toInputFieldDate(manager.uploadConfig.endDateUtc)}
                    onChange={(e) => manager.updateProperty('endDateUtc', e.target.value)}
                  />
                </div>
                {manager.errors.endDateUtc && (
                  <div className="alert-danger mt-1 p-2">End date must be tomorrow or later.</div>
                )}
                {manager.runDateOrder && (
                  <div className="alert-danger mt-1 p-2">From date cannot be after end date.</div>
                )}
                <HelpTip className="mt-2">
                  If you omit the start date, the automated imports will begin at the next scheduled
                  occurrence. If you omit the end date, automated imports will continue
                  indefinitely.
                </HelpTip>
              </div>
            </div>
          </fieldset>

          <fieldset className={styles.section}>
            <legend className={styles.sectionLegend}>File Name Configuration</legend>
            <HelpTip className="mt-0 mb-3">
              Import files need to be named with required sections that indicate unique import
              properties. Enter the import properties below and we will automatically generate the
              required file name for you.
            </HelpTip>
            <div className="row">
              <div className="form-group col-xl-5 col-lg-12">
                <label htmlFor="filePrefix">
                  File Name <span className="req">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="filePrefix"
                  placeholder="File Name"
                  maxLength={50}
                  value={manager.uploadConfig.fileNameConfiguration.prefix}
                  onChange={(e) => manager.updateFileOptions('prefix', e.target.value)}
                />
              </div>
              <div className="form-group col-xl-3 col-lg-12">
                <label htmlFor="fileExtension">File Extension</label>
                <input
                  type="text"
                  className="form-control"
                  id="fileExtension"
                  placeholder=".txt .csv .dat"
                  maxLength={20}
                  value={manager.uploadConfig.fileNameConfiguration.extension ?? ''}
                  onChange={(e) => manager.updateFileOptions('extension', e.target.value)}
                />
              </div>
              <div className="form-group col-xl-4 col-lg-12">
                <label htmlFor="fileExpiration">
                  Days until Expiration <span className="req">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fileExpiration"
                  placeholder="None"
                  maxLength={4}
                  value={manager.uploadConfig.fileNameConfiguration.expiration ?? ''}
                  onChange={(e) => manager.updateFileOptions('expiration', e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-12">
                <div>
                  <label>Special Instructions</label>
                </div>
                <div className="form-check mt-2">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={manager.uploadConfig.fileNameConfiguration.expirePrevious}
                      onChange={() =>
                        manager.updateFileOptions(
                          'expirePrevious',
                          !manager.uploadConfig.fileNameConfiguration.expirePrevious
                        )
                      }
                    />
                    Expire the previous file and replace with the imported file.
                  </label>
                </div>
              </div>
            </div>
            {manager.errors.fileName && (
              <div className="alert-danger mt-1 p-2">
                Missing one or more file name configuration details.
              </div>
            )}
            <hr />
            {Helpers.isNullOrWhitespace(manager.uploadConfig.fileName) && (
              <div className="text-danger">
                <em>Enter the required fields above to create your import file name.</em>
              </div>
            )}
            {!Helpers.isNullOrWhitespace(manager.uploadConfig.fileName) && (
              <>
                <div className="form-group">
                  <label htmlFor="generatedFileName">Your Generated Import File Name</label>
                  <div className={styles.generatedFileName}>{manager.getFileNameAsHtml()}</div>
                </div>
                <HelpTip className="mt-0 mb-3">
                  Automated import files uploaded to the SFTP server must match this name in order
                  to be processed. The &apos;_YYYY-MM-DD&apos; section is an optional placeholder
                  for the file due date.
                  <div>
                    For example, import files with a due date of today would be named:{' '}
                    <strong>
                      {manager.uploadConfig.fileName.replace(
                        'YYYY-MM-DD',
                        Helpers.toInputFieldDate(new Date())
                      )}
                    </strong>
                  </div>
                  <div>
                    Import files with no due date would be named:{' '}
                    <strong>{manager.uploadConfig.fileName.replace('_YYYY-MM-DD', '')}</strong>
                  </div>
                </HelpTip>
              </>
            )}
          </fieldset>
          <fieldset className={styles.section}>
            <legend className={styles.sectionLegend}>Notification Settings</legend>
            <div className="form-group mb-2">
              <label htmlFor="successNotifications">Success Notifications</label>
              <input
                type="text"
                className="form-control"
                id="successNotifications"
                placeholder=""
                value={manager.uploadConfig.successNotifications}
                onChange={(e) => manager.updateProperty('successNotifications', e.target.value)}
              />
            </div>
            {manager.errors.successNotifications && (
              <div className="alert-danger mt-1 p-2">One or more email addresses are invalid.</div>
            )}
            <HelpTip className="mt-2 mb-3">
              Enter the email addresses that should receive import success notifications. Multiple
              email addresses should be separated by commas.
            </HelpTip>
            <div className="form-group mb-2">
              <label htmlFor="errorNotifications">Error Notifications</label>
              <input
                type="text"
                className="form-control"
                id="errorNotifications"
                placeholder=""
                value={manager.uploadConfig.errorNotifications}
                onChange={(e) => manager.updateProperty('errorNotifications', e.target.value)}
              />
              <HelpTip className="mt-2">
                Enter the email addresses that should receive import error notifications. Multiple
                email addresses should be separated by commas.
              </HelpTip>
              {manager.errors.errorNotifications && (
                <div className="alert-danger mt-1 p-2">
                  One or more email addresses are invalid.
                </div>
              )}
            </div>
          </fieldset>

          {manager.formHasError() && (
            <div className="alert-danger mb-2 p-2">
              Unable to save changes. Please update the required fields above.
            </div>
          )}
          <div className="form-group mb-2">
            <button className="btn btn-primary" onClick={manager.saveUploadConfig}>
              Save Changes
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default AutomatedUpload;

AutomatedUpload.propTypes = {
  transactionParameters: PropTypes.shape({
    brandId: PropTypes.number,
    customerId: PropTypes.number,
    transactionId: PropTypes.number
  }),
  canImport: PropTypes.bool
};
