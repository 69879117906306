import { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../../../context/provider/AuthContext';
import { Api, Helpers } from '../../../utils/helpers';
import { useInterval } from '../../../utils/hooks';
import {
  ImportParseType,
  AlertStatus,
  ImportQueueStatus,
  TransactionStatus,
  InterestType
} from '../../../utils/constants/enums';
import { NIL, v4 as uuidv4 } from 'uuid';

const useImportConfigure = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const queryTransactionId = Helpers.tryParseInt(queryParams.get('TransactionID'), null);
  const queryCustomerId = Helpers.tryParseInt(queryParams.get('customerId'), null);
  const queryImportFile = queryParams.get('ImportFile') ?? '';

  const [{ user }] = useAuthContext();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [containerPath, setContainerPath] = useState('');
  const [fileDetails, setFileDetails] = useState(null);
  const [message, setMessage] = useState({
    status: AlertStatus.INFO,
    content: ''
  });
  const [modalDialog, setModalDialog] = useState({
    visible: false,
    heading: '',
    message: '',
    okButtonText: '',
    cancelButtonText: '',
    okCallback: () => {},
    cancelCallback: () => {}
  });
  const [preprocessMessage, setPreprocessMessage] = useState({
    status: AlertStatus.INFO,
    content: ''
  });
  const scheduledImports = useRef([]);
  const [showScheduledImportsModal, setShowScheduledImports] = useState(false);
  const [viewScheduledImports, setViewScheduledImports] = useState({
    customerName: '',
    scheduledImports: []
  });
  const transactionConfigurations = useRef([]);
  const processQueue = useRef([]);
  const [preprocessingResult, setPreprocessingResult] = useState([]);
  const [showContinue, setShowContinue] = useState(false);
  const [preprocessCheckInterval, setPreprocessCheckInterval] = useState(null);
  const customers = user.userResources.brands
    .find((brand) => brand.id === user.activeBrand.id)
    .customers.map((customer) => {
      return { id: customer.id, name: customer.name };
    });
  const defaultCustomer = customers.length === 1 ? customers[0] : null;

  const defaultSelectedCustomerId =
    queryCustomerId && customers.some((x) => x.id === queryCustomerId)
      ? queryCustomerId
      : defaultCustomer?.id;

  const hasQueryTransaction = user.userResources.brands
    .find((brand) => brand.id === user.activeBrand.id)
    .customers.find((customer) => customer.id === defaultSelectedCustomerId)
    ?.transactions.some((transaction) => transaction.id === queryTransactionId);

  const defaultSelectedTransactionId =
    queryTransactionId && hasQueryTransaction ? queryTransactionId : null;

  const [searchParameters, setSearchParameters] = useState({
    brandId: user.activeBrand.id,
    customerId: defaultSelectedCustomerId,
    transactionId: defaultSelectedTransactionId,
    secondaryTransactionId: null,
    showSecondaryTransaction: false,
    showInterestControls: false,
    interestTypeId: 0,
    netOutPayments: false
  });

  const transactions = user.userResources.brands
    .find((brand) => brand.id === user.activeBrand.id)
    .customers.find((customer) => customer.id === searchParameters.customerId)
    ?.transactions.filter(
      (transaction) =>
        !transaction.deleted &&
        transaction.status !== TransactionStatus.INACTIVE &&
        transaction.parserTypeId > 0 &&
        !transaction.noImport
    )
    .map((transaction) => {
      return {
        id: transaction.id,
        name: transaction.name,
        status: transaction.status,
        calculateInterest: transaction.calculateInterest
      };
    });

  const currentDate = new Date();
  const currentUtcDate = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const currentDayLimit = new Date(currentUtcDate);
  const autoPayDueDateLimit = Helpers.addDays(currentUtcDate, 5);
  const autoPayDueDateDisplay = Helpers.addDays(currentDate, 5);
  const defaultExpirationDate = Helpers.addMonths(currentDate, 1);
  const defaultRunAtDate = Helpers.addDays(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 7, 0, 0),
    1
  );

  const [scheduleDetails, setScheduleDetails] = useState({
    runLater: false,
    runOnDateUtc: defaultRunAtDate.toISOString()
  });

  const [parseRequest, setParseRequest] = useState({
    id: NIL,
    location: '',
    fileName: '',
    transactionId: null,
    userName: user.userName,
    expirationDate: Helpers.toInputFieldDate(defaultExpirationDate),
    description: '',
    interestCalculationDate: null,
    demandFeeDate: null,
    demandFee: 0.0,
    dueDate: null,
    siteCode: Helpers.getSiteCode(user.activeBrand.id),
    isNotificationDisabled: true,
    parseType: ImportParseType.PREPROCESS,
    isAuto: false
  });

  const messageTimerRef = useRef(null);
  const showMessage = (message, status = AlertStatus.INFO, timeout = null) => {
    clearTimeout(messageTimerRef.current);
    setMessage({
      status: status,
      content: message
    });
    if (timeout && typeof timeout === 'number') {
      messageTimerRef.current = setTimeout(() => {
        clearMessage();
      }, timeout);
    }
  };

  const clearMessage = () => {
    setMessage({
      status: AlertStatus.INFO,
      content: ''
    });
  };

  const preprocessMessageTimerRef = useRef(null);
  const showPreprocessMessage = (message, status = AlertStatus.INFO, timeout = null) => {
    clearTimeout(preprocessMessageTimerRef.current);
    setPreprocessMessage({
      status: status,
      content: message
    });
    if (timeout && typeof timeout === 'number') {
      preprocessMessageTimerRef.current = setTimeout(() => {
        clearPreprocessMessage();
      }, timeout);
    }
  };

  const clearPreprocessMessage = () => {
    setPreprocessMessage({
      status: AlertStatus.INFO,
      content: ''
    });
  };

  const toggleShowSecondaryTransaction = () => {
    setSearchParameters({
      ...searchParameters,
      showSecondaryTransaction: !searchParameters.showSecondaryTransaction
    });
  };

  const updateParseRequest = (key, value) => {
    setErrors({});
    let parseParams = { ...parseRequest };
    parseParams[key] = value;
    setParseRequest(parseParams);
  };

  const updateSearchParams = (key, value) => {
    setErrors({});
    let params = { ...searchParameters };
    params[key] = value;
    setSearchParameters(params);
  };

  const updateScheduleDetails = (key, value) => {
    setErrors({});
    let params = { ...scheduleDetails };
    params[key] = value;
    setScheduleDetails(params);
  };

  const updateExistingSchedule = (id, key, value) => {
    const scheduledItems = { ...viewScheduledImports };
    const scheduledItem = scheduledItems.scheduledImports.find((x) => x.id === id);
    if (scheduledItem) {
      scheduledItem[key] = value;
      scheduledItem.hasChanges = key !== 'showDetails';
    }
    setViewScheduledImports(scheduledItems);
  };

  const hasScheduleChanges = () => {
    return viewScheduledImports?.scheduledImports.some((x) => x.hasChanges) ?? false;
  };

  const validateScheduleChanges = () => {
    const scheduleList = { ...viewScheduledImports };
    const changedSchedules = scheduleList.scheduledImports.filter((x) => x.hasChanges);
    if (changedSchedules.length === 0) return false;

    let isValid = true;

    for (const changedSchedule of changedSchedules) {
      const testDueDate = Helpers.tryParseDate(changedSchedule.importDetails.dueDate, null);
      const autoPayScheduleLimit = Helpers.addDays(testDueDate, -4);
      const testSchDate = Helpers.tryParseDate(changedSchedule.runOnDateUtc, null);
      //did they provide a date?
      if (!testSchDate) {
        changedSchedule.invalidScheduleDate = true;
        isValid = false;
        continue;
      } else {
        delete changedSchedule.invalidScheduleDate;
      }

      //did they provide a date?
      if (!testSchDate) {
        changedSchedule.invalidScheduleDate = true;
        isValid = false;
        continue;
      } else {
        delete changedSchedule.invalidScheduleDate;
      }

      //must be 30+ minutes from now
      const scheduleLimit = Helpers.addMinutes(currentDate, 30);
      if (testSchDate.getTime() < scheduleLimit.getTime()) {
        changedSchedule.invalidScheduleDateTime = true;
        isValid = false;
        continue;
      } else {
        delete changedSchedule.invalidScheduleDateTime;
      }

      const transactionConfig = transactionConfigurations.current.find(
        (x) => x.transactionId === changedSchedule.transactionId
      );
      const isAutoPay = transactionConfig?.isAutoPayment ?? false;

      //non-autopay must be scheduled before the due date
      if (testDueDate && !isAutoPay && testDueDate.getTime() < testSchDate.getTime()) {
        changedSchedule.dueDateScheduleDateError = true;
        isValid = false;
        continue;
      } else {
        delete changedSchedule.dueDateScheduleDateError;
      }

      //autopay has to be scheduled 5 days earlier
      if (isAutoPay && testSchDate.getTime() > autoPayScheduleLimit.getTime()) {
        changedSchedule.autoPayScheduleDateError = true;
        isValid = false;
        continue;
      } else {
        delete changedSchedule.autoPayScheduleDateError;
      }
    }
    setViewScheduledImports(scheduleList);
    return isValid;
  };

  const saveScheduleChanges = async () => {
    const isValid = validateScheduleChanges();
    if (!isValid) return;

    try {
      let scheduleList = { ...viewScheduledImports };
      const updateRequest = scheduleList.scheduledImports
        .filter((x) => x.hasChanges)
        .map((item) => {
          return {
            id: item.id,
            runOnDateUtc: item.runOnDateUtc
          };
        });

      const apiResponse = await Api.put(
        `/policy/ScheduledImports/${user.activeBrand.id}/${searchParameters.customerId}`,
        JSON.stringify(updateRequest)
      );
      if (apiResponse && apiResponse.status.statusCode === 200) {
        for (const item of scheduleList.scheduledImports) {
          item.hasChanges = false;
        }
        setViewScheduledImports(scheduleList);
        loadScheduled(true);
        showMessage('Import schedule has been updated successfully.', AlertStatus.SUCCESS, 8000);
      }
    } catch (e) {
      // failed to update
      console.error(e);
      showMessage(
        'An error occurred updating schedule. Please try again.',
        AlertStatus.ERROR,
        8000
      );
    }
    closeScheduledImportModal();
  };

  const closeModal = () => {
    setLoading(false);
    setShowModal(false);
    setPreprocessingResult([]);
    setPreprocessCheckInterval(null);
  };

  const closeFileModal = () => {
    setShowFileModal(false);
  };

  const closeScheduledImportModal = () => {
    setShowScheduledImports(false);
  };

  const showCancelScheduleDialog = (id) => {
    //hide the schedule and show the cancel confirmation
    closeScheduledImportModal();
    setModalDialog({
      visible: true,
      heading: 'Cancel Scheduled Import?',
      message: 'Are you sure you want to cancel this scheduled import? This cannot be undone.',
      okButtonText: 'Yes, cancel this import.',
      cancelButtonText: "No, don't cancel.",
      okCallback: () => cancelScheduledImport(id),
      cancelCallback: closeCancelScheduleDialog
    });
  };
  const closeCancelScheduleDialog = () => {
    closeModalDialog();
    setShowScheduledImports(true);
  };

  const closeModalDialog = () => {
    setModalDialog({ ...modalDialog, visible: false });
  };

  const cancelScheduledImport = async (id) => {
    var hasRemainingImports = true;
    try {
      const apiResponse = await Api.delete(`/policy/ScheduledImports/${user.activeBrand.id}/${id}`);
      if (apiResponse && apiResponse.status.statusCode === 200) {
        //remove from list and reload to confirm
        let scheduleList = { ...viewScheduledImports };
        scheduleList.scheduledImports = scheduleList.scheduledImports.filter((x) => x.id !== id);
        hasRemainingImports = scheduleList.scheduledImports.length > 0;
        setViewScheduledImports(scheduleList);
        loadScheduled(true);
      }
    } catch (e) {
      // failed to delete
      console.error(e);
    }
    closeModalDialog();
    setShowScheduledImports(hasRemainingImports);
  };

  const createScheduledImport = async () => {
    const testSchDate = Helpers.tryParseDate(scheduleDetails.runOnDateUtc, null);
    const testDueDate = Helpers.tryParseDate(parseRequest.dueDate, null);
    const autoPayScheduleLimit = Helpers.addDays(testDueDate, -4);
    //did they provide a date?
    if (!testSchDate) {
      setErrors({ invalidScheduleDate: true });
      return;
    }
    //must be 30+ minutes from now
    const scheduleLimit = Helpers.addMinutes(currentDate, 30);
    if (testSchDate.getTime() < scheduleLimit.getTime()) {
      setErrors({ invalidScheduleDateTime: true });
      return;
    }

    const transactionConfig = transactionConfigurations.current.find(
      (x) => x.transactionId === searchParameters.transactionId
    );
    const isAutoPay = transactionConfig?.isAutoPayment ?? false;

    //non-autopay must be scheduled before the due date
    if (testDueDate && !isAutoPay && testDueDate.getTime() < testSchDate.getTime()) {
      setErrors({ dueDateScheduleDateError: true });
      return;
    }

    //autopay has to be scheduled 5 days earlier
    if (isAutoPay && testSchDate.getTime() > autoPayScheduleLimit.getTime()) {
      setErrors({ autoPayScheduleDateError: true });
      return;
    }

    //ok to create schedule for all preprocessed files
    const preprocessedItems = processQueue.current.filter(
      (x) =>
        x.status === ImportQueueStatus.COMPLETED || x.status === ImportQueueStatus.FAILED_PREPROCESS
    );

    if (preprocessedItems.length === 0) {
      showMessage(
        'No preprocessed transactions found. Please try running the pre-process again.',
        AlertStatus.ERROR,
        8000
      );
      setShowModal(false);
      return;
    }

    let schedulingSucceeded = true;
    for (const preprocessedItem of preprocessedItems) {
      //get a copy of parse details for storing with the schedule
      const details = {
        ...parseRequest,
        transactionId: preprocessedItem.transactionId,
        transactionName: preprocessedItem.transactionName,
        location: fileDetails.fullPath,
        fileName: fileDetails.name,
        showInterestControls: searchParameters.showInterestControls,
        interestTypeId: searchParameters.interestTypeId,
        netOutPayments: searchParameters.netOutPayments
      };

      const scheduleRequest = {
        id: uuidv4(),
        brandId: user.activeBrand.id,
        customerId: searchParameters.customerId,
        transactionId: preprocessedItem.transactionId,
        filePath: fileDetails.fullPath,
        parserQueueId: preprocessedItem.parseId,
        runOnDateUtc: testSchDate,
        importDetails: JSON.stringify(details)
      };

      try {
        const apiResponse = await Api.post(
          `/policy/ScheduledImports`,
          JSON.stringify(scheduleRequest)
        );
        if (!apiResponse || apiResponse.status.statusCode !== 200) {
          schedulingSucceeded = false;
        }
      } catch {
        // failed to save scheduled import
        schedulingSucceeded = false;
      }
    }

    if (schedulingSucceeded) {
      showMessage('Your import has been scheduled successfully!', AlertStatus.SUCCESS, 8000);
      //reload scheduled imports
      loadScheduled(true);
    } else {
      showMessage(
        'Error occurred scheduling the import. Please try again.',
        AlertStatus.ERROR,
        8000
      );
    }
    //clear process queue and hide modal
    processQueue.current = [];
    setPreprocessingResult([]);
    setShowContinue(false);
    setShowModal(false);
  };

  const startParse = () => {
    // fire off the parse

    //we allow failed preprocess files to be imported, so accept COMPLETED or FAILED_PREPROCESS statuses
    const preprocessedItems = processQueue.current.filter(
      (x) =>
        x.status === ImportQueueStatus.COMPLETED || x.status === ImportQueueStatus.FAILED_PREPROCESS
    );

    if (preprocessedItems.length === 0) {
      showMessage(
        'No preprocessed transactions found. Please try running the pre-process again.',
        AlertStatus.ERROR,
        8000
      );
      setLoading(false);
      setShowModal(false);
      return;
    }

    for (const preprocessedItem of preprocessedItems) {
      Api.post(`/import/parse/${preprocessedItem.parseId}/run`);
    }

    navigate('/files/file-import-monitor', {
      state: { selectedCustomerId: searchParameters.customerId }
    });
  };

  const validateImportRequest = () => {
    let configErrors = {};

    const transactionConfig = transactionConfigurations.current.find(
      (x) => x.transactionId === searchParameters.transactionId
    );
    const isAutoPay = transactionConfig?.isAutoPayment ?? false;
    const testDueDate = Helpers.tryParseDate(parseRequest.dueDate, null);
    const testExpDate = Helpers.tryParseDate(parseRequest.expirationDate, null);
    const testDemandDate = Helpers.tryParseDate(parseRequest.demandFeeDate, null);
    const testInterestDate = Helpers.tryParseDate(parseRequest.interestCalculationDate, null);

    if (Helpers.isNullOrWhitespace(parseRequest.description)) {
      configErrors.missingDescription = true;
    }

    if (!searchParameters.transactionId) configErrors.missingTransaction = true;
    if (!transactionConfig) configErrors.transactionConfigError = true;
    if (!parseRequest.expirationDate) configErrors.missingexpirationDate = true;
    if (!parseRequest.dueDate) configErrors.dueDateError = true;
    if (!fileDetails) configErrors.missingFile = true;

    //if second transaction is selected, make sure it's compatible with the first
    const txsCompatible = checkTransactionCompatibility(
      searchParameters.transactionId,
      searchParameters.secondaryTransactionId
    );
    if (!txsCompatible) configErrors.transactionsNotCompatible = true;

    //non-autopay must be today or later
    if (testDueDate && !isAutoPay && testDueDate.getTime() < currentDayLimit.getTime()) {
      configErrors.dueDateBeforeTodayError = true;
    }
    //autopay must be 5+ days out
    if (testDueDate && isAutoPay && testDueDate.getTime() < autoPayDueDateLimit.getTime()) {
      configErrors.autoPayDueDateError = true;
    }

    //expiration is required and must be after today
    if (!testExpDate) {
      configErrors.missingExpirationDate = true;
    } else if (testDueDate && testExpDate && testExpDate.getTime() <= testDueDate.getTime()) {
      configErrors.expirationLessThanDueDateError = true;
    }

    if (searchParameters.showInterestControls) {
      if (
        (!searchParameters.netOutPayments ||
          searchParameters.interestTypeId !== InterestType.NONE_0PCT) &&
        !parseRequest.interestCalculationDate
      )
        configErrors.interestDateError = true;

      //interest date must be after due date and before expiration date
      if (
        testDueDate &&
        testExpDate &&
        testInterestDate &&
        (testInterestDate.getTime() <= testDueDate.getTime() ||
          testInterestDate.getTime() >= testExpDate.getTime())
      ) {
        configErrors.interestDateRangeError = true;
      }

      if (parseRequest.demandFee) {
        if (!testDemandDate) {
          configErrors.demandFeeDateMissingError = true;
        } else if (
          testDueDate &&
          testDemandDate &&
          testDemandDate.getTime() <= testDueDate.getTime()
        ) {
          configErrors.demandDateLessThanDueDateError = true;
        }
      }
    }
    setErrors(configErrors);
    //do we have any errors?
    return Object.keys(configErrors).length === 0;
  };

  const startProcess = async () => {
    clearMessage();
    clearPreprocessMessage();
    processQueue.current = [];

    const isValid = validateImportRequest();
    if (!isValid) return;

    setShowContinue(false);
    setLoading(true);
    setShowModal(true);

    const txConfig = transactionConfigurations.current.find(
      (x) => x.transactionId === searchParameters.transactionId
    );
    const downloadUrl = await Api.get(
      `/files/containers/${containerPath}/blobs?path=${encodeURIComponent(fileDetails.fullPath)}`
    );
    if (downloadUrl.status.statusCode !== 200) {
      console.error(downloadUrl.status.errors);
      showMessage('Error accessing file download URL. Please try again.', AlertStatus.ERROR, 8000);
      setLoading(false);
      setShowModal(false);
      return;
    }

    const preprocessRequest = {
      ...parseRequest,
      id: NIL,
      transactionId: searchParameters.transactionId,
      location: downloadUrl.response,
      fileName: fileDetails.name,
      isNotificationDisabled: txConfig?.isNotificationDisabled ?? true,
      demandFeeDate: parseRequest.demandFeeDate ?? '0001-01-01T00:00:00'
    };

    // if this transaction does not require interest controls
    // make sure they are cleared
    if (!searchParameters.showInterestControls) {
      preprocessRequest.interestCalculationDate = null;
      preprocessRequest.demandFee = 0;
      preprocessRequest.demandFeeDate = null;
    }
    await addToPreprocessQueue(preprocessRequest, searchParameters.transactionId);
    await addToPreprocessQueue(preprocessRequest, searchParameters.secondaryTransactionId);

    //make sure we have something to process
    if (processQueue.current.length === 0) {
      setShowModal(false);
      setLoading(false);
      showMessage('Unable to add transaction(s) to preprocessor queue.', AlertStatus.ERROR, 8000);
      return;
    }

    //start preprocess queue
    setPreprocessCheckInterval(3000);
  };

  const addToPreprocessQueue = async (preprocessRequest, transactionId) => {
    if (!preprocessRequest || !transactionId) return;
    try {
      const processRequest = { ...preprocessRequest, transactionId: transactionId };
      const parserApiResponse = await Api.post('/import/parse', JSON.stringify(processRequest));
      if (
        parserApiResponse.Status.StatusCode === 200 &&
        !Helpers.isNullOrWhitespace(parserApiResponse.Response.Id)
      ) {
        processQueue.current.push({
          transactionId: transactionId,
          transactionName:
            transactions.find((x) => x.id === transactionId)?.name ??
            `Transaction ${transactionId}`,
          parseId: parserApiResponse.Response.Id,
          status: ImportQueueStatus.PENDING,
          preprocessResults: []
        });
      }
    } catch {
      showMessage('Server error queuing import preprocessor.', AlertStatus.ERROR, 8000);
    }
  };

  const checkTransactionCompatibility = useCallback((transactionId, secondaryTransactionId) => {
    if (!transactionId || !secondaryTransactionId) return true;
    if (transactionId === secondaryTransactionId) return false;

    const primaryConfig = transactionConfigurations.current.find(
      (x) => x.transactionId === transactionId
    );
    const secondaryConfig = transactionConfigurations.current.find(
      (x) => x.transactionId === secondaryTransactionId
    );
    if (!primaryConfig || !secondaryConfig) return false;

    //add other transaction compatibility rules here
    return primaryConfig.showInterestControls === secondaryConfig.showInterestControls;
  }, []);

  const loadTransactionDetails = useCallback(
    async (transactionId) => {
      //no need to continue if incomplete request or we have the transaction info already
      if (
        !user.activeBrand.id ||
        !searchParameters.customerId ||
        !transactionId ||
        transactionConfigurations.current.some((x) => x.transactionId === transactionId)
      ) {
        return;
      }

      const transactionRequest = {
        brandId: user.activeBrand.id,
        customerId: searchParameters.customerId,
        transactionId: transactionId
      };

      try {
        const apiResponse = await Api.post(
          '/policy/Transaction/ImportConfigurationSummary',
          JSON.stringify(transactionRequest)
        );
        if (!apiResponse || apiResponse.status.statusCode !== 200) {
          throw new Error('Error getting transaction details.');
        }
        transactionConfigurations.current.push({
          transactionId: transactionId,
          isNotificationDisabled: apiResponse.response.notificationSuspended,
          showInterestControls: apiResponse.response.calculateInterest ?? false,
          isAutoPayment: apiResponse.response.isAutoPayment ?? false,
          interestTypeId: apiResponse.response.interestTypeId ?? 0,
          netOutPayments: apiResponse.response.interestNetOutPayments ?? false
        });
      } catch {
        //something bad happened, reset transaction ids to try again
        setSearchParameters((sp) => ({ ...sp, transactionId: null, secondaryTransactionId: null }));
        showMessage(
          'An error occurred loading transaction details. Please try selecting the transaction again.',
          AlertStatus.ERROR,
          8000
        );
        return;
      }
    },
    [user.activeBrand.id, searchParameters.customerId, transactions]
  );

  const getScheduledImports = useCallback(
    async (customerId, reload = false) => {
      //no need to continue if incomplete request or we have the schedule info already
      if (
        !user.activeBrand.id ||
        !customerId ||
        (!reload && scheduledImports.current.some((x) => x.customerId === customerId))
      ) {
        return;
      }

      try {
        const apiResponse = await Api.get(
          `/policy/ScheduledImports/${user.activeBrand.id}/${customerId}`
        );
        if (apiResponse && apiResponse.status.statusCode === 200) {
          const existingScheduleSet = scheduledImports.current.find(
            (x) => x.brandId === user.activeBrand.id && x.customerId === customerId
          );
          if (existingScheduleSet) {
            existingScheduleSet.scheduledImports = apiResponse.response;
          } else {
            scheduledImports.current.push({
              brandId: user.activeBrand.id,
              customerId: customerId,
              scheduledImports: apiResponse.response
            });
          }
        }
      } catch (e) {
        // failed to load scheduled imports - this is for informational purposes, so it's safe to ignore
        console.error(e);
      }
    },
    [user.activeBrand.id]
  );

  useEffect(() => {
    const findQueryImportFile = async (filePath) => {
      if (!containerPath || !filePath || fileDetails) return;
      try {
        const apiResponse = await Api.get(`/files/containers/${containerPath}/blobs/directory`);
        if (apiResponse.status.statusCode === 200) {
          const foundFile = apiResponse.response.find(
            (x) => x.name.toLowerCase() === filePath.toLowerCase()
          );
          setFileDetails(foundFile);
        }
      } catch {
        //ignore
      }
    };
    findQueryImportFile(queryImportFile);
  }, [containerPath, queryImportFile]);

  useEffect(() => {
    const loadTx = async () => {
      await loadTransactionDetails(searchParameters.transactionId);

      const txsCompatible = checkTransactionCompatibility(
        searchParameters.transactionId,
        searchParameters.secondaryTransactionId
      );
      setErrors(!txsCompatible ? { transactionsNotCompatible: true } : {});

      const txConfig = transactionConfigurations.current.find(
        (x) => x.transactionId === searchParameters.transactionId
      );

      setSearchParameters((sp) => ({
        ...sp,
        showInterestControls: txConfig?.showInterestControls ?? false,
        interestTypeId: txConfig?.interestTypeId ?? InterestType.UNKNOWN,
        netOutPayments: txConfig?.netOutPayments ?? false
      }));
    };
    loadTx();
  }, [searchParameters.transactionId]);

  useEffect(() => {
    const loadTx = async () => {
      await loadTransactionDetails(searchParameters.secondaryTransactionId);
      const txsCompatible = checkTransactionCompatibility(
        searchParameters.transactionId,
        searchParameters.secondaryTransactionId
      );
      setErrors(!txsCompatible ? { transactionsNotCompatible: true } : {});
    };
    loadTx();
  }, [searchParameters.secondaryTransactionId]);

  const loadScheduled = useCallback(
    async (reload = false) => {
      await getScheduledImports(searchParameters.customerId, reload);
      const customerImportSchedules = scheduledImports.current.find(
        (x) => x.brandId === user.activeBrand.id && x.customerId === searchParameters.customerId
      );
      if (!customerImportSchedules) {
        setViewScheduledImports({
          customerName: '',
          scheduledImports: []
        });
        return;
      }

      const viewCustomerSchedule = {
        customerName:
          customers.find((c) => c.id === searchParameters.customerId)?.name ?? 'Customer',
        scheduledImports: customerImportSchedules.scheduledImports.map((schedule) => {
          let trimmedPath = schedule.filePath;
          if (trimmedPath.toLowerCase().startsWith('in/')) {
            trimmedPath = trimmedPath.substring(3);
          }

          let importDetails = {};
          try {
            importDetails = JSON.parse(schedule.importDetails);
          } catch {
            // unable to parse - this is just for informational purposes, so safe to ignore
          }

          return {
            id: schedule.id,
            parserQueueId: schedule.parserQueueId,
            transactionId: schedule.transactionId,
            transactionName:
              transactions.find((t) => t.id === schedule.transactionId)?.name ??
              'Unknown transaction',
            importDetails: importDetails,
            filePath: trimmedPath,
            runOnDateUtc: schedule.runOnDateUtc,
            hasChanges: schedule.hasChanges ?? false,
            showDetails: false
          };
        })
      };
      setViewScheduledImports(viewCustomerSchedule);
    },
    [customers, transactions, getScheduledImports, user.activeBrand.id, searchParameters.customerId]
  );

  //update container path and clear any selected file if customer or brand changes
  useEffect(() => {
    const containerPath =
      user.activeBrand.id && searchParameters.customerId
        ? user.activeBrand.id + '-' + searchParameters.customerId
        : null;
    setContainerPath(containerPath);
    setFileDetails(null);
    setParseRequest((pr) => ({ ...pr, description: '' }));
    loadScheduled();
  }, [user.activeBrand.id, searchParameters.customerId]);

  useInterval(() => {
    const checkPreProcessResult = async () => {
      //do we have anthing left to check?
      const pendingItems = processQueue.current.filter(
        (x) => x.status === ImportQueueStatus.PENDING
      );

      if (pendingItems.length === 0) setPreprocessCheckInterval(null);

      for (let pendingItem of pendingItems) {
        try {
          const preprocessorApiResponse = await Api.get(
            `/import/parse/${pendingItem.parseId}/PreProcessResult`
          );
          if (preprocessorApiResponse.Status.StatusCode !== 200) {
            pendingItem.status = ImportQueueStatus.FAILED;
            pendingItem.preprocessResults = [
              {
                label: null,
                value: `Error retrieving preprocessor status.`
              }
            ];
            continue;
          }

          //no response yet, so continue with others
          if (!preprocessorApiResponse.Response) continue;

          //add any success or error messages
          if (preprocessorApiResponse.Response.ParseErrors.length === 0) {
            pendingItem.status = ImportQueueStatus.COMPLETED;
            pendingItem.preprocessResults = [
              {
                label: 'Filename',
                value: preprocessorApiResponse.Response.ParseResult.FileName
              },
              {
                label: 'Record Count',
                value: preprocessorApiResponse.Response.ParseResult.RecordCount
              },
              {
                label: 'Total Amount Due',
                value: Helpers.formatCurrency(
                  preprocessorApiResponse.Response.ParseResult.TotalAmountDue
                )
              }
            ];
          } else {
            let errorResults = [];
            preprocessorApiResponse.Response.ParseErrors.forEach((element) => {
              errorResults.push({
                label: '',
                value: element.Error
              });
            });
            pendingItem.status = ImportQueueStatus.FAILED_PREPROCESS;
            pendingItem.preprocessResults = errorResults;
          }
        } catch (e) {
          //bad things happened
          pendingItem.status = ImportQueueStatus.FAILED;
          pendingItem.preprocessResults = [
            {
              label: null,
              value: `Server error retrieving preprocessor status. ${e}`
            }
          ];
        }
      }

      //check to see if there are any items remaining after processing
      const hasPendingItems = processQueue.current.some(
        (x) => x.status === ImportQueueStatus.PENDING
      );
      const hasFailedPreprocessItems = processQueue.current.some(
        (x) => x.status === ImportQueueStatus.FAILED_PREPROCESS
      );
      const hasFailedItems = processQueue.current.some(
        (x) => x.status === ImportQueueStatus.FAILED
      );

      if (!hasPendingItems) {
        setLoading(false);
        setShowContinue(true);
        setPreprocessCheckInterval(null);
        setPreprocessingResult(processQueue.current);

        if (hasFailedItems) {
          showPreprocessMessage(
            'An error occurred while processing the selected transaction and file. Please try again.',
            AlertStatus.ERROR
          );
        } else if (hasFailedPreprocessItems) {
          showPreprocessMessage(
            'Pre-process failed for the selected transaction and file. Please review the errors above for details. If you want to continue with the import, please choose when the import should be run below.',
            AlertStatus.WARNING
          );
        } else {
          showPreprocessMessage(
            'Pre-processing was successful. Please review the import details and choose when the import should be run.',
            AlertStatus.SUCCESS
          );
        }
      }
    };
    checkPreProcessResult();
  }, preprocessCheckInterval);

  return {
    loading,
    errors,
    message,
    preprocessMessage,
    showModal,
    showFileModal,
    showScheduledImportsModal,
    fileDetails,
    containerPath,
    customers,
    transactions,
    defaultCustomer,
    searchParameters,
    parseRequest,
    showContinue,
    preprocessingResult,
    autoPayDueDateDisplay,
    viewScheduledImports,
    scheduleDetails,
    modalDialog,
    queryImportFile,
    setLoading,
    closeModal,
    closeFileModal,
    setShowModal,
    setShowFileModal,
    closeScheduledImportModal,
    setShowScheduledImports,
    setFileDetails,
    startProcess,
    startParse,
    updateSearchParams,
    setParseRequest,
    updateParseRequest,
    toggleShowSecondaryTransaction,
    updateScheduleDetails,
    createScheduledImport,
    showCancelScheduleDialog,
    updateExistingSchedule,
    hasScheduleChanges,
    saveScheduleChanges
  };
};

export default useImportConfigure;
