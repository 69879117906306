import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Helpers } from '../../../utils/helpers';
import styles from './RecurringDaySelect.module.css';
import { RecurrenceType, RecurringDaySelectType } from '../../../utils/constants/enums';

export const RecurringDaySelect = ({
  recurringDaySelectType,
  recurrenceType,
  dayNumber,
  frequencyInterval,
  dayOfWeek,
  monthNumber,
  className,
  onChange
}) => {
  const recurrenceTypeGroupName = `rtype-${uuidv4()}`;
  const frequencyIntervals = Helpers.getFrequencyIntervals();
  const daysOfTheWeek = Helpers.getDaysOfTheWeek();
  const monthsOfTheYear = Helpers.getMonths();

  const updateValueByKey = (key, value, minValue, maxValue) => {
    let updateValues = { recurrenceType, dayNumber, frequencyInterval, dayOfWeek, monthNumber };
    let checkValue = Helpers.tryParseInt(value, 1);
    if (maxValue && checkValue > maxValue) {
      checkValue = maxValue;
    } else if (minValue && checkValue < minValue) {
      checkValue = minValue;
    }
    updateValues[key] = checkValue;
    onChange(updateValues);
  };

  const isMonthSelectType = recurringDaySelectType === RecurringDaySelectType.MONTH;
  const isYearSelectType = recurringDaySelectType === RecurringDaySelectType.YEAR;
  const specificDayDisabledClass =
    recurrenceType === RecurrenceType.SPECIFIC_DAY ? '' : styles.sectionDisabled;
  const intervalDisabledClass =
    recurrenceType === RecurrenceType.FREQUENCY_INTERVAL ? '' : styles.sectionDisabled;

  return (
    <div className={styles.wrapper + ' ' + className}>
      {/* Specific Day */}
      <div className="d-flex align-items-center mb-2">
        <div className="flex-shrink-1 pe-2">
          <input
            type="radio"
            name={recurrenceTypeGroupName}
            checked={recurrenceType === RecurrenceType.SPECIFIC_DAY}
            onChange={() => updateValueByKey('recurrenceType', RecurrenceType.SPECIFIC_DAY)}
          />
        </div>
        <div className={'d-flex align-items-center ' + specificDayDisabledClass}>
          {/* Month or Quarter - Specific Day Options */}
          {!isYearSelectType && (
            <>
              <div className="text-nowrap me-1">On day</div>
              <input
                type="number"
                className={'form-control me-2 ' + styles.dayNumber}
                value={dayNumber ?? ''}
                disabled={recurrenceType !== RecurrenceType.SPECIFIC_DAY}
                onChange={(e) => updateValueByKey('dayNumber', e.target.value, 1, 31)}
              />
              <div className="text-nowrap me-1">
                {isMonthSelectType ? 'of every month.' : 'of each quarter.'}
              </div>
            </>
          )}
          {/* Year - Specific Day Options */}
          {isYearSelectType && (
            <>
              <div className="text-nowrap me-1">On</div>
              <select
                className={'form-select me-1 ' + styles.dropdown}
                value={monthNumber}
                disabled={recurrenceType !== RecurrenceType.SPECIFIC_DAY}
                onChange={(e) => updateValueByKey('monthNumber', e.target.value)}>
                {monthsOfTheYear.map((month) => {
                  return (
                    <option key={month.value} value={month.value}>
                      {month.name}
                    </option>
                  );
                })}
              </select>
              <input
                type="number"
                className={'form-control me-2 ' + styles.dayNumber}
                value={dayNumber ?? ''}
                disabled={recurrenceType !== RecurrenceType.SPECIFIC_DAY}
                onChange={(e) => updateValueByKey('dayNumber', e.target.value, 1, 31)}
              />
            </>
          )}
        </div>
      </div>

      {/* Interval Frequency */}
      <div className="d-flex align-items-center">
        <div className="flex-shrink-1 pe-2">
          <input
            type="radio"
            name={recurrenceTypeGroupName}
            checked={recurrenceType === RecurrenceType.FREQUENCY_INTERVAL}
            onChange={() => updateValueByKey('recurrenceType', RecurrenceType.FREQUENCY_INTERVAL)}
          />
        </div>
        <div
          className={
            'd-flex flex-sm-wrap flex-md-nowrap align-items-center ' + intervalDisabledClass
          }>
          {/* Month or Quarter - Interval Options */}
          {!isYearSelectType && (
            <>
              <div className="text-nowrap me-1">On the {isMonthSelectType ? '' : 'first'}</div>
              {isMonthSelectType && (
                <select
                  className={'form-select me-2 ' + styles.dropdown}
                  value={frequencyInterval}
                  disabled={recurrenceType !== RecurrenceType.FREQUENCY_INTERVAL}
                  onChange={(e) => updateValueByKey('frequencyInterval', e.target.value)}>
                  {frequencyIntervals.map((interval) => {
                    return (
                      <option key={interval.value} value={interval.value}>
                        {interval.name}
                      </option>
                    );
                  })}
                </select>
              )}
              <select
                className={'form-select me-2 ' + styles.dropdown}
                value={dayOfWeek}
                disabled={recurrenceType !== RecurrenceType.FREQUENCY_INTERVAL}
                onChange={(e) => updateValueByKey('dayOfWeek', e.target.value)}>
                {daysOfTheWeek.map((day) => {
                  return (
                    <option key={day.value} value={day.value}>
                      {day.name}
                    </option>
                  );
                })}
              </select>
              <div className="text-nowrap">
                {isMonthSelectType ? 'of every month.' : 'of each quarter.'}
              </div>
            </>
          )}

          {/* Year - Interval Options */}
          {isYearSelectType && (
            <>
              <div className="text-nowrap me-1">On the</div>
              <select
                className={'form-select me-2 ' + styles.dropdown}
                value={frequencyInterval}
                disabled={recurrenceType !== RecurrenceType.FREQUENCY_INTERVAL}
                onChange={(e) => updateValueByKey('frequencyInterval', e.target.value)}>
                {frequencyIntervals.map((interval) => {
                  return (
                    <option key={interval.value} value={interval.value}>
                      {interval.name}
                    </option>
                  );
                })}
              </select>
              <select
                className={'form-select me-2 ' + styles.dropdown}
                value={dayOfWeek}
                disabled={recurrenceType !== RecurrenceType.FREQUENCY_INTERVAL}
                onChange={(e) => updateValueByKey('dayOfWeek', e.target.value)}>
                {daysOfTheWeek.map((day) => {
                  return (
                    <option key={day.value} value={day.value}>
                      {day.name}
                    </option>
                  );
                })}
              </select>
              <div className="text-nowrap me-2">of</div>
              <select
                className={'form-select me-1 ' + styles.dropdown}
                value={monthNumber}
                disabled={recurrenceType !== RecurrenceType.FREQUENCY_INTERVAL}
                onChange={(e) => updateValueByKey('monthNumber', e.target.value)}>
                {monthsOfTheYear.map((month) => {
                  return (
                    <option key={month.value} value={month.value}>
                      {month.name}
                    </option>
                  );
                })}
              </select>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecurringDaySelect;

RecurringDaySelect.propTypes = {
  recurringDaySelectType: PropTypes.number,
  dayNumber: PropTypes.number,
  frequencyInterval: PropTypes.number,
  dayOfWeek: PropTypes.number,
  monthNumber: PropTypes.number,
  className: PropTypes.string,
  onChange: PropTypes.func
};
