import { DefaultLayout as Layout } from '../../../layouts';
import { useAuthorization } from '../../../utils/hooks';
import { ProgressCircle, PagingControls } from '../../../components/ui';

export const StyleGuidePage = () => {
  useAuthorization();

  return (
    <Layout>
      <h1 className="pageTitle">Style Guide</h1>

      <div className="row">
        <div className="col-4">
          <h1>Heading One</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, <strong>sed do eiusmod</strong>{' '}
            tempor{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
              }}>
              incididunt ut labore
            </a>{' '}
            et dolore magna aliqua. Ut enim <em>ad minim veniam</em>, exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat. Libero enim sed{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
              }}>
              faucibus turpis
            </a>{' '}
            in eu mi bibendum. Odio pellentesque diam volutpat volutpat commodo. Id diam vel quam
            elementum pulvinar. Quisque sagittis purus sit amet volutpat consequat mauris nunc.
          </p>
          <h2>Heading two</h2>
          <p>
            Risus in hendrerit gravida rutrum quisque non tellus orci. Quis ipsum suspendisse
            ultrices gravida dictum fusce ut placerat orci. Dolor sed viverra ipsum nunc. Dapibus
            ultrices in iaculis nunc.
          </p>
          <h3>Heading three</h3>
          <p>
            Et ligula ullamcorper malesuada proin libero nunc consequat interdum. Tortor id aliquet
            lectus proin. Mauris augue neque gravida in.
          </p>
          <h4>Heading four</h4>
          <p>
            Amet nisl purus in mollis nunc. Habitasse platea dictumst vestibulum rhoncus est
            pellentesque elit ullamcorper dignissim. Etiam tempor orci eu lobortis elementum nibh
            tellus molestie nunc. Egestas quis ipsum suspendisse ultrices gravida dictum fusce.
          </p>
          <h5>Heading five</h5>
          <p>
            Fringilla urna porttitor rhoncus dolor purus non enim praesent. Consectetur lorem donec
            massa sapien. Sem viverra aliquet eget sit amet tellus cras. Vel pharetra vel turpis
            nunc eget lorem. Pellentesque elit eget gravida cum sociis. Nullam non nisi est sit amet
            facilisis magna. Et pharetra pharetra massa massa ultricies mi.
          </p>
          <h6>Heading Six</h6>
          <p>
            Dictum at tempor commodo ullamcorper. Ac felis donec et odio pellentesque. Risus nullam
            eget felis eget nunc. Neque vitae tempus quam pellentesque. Dictum varius duis at
            consectetur lorem donec massa sapien faucibus. Consectetur adipiscing elit duis
            tristique.
          </p>
        </div>
        <div className="col-3">
          <div className="d-grid gap-2 mb-4">
            <button className="btn btn-primary">Primary</button>
            <button className="btn btn-primary" disabled={true}>
              Primary Disabled
            </button>
            <button className="btn btn-primary btn-sm">Primary</button>
          </div>
          <div className="d-grid gap-2 mb-4">
            <button className="btn btn-secondary">Secondary</button>
            <button className="btn btn-secondary" disabled={true}>
              Secondary Disabled
            </button>
            <button className="btn btn-secondary btn-sm">Secondary Small</button>
          </div>
          <div className="d-grid gap-2 mb-4">
            <button className="btn btn-success">Success</button>
            <button className="btn btn-success" disabled={true}>
              Success Disabled
            </button>
            <button className="btn btn-success btn-sm">Success</button>
          </div>
          <div className="d-grid gap-2 mb-4">
            <button className="btn btn-danger">Danger</button>
            <button className="btn btn-danger" disabled={true}>
              Danger Disabled
            </button>
            <button className="btn btn-danger btn-sm">Danger</button>
          </div>
          {/*
          <div className="d-grid gap-2 mb-4">
            <button className="btn green">Green</button>
            <button className="btn green" disabled={true}>
              Green Disabled
            </button>
          </div>
          <div className="d-grid gap-2 mb-4">
            <button className="btn red">Red</button>
            <button className="btn red" disabled={true}>
              Red Disabled
            </button>
          </div>
          */}
          <div className="mb-2">
            <button className="btn btn-primary">Primary</button>
          </div>
          <div className="mb-2">
            <button className="btn btn-secondary">Secondary</button>
          </div>
          <div className="mb-4">
            <button className="btn btn-danger">Danger</button>
          </div>
          <div className="mb-2">
            <button className="btn btn-primary btn-sm">Primary Small</button>
          </div>
          <div className="mb-2">
            <button className="btn btn-secondary btn-sm">Secondary Small</button>
          </div>
          <div className="mb-2">
            <button className="btn btn-danger btn-sm">Danger Small</button>
          </div>
        </div>
        <div className="col-5">
          <div className="navbar mb-4">
            <div className="myAccount">
              <span className="circle">
                <ProgressCircle
                  size={38}
                  strokeWidth={2}
                  visible={true}
                  percentage={65}
                  color="#ffffff77"
                  trailColor="#ffffff66">
                  AZ
                </ProgressCircle>
              </span>
            </div>
          </div>
          <div className="mb-4">
            <a href="#" onClick={() => false}>
              Standard Link
            </a>
            <br />
            <a href="#" className="text-danger" onClick={() => false}>
              Danger Link
            </a>
          </div>

          <div className="mb-5">
            <PagingControls page={2} pageCount={6} changePageCallback={() => {}} />
          </div>

          <div className="mb-5">
            <h4>Standard Table</h4>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Column Header</th>
                  <th scope="col">Column Header</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Column Value</td>
                  <td>Column Value</td>
                </tr>
                <tr>
                  <td>Column Value</td>
                  <td>Column Value</td>
                </tr>
                <tr>
                  <td>Column Value</td>
                  <td>Column Value</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mb-4">
            <h4>Striped Table with Hover</h4>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">Column Header</th>
                  <th scope="col">Column Header</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Column Value</td>
                  <td>Column Value</td>
                </tr>
                <tr>
                  <td>Column Value</td>
                  <td>Column Value</td>
                </tr>
                <tr>
                  <td>Column Value</td>
                  <td>Column Value</td>
                </tr>
                <tr>
                  <td>Column Value</td>
                  <td>Column Value</td>
                </tr>
                <tr>
                  <td>Column Value</td>
                  <td>Column Value</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};
