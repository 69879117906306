export const ReasonCodes = [
  {
    code: 'R01',
    desc: 'Insufficient Funds'
  },
  {
    code: 'R02',
    desc: 'Account Closed'
  },
  {
    code: 'R03',
    desc: 'No Account on File'
  },
  {
    code: 'R04',
    desc: 'Invalid Account Number'
  },
  {
    code: 'R05',
    desc: 'Required Prenotification Not Received'
  },
  {
    code: 'R06',
    desc: "Returned Per ODFI's Request"
  },
  {
    code: 'R07',
    desc: 'Authorization Revoked by Customer'
  },
  {
    code: 'R08',
    desc: 'Payment Stopped'
  },
  {
    code: 'R09',
    desc: 'Uncollected Funds'
  },
  {
    code: 'R10',
    desc: 'Customer Advises Not Authorized'
  },
  {
    code: 'R11',
    desc: 'Check Truncation Entry Return'
  },
  {
    code: 'R12',
    desc: 'Branch Sold to Another DFI'
  },
  {
    code: 'R13',
    desc: 'Invalid Routing Number'
  },
  {
    code: 'R14',
    desc: 'Account-Holder Deceased'
  },
  {
    code: 'R15',
    desc: 'Beneficiary Deceased'
  },
  {
    code: 'R16',
    desc: 'Account Frozen'
  },
  {
    code: 'R17',
    desc: 'File Record Edit Criteria'
  },
  {
    code: 'R20',
    desc: 'Non-Transaction Account'
  },
  {
    code: 'R21',
    desc: 'Invalid Company ID'
  },
  {
    code: 'R22',
    desc: 'Invalid Individual ID Number'
  },
  {
    code: 'R23',
    desc: 'Payment Refused by Biller'
  },
  {
    code: 'R24',
    desc: 'Duplicate Entry'
  },
  {
    code: 'R26',
    desc: 'Mandatory Field Error'
  },
  {
    code: 'R28',
    desc: 'Invalid Check Digit'
  },
  {
    code: 'R29',
    desc: 'Corp. Customer Advises Not Authorized'
  },
  {
    code: 'R30',
    desc: 'Refund'
  },
  {
    code: 'R31',
    desc: 'Credit Card Dispute'
  },
  {
    code: 'R32',
    desc: 'Cust Adv. Entry Not in Accord. w/ Auth Terms'
  }
];
