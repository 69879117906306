import { Helpers } from './Helpers';

export class Api {
  static get = async (endpoint) => {
    return await this.sendRequest(endpoint, 'GET');
  };
  static post = async (endpoint, body, contentType = 'application/json') => {
    return await this.sendRequest(endpoint, 'POST', body, contentType);
  };
  static put = async (endpoint, body, contentType = 'application/json') => {
    return await this.sendRequest(endpoint, 'PUT', body, contentType);
  };
  static delete = async (endpoint) => {
    return await this.sendRequest(endpoint, 'DELETE');
  };

  static sendRequest = async (endpoint, method = 'GET', body, contentType = 'application/json') => {
    const bffApiUrl = process.env.REACT_APP_BFF_API_URL;
    try {
      const requestOptions = {
        method: method,
        headers: {},
        credentials: 'include',
        body: body
      };

      if (contentType) {
        requestOptions.headers['Content-Type'] = contentType;
      }

      const apiResponse = await fetch(`${bffApiUrl}${endpoint}`, requestOptions);
      switch (apiResponse.status) {
        case 200:
          return await apiResponse.json();
        case 401:
        case 403:
          if (
            window.location.pathname !== '/' &&
            window.location.pathname.toLowerCase() !== '/login' &&
            window.location.pathname.toLowerCase() !== '/dev-tools/api'
          ) {
            sessionStorage.setItem('ReturnUrl', window.location.pathname + window.location.search);
            window.location.href = '/login';
          }
          break;
        default:
          return this.createApiResponse(apiResponse.status, apiResponse.statusText);
      }
    } catch (e) {
      return this.createApiResponse(500, e);
    }
  };

  static upload = async (endpoint, body, progressCallback, completeCallback) => {
    const bffApiUrl = process.env.REACT_APP_BFF_API_URL;
    const xhr = new XMLHttpRequest();
    await new Promise((resolve) => {
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          if (progressCallback && typeof progressCallback === 'function') {
            const percentage = Helpers.round((event.loaded / event.total) * 100, 1);
            progressCallback(percentage);
          }
        }
      });
      xhr.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          if (progressCallback && typeof progressCallback === 'function') {
            const percentage = Helpers.round((event.loaded / event.total) * 100, 1);
            progressCallback(percentage);
          }
        }
      });
      xhr.addEventListener('loadend', () => {
        const success = xhr.readyState === 4 && xhr.status === 200;
        if (completeCallback && typeof completeCallback === 'function') {
          completeCallback(success, xhr.response);
        }
        resolve(success);
      });
      xhr.open('POST', `${bffApiUrl}${endpoint}`, true);
      xhr.withCredentials = true;
      xhr.send(body);
    });
  };

  static createApiResponse = (statusCode, statusText) => {
    return {
      status: {
        statusCode: statusCode,
        statusDescription: statusText,
        statusDetail: ''
      },
      response: null,
      contentType: null,
      errors: []
    };
  };
}
