import { Helpers } from '../../../utils/helpers';
import { DefaultLayout as Layout } from '../../../layouts';
import { FileImportMonitor } from './FileImportMonitor';

export const FileImportMonitorPage = () => {
  return (
    <Layout>
      <div className="titleBar">
        <h1 className="pageTitle">Import Monitor</h1>
      </div>
      <FileImportMonitor />
    </Layout>
  );
};
