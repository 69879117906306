import React from 'react';
import styles from '../../../PayPal.module.css';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import useBeneficialOwner from './useBeneficialOwner';

export const BeneficialOwner = ({ containerFormData, formStatusCallback }) => {
  const manager = useBeneficialOwner(containerFormData, formStatusCallback);

  return (
    <>
      {!manager.loading && (
        <div>
          <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
            <thead>
              <tr>
                <th className={styles.paypalOnboardingSectionHeader}>Beneficial Owner</th>
                {manager.componentStatus === FormComponentStatus.COMPLETE && (
                  <th className={styles.paypalOnboardingCompleteStatusHeader}>Complete</th>
                )}
                {manager.componentStatus === FormComponentStatus.INCOMPLETE && (
                  <th className={styles.paypalOnboardingIncompleteStatusHeader}>Incomplete</th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label htmlFor="beneficialOwnerGivenName">
                    <span className="req">*</span>Legal First Name:
                  </label>
                </td>
                <td>
                  <input
                    name="beneficialOwnerGivenName"
                    id="beneficialOwnerGivenName"
                    type="text"
                    className="form-control"
                    value={manager.formData.beneficialOwnerGivenName}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.beneficialOwnerGivenName && (
                    <div className="alert-danger mt-1 p-2">Legal First Name is required.</div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="beneficialOwnerSurname">
                    <span className="req">*</span>Legal Last Name:
                  </label>
                </td>
                <td>
                  <input
                    name="beneficialOwnerSurname"
                    id="beneficialOwnerSurname"
                    type="text"
                    className="form-control"
                    value={manager.formData.beneficialOwnerSurname}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.beneficialOwnerSurname && (
                    <div className="alert-danger mt-1 p-2">Legal Last Name is required.</div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="beneficialOwnerDOB">
                    <span className="req">*</span>Date of Birth:
                  </label>
                </td>
                <td>
                  <input
                    name="beneficialOwnerDOB"
                    id="beneficialOwnerDOB"
                    type="text"
                    className="form-control"
                    value={manager.formData.beneficialOwnerDOB}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.beneficialOwnerDOB && (
                    <div className="alert-danger mt-1 p-2">
                      Date of Birth is required. Format: &apos;yyyy-mm-dd&apos;.
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="beneficialOwnerEmailAddress">
                    <span className="req">*</span>Email Address:
                  </label>
                </td>
                <td>
                  <input
                    name="beneficialOwnerEmailAddress"
                    id="beneficialOwnerEmailAddress"
                    type="text"
                    className="form-control"
                    value={manager.formData.beneficialOwnerEmailAddress}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.beneficialOwnerEmailAddress && (
                    <div className="alert-danger mt-1 p-2">Email Address is required.</div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="beneficialOwnerPhoneNumber">
                    <span className="req">*</span>Work Phone Number:
                  </label>
                </td>
                <td>
                  <input
                    name="beneficialOwnerPhoneNumber"
                    id="beneficialOwnerPhoneNumber"
                    type="text"
                    className="form-control"
                    placeholder="Numerical digits only: 5081234567"
                    value={manager.formData.beneficialOwnerPhoneNumber}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.beneficialOwnerPhoneNumber && (
                    <div className="alert-danger mt-1 p-2">
                      Work Phone Number is required. Numerical digits only, no parentheses or
                      dashes.
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="beneficialOwnerPercentageOfOwnership">
                    <span className="req">*</span>Percentage of Ownership:
                  </label>
                </td>
                <td>
                  <input
                    name="beneficialOwnerPercentageOfOwnership"
                    id="beneficialOwnerPercentageOfOwnership"
                    type="text"
                    className="form-control"
                    value={manager.formData.beneficialOwnerPercentageOfOwnership}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.beneficialOwnerPercentageOfOwnership && (
                    <div className="alert-danger mt-1 p-2">
                      Percentage of Ownership is required.
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table className={'table ' + styles.nestedStripedTable}>
                    <thead>
                      <tr>
                        <th className={styles.nestedStripedTableHeader} colSpan={2}>
                          Beneficial Owner - Address
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="beneficialOwnerAddressLine1">
                            <span className="req">*</span>Address Line 1:
                          </label>
                        </td>
                        <td>
                          <input
                            name="beneficialOwnerAddressLine1"
                            id="beneficialOwnerAddressLine1"
                            type="text"
                            className="form-control"
                            value={manager.formData.beneficialOwnerAddressLine1}
                            onChange={(e) => manager.updateValue(e.target)}
                            onBlur={(e) => manager.updateValue(e.target)}
                          />
                          {manager.errors.beneficialOwnerAddressLine1 && (
                            <div className="alert-danger mt-1 p-2">Address Line 1 is required.</div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="beneficialOwnerAddressLine2">Address Line 2:</label>
                        </td>
                        <td>
                          <input
                            name="beneficialOwnerAddressLine2"
                            id="beneficialOwnerAddressLine2"
                            type="text"
                            className="form-control"
                            value={manager.formData.beneficialOwnerAddressLine2}
                            onChange={(e) => manager.updateValue(e.target)}
                            onBlur={(e) => manager.updateValue(e.target)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="beneficialOwnerAdminArea2">
                            <span className="req">*</span>City:
                          </label>
                        </td>
                        <td>
                          <input
                            name="beneficialOwnerAdminArea2"
                            id="beneficialOwnerAdminArea2"
                            type="text"
                            className="form-control"
                            value={manager.formData.beneficialOwnerAdminArea2}
                            onChange={(e) => manager.updateValue(e.target)}
                            onBlur={(e) => manager.updateValue(e.target)}
                          />
                          {manager.errors.beneficialOwnerAdminArea2 && (
                            <div className="alert-danger mt-1 p-2">City is required.</div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="beneficialOwnerAdminArea1">
                            <span className="req">*</span>State:
                          </label>
                        </td>
                        <td>
                          <input
                            name="beneficialOwnerAdminArea1"
                            id="beneficialOwnerAdminArea1"
                            type="text"
                            className="form-control"
                            value={manager.formData.beneficialOwnerAdminArea1}
                            onChange={(e) => manager.updateValue(e.target)}
                            onBlur={(e) => manager.updateValue(e.target)}
                          />
                          {manager.errors.beneficialOwnerAdminArea1 && (
                            <div className="alert-danger mt-1 p-2">State is required.</div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="beneficialOwnerPostalCode">
                            <span className="req">*</span>Postal Code:
                          </label>
                        </td>
                        <td>
                          <input
                            name="beneficialOwnerPostalCode"
                            id="beneficialOwnerPostalCode"
                            type="text"
                            className="form-control"
                            value={manager.formData.beneficialOwnerPostalCode}
                            onChange={(e) => manager.updateValue(e.target)}
                            onBlur={(e) => manager.updateValue(e.target)}
                          />
                          {manager.errors.beneficialOwnerPostalCode && (
                            <div className="alert-danger mt-1 p-2">Postal Code is required.</div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table className={'table ' + styles.nestedStripedTable}>
                    <thead>
                      <tr>
                        <th className={styles.nestedStripedTableHeader} colSpan={2}>
                          Beneficial Owner - Identification Document
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="beneficialOwnerIdentificationDocumentType">
                            <span className="req">*</span>Type:
                          </label>
                        </td>
                        <td>
                          <select
                            name="beneficialOwnerIdentificationDocumentType"
                            id="beneficialOwnerIdentificationDocumentType"
                            className="form-select"
                            aria-label="Identification document type selection"
                            value={manager.formData.beneficialOwnerIdentificationDocumentType}
                            onChange={(e) => manager.updateValue(e.target)}>
                            <option value="">- Select -</option>
                            <option value="SOCIAL_SECURITY_NUMBER">Social Security Number</option>
                            <option value="INDIVIDUAL_TAX_IDENTIFICATION_NUMBER">
                              Individual Tax Identification Number
                            </option>
                          </select>
                          {manager.errors.beneficialOwnerIdentificationDocumentType && (
                            <div className="alert-danger mt-1 p-2">
                              Beneficial Owner - Identification Document Type is required.
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="beneficialOwnerIdentificationDocumentIdNumber">
                            <span className="req">*</span>Identification Number:
                          </label>
                        </td>
                        <td>
                          <input
                            name="beneficialOwnerIdentificationDocumentIdNumber"
                            id="beneficialOwnerIdentificationDocumentIdNumber"
                            type="text"
                            className="form-control"
                            value={manager.formData.beneficialOwnerIdentificationDocumentIdNumber}
                            onChange={(e) => manager.updateValue(e.target)}
                            onBlur={(e) => manager.updateValue(e.target)}
                          />
                          {manager.errors.beneficialOwnerIdentificationDocumentIdNumber && (
                            <div className="alert-danger mt-1 p-2">
                              Identification Number is required.
                              <br />9 digit numeric-only digits like xxxxxxxxx.
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
