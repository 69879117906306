import React from 'react';
import styles from '../../../PayPal.module.css';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import usePrimaryAuthorizedUser from './usePrimaryAuthorizedUser';

export const PrimaryAuthorizedUser = ({ containerFormData, formStatusCallback }) => {
  const manager = usePrimaryAuthorizedUser(containerFormData, formStatusCallback);

  return (
    <>
      <div>
        <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
          <thead>
            <tr>
              <th className={styles.paypalOnboardingSectionHeader}>Primary Authorized User</th>
              {manager.componentStatus === FormComponentStatus.COMPLETE && (
                <th className={styles.paypalOnboardingCompleteStatusHeader}>Complete</th>
              )}
              {manager.componentStatus === FormComponentStatus.INCOMPLETE && (
                <th className={styles.paypalOnboardingIncompleteStatusHeader}>Incomplete</th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label htmlFor="primaryAuthorizedUserGivenName">
                  <span className="req">*</span>Legal First Name:
                </label>
              </td>
              <td>
                <input
                  name="primaryAuthorizedUserGivenName"
                  id="primaryAuthorizedUserGivenName"
                  type="text"
                  className="form-control"
                  value={manager.formData.primaryAuthorizedUserGivenName}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.primaryAuthorizedUserGivenName && (
                  <div className="alert-danger mt-1 p-2">Legal First Name is required.</div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="primaryAuthorizedUserSurname">
                  <span className="req">*</span>Legal Last Name:
                </label>
              </td>
              <td>
                <input
                  name="primaryAuthorizedUserSurname"
                  id="primaryAuthorizedUserSurname"
                  type="text"
                  className="form-control"
                  value={manager.formData.primaryAuthorizedUserSurname}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.primaryAuthorizedUserSurname && (
                  <div className="alert-danger mt-1 p-2">Legal Last Name is required.</div>
                )}
              </td>
            </tr>
            {!manager.isProprietorship && (
              <tr>
                <td>
                  <label htmlFor="primaryAuthorizedUserOccupation">
                    <span className="req">*</span>Occupation:
                  </label>
                </td>
                <td>
                  <input
                    name="primaryAuthorizedUserOccupation"
                    id="primaryAuthorizedUserOccupation"
                    type="text"
                    className="form-control"
                    value={manager.formData.primaryAuthorizedUserOccupation}
                    onChange={(e) => manager.updateValue(e.target)}
                    onBlur={(e) => manager.updateValue(e.target)}
                  />
                  {manager.errors.primaryAuthorizedUserOccupation && (
                    <div className="alert-danger mt-1 p-2">Occupation is required.</div>
                  )}
                </td>
              </tr>
            )}
            <tr>
              <td>
                <label htmlFor="primaryAuthorizedUserEmail">
                  {manager.isProprietorship && <span className="req">*</span>}Email Address:
                </label>
              </td>
              <td>
                <input
                  name="primaryAuthorizedUserEmail"
                  id="primaryAuthorizedUserEmail"
                  type="text"
                  className="form-control"
                  value={manager.formData.primaryAuthorizedUserEmail}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.primaryAuthorizedUserEmail && (
                  <div className="alert-danger mt-1 p-2">Email Address is required.</div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="primaryAuthorizedUserBirthDetailDOB">
                  <span className="req">*</span>Date of Birth:
                </label>
              </td>
              <td>
                <input
                  name="primaryAuthorizedUserBirthDetailDOB"
                  id="primaryAuthorizedUserBirthDetailDOB"
                  type="text"
                  className="form-control"
                  placeholder="yyyy-mm-dd"
                  value={manager.formData.primaryAuthorizedUserBirthDetailDOB}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.primaryAuthorizedUserBirthDetailDOB && (
                  <div className="alert-danger mt-1 p-2">
                    Date of Birth is required. Format: &apos;yyyy-mm-dd&apos;.
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <table className={'table ' + styles.nestedStripedTable}>
                  <thead>
                    <tr>
                      <th className={styles.nestedStripedTableHeader} colSpan={2}>
                        Primary Authorized User - Primary Residence
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="primaryAuthorizedUserAddressLine1">
                          {manager.isProprietorship && <span className="req">*</span>}Address Line
                          1:
                        </label>
                      </td>
                      <td>
                        <input
                          name="primaryAuthorizedUserAddressLine1"
                          id="primaryAuthorizedUserAddressLine1"
                          type="text"
                          className="form-control"
                          value={manager.formData.primaryAuthorizedUserAddressLine1}
                          onChange={(e) => manager.updateValue(e.target)}
                          onBlur={(e) => manager.updateValue(e.target)}
                        />
                        {manager.errors.primaryAuthorizedUserAddressLine1 && (
                          <div className="alert-danger mt-1 p-2">Address Line 1 is required.</div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="primaryAuthorizedUserAddressLine2">Address Line 2:</label>
                      </td>
                      <td>
                        <input
                          name="primaryAuthorizedUserAddressLine2"
                          id="primaryAuthorizedUserAddressLine2"
                          type="text"
                          className="form-control"
                          value={manager.formData.primaryAuthorizedUserAddressLine2}
                          onChange={(e) => manager.updateValue(e.target)}
                          onBlur={(e) => manager.updateValue(e.target)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="primaryAuthorizedUserAdminArea2">
                          {manager.isProprietorship && <span className="req">*</span>}City:
                        </label>
                      </td>
                      <td>
                        <input
                          name="primaryAuthorizedUserAdminArea2"
                          id="primaryAuthorizedUserAdminArea2"
                          type="text"
                          className="form-control"
                          value={manager.formData.primaryAuthorizedUserAdminArea2}
                          onChange={(e) => manager.updateValue(e.target)}
                          onBlur={(e) => manager.updateValue(e.target)}
                        />
                        {manager.errors.primaryAuthorizedUserAdminArea2 && (
                          <div className="alert-danger mt-1 p-2">City is required.</div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="primaryAuthorizedUserAdminArea1">
                          {manager.isProprietorship && <span className="req">*</span>}State:
                        </label>
                      </td>
                      <td>
                        <input
                          name="primaryAuthorizedUserAdminArea1"
                          id="primaryAuthorizedUserAdminArea1"
                          type="text"
                          className="form-control"
                          value={manager.formData.primaryAuthorizedUserAdminArea1}
                          onChange={(e) => manager.updateValue(e.target)}
                          onBlur={(e) => manager.updateValue(e.target)}
                        />
                        {manager.errors.primaryAuthorizedUserAdminArea1 && (
                          <div className="alert-danger mt-1 p-2">State is required.</div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="primaryAuthorizedUserPostalCode">
                          {manager.isProprietorship && <span className="req">*</span>}Postal Code:
                        </label>
                      </td>
                      <td>
                        <input
                          name="primaryAuthorizedUserPostalCode"
                          id="primaryAuthorizedUserPostalCode"
                          type="text"
                          className="form-control"
                          value={manager.formData.primaryAuthorizedUserPostalCode}
                          onChange={(e) => manager.updateValue(e.target)}
                          onBlur={(e) => manager.updateValue(e.target)}
                        />
                        {manager.errors.primaryAuthorizedUserPostalCode && (
                          <div className="alert-danger mt-1 p-2">Postal Code is required.</div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <table className={'table ' + styles.nestedStripedTable}>
                  <thead>
                    <tr>
                      <th className={styles.nestedStripedTableHeader} colSpan={2}>
                        Primary Authorized User - Phone Number
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="primaryAuthorizedUserPhoneNumber">
                          {manager.isProprietorship && <span className="req">*</span>}Phone Number:
                        </label>
                      </td>
                      <td>
                        <input
                          name="primaryAuthorizedUserPhoneNumber"
                          id="primaryAuthorizedUserPhoneNumber"
                          type="text"
                          className="form-control"
                          placeholder="Numerical digits only: 5081234567"
                          value={manager.formData.primaryAuthorizedUserPhoneNumber}
                          onChange={(e) => manager.updateValue(e.target)}
                          onBlur={(e) => manager.updateValue(e.target)}
                        />
                        {manager.errors.primaryAuthorizedUserPhoneNumber && (
                          <div className="alert-danger mt-1 p-2">
                            Phone Number is required. Numerical digits only, no parentheses or
                            dashes.
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="primaryAuthorizedUserPhoneType">
                          {manager.isProprietorship && <span className="req">*</span>}Type:
                        </label>
                      </td>
                      <td>
                        <select
                          name="primaryAuthorizedUserPhoneType"
                          id="primaryAuthorizedUserPhoneType"
                          className="form-select"
                          aria-label="Phone type selection"
                          value={manager.formData.primaryAuthorizedUserPhoneType}
                          onChange={(e) => manager.updateValue(e.target)}>
                          <option value="">- Select -</option>
                          <option value="HOME">Home</option>
                          <option value="MOBILE">Mobile</option>
                        </select>
                        {manager.errors.primaryAuthorizedUserPhoneType && (
                          <div className="alert-danger mt-1 p-2">
                            Primary Authorized User - Phone Type is required.
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <table className={'table ' + styles.nestedStripedTable}>
                  <thead>
                    <tr>
                      <th className={styles.nestedStripedTableHeader} colSpan={2}>
                        Primary Authorized User - Identification Document
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="primaryAuthorizedUserIdentificationDocumentType">
                          {manager.isProprietorship && <span className="req">*</span>}Type:
                        </label>
                      </td>
                      <td>
                        <select
                          name="primaryAuthorizedUserIdentificationDocumentType"
                          id="primaryAuthorizedUserIdentificationDocumentType"
                          className="form-select"
                          aria-label="Identification document type selection"
                          value={manager.formData.primaryAuthorizedUserIdentificationDocumentType}
                          onChange={(e) => manager.updateValue(e.target)}>
                          <option value="">- Select -</option>
                          <option value="SOCIAL_SECURITY_NUMBER">Social Security Number</option>
                          <option value="INDIVIDUAL_TAX_IDENTIFICATION_NUMBER">
                            Individual Tax Identification Number
                          </option>
                        </select>
                        {manager.errors.primaryAuthorizedUserIdentificationDocumentType && (
                          <div className="alert-danger mt-1 p-2">
                            Primary Authorized User - Identification Document Type is required.
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="primaryAuthorizedUserIdentificationDocumentNumber">
                          {manager.isProprietorship && <span className="req">*</span>}
                          Identification Number:
                        </label>
                      </td>
                      <td>
                        <input
                          name="primaryAuthorizedUserIdentificationDocumentNumber"
                          id="primaryAuthorizedUserIdentificationDocumentNumber"
                          type="text"
                          className="form-control"
                          value={manager.formData.primaryAuthorizedUserIdentificationDocumentNumber}
                          onChange={(e) => manager.updateValue(e.target)}
                          onBlur={(e) => manager.updateValue(e.target)}
                        />
                        {manager.errors.primaryAuthorizedUserIdentificationDocumentNumber && (
                          <div className="alert-danger mt-1 p-2">
                            Identification Number is required.
                            <br />9 digit numeric-only digits like xxxxxxxxx.
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PrimaryAuthorizedUser;
