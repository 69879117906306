import React from 'react';
import styles from '../../../PayPal.module.css';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import useIdentificationDocuments from './useIdentificationDocuments';

export const IdentificationDocuments = ({ containerFormData, formStatusCallback }) => {
  const manager = useIdentificationDocuments(containerFormData, formStatusCallback);

  return (
    <>
      <div>
        <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
          <thead>
            <tr>
              <th className={styles.paypalOnboardingSectionHeader}>Identification Documents</th>
              {manager.componentStatus === FormComponentStatus.COMPLETE && (
                <th className={styles.paypalOnboardingCompleteStatusHeader}>Complete</th>
              )}
              {manager.componentStatus === FormComponentStatus.INCOMPLETE && (
                <th className={styles.paypalOnboardingIncompleteStatusHeader}>Incomplete</th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label htmlFor="businessIdentificationDocumentType">
                  <span className="req">*</span>Type:
                </label>
              </td>
              <td>
                <select
                  name="businessIdentificationDocumentType"
                  id="businessIdentificationDocumentType"
                  className="form-select"
                  aria-label="Identification document type selection"
                  value={manager.formData.businessIdentificationDocumentType}
                  onChange={(e) => manager.updateValue(e.target)}>
                  <option value="">- Select -</option>
                  <option value="EMPLOYER_IDENTIFICATION_NUMBER">
                    Employer Identification Number
                  </option>
                  <option value="INDIVIDUAL_TAX_IDENTIFICATION_NUMBER">
                    Individual Tax Identification Number
                  </option>
                </select>
                {manager.errors.businessIdentificationDocumentType && (
                  <div className="alert-danger mt-1 p-2">
                    Identification Document Type is required.
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="businessIdentificationDocumentIdNumber">
                  <span className="req">*</span>Identification Code:
                </label>
              </td>
              <td>
                <input
                  name="businessIdentificationDocumentIdNumber"
                  id="businessIdentificationDocumentIdNumber"
                  type="text"
                  className="form-control"
                  value={manager.formData.businessIdentificationDocumentIdNumber}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.businessIdentificationDocumentIdNumber && (
                  <div className="alert-danger mt-1 p-2">
                    Identification Code is required.
                    <br /> Employer Identification Number: 9 digits total with dash like xx-xxxxxxx.
                    <br /> Individual Tax Identification Number: 9 numerical-only digits like
                    xxxxxxxxx.
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default IdentificationDocuments;
