import React from 'react';
import styles from '../../../PayPal.module.css';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import useRegisteredBusinessAddress from './useRegisteredBusinessAddress';

export const RegisteredBusinessAddress = ({ containerFormData, formStatusCallback }) => {
  const manager = useRegisteredBusinessAddress(containerFormData, formStatusCallback);

  return (
    <>
      <div>
        <table className={'table table-striped ' + styles.paypalOnboardingBusiness}>
          <thead>
            <tr>
              <th className={styles.paypalOnboardingSectionHeader}>Registered Business Address</th>
              {manager.componentStatus === FormComponentStatus.COMPLETE && (
                <th className={styles.paypalOnboardingCompleteStatusHeader}>Complete</th>
              )}
              {manager.componentStatus === FormComponentStatus.INCOMPLETE && (
                <th className={styles.paypalOnboardingIncompleteStatusHeader}>Incomplete</th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label htmlFor="businessAddressLine1">
                  <span className="req">*</span>Address:
                </label>
              </td>
              <td>
                <input
                  name="businessAddressLine1"
                  id="businessAddressLine1"
                  type="text"
                  className="form-control"
                  value={manager.formData.businessAddressLine1}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.businessAddressLine1 && (
                  <div className="alert-danger mt-1 p-2">Address is required.</div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="businessAddressAdminArea2">
                  <span className="req">*</span>City:
                </label>
              </td>
              <td>
                <input
                  name="businessAddressAdminArea2"
                  id="businessAddressAdminArea2"
                  type="text"
                  className="form-control"
                  value={manager.formData.businessAddressAdminArea2}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.businessAddressAdminArea2 && (
                  <div className="alert-danger mt-1 p-2">City is required.</div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="businessAddressAdminArea1">
                  <span className="req">*</span>State:
                </label>
              </td>
              <td>
                <input
                  name="businessAddressAdminArea1"
                  id="businessAddressAdminArea1"
                  type="text"
                  className="form-control"
                  value={manager.formData.businessAddressAdminArea1}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.businessAddressAdminArea1 && (
                  <div className="alert-danger mt-1 p-2">State is required.</div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="businessPostalCode">
                  <span className="req">*</span>Postal Code:
                </label>
              </td>
              <td>
                <input
                  name="businessPostalCode"
                  id="businessPostalCode"
                  type="text"
                  className="form-control"
                  value={manager.formData.businessPostalCode}
                  onChange={(e) => manager.updateValue(e.target)}
                  onBlur={(e) => manager.updateValue(e.target)}
                />
                {manager.errors.businessPostalCode && (
                  <div className="alert-danger mt-1 p-2">Postal Code is required.</div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RegisteredBusinessAddress;
