import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../context/provider/AuthContext';
import { routeMapping } from '../../routes/routeMapping';
import { Api, Helpers } from '../helpers';
import * as UserActions from '../../context/reducers/userReducer';
import { Menu } from '../../utils/constants/enums';

export const useAuthorization = () => {
  const bffApiUrl = process.env.REACT_APP_BFF_API_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const [{ user }, dispatch] = useAuthContext();

  const isLoginPage = location.pathname === '/login';
  const isDevToolsPage = location.pathname.startsWith('/dev-tools');

  const logout = async () => {
    dispatch(UserActions.setIsLoggingOut());
    try {
      const apiResponse = await Api.get('/auth/logout');
      if (apiResponse.status.statusCode !== 200 || apiResponse.response.trim() === '') {
        window.location.href = '/';
        return;
      }
      //update the form action with the authority logout url and submit
      document.getElementById('logoutform').setAttribute('action', apiResponse.response);
      document.getElementById('logoutform').submit();
    } catch (e) {
      window.location.href = '/';
      return;
    }
  };

  const changeActiveBrand = (brandId) => {
    const foundBrand = user.userResources.brands.find((brand) => brand.id === parseInt(brandId));
    if (foundBrand) {
      localStorage.setItem('activeBrandId', brandId);
      dispatch(UserActions.setActiveBrand(foundBrand));
    }
  };

  const sidebarMenu = routeMapping.filter((route) => {
    let hasPermissionGroup = true;
    if (route.requiresAnyInPermissionGroup) {
      const groupValues = Object.values(route.requiresAnyInPermissionGroup);
      hasPermissionGroup = groupValues.some((p) => user.permissions.includes(p));
    }

    let hasEbpAccess = true;
    if (route.requiresEbpCustomer) {
      hasEbpAccess = user.userResources.brands.some((b) => b.customers.some((c) => c.ebp));
    }

    let passedVisibilityCheckFunction = true;
    if (route.visibleCheckFunction) {
      passedVisibilityCheckFunction = route.visibleCheckFunction(user);
    }

    return (
      Helpers.hasFlag(route.menu, Menu.SIDEBAR) &&
      hasPermissionGroup &&
      hasEbpAccess &&
      passedVisibilityCheckFunction &&
      route.requiredPermissions.every((p) => user.permissions.includes(p))
    );
  });

  const profileMenu = routeMapping.filter((route) => {
    return (
      Helpers.hasFlag(route.menu, Menu.PROFILE) &&
      route.requiredPermissions.every((p) => user.permissions.includes(p))
    );
  });

  const brandOptions = user.userResources.brands.map((brand) => {
    return {
      id: brand.id,
      name: brand.name
    };
  });

  const themeLogo = `${bffApiUrl}/policy/brands/${user.activeBrand.id}/logo`;
  const themeClass = user.activeBrand.theme;

  useEffect(() => {
    //not done loading user yet
    if (user.isLoading) {
      return;
    }

    // on dev tools page, but not running local
    if (isDevToolsPage && !Helpers.isRunningLocal()) {
      navigate('/not-authorized');
      return;
    }

    // on login page, but already authenticated, send to dashboard
    if (isLoginPage && user.isAuthenticated) {
      navigate('/');
      return;
    }

    // not on login page and not authenticated, send to login page
    if (!isLoginPage && !user.isAuthenticated) {
      sessionStorage.setItem('ReturnUrl', location.pathname + location.search);
      navigate('/login');
      return;
    }

    // handle return url
    const returnUrl = sessionStorage.getItem('ReturnUrl');
    if (!isLoginPage && !Helpers.isNullOrWhitespace(returnUrl)) {
      sessionStorage.setItem('ReturnUrl', '');
      navigate(returnUrl);
      return;
    }

    // check page permissions
    const currentRoute = routeMapping.find((route) => route.path === location.pathname);
    if (currentRoute && currentRoute.requiredPermissions.length > 0) {
      let hasRequiredPermissions = currentRoute.requiredPermissions.every((p) =>
        user.permissions.includes(p)
      );

      //check for ebp customer if this route requires it
      if (hasRequiredPermissions && currentRoute.requiresEbpCustomer) {
        hasRequiredPermissions = user.userResources.brands.some((b) =>
          b.customers.some((c) => c.ebp)
        );
      }

      if (currentRoute.visibleCheckFunction) {
        const passedVisibilityCheckFunction = currentRoute.visibleCheckFunction(user);
        if (!passedVisibilityCheckFunction) hasRequiredPermissions = false;
      }

      if (!hasRequiredPermissions) {
        navigate('/not-authorized');
      }
    }
  }, [
    navigate,
    user.isLoading,
    isLoginPage,
    user.isAuthenticated,
    user.permissions,
    user.userResources,
    user.userResources.brands,
    location.pathname,
    location.search,
    isDevToolsPage
  ]);

  return {
    isLoading: user.isLoading,
    themeLogo,
    themeClass,
    sidebarMenu,
    profileMenu,
    brandOptions,
    changeActiveBrand,
    logout
  };
};
