import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helpers } from '../../utils/helpers/Helpers';
import { ProgressCircle } from '../../components/ui';
import Form from 'react-bootstrap/Form';
import { MessageNotification } from '../../components/messages';
import { useAuthContext } from '../../context/provider/AuthContext';
import { useInterval } from '../../utils/hooks';
import './TopNav.css';

export const TopNav = ({ authorization }) => {
  const [{ user }] = useAuthContext();
  const [timeoutPercentage, setTimeoutPercentage] = useState(100);
  const sessionMaxTime = 60 * 60; //seconds

  const sidebarToggle = () => {
    document.body.classList.toggle('sb-sidenav-toggled');
  };

  const logoutUser = async (e) => {
    e.preventDefault();
    await authorization.logout();
  };

  const initials = (user.firstName[0] ?? '') + (user.lastName[0] ?? '');

  useInterval(() => {
    const tokenTimeRemaining = Math.floor(
      (user.tokenExpires.getTime() - Helpers.getUtcDate().getTime()) / 1000
    );
    let timePercentage = 100;
    if (tokenTimeRemaining > 0) {
      timePercentage = ((tokenTimeRemaining / sessionMaxTime) * 100).toFixed(2);
    }
    setTimeoutPercentage(timePercentage);
  }, 1000);

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <button id="sidebarToggle" onClick={sidebarToggle}>
          <i className="icofont-navigation-menu"></i> <span className="sr-only">Toggle Menu</span>
        </button>
        <div className="date">{Helpers.toLongDate(new Date())}</div>
        <div className="navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
            {authorization.brandOptions.length > 1 && (
              <li className="nav-item me-4">
                <Form.Select
                  onChange={(e) => authorization.changeActiveBrand(e.target.value)}
                  value={user.activeBrand?.id ?? ''}>
                  {authorization.brandOptions.map((brand) => {
                    return (
                      <option key={brand.id} value={brand.id}>
                        {brand.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </li>
            )}
            <li className="nav-item dropdown">
              <MessageNotification />
            </li>
            <li className="nav-item dropdown">
              <a
                title={`Token Refresh: ${Helpers.toShortTime(
                  user.tokenRefresh
                )}\nSession Timeout: ${Helpers.toShortTime(user.tokenExpires)}`}
                className="nav-link dropdown-toggle myAccount"
                id="navbarDropdown"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <span className="circle">
                  <ProgressCircle
                    size={38}
                    strokeWidth={2}
                    visible={true}
                    percentage={timeoutPercentage}
                    color="#ffffff77"
                    trailColor="#ffffff66">
                    {initials}
                  </ProgressCircle>
                </span>
                <span className="sr-only">My Account</span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end animate slideIn"
                aria-labelledby="navbarDropdown">
                {authorization.profileMenu?.map((item) => {
                  return (
                    <Link to={item.path} key={item.path} className="dropdown-item">
                      <i className={item.iconClass}></i> {item.text}
                    </Link>
                  );
                })}
                <div className="dropdown-divider"></div>
                <Link to="#" onClick={logoutUser} className="dropdown-item">
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
