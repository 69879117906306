import { useState, useEffect, useCallback } from 'react';
import { Api } from '../../../../utils/helpers';

const useCustomerSummary = ({ customerParameters }) => {
  const [loading, setLoading] = useState(true);
  const [summaryResult, setSummaryResult] = useState(null);

  const runReport = useCallback(async () => {
    setLoading(true);
    setSummaryResult(null);

    if (customerParameters.brandId <= 0 || customerParameters.customerId <= 0) {
      return false;
    }
    setLoading(true);

    const summaryEndpoint = `/policy/brand/${customerParameters.brandId}/customer/${customerParameters.customerId}/summary`;
    const apiResponse = await Api.get(summaryEndpoint);

    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }

    setSummaryResult(apiResponse.response);
    setLoading(false);
  }, [customerParameters]);

  useEffect(() => {
    runReport();
  }, [runReport]);

  return {
    loading,
    summaryResult
  };
};

export default useCustomerSummary;
