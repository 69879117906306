import { Menu } from '../utils/constants/enums';
import { Permissions } from '../utils/constants/permissions';
import {
  LoginPage,
  DashboardPage,
  FileManagementPage,
  MaintainImportFileDatesPage,
  ResearchPage,
  BillSearchPage,
  PayPalActivityPage,
  ReportingPage,
  ScheduledReportsPage,
  GeneratedReportsPage,
  MessageManagementPage,
  MessageListPage,
  ViewMessagePage,
  RoleManagementPage,
  UserProfilePage,
  UserManagementPage,
  UniBankAdminToolsPage,
  EBPManagerPage,
  ToolsHomePage,
  StyleGuidePage,
  NotFoundPage,
  NotAuthorizedPage,
  OutboundPage,
  DevToolsHomePage,
  ApiTestPage,
  FileUploadTestPage,
  AuditReportPage,
  TransactionManagementPage,
  FileImportPage,
  FileImportMonitorPage,
  CustomerManagementPage
} from '../views';

const canViewPayPalActivity = (user) => {
  // Allow the paypal menu if we find any customers that are non-government and have a paypal id
  // Or if the permissions allow viewing non-government customers, just check for paypal id
  // (This check is in addition to the [PayPal.ACTIVITY_SEARCH] permission check)
  const includeGovt = user.permissions.includes(Permissions.PayPal.INCLUDE_GOVT_CUSTOMERS);
  const foundPayPalCustomer = user.userResources.brands.some((brand) =>
    brand.customers.some(
      (cust) =>
        (includeGovt || cust.businessType.toLowerCase() !== 'government') && cust.hasPayPalId
    )
  );
  return foundPayPalCustomer;
};

const canViewBillSearch = (user) => {
  // Allow the 'Bill Search' menu if we find any customers & transactions with bill search enabled
  // (This check is in addition to the [Research.BILL_SEARCH] permission check)
  return user.userResources.brands.some((brand) =>
    brand.customers.some(
      (cust) => cust.billSearchEnabled && cust.transactions.some((tx) => tx.billSearchEnabled)
    )
  );
};

const canViewPaymentSearch = (user) => {
  // Allow the 'Research / Payment Search' menu if we find any customers with payment search enabled
  // (This check is in addition to the [Research.SEARCH] permission check)
  return user.userResources.brands.some((brand) =>
    brand.customers.some((cust) => cust.paymentSearchEnabled)
  );
};

export const routeMapping = [
  {
    path: '/',
    requiredPermissions: [],
    element: <DashboardPage />,
    iconClass: 'icofont-home',
    text: 'Home',
    menu: Menu.SIDEBAR
  },
  {
    path: '/login',
    requiredPermissions: [],
    element: <LoginPage />,
    menu: Menu.NONE
  },
  {
    path: '/manage-my-team',
    requiredPermissions: [Permissions.User.MANAGE],
    element: <UserManagementPage />,
    iconClass: 'icofont-users-alt-5',
    text: 'Manage My Team',
    menu: Menu.SIDEBAR
  },
  {
    path: '/manage-roles',
    requiredPermissions: [Permissions.Admin.ROLES],
    element: <RoleManagementPage />,
    iconClass: 'icofont-ui-lock',
    text: 'Manage Roles',
    menu: Menu.SIDEBAR
  },
  {
    path: '/manage-my-files',
    requiredPermissions: [Permissions.Admin.FILES],
    element: <FileManagementPage />,
    iconClass: 'icofont-files-stack',
    text: 'Manage My Files',
    menu: Menu.SIDEBAR
  },
  {
    path: '/files/maintain-import-file-dates',
    requiredPermissions: [Permissions.Admin.FILES],
    element: <MaintainImportFileDatesPage />,
    iconClass: 'icofont-files-stack',
    text: 'Maintain Import File Dates'
  },
  {
    path: '/files/file-import',
    requiredPermissions: [Permissions.Admin.FILES],
    element: <FileImportPage />,
    iconClass: 'icofont-cloud-upload',
    text: 'Import Files',
    menu: Menu.NONE
  },
  {
    path: '/files/file-import-monitor',
    requiredPermissions: [Permissions.Admin.FILES],
    element: <FileImportMonitorPage />,
    iconClass: 'icofont-heart-beat',
    text: 'Import File Monitor',
    menu: Menu.NONE
  },
  {
    path: '/outbound/:target',
    requiredPermissions: [],
    element: <OutboundPage />,
    iconClass: 'icofont-search-document',
    text: 'Outbound',
    menu: Menu.NONE
  },
  {
    path: '/research',
    requiredPermissions: [Permissions.Research.SEARCH],
    element: <ResearchPage />,
    iconClass: 'icofont-search-document',
    text: 'Research',
    menu: Menu.SIDEBAR,
    visibleCheckFunction: canViewPaymentSearch
  },
  {
    path: '/bill-search',
    requiredPermissions: [Permissions.Research.BILL_SEARCH],
    element: <BillSearchPage />,
    iconClass: 'icofont-search-user',
    text: 'Bill Search',
    menu: Menu.SIDEBAR,
    visibleCheckFunction: canViewBillSearch
  },
  {
    path: '/paypal-activity',
    requiredPermissions: [Permissions.PayPal.ACTIVITY_SEARCH],
    element: <PayPalActivityPage />,
    iconClass: 'icofont-brand-paypal',
    text: 'PayPal Activity',
    menu: Menu.SIDEBAR,
    visibleCheckFunction: canViewPayPalActivity
  },
  {
    path: '/reporting',
    requiresAnyInPermissionGroup: Permissions.Reports,
    requiredPermissions: [],
    element: <ReportingPage />,
    iconClass: 'icofont-papers',
    text: 'View Reports',
    menu: Menu.SIDEBAR
  },
  {
    path: '/scheduled-reports',
    requiresAnyInPermissionGroup: Permissions.Reports,
    requiredPermissions: [],
    element: <ScheduledReportsPage />,
    iconClass: 'icofont-ui-calendar',
    text: 'Scheduled Reports',
    menu: Menu.SIDEBAR
  },
  {
    path: '/view-reports/:reportId',
    requiresAnyInPermissionGroup: Permissions.Reports,
    requiredPermissions: [],
    element: <GeneratedReportsPage />,
    menu: Menu.NONE
  },
  {
    path: '/view-reports/:reportId/:generatedId',
    requiresAnyInPermissionGroup: Permissions.Reports,
    requiredPermissions: [],
    element: <GeneratedReportsPage />,
    menu: Menu.NONE
  },
  {
    path: '/unibank-admin-tools',
    requiresAnyInPermissionGroup: Permissions.AdminTools,
    requiredPermissions: [],
    element: <UniBankAdminToolsPage />,
    iconClass: 'icofont-tools-alt-2',
    text: 'UniBank Admin Tools',
    menu: Menu.SIDEBAR
  },
  {
    path: '/ebp-manager',
    requiredPermissions: [Permissions.Ebp.DASHBOARD],
    element: <EBPManagerPage />,
    iconClass: 'icofont-pay',
    text: 'EBP Manager',
    menu: Menu.SIDEBAR,
    requiresEbpCustomer: true
  },
  {
    path: '/user-audit-report',
    requiredPermissions: [Permissions.AdminTools.USER_AUDIT_REPORT],
    element: <AuditReportPage />,
    iconClass: 'icofont-papers',
    text: 'Audit Reports',
    menu: Menu.SIDEBAR
  },
  {
    path: '/manage-messages',
    requiredPermissions: [Permissions.Admin.MESSAGES],
    element: <MessageManagementPage />,
    iconClass: 'icofont-ui-message',
    text: 'Manage Messages',
    menu: Menu.SIDEBAR
  },
  {
    path: '/messages',
    requiredPermissions: [],
    element: <MessageListPage />,
    menu: Menu.NONE
  },
  {
    path: '/messages/:messageId',
    requiredPermissions: [],
    element: <ViewMessagePage />,
    menu: Menu.NONE
  },
  {
    path: '/user-profile',
    requiredPermissions: [],
    element: <UserProfilePage />,
    text: 'Profile',
    menu: Menu.PROFILE
  },
  {
    path: '/not-authorized',
    requiredPermissions: [],
    element: <NotAuthorizedPage />,
    menu: Menu.NONE
  },
  {
    path: '/transactions',
    requiredPermissions: [Permissions.AdminTools.TRANSACTION_SUMMARY],
    element: <TransactionManagementPage />,
    iconClass: 'icofont-settings',
    text: 'Transaction Summary',
    menu: Menu.SIDEBAR
  },
  {
    path: '/customers',
    requiredPermissions: [Permissions.AdminTools.MANAGE_CUSTOMERS],
    element: <CustomerManagementPage />,
    iconClass: 'icofont-briefcase',
    text: 'Manage Customers',
    menu: Menu.SIDEBAR //TODO - set to Menu.SIDEBAR when ready to show the customer management section
  },
  //TOOLS & UTILITIES
  {
    path: '/tools',
    requiredPermissions: [],
    element: <ToolsHomePage />,
    menu: Menu.NONE
  },
  {
    path: '/tools/style-guide',
    requiredPermissions: [],
    element: <StyleGuidePage />,
    menu: Menu.NONE
  },
  //DEV TOOLS
  {
    path: '/dev-tools',
    requiredPermissions: [],
    element: <DevToolsHomePage />,
    menu: Menu.NONE
  },
  {
    path: '/dev-tools/api',
    requiredPermissions: [],
    element: <ApiTestPage />,
    menu: Menu.NONE
  },
  {
    path: '/dev-tools/file-upload',
    requiredPermissions: [],
    element: <FileUploadTestPage />,
    menu: Menu.NONE
  },
  {
    path: '*',
    requiredPermissions: [],
    element: <NotFoundPage />,
    menu: Menu.NONE
  }
];
