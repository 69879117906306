import { DefaultLayout as Layout } from '../../../layouts';
import { PayPalActivitySearch } from './PayPalActivitySearch/PayPalActivitySearch';

export const PayPalActivityPage = () => {
  return (
    <Layout>
      <PayPalActivitySearch />
    </Layout>
  );
};
