import PropTypes from 'prop-types';
import { HelpTip } from '../../../../components/ui';
import { ReportDateRangeType, RecurringDaySelectType } from '../../../../utils/constants/enums';
import { DayOfTheWeekSelect, RecurringDaySelect } from '../../../../components/ui';
import styles from './RecurrenceOptions.module.css';
import { Helpers } from '../../../../utils/helpers';

export const RecurrenceOptions = ({
  visible,
  dateRangeType,
  recurrenceParameters,
  parameterChangeHandler,
  errors,
  clearErrorForKeysCallback
}) => {
  if (!visible) return;

  const paramChangeByKey = (key, value) => {
    switch (key) {
      case 'startDateUtc':
      case 'endDateUtc':
        clearErrorForKeysCallback([key, 'runDateOrder']);
        break;
      default:
        clearErrorForKeysCallback([key]);
        break;
    }

    let config = { ...recurrenceParameters };
    config[key] = value;
    parameterChangeHandler(config);
  };

  const recurringDaySelectChange = (values) => {
    let config = { ...recurrenceParameters };
    config.recurrenceType = values.recurrenceType;
    config.dayNumber = values.dayNumber;
    config.frequencyInterval = values.frequencyInterval;
    config.dayOfWeek = values.dayOfWeek;
    config.monthNumber = values.monthNumber;
    parameterChangeHandler(config);
  };

  return (
    <>
      {dateRangeType === ReportDateRangeType.YESTERDAY && (
        <>
          <div>
            <label>Select which days you would like to receive the report:</label>
          </div>
          <DayOfTheWeekSelect
            className="mt-2"
            multiSelect={true}
            abbreviatedDayNames={true}
            value={recurrenceParameters.daysOfWeek}
            onChange={(value) => paramChangeByKey('daysOfWeek', value)}
          />
          {errors.daysOfWeek && (
            <div className="alert-danger mt-1 p-2">You must select at least one day.</div>
          )}
          {recurrenceParameters.daysOfWeek == 62 && (
            <HelpTip className="mt-2">
              If you want only want to receive reports on weekdays, consider selecting the Business
              Daily date range option above. Business Daily reports generated on Monday will include
              the entire preceding weekend (Friday-Sunday).
            </HelpTip>
          )}
        </>
      )}

      {dateRangeType === ReportDateRangeType.BUSINESS_DAILY && (
        <>
          <div>
            <label>Select which weekdays you would like to receive the report:</label>
          </div>
          <DayOfTheWeekSelect
            className="mt-2"
            multiSelect={true}
            weekdayOnly={true}
            abbreviatedDayNames={true}
            value={recurrenceParameters.daysOfWeek}
            onChange={(value) => paramChangeByKey('daysOfWeek', value)}
          />
          <HelpTip className="mt-2">
            Business Daily reports generated on Monday will include the entire preceding weekend
            (Friday-Sunday). All other days will report on the previous day.
          </HelpTip>
        </>
      )}

      {dateRangeType === ReportDateRangeType.WEEKLY && (
        <>
          <div>
            <label>Run report every week on the following day:</label>
          </div>
          <DayOfTheWeekSelect
            className="mt-2"
            multiSelect={false}
            weekdayOnly={false}
            abbreviatedDayNames={true}
            value={recurrenceParameters.dayOfWeek}
            onChange={(value) => paramChangeByKey('dayOfWeek', value)}
          />
        </>
      )}

      {dateRangeType === ReportDateRangeType.PREVIOUS_7_DAYS && (
        <>
          <div>
            <label>Run report every week on the following day:</label>
          </div>
          <DayOfTheWeekSelect
            className="mt-2"
            multiSelect={false}
            weekdayOnly={false}
            abbreviatedDayNames={true}
            value={recurrenceParameters.dayOfWeek}
            onChange={(value) => paramChangeByKey('dayOfWeek', value)}
          />
        </>
      )}

      {dateRangeType === ReportDateRangeType.PREVIOUS_MONTH && (
        <>
          <div>
            <label>Run this report...</label>
          </div>
          <RecurringDaySelect
            className="mt-2"
            recurringDaySelectType={RecurringDaySelectType.MONTH}
            recurrenceType={recurrenceParameters.recurrenceType}
            dayNumber={recurrenceParameters.dayNumber}
            frequencyInterval={recurrenceParameters.frequencyInterval}
            dayOfWeek={recurrenceParameters.dayOfWeek}
            monthNumber={recurrenceParameters.monthNumber}
            onChange={(values) => recurringDaySelectChange(values)}
          />
        </>
      )}

      {dateRangeType === ReportDateRangeType.PREVIOUS_QUARTER && (
        <>
          <div>
            <label>Run this report...</label>
          </div>
          <RecurringDaySelect
            className="mt-2"
            recurringDaySelectType={RecurringDaySelectType.QUARTER}
            recurrenceType={recurrenceParameters.recurrenceType}
            dayNumber={recurrenceParameters.dayNumber}
            frequencyInterval={recurrenceParameters.frequencyInterval}
            dayOfWeek={recurrenceParameters.dayOfWeek}
            monthNumber={recurrenceParameters.monthNumber}
            onChange={(values) => recurringDaySelectChange(values)}
          />
        </>
      )}

      {dateRangeType === ReportDateRangeType.PREVIOUS_YEAR && (
        <>
          <div>
            <label>Run this report...</label>
          </div>
          <RecurringDaySelect
            className="mt-2"
            recurringDaySelectType={RecurringDaySelectType.YEAR}
            recurrenceType={recurrenceParameters.recurrenceType}
            dayNumber={recurrenceParameters.dayNumber}
            frequencyInterval={recurrenceParameters.frequencyInterval}
            dayOfWeek={recurrenceParameters.dayOfWeek}
            monthNumber={recurrenceParameters.monthNumber}
            onChange={(values) => recurringDaySelectChange(values)}
          />
        </>
      )}

      {dateRangeType === ReportDateRangeType.PREVIOUS_FISCAL_YEAR && (
        <>
          <RecurringDaySelect
            className="mt-2"
            recurringDaySelectType={RecurringDaySelectType.YEAR}
            recurrenceType={recurrenceParameters.recurrenceType}
            dayNumber={recurrenceParameters.dayNumber}
            frequencyInterval={recurrenceParameters.frequencyInterval}
            dayOfWeek={recurrenceParameters.dayOfWeek}
            monthNumber={recurrenceParameters.monthNumber}
            onChange={(values) => recurringDaySelectChange(values)}
          />
        </>
      )}

      <div className="mt-4">
        <label>Limit report generation to the following date range:</label>
      </div>
      <div className="mt-2 d-flex align-items-center">
        <label htmlFor="reportStartDateUtc" className={styles.dateLabel}>
          From
        </label>
        <input
          type="date"
          id="reportStartDateUtc"
          className={'form-control ' + styles.dateSelect}
          value={Helpers.toInputFieldDate(recurrenceParameters.startDateUtc)}
          onChange={(e) => paramChangeByKey('startDateUtc', e.target.value)}
        />

        <label htmlFor="reportEndDateUtc" className={styles.dateLabel}>
          until
        </label>
        <input
          type="date"
          id="reportEndDateUtc"
          className={'form-control ' + styles.dateSelect}
          value={Helpers.toInputFieldDate(recurrenceParameters.endDateUtc)}
          onChange={(e) => paramChangeByKey('endDateUtc', e.target.value)}
        />
      </div>
      {errors.endDateUtc && (
        <div className="alert-danger mt-1 p-2">End date must be tomorrow or later.</div>
      )}
      {errors.runDateOrder && (
        <div className="alert-danger mt-1 p-2">From date cannot be after end date.</div>
      )}
      <HelpTip className="mt-2">
        If you omit the start date, the report generation will begin at the next scheduled
        occurrence. If you omit the end date, report generation will continue indefinitely.
      </HelpTip>
    </>
  );
};

export default RecurrenceOptions;

RecurrenceOptions.propTypes = {
  visible: PropTypes.bool,
  dateRangeType: PropTypes.number,
  clearErrorForKeysCallback: PropTypes.func,
  recurrenceParameters: PropTypes.shape({
    daysOfWeek: PropTypes.number,
    dayOfWeek: PropTypes.number,
    dayNumber: PropTypes.number,
    frequencyInterval: PropTypes.number,
    monthNumber: PropTypes.number
  })
};
