import { DefaultLayout as Layout } from '../../../layouts';
import { UserManager } from './UserManager/UserManager';

export const UserManagementPage = () => {
  return (
    <Layout>
      <UserManager />
    </Layout>
  );
};
