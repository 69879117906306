import React, { useEffect, createContext, useContext, useCallback, useReducer } from 'react';
import { Api } from '../../utils/helpers';
import { LoadingOverlay } from '../../components/ui';
import mainReducer from '../reducers/mainReducer';
import * as UserActions from '../reducers/userReducer';
import * as SettingsActions from '../reducers/settingsReducer';
import { MfaNotificationMethod } from '../../utils/constants/enums';
import { useInterval } from '../../utils/hooks';
export const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

const defaultActiveBrand = {
  id: 0,
  theme: 'theme-UniBank'
};

const defaultUserResources = {
  roles: [],
  brands: []
};

const defaultUserState = {
  isLoading: true,
  isLoggingOut: false,
  isAuthenticated: false,
  userId: null,
  userName: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  emailAddress: '',
  mfaNotificationMethod: MfaNotificationMethod.EMAIL,
  tokenExpires: null,
  tokenRefresh: null,
  activeBrand: defaultActiveBrand,
  roles: [],
  permissions: [],
  resources: {
    brands: []
  },
  userResources: defaultUserResources
};

const defaultUserSettings = {
  dashboardTileLayout: ''
};

export const AuthProvider = ({ children }) => {
  const initialState = {
    user: defaultUserState,
    settings: defaultUserSettings
  };
  const [state, dispatch] = useReducer(mainReducer, initialState);

  const getUserSettings = useCallback(async () => {
    try {
      const apiResponse = await Api.get('/policy/UserSettings/me');
      if (apiResponse.status.statusCode !== 200) {
        dispatch(SettingsActions.setUserSettings(defaultUserSettings));
        return;
      }
      dispatch(SettingsActions.setUserSettings(apiResponse.response));
    } catch (e) {
      dispatch(SettingsActions.setUserSettings(defaultUserSettings));
    }
  }, []);

  const getUserResources = useCallback(async () => {
    try {
      const apiResponse = await Api.get('/policy/UserResources/me');
      if (apiResponse.status.statusCode !== 200) {
        dispatch(UserActions.setUserResources(defaultUserResources));
        return;
      }
      const data = apiResponse.response;
      if (data.brands.length > 0) {
        //check if they have a brand id in storage, otherwise set to the first brand
        let defaultBrandId = parseInt(localStorage.getItem('activeBrandId')) ?? data.brands[0].id;

        if (!data.brands.some((brand) => brand.id === defaultBrandId)) {
          defaultBrandId = data.brands[0].id;
        }
        const defaultBrand = data.brands.find((brand) => brand.id === defaultBrandId);
        dispatch(UserActions.setActiveBrand(defaultBrand));
      }
      dispatch(UserActions.setUserResources(data));
    } catch (e) {
      dispatch(UserActions.setUserResources(defaultUserResources));
    }
  }, []);

  const getUser = useCallback(
    async (isRefresh = false) => {
      //if just refreshing the user state, no need to show the loading screen
      if (!isRefresh) {
        dispatch(UserActions.setIsLoading(true));
      }

      try {
        const apiResponse = await Api.get('/auth/user');
        if (apiResponse.status.statusCode !== 200) {
          console.error('api error occurred');
          dispatch(UserActions.setUser(defaultUserState));
          return;
        }

        const usr = apiResponse.response;
        if (usr.tokenExpires) {
          usr.tokenExpires = new Date(usr.tokenExpires);
        }
        if (usr.tokenRefresh) {
          usr.tokenRefresh = new Date(usr.tokenRefresh);
        }

        dispatch(UserActions.setUser(usr));

        if (usr.isAuthenticated) {
          await getUserResources();
          if (!isRefresh) {
            //only need to load settings once, so skip if refreshing
            await getUserSettings();
          }
        }
      } catch {
        dispatch(UserActions.setUser(defaultUserState));
      } finally {
        dispatch(UserActions.setIsLoading(false));
      }
    },
    [getUserResources, getUserSettings]
  );

  useEffect(() => {
    getUser();
  }, [getUser]);

  //check for updated policy every 30 seconds
  useInterval(() => {
    getUser(true);
  }, 30000);

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {state.user.isLoggingOut && (
        <LoadingOverlay>
          <h4 className="mt-3">Please wait, we&apos;re logging you out.</h4>
          <form id="logoutform" action="#" method="post">
            <input
              type="hidden"
              name="post_logout_redirect_uri"
              value={`${window.location.protocol}//${window.location.host}`}
            />
          </form>
        </LoadingOverlay>
      )}
      {children}
    </AuthContext.Provider>
  );
};
